import {gql} from "@apollo/client";


export const BLOCK_PRODUCT_FRAGMENT = gql`
    fragment BlockProductFragment on BlockProduct {
        id
        block_id
        product_id
        position
    } 
`;

export const UPSERT_BLOCK_PRODUCT = gql`
    ${BLOCK_PRODUCT_FRAGMENT}
    mutation ($id: ID, $block_id: ID, $product_id: ID)  {
        upsertBlockProduct(id: $id, block_id: $block_id, product_id: $product_id) {
            ...BlockProductFragment
        }
    }
`

export const DELETE_BLOCK_PRODUCT = gql`
    ${BLOCK_PRODUCT_FRAGMENT}
    mutation ($id: ID!) {
        deleteBlockProduct(id: $id) {
            ...BlockProductFragment
        }
    }
`