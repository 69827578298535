import BasketDrawerContext from "../contexts/BasketDrawerContext";
import React, {useContext} from "react";
import {ShoppingBag, X} from "react-feather";
import Drawer from "rc-drawer";
import {formatPrice} from "../../../app/helpers";
import {route, trans, widget} from "../../modulesCompiler";
import BasketContext from "../contexts/BasketContext";
import {Link} from "react-router-dom";
import ProductForm from "./ProductForm";
import {useSwipeable} from "react-swipeable";


function BasketDrawer()    {
    const routeCheckout = route("orders.checkout");
    const currency = trans("currency");
    const transFinishOrder = trans("zavrsite porudzbinu");

    const {open, setOpen} = useContext(BasketDrawerContext);
    const {basket, total, remove} = useContext(BasketContext);
    const swipeRightToClose = useSwipeable({
        onSwipedRight: (eventData) => setOpen(false),
    });

    return <Drawer
        open={open}
        onClose={() => setOpen(false)}
        handler={null}
        level={null} width="340px" placement="right">

        <div {...swipeRightToClose} className="d-flex h-100 flex-column justify-content-between">
            <div className="flex-grow-1" style={{overflowY: "auto"}}>
                <div className="d-flex justify-content-between align-items-center p-2">
                    <div className="fw-bold h4 mb-0">{trans("korpa")}</div>
                    <button onClick={() => setOpen(false)} className="btn btn-link">
                        <X />
                    </button>
                </div>

                <div className="d-flex justify-content-between p-3 bg-light">
                    <div> {trans("ukupno")}: </div>
                    <div className="fw-bold"> {formatPrice(total())} {trans("currency")} </div>
                </div>

                <div>
                    {basket.length === 0
                        ? <div className="h5 text-center mt-4">
                            <ShoppingBag size={29} />
                            <div className="mt-2 oswald">
                                {trans("korpa je prazna")}
                            </div>
                        </div>
                        : <div className="mt-3">
                            {basket.map(item => {
                                const product = item.product;
                                return <div key={item.id} className="pb-3 mb-3 text-center border-bottom">
                                    <div className="d-flex justify-content-center">
                                        <div style={{width: 140}}>
                                            <div className="bcg_image" style={{backgroundImage: `url(${widget("storage.image", product.image)})`}} />
                                        </div>
                                    </div>

                                    <div className="mt-2 flex-grow-1 fw-bold oswald mb-2">
                                        <div>  {product.name}  </div>
                                        <div>
                                            {formatPrice(product.process_price)} x {item.qty} = {formatPrice(product.process_price * item.qty)} {currency}
                                        </div>
                                    </div>

                                    <ProductForm item={product} basket={item} />

                                    <div className="mt-2 text-center">
                                        <button onClick={() => remove(item.id)} className="btn btn-light btn-sm shadow-sm">
                                            <X /> {trans("uklonite")}
                                        </button>
                                    </div>
                                </div>
                            })}
                        </div>}
                </div>
            </div>

            {basket.length > 0 &&
            <div className="mb-2 ms-3 me-3 d-flex">
                <Link onClick={() => setOpen(false)} to={routeCheckout}
                      className="btn btn-secondary pt-2 pb-2 fw-bold w-100 shadow-lg flex-grow-1 me-1">
                    {transFinishOrder}
                </Link>
                <button onClick={() => setOpen(false)}
                        className="btn btn-light">
                    <X size={20} strokeWidth={3} />
                </button>
            </div>}
        </div>

    </Drawer>
}
export default BasketDrawer;