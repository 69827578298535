import React from "react";
import {route, trans, widget} from "../../../modulesCompiler";
import {useQuery} from "@apollo/client";
import {GET_BLOG, GET_BLOG_QUERY} from "../../graphql/blog";
import Loader from "../../../../app/Loader";
import BlogRender from "./BlogRender";


function Blog(props) {
    const id = props.computedMatch?.params?.id;
    const {data, loading} = useQuery(GET_BLOG, {
        variables: {id: id}
    });
    const blogBreadcrumbs = {trans: trans("blog"), route: route("blog.blogs")};

    if(loading) return <Loader />;
    const blog = data[GET_BLOG_QUERY];

    if(!blog)    {
        return <>
            {widget("public.Breadcrumbs", {links: [blogBreadcrumbs]})}
            {widget("public.Error404")}
        </>
    }

    widget("seo.seoTitle", blog.title);
    widget("seo.seoKeywords", blog.title);
    widget("seo.seoDescription", blog.title);

    return <BlogRender blog={blog} />
}

export default Blog;
