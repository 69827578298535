import React, {useState} from "react";
import {useQuery} from "@apollo/client";
import Loader from "../../../../app/Loader";
import GraphQLErrorMessage from "../../../../app/GraphQLErrorMessage";
import {GET_BLOGS, GET_BLOGS_QUERY} from "../../graphql/blog";
import {route, trans, widget} from "../../../modulesCompiler";
import {Link, useHistory} from "react-router-dom";
import {dateTime} from "../../../../app/helpers";
import {urlQueryOptions} from "../../../../app/config";
import useGetSearchParams from "../../../../app/useGetSearchParams";
import {Calendar} from "react-feather";



function Blogs() {
    const history = useHistory();
    const searchParams = ["page"];
    const [page] = useGetSearchParams(searchParams);

    const [search] = useState(undefined);
    const { data, loading, error, variables, refetch, previousData } =  useQuery(GET_BLOGS, {
        variables: {first: 5, page: page, search},
        notifyOnNetworkStatusChange: true
    });

    if(loading && !previousData) return <Loader />
    if(error)   return <GraphQLErrorMessage error={error} />
    let holder = data || previousData;
    const blogs = holder ? holder[GET_BLOGS_QUERY].data : [];
    const paginatorInfo = holder ? holder[GET_BLOGS_QUERY].paginatorInfo : {};

    const url = (field, value) => {
        let params = [];
        for(let i=0; i<searchParams.length; i++) {
            if(!urlQueryOptions[searchParams[i]]) continue;
            if(searchParams[i] !== field)   {
                if(urlQueryOptions[searchParams[i]].default === variables[searchParams[i]])  {
                    continue;
                }
                params.push(`${searchParams[i]}=${variables[searchParams[i]]}`);
            } else  {
                if(urlQueryOptions[searchParams[i]].default === value)  {
                    continue;
                }
                if(urlQueryOptions[searchParams[i]].array)  {
                    let values = variables[searchParams[i]];
                    if(values)   {
                        const valArr = values.split(",");
                        if(valArr.includes(value))  {
                            const mapped = valArr.filter(item => item !== value);
                            if(mapped.length === 0) {
                                continue;
                            }
                            values = mapped.join(",");
                            params.push(`${searchParams[i]}=${values}`);
                        } else  {
                            params.push(`${searchParams[i]}=${values + "," + value}`);
                        }
                    } else  {
                        params.push(`${searchParams[i]}=${value}`);
                    }
                } else  {
                    params.push(`${searchParams[i]}=${value}`);
                }
            }
        }
        let str = "?";
        if(params.length > 0)   {
            str += params.join("&");
        }
        history.push(str);
    }

    return (<>
        {widget("public.Breadcrumbs", {links: [{trans: trans("blog")}]})}

            <div className="container mb-6">

                <div className="mb-3 bg-light p-3 row ms-1 ms-md-0 me-1 me-md-0">
                    <div className="col-md-6 mb-2 mb-md-0">
                        <input value={variables.search || ""} name="search"
                               onChange={(e) => refetch({search: e.target.value || undefined, page: 1})}
                               type="text" placeholder="Pretraga..." className="form-control" />
                    </div>
                    <div className="col-md-6">
                        {blogs.length > 0 &&
                        <div className="d-flex flex-column flex-md-row align-items-center justify-content-end">
                            <div className="me-md-2">
                                {paginatorInfo.total} {trans("ukupno")}
                            </div>
                            {widget("public.Pagination", {paginatorInfo, url})}
                        </div>}
                    </div>
                </div>

                {blogs.length === 0 &&
                <div className="p-5 text-center fw-bold"> Prazna pretraga </div>}

                {blogs.map(item => {
                    return <div key={item.id} className="row mb-3 p-3 border-bottom">
                        <div className="col-md-3">
                            <img src={widget("storage.image", item.image)} className="img-fluid" alt={item.title} />
                        </div>

                        <div className="col-md-9">
                            <Link to={route("blog.blog", item)}>
                                <h1 className="oswald h2 mb-3">{item.title}</h1>
                            </Link>
                            <div className="prewrap mb-3">{item.description}</div>
                            <div className="text-black-50 mb-4">
                               <Calendar /> {dateTime(item.created_at)}
                            </div>
                            <div>
                                <Link to={route("blog.blog", item)} className="btn btn-primary fw-bold">
                                    {trans("detaljnije")}
                                </Link>
                            </div>
                        </div>
                    </div>
                })}

                <div className="bg-light p-3">
                    {blogs.length > 0 &&
                    <div className="d-flex flex-column flex-md-row align-items-center justify-content-end">
                        <div className="me-md-2">
                            {paginatorInfo.total} {trans("ukupno")}
                        </div>
                        {widget("public.Pagination", {paginatorInfo, url})}
                    </div>}
                </div>

            </div>
    </>
    )
}

export default Blogs;
