import {useQuery} from "@apollo/client";
import {GET_ORDERS, GET_ORDERS_QUERY} from "../../graphql/order";
import Loader from "../../../../app/Loader";
import GraphQLErrorMessage from "../../../../app/GraphQLErrorMessage";
import {widget} from "../../../modulesCompiler";
import OrderItem from "./OrderItem";


function Orders() {
    const {data, loading, error, refetch, previousData, variables} = useQuery(GET_ORDERS, {
        variables: {first: 20, page: 1},
        //pollInterval: 5000,
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only'
    });
    if(loading && !previousData) return <Loader />
    if(error)   return <GraphQLErrorMessage error={error} />
    let holder = data || previousData;
    const orders = holder ? holder[GET_ORDERS_QUERY].data : [];
    const paginatorInfo = holder ? holder[GET_ORDERS_QUERY].paginatorInfo : {};

    const filterStatus = (e) => {
        let val = e.target.value;
        if(val === "0")  val = undefined;
        if(val === "1")  val = true;
        if(val === "2")  val = null;
        refetch({status:  val, page: 1});
    }

    let status = "";
    if(variables.status === true) status = 1;
    if(variables.status === null) status = 2;

    return (
        <div className="container">
            <div className="mb-3 row">
                <div className="col-md-6">
                    <input value={variables.search || ""} name="search"
                        //onChange={(e) => setSearch(e.target.value)}
                           onChange={(e) => refetch({search: e.target.value || undefined, page: 1})}
                           type="text" placeholder="Šifra porudžbine, ime poručioca..." className="form-control shadow-sm" />
                </div>

                {/*<div className="col-md-3 text-end">
                    <select onChange={filterStatus}
                            className="form-control shadow-sm" value={status || ""}>
                        <option value={0}>sve</option>
                        <option value={1}>obradjene</option>
                        <option value={2}>neobradjene</option>
                    </select>
                </div>*/}
                <div className="col-md-6 text-end">
                    {orders.length > 0 &&
                    widget("cms.Pagination", {paginatorInfo: paginatorInfo, refetch: refetch})}
                </div>
            </div>

            {orders.length === 0 &&
            <div className="p-5 text-center fw-bold"> Prazna pretraga </div>}
            {orders.map(item => {
                return <div key={item.id} className="d-flex align-items-center bg-white mb-2 shadow-sm list_item">
                    <div className="flex-grow-1">
                        <OrderItem item={item} refetch={refetch} />
                    </div>
                </div>
            })}

            <div className="mb-3 text-end">
                {orders.length > 0 && widget("cms.Pagination", {paginatorInfo, refetch})}
            </div>
        </div>
    )
}
export default Orders;