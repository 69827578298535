import {options} from "../config";
import {widget} from "../../modulesCompiler";


function LangCmsUpdateImage(props)   {

    if(options.length === 1)    {
        return (
            widget("storage.UpdateImage", {...props, item: props.values ? props.values[options[0].id] : null})
        )
    }

    return (
        options.map(item => {
            return <div key={item.short} className="mb-1 bg-light p-1 d-flex align-items-center">
                <div className="me-2">{item.label}</div>
                <div className="flex-grow-1">
                    {widget("storage.UpdateImage",
                        {...props, lang: item.id, item: props.values ? props.values[item.id] : null})}
                </div>
            </div>
        })
    )
}
export default LangCmsUpdateImage;