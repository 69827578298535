import React, {useCallback, useEffect, useState} from "react";
import { useEmblaCarousel } from "embla-carousel/react"
import {DotButton, NextButton, PrevButton} from "../../../app/EmblaNavigation";
import {route, widget} from "../../modulesCompiler";
import {Link as FLink} from "react-feather";
import {Link} from "react-router-dom";


const CategoriesCarousel = ({categories}) => {
    const [viewportRef, embla] = useEmblaCarousel({
        containScroll: "trimSnaps",
        align: "start"
    });

    const [selectedIndex, setSelectedIndex] = useState(0);
    const [scrollSnaps, setScrollSnaps] = useState([]);
    const scrollTo = useCallback((index) => embla && embla.scrollTo(index), [
        embla
    ]);

    const [prevBtnEnabled, setPrevBtnEnabled] = useState(false);
    const [nextBtnEnabled, setNextBtnEnabled] = useState(false);
    const scrollPrev = useCallback(() => embla && embla.scrollPrev(), [embla]);
    const scrollNext = useCallback(() => embla && embla.scrollNext(), [embla]);

    const onSelect = useCallback(() => {
        if (!embla) return;
        setSelectedIndex(embla.selectedScrollSnap());
        setPrevBtnEnabled(embla.canScrollPrev());
        setNextBtnEnabled(embla.canScrollNext());
    }, [embla, setSelectedIndex]);

    useEffect(() => {
        if (!embla) return;
        onSelect();
        setScrollSnaps(embla.scrollSnapList());
        embla.on("select", onSelect);
    }, [embla, setScrollSnaps, onSelect]);

    return (
        <div className="embla mb-2">
            <div className="embla__viewport" ref={viewportRef}>
                <div className="embla__container">
                    {categories.map(category => {
                        return <div className="embla__slide category_carousel ps-md-2 pe-md-2" key={category.id}>
                            <Link to={route("products.shop", category)}>
                                <div className="m-2">
                                    <div className="mb-3 position-relative image_link">
                                        <div className="bcg_image" style={{backgroundImage: `url(${widget("storage.image", category.image)})`}} />
                                        <div className="position-absolute image_link_icon">
                                            <FLink className="text-white" strokeWidth={3} />
                                        </div>
                                    </div>
                                    <h3 className="oswald text-uppercase text-center h5"> {category.name} </h3>
                                </div>
                            </Link>
                        </div>
                    })}
                </div>
            </div>

            <div className="d-none d-md-block">
                <PrevButton onClick={scrollPrev} enabled={prevBtnEnabled} />
                <NextButton onClick={scrollNext} enabled={nextBtnEnabled} />
            </div>

            <div className="embla__dots position-relative mt-3  d-md-none">
                {scrollSnaps.map((_, index) => (
                    <DotButton
                        key={index}
                        selected={index === selectedIndex}
                        onClick={() => scrollTo(index)}
                    />
                ))}
            </div>
        </div>
    );
};

export default CategoriesCarousel;