import React, {useState} from "react";
import {useMutation, useQuery} from "@apollo/client";
import Loader from "../../../../app/Loader";
import GraphQLErrorMessage from "../../../../app/GraphQLErrorMessage";
import Nestable from "react-nestable";
import {GET_FILTERS, GET_FILTERS_QUERY} from "../../graphql/filter";
import AddFilterForm from "./AddFilterForm";
import FilterItem from "./FilterItem";
import {BookOpen, FolderMinus, FolderPlus, Repeat} from "react-feather";
import {ORDER_ITEMS} from "../../../_cms/graphql/items";
import toast from "react-hot-toast";

const Filters = (props) => {
    const [collapsed, setCollapsed] = useState(true);
    const [orderItems] = useMutation(ORDER_ITEMS);
    const { data, loading, error, refetch } =  useQuery(GET_FILTERS, {
        variables: {parent: null}
    });
    if(loading) return <Loader />
    if(error)   return <GraphQLErrorMessage error={error} />
    const filters = data[GET_FILTERS_QUERY];

    const renderItem = (props) => {
        return <FilterItem {...props} refetch={refetch} />
    };

    const collapseIcons = ({ isCollapsed }) => {
        if(isCollapsed) return <span className="nestable_icon">
             <FolderPlus />
        </span>
        return <span className="nestable_icon">
            <FolderMinus />
        </span>;
    };

    const changedOrder = (items) => {
        orderItems({variables: {items: JSON.stringify(items.items), table: "filters"}}).then(response => {
            toast.success("Sacuvano!");
            refetch();
        }).catch(error => {
            toast.error(error.message);
        });
    }

    return (
        <div className="container">
            <div className="mb-3 row">
                <div className="col-md-8">
                    <AddFilterForm refetch={refetch} />
                </div>
                <div className="col-md-4">
                    <button onClick={() => setCollapsed(!collapsed)} className="btn btn-light float-end">
                        <BookOpen /> {collapsed ? "Otvorite sve" : "Zatvorite sve"}
                    </button>
                </div>
            </div>
            <Nestable
                collapsed={collapsed}
                maxDepth={2}
                onChange={changedOrder}
                renderCollapseIcon={collapseIcons}
                handler={<span className="nestable_icon" title="Prevucite da promenite raspored.">
                    <Repeat size={17} />
                </span>}
                items={filters}
                renderItem={renderItem} />
        </div>
    )
}

export default Filters;
