import {Link} from "react-router-dom";
import {route, trans, widget} from "../../modulesCompiler";
import {calculateDiscount} from "../../../app/helpers";
import React, {useEffect, useState} from "react";


function ProductRender({item, load=true}) {
    const [current, setCurrent] = useState(item);
    const [visible, setVisible] = useState(load);
    const [group] = useState(item.group || []);

    useEffect(() => {
        if(!load) return false;
        if(!visible)    {
            setVisible(true);
        }
    }, [load]);

    return (
        <div>
            {!current.active &&
            <div className="alert alert-danger">
                Proizvod je deaktiviran. <br />
                Vidite ga samo dok ste ulogovani kao admin.
            </div>}
            <div className="position-relative bg-light mb-2">
                <Link to={route("products.shopProduct", current)}>
                    {visible
                        ? <div className="bcg_image img_overlay" style={{backgroundImage: `url(${widget("storage.image", current.image)})`}}/>
                        : <div className="bcg_image img_overlay" />}
                </Link>
                <div className="product_render_tags oswald position-absolute text-white text-center fs-s"
                     style={{top: 0, right: 0, width: 50}}>
                    {calculateDiscount(current) &&
                    <div className="bg-dark pt-1 pb-1 w-100 mb-1">
                        -{calculateDiscount(current)}%
                    </div>}
                    {current.new &&
                    <div className="bg-white text-dark border border-dark w-100 mb-1">
                        {trans("novo")}
                    </div>}
                    {current.action &&
                    <div className="bg-dark pt-1 pb-1 w-100 mb-1">
                        {trans("akcija")}
                    </div>}
                </div>
            </div>

            {group.length > 0 &&
            <div className="d-flex flex-wrap">
                {group.map(gr => {
                    if(current.id === gr.id) return null;
                    return <div key={gr.id} className="mb-2 me-2 border hover_border p-1" style={{width: 40}}>
                        <div className="pointer" onClick={() => setCurrent(gr)}>
                            {visible
                                ? <div className="bcg_image" style={{backgroundImage: `url(${widget("storage.image", gr.image)})`}} />
                                : <div className="bcg_image img_overlay" />}
                        </div>
                    </div>
                })}
            </div>}

            <div className="oswald fw-bold mb-1">
                <Link to={route("products.shopProduct", current)}>
                    {current.name}
                </Link>
            </div>
            <div className="oswald">
                <span className="fs-5 me-2">{current.process_price} {trans("currency")}</span>
                {current.discount_price &&
                <del className="text-black-50 fs-s">{current.price} {trans("currency")}</del>}
            </div>
        </div>

    )
}

export default ProductRender;