import {route, trans, widget} from "../../../modulesCompiler";
import React from "react";


function PolitikaKolacica() {
    const lang = widget("lang.useGetLang", null);

    return (
        <>
            {widget("public.Breadcrumbs", {links: [{trans: trans("politika privatnosti")}]})}
            <div className="container mb-6">
                <h1 className="oswald fw-bold text-uppercase h3">{trans("politika kolacica")}</h1>
                <br/>
                {lang === 0 &&
                <>
                    <h5 className="oswald fw-bold">1. OBAVEŠTENJE O KOLAČIĆIMA</h5>

                    Kako bi HORUS Internet prodavnica radila ispravno, te kako bismo bili u stanju vršiti unapređenja stranice, u svrhu poboljšavanja iskustva pretraživanja Potrošača, ova stranica mora na Vaš računar spremiti malu količinu informacija (kolačići, Cookies).
                    <br/><br/>
                    U skladu sa važećim Zakonom o zaštiti podataka o ličnosti, dužni smo da obavestimo Potrošače o načinu korišćenja kolačića.
                    <br/><br/>
                    Korišćenjem HORUS Internet prodavnice pristajete na upotrebu kolačića koji su nužni, u cilju obezbeđivanja pojedinih tehničkih karakteristika i pružanja Potrošačima pozitivnog korisničkog iskustva. Za korišćenje kolačića, koji nisu nužni za funkcionisanje Internet stranice, ali poboljšavaju uslugu, zatražićemo saglasnost Potrošača. Ako Potrošač ne da svoju saglasnost za korišćenje određenih kolačića - i dalje može pregledati stranicu, ali neke mogućnosti neće biti dostupne.
                    <br/><br/>
                    Korišćenjem HORUS Internet prodavnica kao Potrošač pristajete na korišćenje kolačića u skladu sa ovom politikom kolačića.
                    <br/><br/>
                    Horus Wear doo može ažurirati ovu Politiku kolačića u bilo kom trenutku.
                    <br/><br/>
                    <h5 className="oswald fw-bold">2. ŠTA JE KOLAČIĆ?</h5>
                    Kolačići su tekstualne datoteke, koji sadrže male količine informacija koje se učitavaju na uređaj pri poseti određenog veb-sajta. Kolačići se potom šalju nazad do izvornih internet domena prilikom kasnijih poseta tom domenu. Ovo omogućava veb-sajtu da prikaže informacije prilagođene ličnim potrebama korisnika, jer pomoću kolačića veb-sajt pamti radnje i podešavanja tokom određenog razdoblja. Svaki put kada korisnik otvori neku internet stranicu, veb-server može pročitati vrednosti koje je ranije spremio u kolačićima.
                    <br/><br/>
                    Većina veb-sajtova sadrži elemente iz višestrukih internet domena, tako da kada posetite veb-sajt internet pretraživač može da primi kolačiće iz više izvora.
                    <br/><br/>
                    Kolačići su korisni zato što omogućavaju veb-sajtovima da prepoznaju uređaj određenog korisnika. Kolačići omogućavaju korisnicima da efikasno obavljaju navigaciju između stranica, zapamte prioritete i generalno unaprede korisničko iskustvo. Oni takođe mogu da se koriste u cilju prilagođavanja reklamiranja interesovanjima određenog korisnika, kroz praćenje pretraživanja i drugih veb-sajtova.
                    <br/><br/>
                    Kolačići mogu čuvati širok dijapazon informacija uključujući podatke (kao što su: ime i prezime, e-mail adresa, IP adresa). Ipak, ovi podaci biće korišćeni putem kolačića samo ukoliko postoji saglasnost korisnika – veb-sajtovi ne mogu dobiti pristup informacijama, niti mogu pristupiti drugim datotekama na uređajima korisnika, ukoliko za to nije data prethodna saglasnost. Zadate aktivnosti čuvanja i slanja kolačića nisu vidljive korisnicima. Ipak, korisnik može da promeni podešavanja internet pretraživača, u cilju odobravanja ili odbijanja zahteva za čuvanje kolačića, automatskog brisanja sačuvanih kolačića kada se internet pretraživač ugasi, i sl. Takođe, prethodno pomenutu saglasnost za korišćenje kolačića korisnik u svakom trenutku može povući.
                    <br/><br/>
                    Kolačići, koje koristi HORUS veb-sajt, ne mogu da se koriste u cilju pokretanja programa ili isporučivanja virusa na računaru korisnika.
                    <br/><br/>
                    Postoje različite vrste kolačića, a obično se razvrstavaju po trajanju, domenu kome pripadaju i nameni:
                    <br/><br/>
                    Privremeni (sesijski) kolačići se brišu nakon što korisnik zatvori svoj pretraživač. Trajni kolačići ostaju pohranjeni na korisnikovom uređaju na određeno vreme.
                    <br/><br/>
                    Kolačići prve strane su postavljeni od strane server domena koji je posećen. Kolačići treće strane su postavljeni sa domena različitog od onog koju je korisnik posetio.
                    <br/><br/>
                    Prema nameni kolačići mogu biti nužni ako potpomažu osnovne tehničke karakteristike veb-sajta, funkcionalni za poboljšanje korisničkog iskustva, analitički za analizu upotrebe stranica i marketinški za praćenje interesa pojedinog korisnika.
                    <br/><br/>
                    Kolačići nisu:
                    <br/><br/>
                    Namenjeni špijuniranju korisnika i NE prate sve što korisnik radi;
                    <br/><br/>
                    Maliciozni kod, niti virus;
                    <br/><br/>
                    Vezani za neželjene poruke;
                    <br/><br/>
                    U stanju da prate Vaše komande na tastaturi (ne čuvaju Vaše šifre).
                    <br/><br/>
                    Kolačići su jedino i isključivo namenjeni analitici i reklamiranju.
                    <br/><br/>

                    <h5 className="oswald fw-bold">KAKVE KOLAČIĆE KORISTI HORUS I ZAŠTO</h5>

                    <strong>PRIVREMENI KOLAČIĆI (SESSION COOKIES)</strong> – u pitanju su kolačići koji ističu i koji se automatski brišu kada korisnik zatvori internet pretraživač. HORUS Internet prodavnica koristi privremene kolačiće, kako bi se korisnicima omogućio pristup sadržaju i komentarisanju (stvari koje morate učiniti kada se prijavite sa svojim podacima na veb stranicu).
                    <br/><br/>
                    <strong>TRAJNI KOLAČIĆI (PERSISTENT COOKIES)</strong> – u pitanju su kolačići koji ostaju u pretraživaču dok ne isteknu ili dok ih korisnik ručno ne izbriše. HORUS Internet prodavnica koristi trajne kolačiće, u cilju boljeg razumevanja navika korisnika i u cilju poboljšanja HORUS Internet prodavnice prema navikama određenog korisnika. Prethodno pomenuta informacija je anonimna - individualni podaci korisnika nisu vidljivi. Maksimalno trajanje ovih kolačića je godinu dana.
                    <br/><br/>
                    <strong>NEOPHODNI KOLAČIĆI</strong> – u pitanju su kolačići, koji su oni koji su striktno neophodni za rad veb-sajta. Bez ovih kolačića, veb-sajt ne može da radi ispravno. Iz tog razloga, za ove kolačiće se ne traži pristanak korisnika. Ovi kolačići su bitni za osnovne radnje veb-sajta, tako što podržavaju bitne funkcije kao što su korpa za kupovinu i tehničke operacije veb-sajta, kako bi se omogućilo njegovo očekivano funkcionisanje.
                    <br/><br/>
                    <strong>ANALITIČKI KOLAČIĆI</strong> – u pitanju su kolačići, koji HORUSU omogućuju veb analitiku, tj. analizu upotrebe naših stranica i merenje posećenosti, koju HORUS sprovodi kako bi se poboljšao kvalitet i sadržaj ponuđenih usluga. Za navedeno HORUS koristi Google Analytics sastav i kolačiće _gat, _ga i _gid. HORUS će koristiti navedene kolačiće samo ukoliko korisnik da svoju saglasnost. Kolačići _gat i _gid uklanjaju se po zatvaranju internet pretraživača dok kolačić _ga ostaje sačuvan na internetu i ne briše se, odnosno ne ističe automatski. Ukoliko korisnik ne želi da se pomenuti kolačić čuva, možete izmeniti podešavanja kolačića prema uputstvima na sledećem linku: https://tools.google.com/ dlpage/gaoptout
                    <br/><br/>
                    <strong>MARKETINŠKI KOLAČIĆI</strong> – koriste se kako bi se korisnicima prikazivali relevantni oglasi. HORUS koristi Google Analytics kolačiće ads/ga-audiences i collect.
                    <br/><br/>
                    HORUS ne koristi kolačiće niti slične tehnologije za praćenje interesovanja korisnika i slanje marketinških materijala.
                    <br/><br/>

                    <h5 className="oswald fw-bold">3. POSTOJE LI NA WEB STRANICI HORUS KOLAČIĆI TREĆE STRANE?</h5>
                    Postoji nekoliko eksternih servisa koji korisniku skladište limitirane kolačiće.
                    Ovi kolačići nisu postavljeni od strane HORUS veb-stranice, ali neki služe za normalno
                    funkcionisanje određenih mogućnosti, koji korisnicima olakšavaju pristup sadržaju.
                    HORUS trenutno omogućuje:
                    <br/><br/>
                    <h5 className="oswald fw-bold">MERENJE POSEĆENOSTI</h5>
                    - HORUS koristi servis za merenje posećenosti, Google Analytics.
                    <br/><br/>
                    Google politiku privatnosti možete pronaću putem linka <a href="http://www.google.com/analytics/learn/privacy.html"
                            target="_blank" rel="noreferrer" className="link-secondary">http://www.google.com/analytics/learn/privacy.html</a>
                    <br/><br/>
                    <h5 className="oswald fw-bold">4. KAKO ONEMOGUĆITI KOLAČIĆE</h5>
                    Prilikom prvog pristupa, HORUS veb-stranica će zatražiti saglasnost korisnika za korišćenje kolačića koji nisu nužni (npr. funkcionalnih ili analitičkih kolačića).
                    <br/><br/>
                    U svakom trenutku korisnik može da isključiti kolačiće koji nisu nužni te se oni neće čuvati na uređajima korisnika. Postavke kolačića se mogu kontrolisati i konfigurisati u internet pretraživaču korisnika. Za informacije o postavkama kolačića potrebno je odabrati opciju “pomoć” na internet pretraživačima:
                    <br/><br/>
                    Chrome  <br/><br/>
                    Firefox  <br/><br/>
                    Internet Explorer 9 <br/><br/>
                    Internet Explorer 7 i 8 <br/><br/>
                    Opera (stranica na engleskom jeziku) <br/><br/>
                    Safari (stranica na engleskom jeziku).
                    <br/><br/>
                    Ako se kolačići onemoguće, korisnici možda neće moći da koriste određene funkcionalnosti na HORUS Internet prodavnici.
                    <br/><br/>
                    <h5 className="oswald fw-bold">5. DODATNE INFORMACIJA OKO ISKLJUČIVANJA KOLAČIĆA</h5>
                    Trenutno postoji nekoliko veb-sajtova, koji služe za isključivanje čuvanja kolačića za različite servise.
                    <br/><br/>
                    Više se možete informisati na sledećim linkovima:
                    <br/><br/>
                    <a href="http://www.allaboutcookies.org"
                       target="_blank" rel="noreferrer" className="link-secondary">http://www.allaboutcookies.org</a>
                    <br/>
                    <a href="http://www.youronlinechoices.eu"
                       target="_blank" rel="noreferrer" className="link-secondary">http://www.youronlinechoices.eu</a>
                    <br/><br/>
                    <h5 className="oswald fw-bold"> 6. SPOLJNE VEZE</h5>
                    Korišćenjem spoljnih veza na našoj internet stranici, (npr. društvene mreže Facebook, Instagram, YouTube, LinkedIn, itd) korisnici će biti preusmereni na spoljne domene, koji mogu koristiti kolačiće, ali na koje HORUS veb-sajt nema uticaja. Podatke prikupljene uz pomoć kolačića i sličnih tehnologija na tim domenima HORUS ne dobija od njihovih vlasnika ili zastupnika. Za više informacija o korišćenim kolačićima i mogućnostima upravljanja, molimo korisnike da provere izjave i pravila o kolačićima na posećenim domenima.


                </>}
                {lang === 1 &&
                <>
                    <h5 className="oswald fw-bold">1. ОБАВЕШТЕЊЕ О КОЛАЧИЋИМА</h5>
                    Како би ХОРУС интернет продавница радила исправно, те како бисмо били у стању вршити унапређења странице, у сврху побољшавања искуства претраживања Потрошача, ова страница мора на Ваш рачунар спремити малу количину информација (колачићи, cookies).
                    <br /><br />
                    У складу са важећим Законом о заштити података о личности, дужни смо да обавестимо Потрошаче о начину коришћења колачића.
                    <br /><br />
                    Коришћењем ХОРУС интернет продавнице пристајете на употребу колачића који су нужни, у циљу обезбеђивања појединих техничких карактеристика и пружања Потрошачима позитивног корисничког искуства. За коришћење колачића, који нису нужни за функционисање Интернет странице, али побољшавају услугу, затражићемо сагласност Потрошача. Ако Потрошач не да своју сагласност за коришћење одређених колачића - и даље може прегледати страницу, али неке могућности неће бити доступне.
                    <br /><br />
                    Коришћењем ХОРУС интернет продавница као Потрошач пристајете на коришћење колачића у складу са овом политиком колачића.
                    <br /><br />
                    Horus Wear doo може ажурирати ову Политику колачића у било ком тренутку.
                    <br /><br />
                    <h5 className="oswald fw-bold">2. ШТА ЈЕ КОЛАЧИЋ?</h5>
                    Колачићи су текстуалне датотеке, који садрже мале количине информација које се учитавају на уређај при посети одређеног веб-сајта. Колачићи се потом шаљу назад до изворних интернет домена приликом каснијих посета том домену. Ово омогућава веб-сајту да прикаже информације прилагођене личним потребама корисника, јер помоћу колачића веб-сајт памти радње и подешавања током одређеног раздобља. Сваки пут када корисник отвори неку интернет страницу, веб-сервер може прочитати вредности које је раније спремио у колачићима.
                    <br /><br />
                    Већина веб-сајтова садржи елементе из вишеструких интернет домена, тако да када посетите веб-сајт интернет претраживач може да прими колачиће из више извора.
                    <br /><br />
                    Колачићи су корисни зато што омогућавају веб-сајтовима да препознају уређај одређеног корисника. Колачићи омогућавају корисницима да ефикасно обављају навигацију између страница, запамте приоритете и генерално унапреде корисничко искуство. Они такође могу да се користе у циљу прилагођавања рекламирања интересовањима одређеног корисника, кроз праћење претраживања и других веб-сајтова.
                    <br /><br />
                    Колачићи могу чувати широк дијапазон информација укључујући податке (као што су: име и презиме, е-маил адреса, ИП адреса). Ипак, ови подаци биће коришћени путем колачића само уколико постоји сагласност корисника – веб-сајтови не могу добити приступ информацијама, нити могу приступити другим датотекама на уређајима корисника, уколико за то није дата претходна сагласност. Задате активности чувања и слања колачића нису видљиве корисницима. Ипак, корисник може да промени подешавања интернет претраживача, у циљу одобравања или одбијања захтева за чување колачића, аутоматског брисања сачуваних колачића када се интернет претраживач угаси, и сл. Такође, претходно поменуту сагласност за коришћење колачића корисник у сваком тренутку може повући.
                    <br /><br />
                    Колачићи, које користи ХОРУС веб-сајт, не могу да се користе у циљу покретања програма или испоручивања вируса на рачунару корисника.
                    <br /><br />
                    Постоје различите врсте колачића, а обично се разврставају по трајању, домену коме припадају и намени:
                    <br /><br />
                    Привремени (сесијски) колачићи се бришу након што корисник затвори свој претраживач. Трајни колачићи остају похрањени на корисниковом уређају на одређено време.
                    <br /><br />
                    Колачићи прве стране су постављени од стране сервер домена који је посећен. Колачићи треће стране су постављени са домена различитог од оног коју је корисник посетио.
                    <br /><br />
                    Према намени колачићи могу бити нужни ако потпомажу основне техничке карактеристике веб-сајта, функционални за побољшање корисничког искуства, аналитички за анализу употребе страница и маркетиншки за праћење интереса појединог корисника.
                    <br /><br />
                    Колачићи нису:    <br /><br />
                    Намењени шпијунирању корисника и НЕ прате све што корисник ради;    <br /><br />
                    Малициозни код, нити вирус;    <br /><br />
                    Везани за нежељене поруке;    <br /><br />
                    У стању да прате Ваше команде на тастатури (не чувају Ваше шифре).    <br /><br />
                    Колачићи су једино и искључиво намењени аналитици и рекламирању.    <br /><br />
                    <h5 className="oswald fw-bold">КАКВЕ КОЛАЧИЋЕ КОРИСТИ ХОРУС И ЗАШТО</h5>
                    <strong>ПРИВРЕМЕНИ КОЛАЧИЋИ (SESSION COOKIES)</strong> – у питању су колачићи који истичу и који се аутоматски бришу када корисник затвори интернет претраживач. ХОРУС Интернет продавница користи привремене колачиће, како би се корисницима омогућио приступ садржају и коментарисању (ствари које морате учинити када се пријавите са својим подацима на веб страницу).
                    <br /><br />
                    <strong>ТРАЈНИ КОЛАЧИЋИ (PERSISTENT COOKIES)</strong> – у питању су колачићи који остају у претраживачу док не истекну или док их корисник ручно не избрише. ХОРУС интернет продавница користи трајне колачиће, у циљу бољег разумевања навика корисника и у циљу побољшања ХОРУС интернет продавнице према навикама одређеног корисника. Претходно поменута информација је анонимна - индивидуални подаци корисника нису видљиви. Максимално трајање ових колачића је годину дана.
                    <br /><br />
                    <strong>НЕОПХОДНИ КОЛАЧИЋИ</strong> – у питању су колачићи, који су они који су стриктно неопходни за рад веб-сајта. Без ових колачића, веб-сајт не може да ради исправно. Из тог разлога, за ове колачиће се не тражи пристанак корисника. Ови колачићи су битни за основне радње веб-сајта, тако што подржавају битне функције као што су корпа за куповину и техничке операције веб-сајта, како би се омогућило његово очекивано функционисање.
                    <br /><br />
                    <strong>АНАЛИТИЧКИ КОЛАЧИЋИ</strong> – у питању су колачићи, који ХОРУСУ омогућују веб
                    аналитику, тј. анализу употребе наших страница и мерење посећености, коју
                    ХОРУС спроводи како би се побољшао квалитет и садржај понуђених услуга.
                    За наведено ХОРУС користи Google Analytics састав и колачиће _gat, _ga и _gid.
                    ХОРУС ће користити наведене колачиће само уколико корисник да своју сагласност.
                    Колачићи _gat и _gid уклањају се по затварању интернет претраживача док колачић
                    _ga остаје сачуван на интернету и не брише се, односно не истиче аутоматски.
                    Уколико корисник не жели да се поменути колачић чува, можете изменити подешавања
                    колачића према упутствима на следећем линку: <br />
                    <a href="https://tools.google.com/ dlpage/gaoptout"
                       target="_blank" rel="noreferrer" className="link-secondary">https://tools.google.com/ dlpage/gaoptout</a>

                    <br /><br />
                    <strong>МАРКЕТИНШКИ КОЛАЧИЋИ</strong> – користе се како би се корисницима приказивали релевантни огласи. ХОРУС користи Google Analytics колачиће ads/ga-audiences i collect.
                    <br /><br />
                    ХОРУС не користи колачиће нити сличне технологије за праћење интересовања корисника и слање маркетиншких материјала.
                    <br /><br />

                    <h5 className="oswald fw-bold">3. ПОСТОЈЕ ЛИ НА ВЕБ СТРАНИЦИ ХОРУС КОЛАЧИЋИ ТРЕЋЕ СТРАНЕ?</h5>
                    Постоји неколико екстерних сервиса који кориснику складиште лимитиране колачиће. Ови колачићи нису постављени од стране ХОРУС веб-странице, али неки служе за нормално функционисање одређених могућности, који корисницима олакшавају приступ садржају. ХОРУС тренутно омогућује:
                    <br /><br />
                    <h5 className="oswald fw-bold">МЕРЕЊЕ ПОСЕЋЕНОСТИ</h5>
                    - ХОРУС користи сервис за мерење посећености, Google Analytics.
                    <br /><br />
                    Google политику приватности можете пронаћу путем линка  <a
                    href="http://www.google.com/analytics/learn/privacy.html" target="_blank" rel="noreferrer" className="link-secondary">
                        http://www.google.com/analytics/learn/privacy.html
                    </a>

                    <br /><br />
                    <h5 className="oswald fw-bold">4. КАКО ОНЕМОГУЋИТИ КОЛАЧИЋЕ</h5>
                    Приликом првог приступа, ХОРУС веб-страница ће затражити сагласност корисника за коришћење колачића који нису нужни (нпр. функционалних или аналитичких колачића).
                    <br /><br />
                    У сваком тренутку корисник може да искључити колачиће који нису нужни те се они неће чувати на уређајима корисника. Поставке колачића се могу контролисати и конфигурисати у интернет претраживачу корисника. За информације о поставкама колачића потребно је одабрати опцију “помоћ” на интернет претраживачима:
                    <br /><br />
                    Chrome <br /><br />
                    Firefox <br /><br />
                    Internet Explorer 9 <br /><br />
                    Internet Explorer 7 i 8 <br /><br />
                    Opera (страница на енглеском језику) <br /><br />
                    Safari (страница на енглеском језику). <br /><br />
                    Ако се колачићи онемогуће, корисници можда неће моћи да користе одређене функционалности на ХОРУС интернет продавници.
                    <br /><br />
                    <h5 className="oswald fw-bold">5. ДОДАТНЕ ИНФОРМАЦИЈА ОКО ИСКЉУЧИВАЊА КОЛАЧИЋА</h5>
                    Тренутно постоји неколико веб-сајтова, који служе за искључивање чувања колачића за различите сервисе.
                    <br /><br />
                    Више се можете информисати на следећим линковима:
                    <br /><br />
                    <a
                        href="http://www.allaboutcookies.org/" target="_blank" rel="noreferrer" className="link-secondary">
                        http://www.allaboutcookies.org/
                    </a>
                     <br />
                    <a
                        href="http://www.youronlinechoices.eu/" target="_blank" rel="noreferrer" className="link-secondary">
                        http://www.youronlinechoices.eu/
                    </a>
                     <br /><br />
                    <h5 className="oswald fw-bold">6. СПОЉНЕ ВЕЗЕ</h5>

                    Коришћењем спољних веза на нашој интернет страници, (нпр. друштвене мреже Facebook, Instagram, YouTube, LinkedIn, итд) корисници ће бити преусмерени на спољне домене, који могу користити колачиће, али на које ХОРУС веб-сајт нема утицаја. Податке прикупљене уз помоћ колачића и сличних технологија на тим доменима ХОРУС не добија од њихових власника или заступника. За више информација о коришћеним колачићима и могућностима управљања, молимо кориснике да провере изјаве и правила о колачићима на посећеним доменима.

                </>}

                {lang === 2 &&
                <>
                    <h5 className="oswald fw-bold">1. NOTIFICATION ON COOKIES</h5>

                    With the aim of having this webpage functioning properly and in order to be able to make improvements of the page, with the purpose of the improvement of Your browsing experience, this webpage must prepare a small amount of information to Your computer (Cookies).
                    <br /><br />
                    In compliance with a new regulation of personal data protection, especially in compliance with General Data Protection Regulation, we are obliged to inform you on the manner of use of cookies.
                    <br /><br />
                    By using this webpage you provide consent to the use of cookies which are essential for proper functioning of our webpage and for us to provide You with certain technical characteristics and thus provide you with positive user experience. For the use of cookies which are not indispensable for functioning of the webpage, but still improve the service we provide for You, we will ask for Your consent. If You do not provide consent to the use of certain cookies, you still may browse through the page, but some options will not be available to you.
                    <br /><br />
                    By using this website you accept the use of cookies in compliance with this cookie policy.
                    <br /><br />
                    HORUS WEAR doo may update this Cookie Policy at any point. We advise you to check this policy regularly in order to be informed about how we use cookies.
                    <br /><br />
                    <h5 className="oswald fw-bold">2. WHAT IS A COOKIE?</h5>
                    Cookies are textual files which contain small amounts of information which are read into your device when you visit a website. Cookies then are sent back to their source web domains during your later visits to this domain. This enables a webpage to present information adjusted to Your needs because via cookies webpage remembers Your actions and adjustments during a certain period. Every time the user opens a webpage, web server may read the value previously sent into cookies.
                    <br /><br />
                    Most of webpages contain elements from multiple web domains so that, when you visit a website, your browser may receive cookies from multiple sources.
                    <br /><br />
                    Cookies are useful because they enable a website to recognize the device of a user. Cookies enable you to navigate efficiently between pages, remember your priorities and generally improve user experience. They also may be used to adjust advertisements to your interests through tracking of your browsing through websites.
                    <br /><br />

                    Cookies may keep a wide range of information including data (such as Your name, e-mail address, IP address). However, these data will be used by cookies only if you agreed to that -web pages cannot get access to information you have not provided and cannot access other files at Your computer. Assigned activities of storing and sending cookies are not visible to You. Nevertheless, you may change your settings of the Internet browser so as to choose yourselves whether you want to approve or reject the requests for cookie storing, automatically delete saved cookies when you close the browser, etc. furthermore, if you provide your consent to the use of cookies, you may withdraw it at any point.
                    <br /><br />
                    Our cookies cannot be used to initiate a program or deliver a virus to Your computer.
                    <br /><br />
                    There are various types of cookies, and usually they are classified according to duration, domain they belong to and purpose:
                    <br /><br />
                    Temporary (session) cookies are deleted after the user closes the browser. Permanent (persistent) cookies remain stored at the user’s device for a certain period of time.
                    <br /><br />
                    First-party cookies are set by the server of the visited domain. Third-party  cookies are set by the domain different from the one visited by the user.
                    <br /><br />
                    According to the purpose, cookies may be essential if they support basic technical characteristics of the webpage, functional if they improve user experience, analytical for the analysis of the use of pages and marketing for tracking of the individual user interest.
                    <br /><br />
                    Cookies are not: <br /><br />
                    Designed for spying of users and DO NOT follow everything the user does. <br /><br />
                    Malicious code or virus <br /><br />
                    Related to undesired messages <br /><br />
                    Capable of following Your commands on the keyboard (do not store your passwords) <br /><br />
                    They are only and exclusively designed for analytics and advertising <br /><br />

                    <h5 className="oswald fw-bold">WHAT TYPES OF COOKIES HORUS WEAR DOO USES AND WHY</h5>
                    <strong>SESSION COOKIES </strong>– these are temporary cookies which expire (and are automatically deleted) when you close the Internet browser—we use session cookies to enable the access to the content and enable commenting (things you must do when you are applied to a webpage with your data).
                    <br /><br />
                    <strong>PERSISTENT COOKIES </strong>– these cookies usually have expiration date in distant future and thus they will remain in your browser until they expire or until you delete them manually. We use persistent cookies in order to have better understanding of the user habits, so that we can improve the web page according to Your habits. This information is anonymous – we do not see individual data of users. Maximum duration of these cookies is one year.
                    <br /><br />
                    <strong>ESSENTIAL COOKIES</strong>–Indispensable cookies are those strictly necessary for the website operation. Without these cookies website will not function properly. Therefore, for these cookies we do not ask for Your consent. These cookies are important for fundamental website operations, such as shopping cart and technical operations of the website in order to enable its functioning as you expect it.
                    <br /><br />
                    <strong>ANALYTICAL COOKIES</strong> – these are the cookies which provide HORUS with web analytics, i.e. the analysis of the use of our pages and measuring of visits, which HORUS implements in order to improve the quality and content of offered services. For the stated, HORUS WEAR doo uses Google Analytics composition and cookies _gat, _ga i _gid. HORUS WEAR doo will use the stated cookies only if You give us your consent.  Cookies _gat and _gid are removed when the Internet browser is closed whereas the cookie _ga remains saved at the internet and is not deleted, in other words, does not expire automatically. If you do not want the stated cookie to be saved, you may do that according to the instructions in the following link:  https://tools.google.com/ dlpage/gaoptout
                    <br /><br />
                    <strong>MARKETING COOKIES</strong> – these are used so that the users would be presented with relevant ads. HORUS WEAR doo uses Google Analytics cookies ads/ga-audiences and collect, Facebook cookies etc.
                    <br /><br />
                    HORUS WEAR doo does not use cookies or similar technologies for tracking of Your interests and sending of marketing material.
                    <br /><br />

                    <h5 className="oswald fw-bold">3. ARE THERE THIRD-PARTY COOKIES AT THE HORUS WEBPAGE?</h5>

                    There are several external services which store limited cookies to the user. These cookies are not set by this web page, but some of them are used for normal functioning of certain possibilities, which make the access to the content easier for users. Currently we provide:
                    <br /><br />
                    <h5 className="oswald fw-bold">MEASURING OF THE NUMBER OF VISITS</h5>
                    - HORUS WEAR doo uses the service for the measuring of the number of visits, Google Analytics i Facebook Pixel
                    <br /><br />
                    Google privacy policy with regards to Google analytics may be found via the following link <a
                    href="http://www.google.com/analytics/learn/privacy.html" target="_blank" rel="noreferrer" className="link-secondary">
                    http://www.google.com/analytics/learn/privacy.html
                </a>
                    <br /><br />
                    Facebook privacy policy may be found on the following link <a
                        href="https://www.facebook.com/privacy/explanation" target="_blank" rel="noreferrer" className="link-secondary">
                        https://www.facebook.com/privacy/explanation
                </a>
                    <br /><br />

                    <h5 className="oswald fw-bold">4. HOW TO DISABLE COOKIES</h5>
                    During the first access to the page, we will ask for Your consent for the use of cookies which are not mandatory (e.g. functional or analytical cookies).
                    <br /><br />
                    At any point you may turn off the cookies which are not mandatory so they will not be kept at Your computer. Cookie setups may be controlled and configured in your web browser. For the information on cookie setup, choose option “help” at the web browser which  you use:
                    <br /><br />
                    Chrome  <br /><br />
                    Firefox  <br /><br />
                    Internet Explorer 9  <br /><br />
                    Internet Explorer 7 i 8  <br /><br />
                    Opera (page in English)  <br /><br />
                    Safari (page in English).  <br /><br />
                    If you disable cookies, you will not be able to use some of the functionalities at web pages.
                    <br /><br />
                    <h5 className="oswald fw-bold">5. ADDITIONAL INFORMATION ABOUT COOKIE TURNOFF</h5>
                    Currently there are several web pages for turning off the cookie keeping for various services.
                    <br /><br />
                    For further information, go to the following links: <br /><br />
                    <a
                        href="http://www.allaboutcookies.org/" target="_blank" rel="noreferrer" className="link-secondary">
                        http://www.allaboutcookies.org/
                    </a>
                      <br />
                    <a
                        href=" http://www.youronlinechoices.eu/" target="_blank" rel="noreferrer" className="link-secondary">
                        http://www.youronlinechoices.eu/
                    </a>
                    <br /><br />
                    <h5 className="oswald fw-bold">6. EXTERNAL LINKS</h5>
                    By using external links at our Internet page, e.g. social networks Facebook, Instagram, YouTube or Linkedin you will be redirected to external domains which may use cookies without our influence there. We do not obtain the data collected via cookies and similar technologies at these domains from their owners or representatives. For further information about used cookies and opportunities of management, please check the statements and rules on cookies at visited domains.

                </>}
            </div>
        </>
    )
}
export default PolitikaKolacica;