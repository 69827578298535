import {trans, widget} from "../../../modulesCompiler";
import React from "react";
import logo from "../../../../assets/images/daily-express-kurir-srbija.jpg";


function UsloviIsporuke() {
    const lang = widget("lang.useGetLang", null);

    return (
        <>
            {widget("public.Breadcrumbs", {links: [{trans: trans("uslovi isporuke")}]})}
            <div className="container mb-6">
                <h1 className="oswald fw-bold text-uppercase h3">{trans("uslovi isporuke")}</h1>
                <br/>
                {lang === 0 &&
                <>
                    <h5 className="oswald fw-bold">SRBIJA</h5>
                    Prodavac se obavezuje da poručenu robu isporuči Potrošaču slanjem robe preko kurirske službe, sa kojom ima zaključen Ugovor o pružanju ekspresnih usluga u unutrašnjem saobraćaju.
                    <br/><br/>

                    Za sve porudžbine Potrošač se, prihvatajući ove Uslove korišćenja, obavezuje da na ime
                    troškova isporuke prilikom preuzimanja pošiljke plati iznos po važećem cenovniku po
                    sledećim težinskim razredima:
                    <br/>
                    <table className="table table-bordered">
                        <tbody>
                        <tr>
                            <td className="fw-bold">Masa paketa</td>
                            <td className="fw-bold">Cena sa PDV</td>
                        </tr>
                        <tr><td>do 1 kg </td><td> 390,00 RSD </td></tr>
                        <tr> <td>od 1 do 2 kg</td><td> 490,00 RSD </td></tr>
                        <tr> <td>od 2 do 5 kg</td><td> 590,00 RSD </td></tr>
                        <tr> <td>od 5 do 10 kg</td><td> 690,00 RSD </td></tr>
                        <tr> <td>od 10 do 20 kg</td><td> 890,00 RSD </td></tr>
                        </tbody>
                    </table>

                    <br/>
                    Troškovi isporuke će biti jasno istaknuti na fiskalnom računu kao posebna stavka u skladu sa
                    Zakonom RS.
                    <br/><br/>
                    Toškovi isporuke za porudžbine od 8000,00 RSD sa PDV ili više se <strong>ne naplaćuju</strong>.


                    <br/><br/>
                    Nakon prijema porudžbine od strane Potrošača, Služba Internet prodaje kompanije Horus Wear doo vrši proveru dostupnosti i ispravnosti artikala. Ukoliko nekih od poručenih artikala nema na stanju, Potrošač dobija e-mail o nedostupnosti artikla.
                    <br/><br/>
                    O vremenu dostave Potrošač će biti obavešten telefonom od strane kurirske službe.
                    <br/><br/>
                    Na zahtev Potrošača može biti prosleđen kod pošiljke koju može pratiti <a
                     href="https://www.dexpress.rs/rs/pracenje-posiljaka" target="_blank" rel="noreferrer" className="link-secondary">
                    OVDE
                    </a>.
                    <br/><br/>
                    Rok isporuke iznosi od 1 do 3 radna dana od prvog narednog dana od dana izvršenja porudžbine od strane potrošača.
                    <br/><br/>
                    Primer 1: Porudžbine kreirane do 12 časova, ukoliko poručenu robu imamo na stanju biće poslata istog dana, a na adresi Primaoca će biti sledeći dan.
                    <br/><br/>
                    Primer 2: Ukoliko je porudžbina kreirana u petak posle 12 časova, paket će biti poslat u ponedeljak a na adresi Primaoca će biti u utorak
                    <br/><br/>
                    Isporuke paketa se vrše samo radnim danima, tj. vikendom se isporuke paketa ne vrše.
                    <br/><br/>
                    Molimo Vas za razumevanje za moguća kašnjenja u isporuci u periodima akcija i sezonskih sniženja obzirom da isporuka zavisi od eksternih službi.
                    <br/><br/>

                    <div>
                        <a
                            href="https://www.dexpress.rs/rs" target="_blank" rel="noreferrer" className="link-secondary">
                            <img src={logo} className="" width={400} alt="DEX express" />
                        </a>
                    </div>
                    <br/>
                    Kuriri Dexpress-a (eksterna firma koja vrši dostavu robe za nas)﻿, pošiljke donose na adresu za isporuku u periodu od 8-16h. Molimo Vas da u tom periodu obezbedite da na adresi bude osoba koja može preuzeti pošiljku. Prilikom preuzimanja pošiljke potrebno je da vizuelno pregledate paket da slučajno ne postoje neka vidna oštećenja. Ukoliko primetite da je transportna kutija značajno oštećena i posumnjate da je proizvod možda oštećen, odbijte prijem pošiljke i odmah nas obavestite. Ukoliko je pošiljka naizgled bez oštećenja slobodno preuzmite pošiljku, potpišite kuriru adresnicu i čuvajte istu u slučaju naknadnih reklamacija. Kurir pokušava svaku pošiljku da uruči u dva navrata. Uobičajena praksa je da ukoliko Vas kurir ne pronađe na adresi, da Vas pozove na telefon koji ste ostavili prilikom kreiranja narudžbenice i ugovori novi termin isporuke. Ukoliko Vas i tada ne pronađe na adresi, pošiljka će se vratiti nama. Po prijemu pošiljke, kontaktiraćemo Vas kako bi ustanovili razlog neuručenja.
                    <br/><br/>
                    O vremenu dostave Kupac će biti obavešten telefonom od strane kurirske službe. <br/><br/>
                    Isporuka se vrši isključivo na teritoriji Republike Srbije, osim na teritoriji Kosova i Metohije zbog trenutno nedefinisanih administrativnih procedura zbog kojih se poslati paketi odmah vraćaju Prodavcu.
                    <br/><br/>
                    <h5 className="oswald fw-bold">INOSTRANSTVO</h5>

                    Artikli se mogu naručiti i iz drugih država, u tom slučaju se obračunavaju izvozne cene
                    u EUR valuti koje su istaknute na sajtu kada izaberete engleski jezik: <a
                    href="/home" target="_blank" rel="noreferrer" className="link-secondary">
                    www.horus.rs/home
                </a> .
                    <br/><br/>
                    Ukoliko kao Kupac pošaljete porudžbenicu na engleskom jeziku, izvoz se obavlja uslugom JP “Pošta Srbije” – PostExport nakon evidentiranja uplate na račun Prodavca. Cenovnik troškova izvoza PostExport usluge možete pogledati ovde.
                    <br/><br/>
                    Takodje, da bi izbegli dodatne troškove izvoza naručeni artikal uz dogovor možete naručiti i na adresu Vašeg prijatelja ili rođaka u Srbiji koji će Vam artikal proslediti kao poklon.
                    <br/><br/>
                    Rok isporuke je od 7 – 30 dana, u zavisnosti od zemlje u koju se izvoz vrši.
                    <br/><br/>
                    Paket se nakon slanja može pratiti putem koda koji će biti dostavljen Kupcu, na  <a
                    href="https://www.posta.rs/eng/alati/pracenje-posiljke.aspx" target="_blank" rel="noreferrer" className="link-secondary">
                    ovoj
                </a> veb stranici JP “Pošta Srbije”.
                </>}

                {lang === 1 &&
                <>
                    <h5 className="oswald fw-bold">СРБИЈА</h5>

                    Продавац се обавезује да поручену робу испоручи Потрошачу слањем робе преко курирске службе, са којом има закључен Уговор о пружању експресних услуга у унутрашњем саобраћају.
                    <br/><br/>


                    За све поруџбине Потрошач се, прихватајући ове Услове коришћења, обавезује да на име
                    трошкова испоруке приликом преузимања пошиљке плати износ по важећем ценовнику по
                    следећим тежинским разредима:
                    <br/>
                    <table className="table table-bordered">
                        <tbody>
                        <tr>
                            <td className="fw-bold">Маса пакета</td>
                            <td className="fw-bold">Цена са ПДВ</td>
                        </tr>
                        <tr><td>до 1 кг </td><td> 390,00 РСД </td></tr>
                        <tr> <td>од 1 до 2 кг</td><td> 490,00 РСД </td></tr>
                        <tr> <td>од 2 до 5 кг</td><td> 590,00 РСД </td></tr>
                        <tr> <td>од 5 до 10 кг</td><td> 690,00 РСД </td></tr>
                        <tr> <td>од 10 до 20 кг</td><td> 890,00 РСД </td></tr>
                        </tbody>
                    </table>

                    Трошкови испоруке ће бити јасно истакнути на фискалном рачуну као посебна ставка у складу са
                    Законом РС.<br/><br/>
                    Тошкови испоруке за поруџбине од 8000,00 РСД са ПДВ или више се <strong>не наплаћују</strong>.

                    <br/><br/>
                    Након пријема поруџбине од стране Потрошача, Служба интернет продаје компаније Horus Wear doo врши проверу доступности и исправности артикала. Уколико неких од поручених артикала нема на стању, Потрошач добија е-маил о недоступности артикла.
                    <br/><br/>
                    О времену доставе Потрошач ће бити обавештен телефоном од стране курирске службе.
                    <br/><br/>
                    На захтев Потрошача може бити прослеђен код пошиљке коју може пратити  <a
                    href="https://www.dexpress.rs/rs/pracenje-posiljaka" target="_blank" rel="noreferrer" className="link-secondary">
                    ОВДЕ
                </a>.
                    <br/><br/>
                    Рок испоруке износи од 1 до 3 радна дана од првог наредног дана од дана извршења поруџбине од стране потрошача.
                    <br/><br/>
                    Пример 1: Поруџбине креиране до 12 часова, уколико поручену робу имамо на стању биће послата истог дана, а на адреси Примаоца ће бити следећи дан.
                    <br/><br/>
                    Пример 2: Уколико је поруџбина креирана у петак после 12 часова, пакет ће бити послат у понедељак а на адреси Примаоца ће бити у уторак
                    <br/><br/>
                    Испоруке пакета се врше само радним данима, тј. викендом се испоруке пакета не врше.
                    <br/><br/>
                    Молимо Вас за разумевање за могућа кашњења у испоруци у периодима акција и сезонских снижења обзиром да испорука зависи од екстерних служби.
                    <br/><br/>
                    <div>
                        <a
                            href="https://www.dexpress.rs/rs" target="_blank" rel="noreferrer" className="link-secondary">
                            <img src={logo} className="" width={400} alt="DEX express" />
                        </a>
                    </div><br/>

                    Курири DExpress-а (екстерна фирма која врши доставу робе за нас), пошиљке доносе на адресу за испоруку у периоду од 8-16 часова. Молимо Вас да у том периоду обезбедите да на адреси буде особа која може преузети пошиљку. Приликом преузимања пошиљке потребно је да визуелно прегледате пакет да случајно не постоје нека видна оштећења. Уколико приметите да је транспортна кутија значајно оштећена и посумњате да је производ можда оштећен, одбијте пријем пошиљке и одмах нас обавестите. Уколико је пошиљка наизглед без оштећења слободно преузмите пошиљку, потпишите куриру адресницу и чувајте исту у случају накнадних рекламација. Курир покушава сваку пошиљку да уручи у два наврата. Уобичајена пракса је да уколико Вас курир не пронађе на адреси, да Вас позове на телефон који сте оставили приликом креирања наруџбенице и уговори нови термин испоруке. Уколико Вас и тада не пронађе на адреси, пошиљка ће се вратити нама. По пријему пошиљке, контактираћемо Вас како би установили разлог неуручења.
                    <br/><br/>
                    О времену доставе Купац ће бити обавештен телефоном од стране курирске службе. <br/><br/>
                    Испорука се врши искључиво на територији Републике Србије, осим на територији Косова и Метохије због тренутно недефинисаних административних процедура због којих се послати пакети одмах враћају Продавцу.
                    <br/><br/>
                    <h5 className="oswald fw-bold">ИНОСТРАНСТВО</h5>

                    Артикли се могу наручити и из других држава, у том случају се обрачунавају извозне цене у ЕУР
                    валути које су истакнуте на сајту када изаберете енглески језик: <a
                    href="/home" target="_blank" rel="noreferrer" className="link-secondary">
                    www.horus.rs/home
                </a>.
                    <br/><br/>
                    Уколико као Купац пошаљете поруџбеницу на енглеском језику, извоз се обавља услугом ЈП “Пошта Србије” – PostExport након евидентирања уплате на рачун Продавца. Ценовник трошкова извоза PostExport услуге можете погледати овде.
                    <br/><br/>
                    Такође, да би избегли додатне трошкове извоза наручени артикал уз договор можете наручити и на адресу Вашег пријатеља или рођака у Србији који ће Вам артикал проследити као поклон.
                    <br/><br/>
                    Рок испоруке је од 7 – 30 дана, у зависности од земље у коју се извоз врши.
                    <br/><br/>
                    Пакет се након слања може пратити путем кода који ће бити достављен Купцу, на <a
                    href="https://www.posta.rs/eng/alati/pracenje-posiljke.aspx" target="_blank" rel="noreferrer" className="link-secondary">
                    овој
                </a> веб страници ЈП “Пошта Србије”.
                </>}


                {lang === 2 &&
                <>
                    <h5 className="oswald fw-bold">POST EXPORT</h5>
                    Most often, it takes about 20 business days to process orders. For all orders placed on Fridays and weekends, the seller will contact you at the beginning of the following week.
                    <br/><br/>
                    All costs of delivery will be clearly stated, separately from the item price, with the overall price stated as well.
                    <br/><br/>
                    Post export (an external company that delivers goods for HORUS WEAR doo), delivers packages to the delivery address in the period from 8-16h. Post export is in cooperation with the Posts in all countries so the delivery can depend on time zone and your local post. Please, ensure that at the address is a person who can receive the shipment.  If courier do not find you at the address, the shipment will return to us. Upon receiving of the shipment, we will contact you to determine the reason why shipment isn't delivered and agree to resend it.
                    <br/><br/>
                    After your order has been shipped, we will send you a confirmation e-mail with the unique code of your order that you can use to track your package.
                    <br/><br/>
                    POST EXPORT link for tracking your order is:<br/>
                    <a
                        href="https://www.posta.rs/eng/alati/pracenje-posiljke.aspx" target="_blank" rel="noreferrer" className="link-secondary">
                        https://www.posta.rs/eng/alati/pracenje-posiljke.aspx
                    </a>
                    <br/><br/>
                    <h5 className="oswald fw-bold">IMPORTANT NOTICE</h5>
                    The estimated delivery time is shown for informative purposes only. HORUS WEAR doo company can not vouch for the estimated delivery time shown in the table. The package can be kept longer than initially planned at certain posts for safety checking procedures, which can postpone the estimated delivery time. According to previosly stated, delivery can last up to 30 days. Prolonged deliveries are also prone to happen during holiday seasons, as processing the packages can take longer than the usual.

                    <br/><br/>
                    <div className="fw-bold">
                        <h5 className="oswald fw-bold">IMPORTANT!</h5>
                        DUE TO EXTRA POSTAL SECURITY CHECKS IT CAN HAPPEN THAT SHIPMENT TAKE LONGER TO REACH RECIPIENT. CASES LIKE THIS USUALLY OCCUR IN THE TIMES OF INCREASED ’ANTI-TERRORIST’ ACTIVITY.
                        <br/>
                        DELAYS IN SHIPMENTS CAN ALSO BE EXPECTED IN THE BUSY HOLLIDAY SEASONS, WHEN POSTAL HANDLING OF SHIPMENT GOES MUCH SLOWER
                        <br/><br/>
                        PLEASE KEEP IN MIND THAT THE SHIPMENT MAY BE SUBJECT TO YOUR LOCAL CUSTOMS IMPORT TAXES. FOR MORE INFORMATION ON THAT, PLEASE CONTACT YOUR LOCAL CUSTOMS OFFICE.
                    </div> <br/>

                    The package can be subject to the local taxes of the delivery country – for more information on this, please check with your local customs service office. HORUS WEAR doo can not be held responsible for higher delivery expenses due to the customs taxes or any other matters valid in the country the package is being delivered to. We have tried to provide all the necessery informations for the customs so you do not need to pay the customs taxes only the VAT in your country. IMPORTANT NOTICE - we can not guarantee that the customs will free you off all customs taxes.
                    <br/><br/>
                    <b> PLEASE NOTE: </b>
                    <br/><br/>
                    By confirming and accepting the order and clicking on the ‘SUBMIT ORDER’ button, you agree with the delivery terms and conditions stated.
                    <br/><br/>
                    If you have any queries, complaints, or would like to share any information with us – please feel free to send us an
                    e-mail at: <a href="mailto:shop@horus.rs" className="link-secondary">shop@horus.rs</a>.

                </>}
                </div>
        </>
    )
}
export default UsloviIsporuke;