import {useQuery} from "@apollo/client";
import {GET_PRODUCT, GET_PRODUCT_QUERY} from "../../graphql/product";
import Loader from "../../../../app/Loader";
import GraphQLErrorMessage from "../../../../app/GraphQLErrorMessage";
import {route, trans, widget} from "../../../modulesCompiler";
import ShopProductRender from "./ShopProductRender";


function ShopProduct(props)  {
    const id = props.computedMatch?.params?.id;
    const shopBreadcrumbs = {trans: trans("shop"), route: route("products.shop")};

    const { data, loading, error } =  useQuery(GET_PRODUCT, {
        variables: {id: id, all: true}
    });
    if(loading) return <Loader />
    if(error)   return <GraphQLErrorMessage error={error} />
    const product = data[GET_PRODUCT_QUERY];

    if(!product)    {
        return <>
            {widget("public.Breadcrumbs", {links: [shopBreadcrumbs]})}
            {widget("public.Error404")}
        </>
    }

    widget("seo.seoTitle", product.seo_title || product.name);
    widget("seo.seoKeywords", product.seo_keywords);
    widget("seo.seoDescription", product.seo_description);
    widget("seo.ogImage", widget("storage.image")(product.image, true));

    return <ShopProductRender product={product} />
}
export default ShopProduct;