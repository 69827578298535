import GlobalLangWrapper from "./middlewares/GlobalLangWrapper";
import UseTrans from "./widgets/useTrans";
import useRoute from "./widgets/useRoute";
import useGetLang from "./widgets/useGetLang";
import DropDown from "./widgets/DropDown";
import {options} from "./config";
import LangCmsUpdateField from "./widgets/LangCmsUpdateField";
import LangCmsUpdateImage from "./widgets/LangCmsUpdateImage";


const exports = {
    widgets: {
        trans: UseTrans,
        route: useRoute,
        useGetLang: useGetLang,
        DropDown: DropDown,
        options: options,
        LangCmsUpdateField: LangCmsUpdateField,
        LangCmsUpdateImage: LangCmsUpdateImage,
    },
    wrappers: [GlobalLangWrapper]
}
export default exports;