import React from "react";
import {Link} from "react-router-dom";
import {route, trans} from "../../modulesCompiler";


function Breadcrumbs({links}) {

    return (
        <div className="bg-light pt-4 pb-4 mb-4 mb-md-5">
            <div className="container text-uppercase fw-bold oswald text-black-50">
                <Link to={route("public.index")} className="text-black-50">
                    {trans("naslovna")}
                </Link>
                {links &&
                <>
                    <span> / </span>
                    {links.map((item, index) => {
                        return <React.Fragment key={index}>
                            {item.route
                                ? <Link to={item.route} className="text-black-50">
                                    {item.trans}
                                </Link>
                                : <span> {item.trans} </span>}
                            {((index+1) !== links.length) &&
                            <span> / </span>}
                        </React.Fragment>
                    })}
                </>}
            </div>
        </div>
    )
}
export default Breadcrumbs;