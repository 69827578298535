import SeoMiddleware from "./middlewares/SeoMiddleware";
import Pages from "./views/pages/Pages";
import {ogImage, seoDescription, seoKeywords, seoTitle} from "./seoProvider";


const routes = {
   pages: {path: "/cms/stranice", component: Pages, middleware: "cms.AdminAuthenticatedMiddleware"},
};
const cmsLeftMenu = [
   {path: routes.pages.path, name: "SEO", position: 3},
];


const exports = {
   routes: routes,
   cmsLeftMenu: cmsLeftMenu,
   widgets: {
      SeoMiddleware: SeoMiddleware,
      seoTitle: seoTitle,
      ogImage: ogImage,
      seoDescription: seoDescription,
      seoKeywords: seoKeywords,
   }
}
export default exports;