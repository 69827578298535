import React, {useState} from "react";
import {useMutation} from "@apollo/client";
import {widget} from "../../../modulesCompiler";
import toast from "react-hot-toast";
import GraphQLErrorMessage from "../../../../app/GraphQLErrorMessage";
import {DELETE_BLOCK_CATEGORIES, UPSERT_BLOCK_CATEGORIES} from "../../graphql/categories";
import Nestable from "react-nestable";
import {ORDER_ITEMS} from "../../../_cms/graphql/items";



function BlockCategories({block, refetch}) {
    const [update, {error}] = useMutation(UPSERT_BLOCK_CATEGORIES);
    const [deleteBlockCategory] = useMutation(DELETE_BLOCK_CATEGORIES);
    const [orderItems] = useMutation(ORDER_ITEMS);
    const [select, setSelect] = useState(null);

    const addCategory = (category) => {
        if(!category)    {
            toast.error("Kategorija ne sme biti prazna!");
            return false;
        }
        update({variables: {block_id: block.id, category_id: category.id}}).then(response => {
            toast.success("Dodata kategorija!");
            refetch();
            setSelect("");
        }).catch(error => {
            toast.error(error.message);
        });
    }
    const deleteItem = (item) => {
        if(!window.confirm("Zelite da obrisete kategoriju?"))  {
            return false;
        }
        deleteBlockCategory({variables: {id: item.block.id}}).then(response => {
            toast.success("Obrisano!");
            refetch();
        }).catch(error => {
            toast.error(error.message);
        });
    }
    const changedOrder = (items) => {
        const holder = items.items.map(p => {
            p.id = p.block.id;
            return p;
        })
        orderItems(
            {variables: {items: JSON.stringify(holder), table: "block_categories"}}
        ).then(response => {
            toast.success("Sacuvano!");
            refetch();
        }).catch(error => {
            toast.error(error.message);
        });
    }

    return  (
        <div className="p-3">
            <GraphQLErrorMessage error={error} />
            <div className="text-black-50 mb-3">
                Karusel sa kategorijama.
            </div>
            <div className="mb-4">
                <Nestable
                    collapsed={false}
                    maxDepth={1}
                    onChange={changedOrder}
                    handler={widget("cms.DragHandler")}
                    items={block.categories}
                    renderItem={(props) => <div key={props.item.block.id}
                                className="p-2 bg-light d-flex align-items-center col-md-6">
                        {props.handler}
                        <div className="fw-bold flex-grow-1"> {props.item.name} </div>
                        <button onClick={() => deleteItem(props.item)}
                            className="btn btn-light btn-sm">
                            uklonite
                        </button>
                    </div>} />
            </div>

            <div className="mb-3">
                <div className="d-flex align-items-center mb-3">
                    <div className="me-2">Odaberite kategoriju:</div>
                    {widget("categories.CategorySelect",
                        {value: select, callback: addCategory})}
                </div>

            </div>
        </div>
    )
}
export default BlockCategories;