import {customSelectStylesDark, dateTime} from "../../../../app/helpers";
import {useMutation, useQuery} from "@apollo/client";
import {DELETE_ADMIN, GET_SELF, GET_SELF_QUERY, UPDATE_ADMIN} from "../../graphql/admin";
import GraphQLErrorMessage from "../../../../app/GraphQLErrorMessage";
import {Trash2} from "react-feather";
import Select from "react-select";
import {options} from "../../../_lang/config";
import {roles} from "../../../../app/config";


const AdministratorItem = ({item, refetchAdmins}) => {
    const {data} = useQuery(GET_SELF);
    const admin = data ? data[GET_SELF_QUERY] : null;

    const [deleteAdmin, deleteResponse] = useMutation(DELETE_ADMIN);
    const [updateAdmin, updateResponse] = useMutation(UPDATE_ADMIN);

    const deleteItem = () => {
        if(!window.confirm("Zelite da obrisete administratora?"))  {
            return false;
        }
        deleteAdmin({variables: {id: item.id}}).then(response => {
            refetchAdmins();
        }).catch(error => {
            console.log(error);
        });
    }

    const changeRole = ({role}) => {
        if(!window.confirm("Zelite da promenite ulogu?"))  {
            return false;
        }
        updateAdmin({variables: {id: item.id, role}}).then(response => {
            refetchAdmins();
        }).catch(error => {
            console.log(error);
        });
    }

    return (
        <div className="row align-items-center bg-white mb-2 shadow-sm p-3 list_item">
            <div className="col-md-6">
                {item.name} {item.lastname} <br />
                {item.email}

                <div className="mt-2" style={{width: 260}}>
                    {admin?.role === 1
                        ? <Select
                            onChange={changeRole}
                            //styles={customSelectStylesDark}
                            isSearchable={false}
                            placeholder="Role"
                            value={roles[item.role]}
                            options={Object.values(roles)}
                            getOptionLabel={option => option.name}
                            getOptionValue={option => option.role}
                        />
                        : <div className="fw-bold"> {roles[item.role]?.name} </div>}

                </div>
            </div>
            <div className="col-md-6 d-flex justify-content-between">
                <div>
                    Pogresni pokusaji: {item.count_login_attempts} <br />
                    Poslednji login: {dateTime(item.last_login)}
                </div>
                <div style={{width: 100}}>
                    <button onClick={deleteItem} className="btn btn-light btn-sm w-100">
                        <Trash2 size={16} /> Obrisite
                    </button>
                </div>
            </div>
            <GraphQLErrorMessage error={deleteResponse.error} />
            <GraphQLErrorMessage error={updateResponse.error} />
        </div>
    )
}

export default AdministratorItem;
