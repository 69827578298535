import React from "react";
import {useQuery} from "@apollo/client";
import {GET_CATEGORIES, GET_CATEGORIES_QUERY} from "../../graphql/categories";
import Loader from "../../../../app/Loader";
import GraphQLErrorMessage from "../../../../app/GraphQLErrorMessage";
import ProductCategoryItem from "./ProductCategoryItem";



function ProductCategories({product, refetch}) {
    const { data, loading, error} =  useQuery(GET_CATEGORIES, {
        variables: {parent: null}
    });

    if(loading) return <Loader />
    if(error)   return <GraphQLErrorMessage error={error} />
    const categories = data[GET_CATEGORIES_QUERY];

    return  (
        <div>
            {categories.map(category => {
                return <ProductCategoryItem key={category.id}
                            checked={product.categories}
                            category={category}
                            product_id={product.id}
                            refetch={refetch} />
            })}
        </div>
    )
}
export default ProductCategories;