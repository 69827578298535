import {gql} from "@apollo/client";


export const CREATE_PRODUCT_IMAGE = gql`
    mutation ($product_id: ID!, $image_id: ID!)  {
        createProductImage(product_id: $product_id, image_id: $image_id) {
            id
        }
    }
`
export const DELETE_PRODUCT_IMAGE = gql`
    mutation ($id: ID!) {
        deleteProductImage(id: $id) {
            id
        }
    }
`