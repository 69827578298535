import CmsBlogs from "./views/cmsBlogs/CmsBlogs";
import slugify from "slugify";
import CmsBlog from "./views/cmsBlog/cmsBlog";
import Blogs from "./views/blogs/Blogs";
import Blog from "./views/blog/Blog";


const blogRoot = ["/vest", "/вест", "/blog"];
const blogsRoot = ["/vesti", "/вести", "/blogs"];
const routes = {
    cmsBlogs: {path: "/cms/blogs", component: CmsBlogs, middleware: 'cms.AdminAuthenticatedMiddleware'},
    blogs: {path: [blogsRoot[0], blogsRoot[1], blogsRoot[2]], component: Blogs, middleware: 'public.PublicMiddleware'},
    cmsBlog: {
        path: `/cms/blog/:id/:slug?`,
        component: CmsBlog,
        middleware: 'cms.AdminAuthenticatedMiddleware',
        compile: (item) => {
            return `/cms/blog/${item.id}/${slugify(item.title)}`;
        },
    },
    blog: {
        path: [`${blogRoot[0]}/:id/:slug?`, `${blogRoot[1]}/:id/:slug?`, `${blogRoot[2]}/:id/:slug?`],
        component: Blog,
        middleware: 'public.PublicMiddleware',
        compile: (item, lang) => {
            if (!item) return blogRoot[lang];
            return `${blogRoot[lang]}/${item.id}/${slugify(item.title)}`;
        },
    },
};
const cmsLeftMenu = [
    {path: routes.cmsBlogs.path, name: "Blog", position: 5},
];

const exports = {
    routes: routes,
    cmsLeftMenu: cmsLeftMenu,
}
export default exports;