import {gql} from "@apollo/client";
import {PRODUCT_FRAGMENT} from "./ProductFragment";
import {PRODUCT_CONNECTED_FRAGMENT} from "./connected";
import {CATEGORY_FRAGMENT} from "../../categories/graphql/categories";
import {STORAGE_FRAGMENT} from "../../storage/graphql/StorageFragment";
import {FILTER_FRAGMENT} from "../../filters/graphql/filter";


export const GET_PRODUCTS_QUERY = "getProducts";
export const GET_PRODUCTS = gql`
    ${PRODUCT_FRAGMENT}
    query($first: Int, $page: Int, $search: String, $category: [ID], $order: Int, $price: Float, 
        $filters: String, $all: Boolean) {
        getProducts(first: $first, page: $page, search: $search, category: $category, order: $order, 
        price: $price, filters: $filters, all: $all) {
            data {
                ...ProductFragment
                group {
                    ...ProductFragment
                }
            }
            paginatorInfo {
                currentPage
                lastPage
                count
                total
            }
        }
    }
`

export const GET_PRODUCTS_WITH_FILTERS = gql`
    ${PRODUCT_FRAGMENT}
    ${FILTER_FRAGMENT}
    query($first: Int, $page: Int, $search: String, $category: [ID], $order: Int, $price: Float,
        $filters: String, $all: Boolean) {
        getProducts(first: $first, page: $page, search: $search, category: $category, order: $order,
            price: $price, filters: $filters, all: $all) {
            data {
                ...ProductFragment
                group {
                    ...ProductFragment
                }
                filters {
                    ...FilterFragment
                }
            }
            paginatorInfo {
                currentPage
                lastPage
                count
                total
            }
        }
    }
`

export const GET_BASKET_PRODUCTS_QUERY = "getBasketProducts";
export const GET_BASKET_PRODUCTS = gql`
    ${PRODUCT_FRAGMENT}
    ${FILTER_FRAGMENT}
    query ($ids: [ID!]!)  {
        getBasketProducts(ids: $ids)     {
            ...ProductFragment
            filters {
                ...FilterFragment
            }
        }
    }
`


export const GET_PRODUCT_QUERY = "getProduct";
export const GET_PRODUCT = gql`
    ${PRODUCT_FRAGMENT}
    ${PRODUCT_CONNECTED_FRAGMENT}
    ${CATEGORY_FRAGMENT}
    ${STORAGE_FRAGMENT}
    ${FILTER_FRAGMENT}
    query($id: ID, $all: Boolean) {
        getProduct(id: $id, all: $all) {
            ...ProductFragment
            description
            descriptions
            sizes_image {
                ...StorageFragment
            }
            categories {
                ...CategoryFragment
                productCategoryPivot {
                    id
                }
            }
             images {
                ...StorageFragment
                 productImagePivot {
                     id
                 }
            }
            filters {
                ...FilterFragment
                productFilterPivot {
                    id
                }
            }
             group {
                ...ProductFragment
            }
            connected   {
                ...ProductFragment
                connectedPivot   {
                    ...ProductConnectedFragment
                }
            }
        }
    }
`

export const CREATE_PRODUCT = gql`
    ${PRODUCT_FRAGMENT}
    mutation ($name: String!)  {
        createProduct(name: $name) {
            ...ProductFragment  
        }
    }
`
export const UPSERT_PRODUCT = gql`
    ${PRODUCT_FRAGMENT}
    mutation ($id: ID!, $name: String, $code: String, $brand: String, $image_id: ID, $active: Boolean,
    $group_id: String, $price: Float, $discount_price: Float, $action: Boolean, $new: Boolean, $video: String,
    $description: String, $seo_title: String, $seo_description: String, $seo_keywords: String,
    $attach_filters: [ID], $detach_filters: [ID],
        
        $sizes_image_id: ID,
        $Cotton: Boolean,
        $Cotton_polyester: Boolean,
        $Leather: Boolean,
        $Polyester: Boolean,
        $Ripstop: Boolean,
        $Twill_keper: Boolean,
        $Very_cold_weather: Boolean,
        $Waterproof: Boolean,
        $Water_repellent: Boolean,
        $Windproof: Boolean
    
    )  {
        upsertProduct(id: $id, name: $name, code: $code, brand: $brand, image_id: $image_id,
            active: $active, group_id: $group_id, price: $price, discount_price: $discount_price,
            action: $action, new: $new, description: $description, video: $video,
            seo_title: $seo_title, seo_description: $seo_description, seo_keywords: $seo_keywords,
            attach_filters: $attach_filters, detach_filters: $detach_filters,
            
            sizes_image_id: $sizes_image_id,
            Cotton: $Cotton,
            Cotton_polyester: $Cotton_polyester,
            Leather: $Leather,
            Polyester: $Polyester,
            Ripstop: $Ripstop,
            Twill_keper: $Twill_keper,
            Very_cold_weather: $Very_cold_weather,
            Waterproof: $Waterproof,
            Water_repellent: $Water_repellent,
            Windproof: $Windproof
            
        ) {
            ...ProductFragment  
        }
    }
`
export const DELETE_PRODUCT = gql`
    ${PRODUCT_FRAGMENT}
    mutation ($id: ID!) {
        deleteProduct(id: $id) {
            ...ProductFragment
        }
    }
`
export const PRODUCT_TO_TOP = gql`
    ${PRODUCT_FRAGMENT}
    mutation ($id: ID!) {
        productToTop(id: $id) {
            ...ProductFragment
        }
    }
`