import {trans, widget} from "../../../modulesCompiler";
import React from "react";
import {useQuery} from "@apollo/client";
import {GET_ORDER_BY_PATH, GET_ORDER_BY_PATH_QUERY} from "../../graphql/order";
import Loader from "../../../../app/Loader";
import GraphQLErrorMessage from "../../../../app/GraphQLErrorMessage";
import OrderRender from "../order/OrderRender";

function OrderStatus(props) {
    const transThankYou1 = trans("uspesno ste porucili");
    const transThankYou2 = trans("hvala na poverenju");

    const code = props.computedMatch?.params?.code;
    const thankYou = props.computedMatch?.params?.thankYou;
    const {data, loading, error} = useQuery(GET_ORDER_BY_PATH, {
        variables: {path: code}
    });
    if(loading) return <Loader />
    if(error)   return <GraphQLErrorMessage error={error} />
    const order = data[GET_ORDER_BY_PATH_QUERY];
    if(!order)   {
        return <>
            {widget("public.Breadcrumbs", {links: [{trans: "STATUS"}]})}
            {widget("public.Error404")}
        </>
    }

    return  (
        <div>
            {widget("public.Breadcrumbs", {links: [{trans: "STATUS"}]})}
            <div className="container mb-6">
                {thankYou && thankYou === "thank-you" &&
                <div className="alert alert-success h4 text-center pt-5 pb-5">
                    <div className="mb-2"> {transThankYou1} </div>
                    {transThankYou2}
                </div>}
                <OrderRender order={order} />
            </div>
        </div>
    )
}
export default OrderStatus;