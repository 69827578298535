import {useState} from "react";
import GraphQLErrorField from "../../../../app/GraphQLErrorField";
import {useMutation} from "@apollo/client";
import {LOGIN_ADMIN, LOGIN_ADMIN_QUERY} from "../../graphql/admin";
import GraphQLErrorMessage from "../../../../app/GraphQLErrorMessage";
import {providerLoginAdmin} from "../../adminProvider";
import {route} from "../../../modulesCompiler";
import {cmsEntryPoint} from "../../config";

function Login()    {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [remember, setRememeber] = useState(true);
    const [loginError, setLoginError] = useState("");
    const [loginAdminMutation, {error, loading}] = useMutation(LOGIN_ADMIN);
    const dashboardRedirect = route(cmsEntryPoint);

    const submit = (e) => {
        e.preventDefault();
        setLoginError("");
        loginAdminMutation({variables: {email: email, password: password}}).then(response => {
            const JWToken = response.data[LOGIN_ADMIN_QUERY];
            if(JWToken.error)   {
                setLoginError(JWToken.error);
                return false;
            }
            if(JWToken.token)   {
                providerLoginAdmin(JWToken.token, remember);
                window.location.href = dashboardRedirect;
            }
        }).catch(({graphQLErrors}) => {
            console.log(graphQLErrors)
        });
    }

    return <div>
        <form onSubmit={submit}>
            <h4> Ulogujte se </h4>

            <label className="d-block mb-3">
                Email:
                <input value={email} onChange={(e) => setEmail(e.target.value)}
                       type="email" className="form-control" required={true} />
            </label>
            <GraphQLErrorField error={error} field="email" />

            <label className="d-block mb-3">
                Password:
                <input  value={password} onChange={(e) => setPassword(e.target.value)}
                        type="password" className="form-control" required={true} />
            </label>
            <label className="d-block mb-3">
                <input onChange={(e) => setRememeber(!!e.target.checked)}
                    type="checkbox" checked={remember} /><span /> Zapamti me
            </label>
            <GraphQLErrorField error={error} field="password" />

            <button disabled={loading} className="btn btn-primary w-100" type="submit">
                Ulogujte se
            </button>

            <GraphQLErrorMessage error={error} />
            {loginError &&
            <div className="alert alert-danger mt-2">{loginError}</div>}
        </form>
    </div>

}
export default Login;