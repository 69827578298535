import React, {useCallback, useEffect, useState} from "react";
import { useEmblaCarousel } from "embla-carousel/react"
import ProductRender from "../widgets/ProductRender";
import {DotButton, NextButton, PrevButton} from "../../../app/EmblaNavigation";



const ProductsCarousel = ({products, count=4}) => {
    const [viewportRef, embla] = useEmblaCarousel({
        containScroll: "trimSnaps",
        align: "start"
    });

    const [selectedIndex, setSelectedIndex] = useState(0);
    const [scrollSnaps, setScrollSnaps] = useState([]);
    const scrollTo = useCallback((index) => embla && embla.scrollTo(index), [
        embla
    ]);

    const [prevBtnEnabled, setPrevBtnEnabled] = useState(false);
    const [nextBtnEnabled, setNextBtnEnabled] = useState(false);
    const scrollPrev = useCallback(() => embla && embla.scrollPrev(), [embla]);
    const scrollNext = useCallback(() => embla && embla.scrollNext(), [embla]);

    const onSelect = useCallback(() => {
        if (!embla) return;
        setSelectedIndex(embla.selectedScrollSnap());
        setPrevBtnEnabled(embla.canScrollPrev());
        setNextBtnEnabled(embla.canScrollNext());
    }, [embla, setSelectedIndex]);

    useEffect(() => {
        if (!embla) return;
        onSelect();
        setScrollSnaps(embla.scrollSnapList());
        embla.on("select", onSelect);
    }, [embla, setScrollSnaps, onSelect]);

    return (
        <div className="border rounded-3 border-2">
            <div className="embla mt-4 mb-2">
                <div className="embla__viewport" ref={viewportRef}>
                    <div className="embla__container">
                        {products.map((product, i) => {
                            return <div  style={{minWidth: 100/count + "%", width: 100/count + "%"}}
                                className="embla__slide products_carousel ps-2 pe-2 pb-2" key={product.id}>
                                <ProductRender
                                    load={((selectedIndex+1) * count) > i}
                                    item={product} />
                            </div>
                        })}
                    </div>
                </div>
                <div className="d-none">
                    <PrevButton onClick={scrollPrev} enabled={prevBtnEnabled} />
                    <NextButton onClick={scrollNext} enabled={nextBtnEnabled} />
                </div>

                <div className="embla__dots position-relative mt-3 d-none d-md-flex">
                    {scrollSnaps.map((_, index) => (
                        <DotButton
                            key={index}
                            selected={index === selectedIndex}
                            onClick={() => scrollTo(index)}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default ProductsCarousel;