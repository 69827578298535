import {Repeat} from "react-feather";


function DragHandler() {

    return (
        <button className="btn btn-sm btn-light p-1 me-2" title="Prevucite da promenite raspored.">
            <Repeat size={17} />
        </button>
    )
}
export default DragHandler;