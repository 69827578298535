import {useState} from "react";
import {ChevronDown, ChevronUp} from "react-feather";


function DropDown({title, body})    {
    const [open, setOpen] = useState(false);

    return (
        <div className="bg-white rounded-3 mb-2 list_item">
            <div onClick={() => setOpen(!open)}  className="d-flex pointer p-3">
                {open ? <ChevronUp size={18} /> : <ChevronDown size={18} />}
                <h6 className="mb-0 ms-2">{title}</h6>
            </div>

            {open &&
            <div className="p-3">
                {body}
            </div>}
        </div>
    )
}
export default DropDown;