import {useQuery} from "@apollo/client";
import Loader from "../../../../app/Loader";
import GraphQLErrorMessage from "../../../../app/GraphQLErrorMessage";
import React, {useState} from "react";
import {GET_PROMO_CODES, GET_PROMO_CODES_QUERY} from "../../graphql/promo";
import {widget} from "../../../modulesCompiler";
import AddPromoCodeForm from "./AddPromoCodeForm";
import PromoCodeItem from "./PromoCodeItem";


function PromoCodes() {
    const [collapsed, setCollapsed] = useState(true);
    const { data, loading, error, variables, refetch, previousData } =  useQuery(GET_PROMO_CODES, {
        variables: {first: 40, page: 1, search: undefined},
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only'
    });

    if(loading && !previousData) return <Loader />
    if(error)   return <GraphQLErrorMessage error={error} />
    let holder = data || previousData;
    const codes = holder ? holder[GET_PROMO_CODES_QUERY].data : [];
    const paginatorInfo = holder ? holder[GET_PROMO_CODES_QUERY].paginatorInfo : {};

    return  (
        <div className="container mb-6">
            <div className="mb-3">
                <AddPromoCodeForm refetch={refetch} />
            </div>

            <div className="mb-3 row">
                <div className="col-md-6">
                    <input value={variables.search || ""} name="search"
                           onChange={(e) => refetch({search: e.target.value || undefined, page: 1})}
                           type="text" placeholder="Pretraga..." className="form-control" />
                </div>
                <div className="col-md-6 text-end">
                    {codes.length > 0 &&
                    widget("cms.Pagination", {paginatorInfo: paginatorInfo, refetch: refetch})}
                </div>
            </div>

            {codes.length === 0 &&
            <div className="p-5 text-center"> Prazna pretraga </div>}
            {codes.map(item => {
                return <PromoCodeItem  key={item.id} item={item} refetch={refetch} />
            })}

            <div className="mb-3 text-end">
                {codes.length > 0 &&
                widget("cms.Pagination", {paginatorInfo: paginatorInfo, refetch: refetch})}
            </div>
        </div>
    )
}
export default PromoCodes;