import React, {useCallback, useEffect, useState} from "react";
import Lightbox from 'react-image-lightbox';
import {widget} from "../../../modulesCompiler";
import {useEmblaCarousel} from "embla-carousel/react";
import ProductRender from "../../widgets/ProductRender";
import {DotButton, NextButton, PrevButton} from "../../../../app/EmblaNavigation";

function ShopProductGallery({image, images}) {
    if(image)   {
        const find = images.find(item => item.id === image.id);
        if(!find)    images = [image, ...images];
    }
    const [open, setOpen] = useState(false);
    const [photoIndex, setPhotoIndex] = useState(0);

    const selected = images[photoIndex];

    const [viewportRef, embla] = useEmblaCarousel({
        containScroll: "trimSnaps",
        align: "start"
    });

    const [selectedIndex, setSelectedIndex] = useState(0);
    const [scrollSnaps, setScrollSnaps] = useState([]);
    const scrollTo = useCallback((index) => embla && embla.scrollTo(index), [
        embla
    ]);

    const onSelect = useCallback(() => {
        if (!embla) return;
        setSelectedIndex(embla.selectedScrollSnap());
    }, [embla, setSelectedIndex]);

    useEffect(() => {
        if (!embla) return;
        onSelect();
        setScrollSnaps(embla.scrollSnapList());
        embla.on("select", onSelect);
    }, [embla, setScrollSnaps, onSelect]);

    return (
        <>
            {open &&
            <Lightbox
                mainSrc={widget("storage.image")(images[photoIndex], true)}
                nextSrc={widget("storage.image")(images[(photoIndex + 1) % images.length], true)}
                prevSrc={widget("storage.image")(images[(photoIndex + images.length - 1) % images.length], true)}
                onCloseRequest={() => setOpen(false)}
                onMovePrevRequest={() => setPhotoIndex((photoIndex + images.length - 1) % images.length)}
                onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % images.length)}
            />}

            <div onClick={setOpen} className="pointer img_overlay">
                <img src={widget("storage.image")(selected, true)} className="img-fluid"
                     alt={selected?.name} />
            </div>

            <div className="embla mt-3 mb-2">
                <div className="embla__viewport" ref={viewportRef}>
                    <div className="embla__container">
                        {images.map((item, i) => {
                            return <div className="embla__slide border rounded-2 p-1 me-1" style={{minWidth: "27%", width: "27%"}} key={item.id}>
                                <div onClick={() => setPhotoIndex(i)}>
                                    <img src={widget("storage.image", item)} className="img-fluid" alt={item.name}/>
                                </div>
                            </div>
                        })}
                    </div>
                </div>

                {scrollSnaps.length > 1 &&
                <div className="embla__dots position-relative mt-3 d-flex">
                    {scrollSnaps.map((_, index) => (
                        <DotButton
                            key={index}
                            selected={index === selectedIndex}
                            onClick={() => scrollTo(index)}
                        />
                    ))}
                </div>}
            </div>
        </>

    )
}
export default ShopProductGallery;
