import {useContext} from "react";
import SettingsContext from "../contexts/SettingsContext";
import {UPSERT_SETTING} from "../graphql/setting";
import {widget} from "../../modulesCompiler";
import CmsUpdateField from "../../../app/CmsUpdateField";


function FieldUpdate({field, title, element = "textarea", single = false}) {
    const {getSetting, refetch} = useContext(SettingsContext);
    const setting = getSetting(field);

    if (single) {
        return (
            <div>
                <div className="mb-1 fw-bold">{title}</div>
                <CmsUpdateField
                    element={element}
                    id={setting.id}
                    MUTATION={UPSERT_SETTING}
                    name="value"
                    refetch={refetch}
                    nullable={true}
                    extend={{field: field}}
                    value={setting.value}/>
            </div>
        )
    }

    return (
        <div>
            <div className="mb-1 fw-bold">{title}</div>
            {widget("lang.LangCmsUpdateField", {
                element: element,
                id: setting.id,
                MUTATION: UPSERT_SETTING,
                name: "value",
                refetch: refetch,
                nullable: true,
                extend: {field: field},
                values: setting.values
            })}
        </div>
    )
}

export default FieldUpdate;