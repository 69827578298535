import React, {useState} from "react";
import {dateTime, fromNow} from "../../../../app/helpers";
import {Trash2} from "react-feather";
import toast from "react-hot-toast";
import {useMutation} from "@apollo/client";
import {DELETE_PROMO_CODE} from "../../graphql/promo";
import moment from "moment";


function PromoCodeItem({item, refetch}) {
    const [collapsed, setCollapsed] = useState(true);
    const [deleteCode] = useMutation(DELETE_PROMO_CODE);

    const deleteItem = () => {
        if(!window.confirm("Zelite da obrišete kod?"))  {
            return false;
        }
        deleteCode({variables: {id: item.id}}).then(response => {
            toast.success("Obrisano!");
            refetch();
        }).catch(error => {
            toast.error(error.message);
        });
    }

    return  (
        <div className="bg-white mb-2 shadow-sm p-3 list_item">
            {item.expires_at && moment.utc(item.expires_at).isBefore() &&
            <div className="alert alert-danger p-1 ps-3"> Kod je istekao. </div>}
            {(item.count < 1) &&
            <div className="alert alert-danger p-1 ps-3"> Kod je iskorišćen. </div>}

            <div className="d-flex align-items-center">
                <div className="flex-grow-1">

                    <div className="h4">
                        {item.code}
                        <span className="text-danger ms-3"> -{item.discount}%</span>
                    </div>
                    <div className="text-black-50">kreirano: {dateTime(item.created_at)}</div>
                    <div className="text-black-50">preostalo: {item.count || 0}</div>
                    {item.expires_at &&
                    <div className="text-black-50">
                        ističe: {dateTime(item.expires_at)}, {fromNow(item.expires_at)}
                    </div>}

                </div>
                <div className="me-2" style={{flex: "120px 0 0 "}}>
                    <button onClick={deleteItem} className="btn btn-light btn-sm w-100">
                        <Trash2 size={16} />  Obrišite
                    </button>
                </div>
            </div>
        </div>
    )
}
export default PromoCodeItem;