import Select from "react-select";
import React, {useContext} from "react";
import {customSelectStylesDark} from "../../../app/helpers";
import LangContext from "../contexts/LangContext";
import {options} from "../config";


function DropDown() {
    const {lang, setLang} = useContext(LangContext);

    const handleChange = (prop) => {
        setLang(prop.id);
    }

    return (
        <div style={{width: 120}}>
            <Select
                onChange={handleChange}
                styles={customSelectStylesDark}
                isSearchable={false}
                options={options}
                defaultValue={options[lang]} />
        </div>
    )
}
export default DropDown;