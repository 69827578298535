import {gql} from "@apollo/client";


export const CREATE_PRODUCT_CATEGORY = gql`
    mutation ($product_id: ID!, $category_id: ID!)  {
        createProductCategory(product_id: $product_id, category_id: $category_id) {
            id
        }
    }
`
export const DELETE_PRODUCT_CATEGORY = gql`
    mutation ($id: ID!) {
        deleteProductCategory(id: $id) {
            id
        }
    }
`
