import moment from "moment";

export function randString(length= 6) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

export function setCookie(name, data, exdays=null) {
    var expires = '';
    if(exdays !== null)     {
        var d = new Date();
        d.setTime(d.getTime() + (exdays*24*60*60*1000));
        expires = "expires="+ d.toUTCString() + ";";
    }
    document.cookie = name + "=" + data + ";" + expires + "path=/";
}

export function parseJwt (token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
}

const dateTimeSqlFormat = 'Do MMMM YYYY, HH:mm';
const dateSqlFormat = 'Do MMMM YYYY';
export function dateTime(timestamp) {
    if(!timestamp) return null;
    return moment.utc(timestamp).local().format(dateTimeSqlFormat)
}
export function date(timestamp) {
    if(!timestamp) return null;
    return moment.utc(timestamp).local().format(dateSqlFormat)
}
export function fromNow(timestamp) {
    if(!timestamp) return null;
    return moment.utc(timestamp).local().fromNow()
}

export const formatPrice = (price) => {
    if(!price && price !== 0) return '';
    price = Number(parseFloat(price).toFixed(2));
    let strPrice = String(price);
    if(price > 999)     {
        const pos = strPrice.includes(".") ?  -6 : -3;
        strPrice = strPrice.slice(0, pos) + " " + strPrice.slice(pos);
    }
    if(price > 999999)     {
        const pos = strPrice.includes(".") ?  -10 : -7;
        strPrice = strPrice.slice(0, pos) + " " + strPrice.slice(pos);
    }
    return strPrice.replace(".", ",");
};
export function processPrice(item)      {
    if(item.discount_price)     return item.discount_price;
    return item.price;
}
export function calculateDiscount(item) {
    if(!item.discount_price) return false;
    if(!item.price) return false;
    return Math.round((item.price - item.discount_price)/item.price * 100);
}

export function youtubeLink(url) {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);

    return (match && match[2].length === 11)
        ? "//www.youtube.com/embed/" + match[2]
        : null;
}

export const customSelectStyles = {
    control: (base, state) => ({
        ...base,
        boxShadow: "none",
        borderColor: "#000000"
        // You can also use state.isFocused to conditionally style based on the focus state
    }),
    option: (provided, state) => ({
        ...provided,
        background: state.isSelected ? "#000000" : 'white',
    }),
}
export const customSelectStylesDark = {
    singleValue: (base, state) => ({
        color: "#ffffff",
    }),
    control: (base, state) => ({
        ...base,
        boxShadow: "none",
        borderColor: "#ffffff",
        background: "#000000",

        // state.isFocused
        // state.isSelected
    }),
    option: (provided, state) => ({
        ...provided,
        background: state.isSelected ? "#000000" : 'white',
    }),
}
