import {ChevronLeft, ChevronRight} from "react-feather";
import React from "react";


export const PrevButton = ({ enabled, onClick }) => (
    <button
        className="embla__button embla__button--prev shadow-sm"
        onClick={onClick}
        disabled={!enabled}
    >
        <ChevronLeft size={26} />
    </button>
);

export const NextButton = ({ enabled, onClick }) => (
    <button
        className="embla__button embla__button--next shadow-sm"
        onClick={onClick}
        disabled={!enabled}
    >
        <ChevronRight size={26} />
    </button>
);

export const DotButton = ({ selected, onClick }) => (
    <button
        className={`embla__dot ${selected ? "is-selected" : ""}`}
        type="button"
        onClick={onClick}
    />
);

