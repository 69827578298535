import React from "react";
import {widget} from "../../../modulesCompiler";
import {UPSERT_BLOCK_TEXT} from "../../graphql/text";



function BlockText({block, refetch, element = "textarea"}) {

    return  (
        <div className="p-3">
            <div className="text-black-50 mb-3">
                Tekst naslov.
            </div>
            <div>
                {widget("lang.LangCmsUpdateField", {
                    tabs: true,
                    element: element,
                    id: block.text?.id,
                    MUTATION: UPSERT_BLOCK_TEXT,
                    name: "text",
                    refetch: refetch,
                    nullable: true,
                    extend: {block_id: block.id},
                    placeholder: "Unesite naslov.",
                    values: block.text?.texts
                })}
            </div>

        </div>
    )
}
export default BlockText;