import {trans, widget} from "../../../modulesCompiler";
import React from "react";


function Reklamacije() {
    const lang = widget("lang.useGetLang", null);

    return (
        <>
            {widget("public.Breadcrumbs", {links: [{trans: trans("reklamacije")}]})}
            <div className="container mb-6">
                {lang === 0 &&
                <>
                    <h1 className="oswald fw-bold text-uppercase h3">KORISNIČKI SERVIS - REKLAMACIJE</h1>
                    <br/>
                    <h5 className="oswald fw-bold">PRIMEDBE I REKLAMACIJE</h5>

                    Žalbe, reklamacije i primedbe potrošači mogu izjaviti:
                    <br/><br/>
                    1. slanjem na elektronsku adresu <a href="mailto:shop@horus.rs" className="link-secondary">shop@horus.rs</a> ,
                    <br/><br/>
                    2. na broj telefona <a href="tel:+381 065 43210 23" className="link-secondary">065/43210-23</a> u periodu od 08 do 16 časova svakog radnog dana,
                    <br/><br/>
                    3. redovnom poštom ili kurirskom službom na adresu – Horus Wear doo, Kralja Milana 22-24, 11000 Beograd – Stari grad, Republika Srbija.
                    <br/><br/>
                    Prodavac će u roku od jednog radnog dana po prijemu reklamacije u maloprodajni objekat, pismenim ili usmenim putem potvrditi Potrošaču da je reklamacija primljena. U roku od 8 dana od dana prijema reklamacije, prodavac će pisanim ili elektronskim putem obavestiti potrošača o odluci (prihvaćena ili odbijena primedba potrošača u vezi sa saobraznošću artikla) u vezi sa reklamiranim artiklom, kao i o eventualnom načinu rešavanju reklamacije u skladu sa Zakonom o zaštiti potrošača (povraćaj novca, odgovarajuće umanjenje cene, zamena ili popravka artikla). Reklamacija će biti rešena u roku od 15 dana od dana prijema reklamacije. Prodavac će učiniti sve što je u njegovoj moći radi rešavanja sporne situacije, a sve u cilju otklanjanja predmetnih nedostataka.
                    <br/><br/>
                    U slučaju reklamacije zbog nesaobraznosti, potrošač može da pošalje robu o trošku trgovca kurirskom službom "Dexpress" sa kojom imamo ugovorni odnos.
                    <br/>   <br/>
                    Kao prodavac dužni smo da potrošaču bez odlaganja izdamo pisanu potvrdu ili elektronskim putem potvrdimo prijem reklamacije, odnosno saopštimo broj pod kojim je zavedena njegova reklamacija u evidenciji primljenih reklamacija.

                </>}
                {lang === 1 &&
                <>
                    <h1 className="oswald fw-bold text-uppercase h3">КОРИСНИЧКИ СЕРВИС - РЕКЛАМАЦИЈЕ</h1>
                    <br/>
                    <h5 className="oswald fw-bold">ПРИМЕДБЕ И РЕКЛАМАЦИЈЕ</h5>

                    Жалбе, рекламације и примедбе потрошачи могу изјавити:
                    <br/><br/>
                    1. слањем на електронску адресу <a href="mailto:shop@horus.rs" className="link-secondary">shop@horus.rs</a>,
                    <br/><br/>
                    2. на број телефона <a href="tel:+381 065 43210 23" className="link-secondary">065/43210-23</a> у периоду од 08 до 16 часова сваког радног дана,
                    <br/><br/>
                    3. редовном поштом или курирском службом на адресу – Horus Wear doo, Краља Милана 22-24, 11000 Београд – Стари град, Република Србија.
                    <br/><br/>
                    Продавац ће у року од једног радног дана по пријему рекламације у малопродајни објекат, писменим или усменим путем потврдити Потрошачу да је рекламација примљена. У року од 8 дана од дана пријема рекламације, продавац ће писаним или електронским путем обавестити потрошача о одлуци (прихваћена или одбијена примедба потрошача у вези са саобразношћу артикла) у вези са рекламираним артиклом, као и о евентуалном начину решавању рекламације у складу са Законом о заштити потрошача (повраћај новца, одговарајуће умањење цене, замена или поправка артикла). Рекламација ће бити решена у року од 15 дана од дана пријема рекламације. Продавац ће учинити све што је у његовој моћи ради решавања спорне ситуације, а све у циљу отклањања предметних недостатака.
                    <br/><br/>
                    У случају рекламације због несаобразности, потрошач може да пошаље робу о трошку трговца курирском службом "Dexpress" са којом имамо уговорни однос.
                    <br/><br/>
                    Као продавац дужни смо да потрошачу без одлагања издамо писану потврду или електронским путем потврдимо пријем рекламације, односно саопштимо број под којим је заведена његова рекламација у евиденцији примљених рекламација.
                    </>}
                {lang === 2 &&
                <>
                    <h1 className="oswald fw-bold text-uppercase h3">COMPLAINTS</h1>
                    <br/>
                    When receiving your shipment, please inspect the package in the presence of the courier. If
                    there are visible signs of damage (torn parts or dents), you should not accept the parcel.
                    In that case, please e-mail us your details (first and last name, contact number)
                    at <a href="mailto:shop@horus.rs" className="link-secondary">shop@horus.rs</a> and specify the reason why you refused the delivery. We will contact you at our earliest convenience with further instructions.
                    <br/><br/>
                    If you have received the shipment and discovered, upon opening the parcel, that the
                    delivered goods do not match those ordered or that the details on the invoice are
                    incorrect, please contact us by sending an email with your details (first and last name,
                    contact number and picture of received item)
                    at <a href="mailto:shop@horus.rs" className="link-secondary">shop@horus.rs</a> (not later than 24h from the time of receipt of said goods) and describe the problem. We will respond by post or email as soon as possible, in any case no later than 8 days from the receipt of the complaint, and inform you on further steps. Sending an e-mail doesn't mean that  complaint is accepted, it is just necessary step in resolving a complaint. Complaints are to be resolved within the period of 15 days of the receipt.
                    <br/><br/>
                    The seller is obliged to deliver the goods at the price featured on the internet, plus the cost of delivery. This is valid for undamaged goods which correspond with those featured on the site.
                    <br/><br/>
                    In the case of a complaint due to non-conformity, the consumer can send the goods at the expense of the merchant by the "Dexpress" courier service with which we have a contract.
                    <br/><br/>
                    In case of improper product handling that results in damage, it is the customer who is solely responsible for the damage and reimbursement is declined, with the product being returned at the customer’s expense.

                </>}
            </div>
        </>
    )
}
export default Reklamacije;