import React from "react";
import {useMutation} from "@apollo/client";
import toast from "react-hot-toast";
import {CREATE_PRODUCT_FILTER, DELETE_PRODUCT_FILTER} from "../../graphql/productFilter";
import {UPSERT_PRODUCT} from "../../../products/graphql/product";



function ProductFilterItem({filter, checked, product_id, refetch}) {
    const [deleteProductFilter] = useMutation(DELETE_PRODUCT_FILTER);
    const [createProductFilter] = useMutation(CREATE_PRODUCT_FILTER);
    const [upsertProduct] = useMutation(UPSERT_PRODUCT);

    const changed = (fil, chkd) => {
        console.log(fil)
        console.log(product_id)
        console.log(checked)

        if(chkd)    {
            upsertProduct({variables: {id: product_id, detach_filters: [fil.id]}}).then(response => {
                toast.success("Obrisano!");
                if(refetch) refetch();
            }).catch(error => {
                toast.error(error.message);
            });
        } else  {
            upsertProduct({variables: {id: product_id, attach_filters: [fil.id]}}).then(response => {
                toast.success("Obrisano!");
                if(refetch) refetch();
            }).catch(error => {
                toast.error(error.message);
            });
        }
    }
    const find = checked.find(item => item.id === filter.id);

    return <div>
        <label>
            <strong>
                <input
                    value={filter.id}
                    name="parent"
                    type="checkbox"
                    onChange={() => changed(find || filter, !!find)}
                    checked={!!find} />  {filter.name}
            </strong>
        </label>

        <div>
            {filter.children.map(child => {
                const findChild = checked.find(item => item.id === child.id);
                return <label key={child.id} className="p-2 border mb-2 me-2 d-inline-block">
                    <input
                        value={child.id}
                        name="category"
                        type="checkbox"
                        onChange={() => changed(findChild || child, !!findChild)}
                        checked={!!findChild} />  {child.name}
                </label>
            })}
        </div>
    </div>

}
export default ProductFilterItem;