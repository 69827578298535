import React, {useCallback, useEffect, useState} from "react";
import { useEmblaCarousel } from "embla-carousel/react"
import Banner from "./Banner";
import {DotButton, NextButton, PrevButton} from "../../../app/EmblaNavigation";

const BannersCarousel = ({slides, count}) => {

    const [viewportRef, embla] = useEmblaCarousel({
        containScroll: "trimSnaps",
        loop: true,
        align: "start"

    });

    const [selectedIndex, setSelectedIndex] = useState(0);
    const [scrollSnaps, setScrollSnaps] = useState([]);
    const scrollTo = useCallback((index) => embla && embla.scrollTo(index), [
        embla
    ]);

    const [prevBtnEnabled, setPrevBtnEnabled] = useState(false);
    const [nextBtnEnabled, setNextBtnEnabled] = useState(false);
    const scrollPrev = useCallback(() => embla && embla.scrollPrev(), [embla]);
    const scrollNext = useCallback(() => embla && embla.scrollNext(), [embla]);


    const onSelect = useCallback(() => {
        if (!embla) return;
        setSelectedIndex(embla.selectedScrollSnap());
        setPrevBtnEnabled(embla.canScrollPrev());
        setNextBtnEnabled(embla.canScrollNext());
    }, [embla, setSelectedIndex]);

    useEffect(() => {
        if (!embla) return;
        onSelect();
        setScrollSnaps(embla.scrollSnapList());
        embla.on("select", onSelect);
    }, [embla, setScrollSnaps, onSelect]);

    return (
        <div className="embla">
            <div className="embla__viewport" ref={viewportRef}>
                <div className="embla__container">
                    {slides.map(banner => {
                        return <div className="embla__slide" key={banner.id} style={{minWidth: 100/count + "%"}}>
                            <div className="ms-2 me-2">
                                <Banner banner={banner} className="w-100" />
                            </div>
                        </div>
                    })}
                </div>
            </div>
            <div className="d-none">
                <PrevButton onClick={scrollPrev} enabled={prevBtnEnabled} />
                <NextButton onClick={scrollNext} enabled={nextBtnEnabled} />
            </div>
            <div className="embla__dots position-relative mt-3">
                {scrollSnaps.map((_, index) => (
                    <DotButton
                        key={index}
                        selected={index === selectedIndex}
                        onClick={() => scrollTo(index)}
                    />
                ))}
            </div>
        </div>
    );
};

export default BannersCarousel;