import React from "react";
import {widget} from "../../../modulesCompiler";
import {useQuery} from "@apollo/client";
import {GET_BLOG, GET_BLOG_QUERY, UPDATE_BLOG} from "../../graphql/blog";
import Loader from "../../../../app/Loader";


function CmsBlog(props) {
    const id = props.computedMatch?.params?.id;
    const {data, loading, refetch} = useQuery(GET_BLOG, {
        variables: {id: id}
    });
    if(loading) return <Loader />;
    const blog = data[GET_BLOG_QUERY];

    return <>
        <div className="container">
            <div className="row mb-4">
                <div className="col-md-3">
                    <div className="bg-white rounded-3 p-3">
                        {widget("storage.UpdateImage", {
                            id: blog.id,
                            MUTATION: UPDATE_BLOG,
                            name: "image_id",
                            refetch: refetch,
                            item: blog.image})}
                    </div>
                </div>
                <div className="col-md-9">
                    <div className="bg-white rounded-3 p-3">
                        <div className="mb-3">
                            <strong className="mb-2"> Naslov: </strong>
                            <div>
                                {widget("lang.LangCmsUpdateField", {
                                    element: "text",
                                    id: blog.id,
                                    MUTATION: UPDATE_BLOG,
                                    name: "title",
                                    refetch: refetch,
                                    required: true,
                                    values: blog.titles
                                })}
                            </div>
                        </div>

                        <div className="mb-3">
                            <strong className="mb-2"> Kratak opis: </strong>
                            <div>
                                {widget("lang.LangCmsUpdateField", {
                                    element: "textarea",
                                    id: blog.id,
                                    MUTATION: UPDATE_BLOG,
                                    name: "description",
                                    refetch: refetch,
                                    nullable: true,
                                    values: blog.descriptions
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            {widget("lang.LangCmsUpdateField", {
                tabs: true,
                element: "editor",
                id: blog.id,
                MUTATION: UPDATE_BLOG,
                name: "content",
                nullable: true,
                refetch: refetch,
                values: blog.contents
            })}
        </div>


    </>
}

export default CmsBlog;
