import DropDown from "./DropDown";
import {widget} from "../../../modulesCompiler";


function Settings() {

    return (
        <div className="container">
            <DropDown
                title="Socijalne mreže"
                body={<>
                    <div className="mb-3">
                    {widget("settings.FieldUpdate", {field: "facebook", title: "FACEBOOK", element: "text", single: true})}
                    </div>
                    <div className="mb-3">
                    {widget("settings.FieldUpdate", {field: "instagram", title: "INSTAGRAM", element: "text", single: true})}
                    </div>
                    <div className="mb-3">
                        {widget("settings.FieldUpdate", {field: "twitter", title: "TWITTER", element: "text", single: true})}
                    </div>
                    {widget("settings.FieldUpdate", {field: "youtube", title: "YOUTUBE", element: "text", single: true})}
                    </>} />

            <DropDown
                title="Kontakt"
                body={<>
                    <div className="mb-3">
                    {widget("settings.FieldUpdate", {field: "contact", title: "KONTAKT"})}
                    </div>
                <div className="mb-3">
                        {widget("settings.FieldUpdate", {field: "contact kragujevac", title: "KRAGUJEVAC KONTAKT"})}
                </div>
                    </>} />

            <DropDown
                title="Footer linkovi"
                body={<>
                    <div className="mb-3">
                        {widget("settings.FieldUpdate", {field: "link 1", title: "LINK 1", element: "text"})}
                    </div>
                    <div className="mb-3">
                        {widget("settings.FieldUpdate", {field: "link 2", title: "LINK 2", element: "text"})}
                    </div>
                    <div className="mb-3">
                        {widget("settings.FieldUpdate", {field: "link 3", title: "LINK 3", element: "text"})}
                    </div>
                    <div className="mb-3">
                        {widget("settings.FieldUpdate", {field: "link 4", title: "LINK 4", element: "text"})}
                    </div>
                    <div className="mb-3">
                        {widget("settings.FieldUpdate", {field: "link 5", title: "LINK 5", element: "text"})}
                    </div>
                </>} />


            <DropDown
                title="DEFAULT SEO"
                body={<>
                    <div className="mb-3">
                        {widget("settings.FieldUpdate", {field: "default SEO title", title: "SEO title"})}
                    </div>
                    <div className="mb-3">
                        {widget("settings.FieldUpdate", {field: "default SEO keywords", title: "SEO keywords"})}
                    </div>
                    <div className="mb-3">
                        {widget("settings.FieldUpdate", {field: "default SEO description", title: "SEO description"})}
                    </div>
                </>} />
        </div>
    )
}
export default Settings;