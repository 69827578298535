import {server} from "../../../app/config";

function storageFile() {
    return server("storage/000-file.png");
}
function noImage() {
    return server("storage/000-no-image.png");
}
export function image(image, lg=false) {
    if(!image)  {
        return noImage();
    }
    if(image.bucket !== "image")    {
        return storageFile();
    }
    const name = lg ? `lg-${image.name}` : image.name;
    return server(`storage/slike/${image.folder}/${name}`);
}
