import {gql} from "@apollo/client";
import {STORAGE_FRAGMENT} from "./StorageFragment";


export const GET_STORAGE_QUERY = "getStorage";
export const GET_STORAGE = gql`
    ${STORAGE_FRAGMENT}
    query ($first: Int, $page: Int, $search: String, $bucket: String) {
        getStorage(first: $first, page: $page, search: $search, bucket: $bucket) {
            data {
                ...StorageFragment
            }
            paginatorInfo {
                currentPage
                lastPage
                count
                total
            }
        }
    }
`
export const DELETE_STORAGE = gql`
    ${STORAGE_FRAGMENT}
    mutation ($ids: [ID!]!) {
        deleteStorage(ids: $ids) {
           ...StorageFragment
        }
    }
`