import React from "react";
import {route, widget} from "../../../modulesCompiler";
import {useMutation} from "@apollo/client";
import toast from "react-hot-toast";
import {Settings, Trash2} from "react-feather";
import {DELETE_BLOG} from "../../graphql/blog";
import {Link} from "react-router-dom";
import {dateTime} from "../../../../app/helpers";



const BlogItem = ({item, refetch}) => {
    const [deleteBlog] = useMutation(DELETE_BLOG);

    const deleteItem = () => {
        if(!window.confirm("Zelite da obrisete vest?"))  {
            return false;
        }
        deleteBlog({variables: {id: item.id}}).then(response => {
            toast.success("Obrisano!");
            refetch();
        }).catch(error => {
            toast.error(error.message);
        });
    }

    return (
        <div className="d-flex">
            <div className="me-3" style={{flex: "100px 0 0"}}>
                <div className="bcg_image" style={{backgroundImage: `url(${widget("storage.image", item.image)})`}} />
            </div>

            <div className="flex-grow-1">
                <Link to={route("blog.cmsBlog", item)}>
                    <h6>{item.title}</h6>
                </Link>
                <div className="prewrap mb-2">{item.description}</div>
                <div className="text-black-50 fs-s">{dateTime(item.created_at)}</div>
            </div>

            <div style={{flex: "130px 0 0"}}>
                <Link to={route("blog.cmsBlog", item)} className="btn btn-light btn-sm w-100 mb-2">
                    <Settings size={16} />  Detaljnije
                </Link>
                <button onClick={deleteItem} className="btn btn-light btn-sm w-100 mb-2">
                    <Trash2 size={16} />  Obrišite
                </button>
            </div>
        </div>
    )
}

export default BlogItem;
