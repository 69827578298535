import {Search} from "react-feather";
import {useState} from "react";
import {route, trans, widget} from "../../modulesCompiler";
import {useQuery} from "@apollo/client";
import {GET_PRODUCTS, GET_PRODUCTS_QUERY} from "../graphql/product";
import {Link} from "react-router-dom";
import {processPrice} from "../../../app/helpers";


function SearchModal({button}) {
    const [open, setOpen] = useState(false);
    const [search, setSearch] = useState("");

    const { data, previousData } = useQuery(GET_PRODUCTS, {
        variables: {first: 10, page: 1, search},
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only'
    });
    let holder = data || previousData;
    const products = holder ? holder[GET_PRODUCTS_QUERY].data : [];

    return (
        <>
            {open && <div onClick={() => setOpen(false)} className="overlay" />}
            {open && <div className="search_from">
                <form action="/" className="position-relative">
                    <input
                        autoFocus
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        placeholder={trans("pretraga") + "..."}
                        className="form-control ps-5" />
                    <Search size={20} strokeWidth={2} className="position-absolute" style={{top: 9, left: 10}} />
                </form>
                {(products.length > 0 && search !== "") &&
                <div className="mt-2 bg-white rounded-3 shadow-lg autocomplete text-start">
                    {products.map(item => {
                        return <div key={item.id}>
                            <Link onClick={() =>setOpen(false)} to={route("products.shopProduct", item)}>
                                <div className="d-flex align-items-center p-2">
                                    <div className="me-3" style={{width: 50}}>
                                        <div className="bcg_image" style={{backgroundImage: `url(${widget("storage.image", item.image)})`}} />
                                    </div>
                                    <div>
                                        <h6 className="oswald mb-0">{item.name}</h6>
                                        <div className="oswald">
                                            {processPrice(item) &&
                                            <span className="me-2">{processPrice(item)} {trans("currency")}</span>}
                                            {item.discount_price &&
                                            <del className="text-black-50 fs-s">{item.price} {trans("currency")}</del>}
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    })}
                </div>}
            </div>}

            {{...button, props: {...button.props, onClick: () => setOpen(true)}}}
        </>

    )
}
export default SearchModal;