import {gql} from "@apollo/client";


export const FILTER_FRAGMENT = gql`
    fragment FilterFragment on Filter {
        id
        name
        names
        parent
        position
        shop 
        locked
    }
`;


export const GET_FILTERS_QUERY = "getFilters";
export const GET_FILTERS = gql`
    ${FILTER_FRAGMENT}
    query ($parent: Int, $category_id: ID) {
        getFilters(parent: $parent, category_id: $category_id) {
            ...FilterFragment
            category_count
            children {
                ...FilterFragment
               category_count
            }
        }
    } 
`

export const UPSERT_FILTER = gql`
    ${FILTER_FRAGMENT}
    mutation ($id: ID, $name: String, $names: [String], $parent: ID)  {
        upsertFilter(id: $id, name: $name, names: $names, parent: $parent) {
            ...FilterFragment
        }
    }
`
export const DELETE_FILTER = gql`
    ${FILTER_FRAGMENT}
    mutation ($id: ID!) {
        deleteFilter(id: $id) {
            ...FilterFragment
        }
    }
`
