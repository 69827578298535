import {gql} from "@apollo/client";


export const CREATE_PRODUCT_FILTER = gql`
    mutation ($product_id: ID!, $filter_id: ID!)  {
        createProductFilter(product_id: $product_id, filter_id: $filter_id) {
            id
        }
    }
`
export const DELETE_PRODUCT_FILTER = gql`
    mutation ($id: ID!) {
        deleteProductFilter(id: $id) {
            id
        }
    }
`
