import {dateTime, formatPrice} from "../../../../app/helpers";
import {payment_method} from "../../orderProvider";
import {route, trans, widget} from "../../../modulesCompiler";
import {Link} from "react-router-dom";
import React from "react";

function OrderRender({order}) {
    const currency = order.currency;

    let orderFullPrice = 0;
    return (
        <>
            <div className="bg-white border mb-4 p-3">
                {/*<div className="mb-4 fs-4" style={{color: status.color}}>
                    <strong> Status: {status.title} </strong>
                </div>*/}
                <div className="row mb-3">
                    <div className="col-md-3">
                        <div className="fw-bold mb-3 border-bottom"> {trans("osnovno")} </div>
                        <div className="mb-1">{trans("sifra")}</div>
                        <div className="mb-3">
                            <strong>#{order.code}</strong>
                        </div>
                        <div className="mb-1">{trans("cena")}</div>
                        <div className="mb-3">
                            <div>

                                {order.promo_code
                                    ? <div className="fw-bold">
                                        <div>
                                            <del>{formatPrice(order.full_price)} {currency}</del>
                                        </div>
                                        <div>
                                            PROMO KOD: {order.promo_code} ({order.promo_code_discount}%)
                                        </div>
                                        <div>
                                            {formatPrice(order.full_price - ((order.full_price/100) * order.promo_code_discount))} {currency}
                                        </div>
                                    </div>
                                    : <strong>{formatPrice(order.full_price)} {currency}</strong>}
                            </div>
                        </div>
                        <div className="mb-1">{trans("datum")}</div>
                        <div className="mb-3">
                            <strong>{dateTime(order.created_at)}</strong>
                        </div>
                    </div>

                    <div className="col-md-3">
                        <div className="fw-bold mb-3 border-bottom"> {trans("kontakt")} </div>
                        <div className="mb-1">{trans("ime i prezime")}</div>
                        <div className="mb-3">
                            <strong>{order.name} {order.lastname}</strong>
                        </div>

                        <div className="mb-1">{trans("telefon")}</div>
                        <div className="mb-3">
                            <strong>{order.phone}</strong>
                        </div>
                        <div className="mb-1">Email</div>
                        <div className="mb-3">
                            <strong>{order.email}</strong>
                        </div>
                    </div>

                    <div className="col-md-3">
                        <div className="fw-bold mb-3 border-bottom"> {trans("adresa")} </div>
                        <div className="mb-1">{trans("adresa")}</div>
                        <div className="mb-3">
                            <strong>{order.address}</strong>
                        </div>
                        <div className="mb-1">{trans("grad")}</div>
                        <div className="mb-3">
                            <strong>{order.zip} {order.city}</strong>
                        </div>
                        <div className="mb-1">{trans("zemlja")}</div>
                        <div className="mb-3">
                            <strong>{order.country}</strong>
                        </div>
                    </div>

                    <div className="col-md-3">
                        <div className="fw-bold mb-3 border-bottom">{trans("nacin placanja")}  </div>
                        {trans(payment_method(order.payment_method))}
                    </div>
                </div>

                {order.message &&
                <div className="p-3 mb-3 bcg-2">
                    <div className="fw-bold mb-3 border-bottom"> {trans("dodatna napomena")} </div>
                    <div className="prewrap">{order.message}</div>
                </div>}
            </div>

            <table className="table table-bordered bg-white">
                <tbody>
                {order.orderProducts.map((orderProduct) => {
                    const productFullPrice = orderProduct.price * orderProduct.qty;
                    orderFullPrice += productFullPrice;
                    const product = orderProduct.product;
                    return <tr key={orderProduct.id}>
                        <td width={140}>
                            <img src={widget("storage.image", product.image)} alt={product.name} className="img-fluid" />
                        </td>
                        <td style={{verticalAlign: "middle"}}>
                            <Link to={route("products.shopProduct", product)}>
                                <strong>{product.name}</strong>
                            </Link>
                            {product.code &&
                            <div>{product.code}</div>}

                            {orderProduct.filters &&
                            <div className="mt-2">
                                {orderProduct.filters.map(item => {
                                    return <div key={item.id}>
                                        {item.parentFilter.name}: <strong>{item.name}</strong>
                                    </div>
                                })}
                            </div>}
                        </td>
                        <td style={{verticalAlign: "middle"}}>
                            {trans("porucena cena")}: <br />
                            <strong>{formatPrice(orderProduct.price)} {currency}</strong>
                        </td>
                        <td style={{verticalAlign: "middle"}}>
                            <div className="mb-1">{trans("kolicina")}:</div>
                            <div className="mb-3">
                                <strong>{orderProduct.qty}</strong>
                            </div>
                        </td>
                        <td style={{verticalAlign: "middle"}}>
                            <div className="mb-1">{trans("ukupno")}:</div>
                            <div className="mb-3">
                                <strong>
                                    {formatPrice(orderProduct.price * orderProduct.qty)} {currency}
                                </strong>
                            </div>
                        </td>
                    </tr>
                })}
                <tr className="fw-bold fs-4">
                    <td colSpan={4} className="p-3 text-end"> {trans("ukupno")}: </td>
                    <td width={180} className="p-3">

                        {order.promo_code
                            ? <div className="fw-bold">
                                <div className="fs-6">
                                    <del>{formatPrice(order.full_price)} {currency}</del>
                                    {widget("cms.AdminGuard", {
                                        children: <div title="IZRACUNATA CENA. AKO SE OVA CENA NE POKLAPA SA CENOM IZNAD, PRIJAVITI PROBLEM"
                                                       className="fw-normal fst-italic text-black-50">
                                            {formatPrice(orderFullPrice)} {currency}
                                        </div>
                                    })}
                                </div>
                                <div className="fs-s">
                                    PROMO KOD: <br /> {order.promo_code} ({order.promo_code_discount}%)
                                </div>
                                <div>
                                    {formatPrice(order.full_price - ((order.full_price/100) * order.promo_code_discount))} {currency}
                                </div>
                            </div>
                            : <div>
                                {formatPrice(order.full_price)} {currency}

                                {widget("cms.AdminGuard", {
                                    children: <div title="IZRACUNATA CENA. AKO SE OVA CENA NE POKLAPA SA CENOM IZNAD, PRIJAVITI PROBLEM"
                                                   className="fw-normal fst-italic fs-6">
                                        {formatPrice(orderFullPrice)} {currency}
                                    </div>
                                })}
                            </div>}
                    </td>
                </tr>
                </tbody>
            </table>
        </>
    )
}
export default OrderRender;