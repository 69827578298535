import React from "react";
import {useMutation, useQuery} from "@apollo/client";
import Loader from "../../../../app/Loader";
import GraphQLErrorMessage from "../../../../app/GraphQLErrorMessage";
import Nestable from "react-nestable";
import {GET_BLOCKS_BY_ADMIN, GET_BLOCKS_QUERY} from "../../graphql/blocks";
import BlockItem from "./BlockItem";
import {ORDER_ITEMS} from "../../../_cms/graphql/items";
import toast from "react-hot-toast";
import AddBlockForm from "./AddBlockForm";
import {widget} from "../../../modulesCompiler";


const Blocks = ({code}) => {
    const [orderBlocks] = useMutation(ORDER_ITEMS);
    const { data, loading, error, refetch } =  useQuery(GET_BLOCKS_BY_ADMIN, {
        variables: {code: code},
        fetchPolicy: 'network-only'
    });
    if(loading) return <Loader />
    if(error)   return <GraphQLErrorMessage error={error} />
    const blocks = data[GET_BLOCKS_QUERY];

    const changedOrder = (items) => {
       orderBlocks({
           variables: {items: JSON.stringify(items.items), table: "blocks"}
       }).then(response => {
           toast.success("Promenjen raspored!");
            refetch();
        }).catch(error => {
           toast.error(error.message);
        });
    }
    return (
        <div className="container">
            <AddBlockForm code={code} refetch={refetch} />
            <Nestable
                collapsed={false}
                maxDepth={1}
                onChange={changedOrder}
                handler={widget("cms.DragHandler")}
                items={blocks}
                renderItem={(props) => <BlockItem key={props.item.id} {...props} refetch={refetch} />} />

        </div>
    )
}

export default Blocks;
