import LangContext from "../contexts/LangContext";
import {matchPath} from "react-router-dom";
import {routes} from "../../modulesCompiler";
import {useMemo, useState} from "react";
import {momentOverride, options} from "../config";
import moment from "moment";


function GlobalLangWrapper({children})    {

    let defaultLang = useMemo(() => {
        const pathname = decodeURI(window.location.pathname);
        let lang = 0;
        for(let key in routes) {
            if(typeof routes[key].path === 'string' && routes[key].path.endsWith("*"))        {
                continue;
            }
            const match = matchPath(pathname, {
                path: routes[key].path,
                exact: true,
                strict: false
            });
            if(match)   {
                const path = routes[key].path;
                if (typeof path === 'string' || path instanceof String)   {
                    lang = 0;
                    break;
                } else {
                    const index = path.indexOf(match.path);
                    if (index > -1) {
                        lang = index;
                        break;
                    }
                }
            }
        }
        return lang;
    }, []);
    if(parseInt(defaultLang) > (options.length - 1))  {
        defaultLang = 0;
    }
    const [lang, setLang] = useState(defaultLang);

    const trans =  {
        naslovna: ["Naslovna", "Насловна", "Home"],
        "o nama": ["O nama", "О нама", "About Us"],
        shop: ["Shop", "Продавница", "Shop"],
        blog: ["Blog", "Блог", "Blog"],
        kontakt: ["Kontakt", "Контакт", "Contact"],
        kategorije: ["Kategorije", "Категорије", "Categories"],
        email: ["Email", "Емаил", "Email"],
        filteri: ["Filteri", "Филтери", "Filters"],
        "mreze": ["Mreže", "Мреже", "Networks"],
        "detaljnije": ["Detaljnije", "Детаљније", "More"],
        "prodajna mesta": ["Prodajna mesta", "Продајна места", "Selling places"],
        "mapa sajta": ["Mapa sajta", "Мапа сајта", "Site map"],
        "kupovina": ["Kupovina", "Куповина", "Shopping"],
        "vazni linkovi": ["Važni linkovi", "Важни линкови", "Important links"],
        "currency": ["RSD", "РСД", "EUR"],
        "akcija": ["AKCIJA", "АКЦИЈА", "ACTION"],
        "novo": ["NOVO", "НОВО", "NEW"],
        "filter": ["Filter", "Филтер", "Filter"],
        "proizvoda": ["proizvoda", "производа", "products"],
        "prazna pretraga": ["Prazna pretraga", "Празна претрага", "Empty search"],
        "korpa je prazna": ["Korpa je prazna", "Корпа је празна", "Shopping bag is empty"],
        "ne postoji stranica": ["Ne postoji stranica.", "Не постоји страница.", "Page doesn't exists."],
        "relevantnosti": ["Po relevantnosti", "По релевантности", "By relevance"],
        "nazivu": ["Po nazivu", "По називу", "By name"],
        "rastuce": ["Po ceni rastuće", "По цени растуће", "By price ascending"],
        "opadajuce": ["Ceni opadajuće", "Цени опадајуће", "By price descending"],
        "pretraga": ["Pretraga", "Претрага", "Search"],
        "strane": ["Strane", "Стране", "Pages"],
        "druge boje": ["BOJA", "БОЈA", "COLOR"],
        "dodajte u korpu": ["DODAJTE U KORPU", "ДОДАЈТЕ У КОРПУ", "ADD TO BASKET"],
        "dodato": ["DODATO U KORPU", "ДОДАТО У КОРПУ", "ADDED TO BASKET"],

        "osnovno": ["Osnovno", "Oсновно", "Informations"],
        "sifra": ["Šifra", "Шифра", "Code"],
        "cena": ["Cena", "Цена", "Price"],
        "datum": ["Datum", "Датум", "Date"],
        "ime i prezime": ["Ime i prezime", "Име и презиме", "Full name"],
        "telefon": ["Telefon", "Телефон", "Phone"],
        "adresa": ["Adresa", "Адреса", "Address"],
        "grad": ["Grad", "Град", "City"],
        "zemlja": ["Zemlja", "Земља", "Country"],
        "nacin placanja": ["Način plaćanja", "Начин плаћања", "Payment method"],
        "dodatna napomena": ["Dodatna napomena", "Додатна напомена", "Additional note"],
        "porucena cena": ["Poručena cena", "Поручена цена", "Ordered price"],
        "kolicina": ["Količina", "Количина", "Quantity"],
        "ukupno": ["Ukupno", "Укупно", "Total"],
        "ukupno sa pdv-om": ["Ukupno sa PDV-om", "Укупно са ПДВ-ом", "Total"],
        "korpa": ["Korpa", "Корпа", "Basket"],
        "uklonite": ["Uklonite", "Уклоните", "Remove"],
        "zavrsite porudzbinu": ["Završite porudžbinu", "Завршите поруџбину", "Go to checkout"],
        "br": ["Br.", "Бр.", "No."],

        "cookies title": ["Ovaj veb sajt koristi kolačiće", "Овај веб сајт користи колачиће", "This website uses cookies"],
        "cookies desc": [
            "Koristimo kolačiće za personalizaciju sadržaja, pružanje funkcija društvenih medija i analiziranje saobraćaja. Ako nastavite da koristite naše veb-stranice, saglasni ste sa korišćenjem naših kolačića.",
            "Користимо колачиће за персонализацију садржаја, пружање функција друштвених медија и анализирање саобраћаја. Ако наставите да користите наше веб-странице, сагласни сте са коришћењем наших колачића.",
            "We use cookies to personalize content, to provide functions social media and traffic analysis. If you continue to use our website, you agree to the use of our cookies."
        ],
        "prihvatam": ["PRIHVATAM", "ПРИХВАТАМ", "ACCEPT"],

        "slika": ["Slika", "Слика", "Image"],
        "artikal": ["Artikal", "Артикал", "Item"],
        "informacije za porucivanje": ["Informacije za poručivanje", "Информације за поручивање", "Informations"],
        "obavezna polja": ["Polja označena zvezdicom (*) su obavezna.", "Поља означена звездицом (*) су обавезна.", "Fields marked with an asterisk (*) are required."],
        "ime": ["Ime", "Артикал", "Name"],
        "prezime": ["Prezime", "Презиме", "Lastname"],
        "postanski broj": ["Poštanski broj", "Поштански број", "Area code"],
        "dostava": ["Dostava", "Достава", "Delivery"],
        "tabela velicina": ["Tabela veličina", "Табела величина", "Size chart"],
        "placanje pouzecem": ["Plaćanje pouzećem", "Плаћање поузећем", "Cash on delivery"],
        "uplata na tekuci racun": ["Uplata na tekući račun", "Уплата на текући рачун", "Payment to current account"],
        "placanje 1 desc": ["Plaćanje kurirskoj službi prilikom preuzimanja paketa", "Плаћање курирској служби приликом преузимања пакета", "Payment to the courier service when picking up the package"],
        "placanje 2 desc": [
            "Nakon što završite proces naručivanja kontaktiraće vas neko od našeg osoblja",
            "Након што завршите процес наручивања контактираће вас неко од нашег особља",
            "Once you have completed the ordering process, you will be contacted by one of our staff"],
        "posalji porudzbinu": ["POŠALJI PORUDŽBINU", "ПОШАЉИ ПОРУЏБИНУ", "SUBMIT ORDER"],
        "dostava desc": [
            "Dostava se vrši na teritoriji Republike Srbije, preko kurirske službe \"Dexpress\" i naplaćuje se po cenovniku:\n" +
            "\n" +
            "do 1 kg - 390,00 rsd\n" +
            "od 1 do 2 kg - 490,00 rsd\n" +
            "od 2 do 5 kg - 590,00 rsd\n" +
            "od 5 do 10 kg - 690,00 rsd\n" +
            "od 10 do 20 kg - 890,00 rsd\n" +
            "\n" +
            "Cene su sa uračunatim PDV. Troškovi dostave će biti istaknuti na računu kao posebna stavka u skladu sa Zakonom RS.\n" +
            "\n" +
            "Za porudžbine preko 8000 rsd, poštarina je besplatna!",
            "Достава се врши на територији Републике Србије, преко курирске службе  \" Dexpress\" и наплаћује се по ценовнику:\n" +
            "\n" +
            "до 1 kг - 390,00 рсд\n" +
            "од 1 до 2 kг - 490,00 рсд\n" +
            "од 2 до 5 kг - 590,00 рсд\n" +
            "од 5 до 10 kг - 690,00 рсд\n" +
            "од 10 до 20 kг - 890,00 рсд\n" +
            "\n" +
            "Цене су са урачунатим ПДВ. Трошкови доставе ће бити истакнути на рачуну као посебна ставка у складу са Законом РС.\n" +
            "\n" +
            "За поруџбине преко 8000 рсд, поштарина је бесплатна!",
            "Delivery is done through the courier service \"Dexpress\""],
        "uspesno ste porucili": ["Uspešno ste poručili!", "Успешно сте поручили!", "You have successfully ordered!"],
        "hvala na poverenju": ["Hvala na poverenju!", "Хвала на поверењу!", "Thank you for your trust!"],

        "politika privatnosti": ["Politika privatnosti", "Политика приватности", "Privacy policy"],
        "uslovi koriscenja": ["Uslovi korišćenja", "услови-коришћења", "Terms of use"],
        "kako kupiti": ["Kako kupiti", "Како купити", "How to buy"],
        "politika kolacica": ["Politika kolačića", "Политика колачића", "Cookie policy"],
        "pravo na odustajanje": ["Pravo na odustajanje", "Право на одустајање", "Right of  Withdrawal-Refund"],
        "uslovi isporuke": ["Uslovi isporuke", "Услови испоруке", "Delivery"],
        "reklamacije": ["Reklamacije", "Рекламације", "Complaints"],
        "instalirajte horus aplikaciju": ["Instalirajte HORUS aplikaciju", "Инсталирајте ХОРУС апликацију", "Install HORUS application"],


        "o nama tekst": [
            "  Brend XOPYC - HORUS osnovan je 2008. godine. <br /><br />\n" +
            "                Inspirisani vazduhoplovnim i vojnim motivima, kao pandan zapadnim robnim markama na\n" +
            "                našem tržištu, započeta je proizvodnja odeće vrhunskog kvaliteta u Srbiji.<br /><br />\n" +
            "                Kako je i sam osnivač veliki ljubitelj avijacije, entuzijazam i istrajnost su bili ključni za nastanak,\n" +
            "                opstajanje i razvoj brenda. Kvalitet je uvek bio i ostao na prvom mestu ali naravno da ništa ne bi\n" +
            "                bilo moguće bez ljudi u kompaniji koji su delili istu strast. To su faktori koji su nas danas učinili\n" +
            "                prepoznatljivim brendom u Srbiji a i šire.<br /><br />\n" +
            "                Originalnost, stručnost i kvalitet izrade doveli su do toga da nakon nekoliko godina posvećenog\n" +
            "                rada Horus postane prepoznatljiv brend koji svoje proizvode sa ponosom proizvodi u Srbiji.\n" +
            "                Trenutno zapošljavamo preko 50 ljudi koji vredno rade i profesionalno ispunjavaju svoje\n" +
            "                zadatke.<br /><br />\n" +
            "                Horus je 2018. godine odlučio da krene u pravcu proizvodnje taktičke opreme. Pod imenom\n" +
            "                Tactix™ započinjemo proizvodnju odeće i opreme namenjene pre svega profesionalcima.\n" +
            "                Kombinacijom proizvoda velikih svetskih brendova uz Tactix, Horus može da ponudi kompletnu\n" +
            "                odeću i opremu koja je neophodna svakom modernom vojniku, policajcu, obezbeđenjima i\n" +
            "                slično.<br /><br />\n" +
            "                Danas Tactix korisi SAJ, Jedinica za obezbedjenje ličnosti, UKP, SBPOK, 72. Brigada za\n" +
            "                specijalne operacije, Specijalna jedinica za obezbedjenje ličnosti Ujedinjenih Arapskih Emirata.\n" +
            "                Pored proizvodnje Horus je i distributer nekoliko stranih brendova kao što su LOWA\n" +
            "                Professional, Magnum, Mechanix, Wiley X, ESP, Ledlenser, Fenix, Sas R&amp;D, SAN Tactical...\n" +
            "                Vođeni velikim iskustvom i željom, koristeći najmodernije tehnologije i slušajući potrebe krajnjih\n" +
            "                korisnika, svakodnevno pokušavamo da uskladimo kvalitet naših proizvoda sve zahtevnijim\n" +
            "                izazovima i potrebama klijenata koji ih koriste, što ujedno i predstavlja najveći izazov.\n" +
            "                Uspešnost u tome, još veći je motiv da nastavimo u istom smeru.\n" +
            "                Sa sve većom željom da zadovoljimo potrebe tržišta za proizvodima vrhunskog kvaliteta i\n" +
            "                našom tendencijom za proširenjem svog poslovanja kako u zemlji, tako i inostranstvu, u planu\n" +
            "                nam je formiranje novih kako proizvodnih, tako i maloprodajnih kapaciteta, a samim tim i\n" +
            "                povećanje broja zaposlenih.<br /><br />\n" +
            "                Ponosni smo što se možemo pohvaliti izgrađenim i funkcionalnim timom, sinhronizacijom rada i\n" +
            "                visokim poznavanjem proizvoda, tržišta i potreba kupaca, ali i dizajna i tehnologije izrade\n" +
            "                proizvoda.",

            "Бренд XOPYC - HORUS основан је 2008. године. <br /><br />\n" +
            "                Инспирисани ваздухопловним и војним мотивима, као пандан западним робним маркама на\n" +
            "                нашем тржишту, започета је производња одеће врхунског квалитета у Србији.<br /><br />\n" +
            "                Како је и сам оснивач велики љубитељ авијације, ентузијазам и истрајност су били кључни за настанак,\n" +
            "                опстајање и развој бренда. Квалитет је увек био и остао на првом месту али наравно да ништа не би\n" +
            "                било могуће без људи у компанији који су делили исту страст. То су фактори који су нас данас учинили\n" +
            "                препознатљивим брендом у Србији а и шире.<br /><br />\n" +
            "                Оригиналност, стручност и квалитет израде довели су до тога да након неколико година посвећеног\n" +
            "                рада Хорус постане препознатљив бренд који своје производе са поносом производи у Србији.\n" +
            "                Тренутно запошљавамо преко 50 људи који вредно раде и професионално испуњавају своје\n" +
            "                задатке.<br /><br />\n" +
            "                Хорус је 2018. године одлучио да крене у правцу производње тактичке опреме. Под именом\n" +
            "                Тацтиџ™ започињемо производњу одеће и опреме намењене пре свега професионалцима.\n" +
            "                Комбинацијом производа великих светских брендова уз Тацтиџ, Хорус може да понуди комплетну\n" +
            "                одећу и опрему која је неопходна сваком модерном војнику, полицајцу, обезбеђењима и\n" +
            "                слично.<br /><br />\n" +
            "                Данас Тацтиџ кориси САЈ, Јединица за обезбеђење личности, УКП, СБПОК, 72. Бригада за\n" +
            "                специјалне операције, Специјална јединица за обезбеђење личности Уједињених Арапских Емирата.\n" +
            "                Поред производње Хорус је и дистрибутер неколико страних брендова као што су ЛОЊА\n" +
            "                Профессионал, Магнум, Мецханиџ, Њилеy X, ЕСП, Ледленсер, Фениџ, Сас Р&амп;Д, САН Тацтицал...\n" +
            "                Вођени великим искуством и жељом, користећи најмодерније технологије и слушајући потребе крајњих\n" +
            "                корисника, свакодневно покушавамо да ускладимо квалитет наших производа све захтевнијим\n" +
            "                изазовима и потребама клијената који их користе, што уједно и представља највећи изазов.\n" +
            "                Успешност у томе, још већи је мотив да наставимо у истом смеру.\n" +
            "                Са све већом жељом да задовољимо потребе тржишта за производима врхунског квалитета и\n" +
            "                нашом тенденцијом за проширењем свог пословања како у земљи, тако и иностранству, у плану\n" +
            "                нам је формирање нових како производних, тако и малопродајних капацитета, а самим тим и\n" +
            "                повећање броја запослених.<br /><br />\n" +
            "                Поносни смо што се можемо похвалити изграђеним и функционалним тимом, синхронизацијом рада и\n" +
            "                високим познавањем производа, тржишта и потреба купаца, али и дизајна и технологије израде\n" +
            "                производа.",
            "The XOPYC - HORUS brand was founded in 2008. <br/> <br/>\n" +
            "            Inspired by aviation and military motifs, as a counterpart to Western brands on\n" +
            "            in our market, the production of top quality clothes in Serbia has started. <br/> <br/>\n" +
            "            As the founder himself is a big fan of aviation, enthusiasm and perseverance were key to the creation,\n" +
            "            brand survival and development. Quality has always been and remains in the first place, but of course nothing would\n" +
            "            it was possible without people in the company sharing the same passion. These are the factors that have made us today\n" +
            "            recognizable brand in Serbia and beyond. <br/> <br/>\n" +
            "            Originality, expertise and quality of workmanship have led to that after a few years of dedication\n" +
            "            rada Horus becomes a recognizable brand that proudly produces its products in Serbia.\n" +
            "            We currently employ over 50 people who work hard and fulfill theirs professionally\n" +
            "            tasks. <br/> <br/>\n" +
            "            In 2018, Horus decided to move in the direction of producing tactical equipment. Under the name\n" +
            "            Tactix ™ we start the production of clothes and equipment intended primarily for professionals.\n" +
            "            By combining the products of major world brands with Tactix, Horus can offer a complete\n" +
            "            clothing and equipment necessary for every modern soldier, police officer, security and\n" +
            "            similar. <br/> <br/>" +
            "Today, Tactix is ​​used by SAJ, Personnel Security Unit, UKP, SBPOK, 72nd Brigade for\n" +
            "            special operations, United Arab Emirates Special Personnel Security Unit.\n" +
            "            In addition to production, Horus is also a distributor of several foreign brands such as LOWA\n" +
            "            Professional, Magnum, Mechanix, Wiley X, ESP, Ledlenser, Fenix, Sas R & D, SAN Tactical ...\n" +
            "            Guided by great experience and desire, using the most modern technologies and listening to the needs of the extreme\n" +
            "            users, every day we try to harmonize the quality of our products with more and more demanding\n" +
            "            challenges and needs of clients who use them, which is also the biggest challenge.\n" +
            "            Success in that is an even bigger motive to continue in the same direction.\n" +
            "            With a growing desire to meet the needs of the market for top quality products and\n" +
            "            our tendency to expand our business both at home and abroad, in the plan\n" +
            "            we have the formation of new both production and retail capacities, and thus\n" +
            "            increasing the number of employees. <br/> <br/>\n" +
            "            We are proud to be able to boast of a built and functional team, work synchronization and\n" +
            "            high knowledge of products, markets and customer needs, but also design and manufacturing technology\n" +
            "            product."
        ],
        "poruka": ["Poruka...", "Порука...", "Message..."],
        "posaljite": ["Pošaljite", "Пошаљите", "Send"],
        "obrazac za identifikaciju": ["Obrazac za identifikaciju", "Образац за идентификацију", "Identification form"],
        "kontaktirajte nas": ["Kontaktiraj s nama", "Контактирајте Нас", "Contact Us"],
        "kragujevac": ["KRAGUJEVAC", "КРАГУЈЕВАЦ", "KRAGUJEVAC"],

        "footer disclaimer": [
            "Podaci su informativnog karaktera i podložni su izmenama. Nastojimo da budemo što precizniji u opisu proizvoda, prikazu slika i samih cena, ali ne možemo garantovati da su sve informacije kompletne i bez grešaka. Svi artikli prikazani na sajtu su deo naše ponude i ne podrazumeva da su dostupni u svakom trenutku. Fotografije ne moraju u potpunosti odgovarati stvarnom izgledu proizvoda.",
            "Подаци су информативног карактера и подложни су изменама. Настојимо да будемо што прецизнији у опису производа, приказу слика и самих цена, али не можемо гарантовати да су све информације комплетне и без грешака. Сви артикли приказани на сајту су део наше понуде и не подразумева да су доступни у сваком тренутку. Фотографије не морају у потпуности одговарати стварном изгледу производа.",
            "The data is informative and subject to change. We strive to be as precise as possible in the description of the product, the display of images and prices, but we can not guarantee that all information is complete and error-free. All the items displayed on the site are part of our offer and do not imply that they are available at any time. Photographs do not have to fully match the actual look of the product."],


        "promo kod": ["Promo kod", "Промо код", "Promo code"],
        "unesite promo kod": ["Unesite promo kod", "Унесите промо код", "Enter promo code"],
        "proverite kod": ["Proverite kod", "Проверите код", "Check code"],
        "nije unet promo kod": ["Nije unet promo kod.", "Није унет промо код.", "Promo code not entered."],
        "proizvod trenutno nema na stanju": ["Proizvod trenutno nema na stanju.", "Производ тренутно нема на стању.", "The product is currently out of stock."],
        "newsletter desc": [
            "Želim da se prijavim na mailing listu i primam buduća obaveštenja",
            "Желим да се пријавим на маилинг листу и примам будућа обавештења",
            "I want to sign up for the mailing list and receive future notifications",
        ]
    }

    if(momentOverride[lang])  {
        moment.updateLocale("rs", momentOverride[lang]);
    } else  {
        moment.updateLocale("rs", momentOverride[0]);
    }

    return <LangContext.Provider value={{lang: lang, setLang: setLang, trans: trans}}>
        {children}
    </LangContext.Provider>
}
export default GlobalLangWrapper;