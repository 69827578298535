import React, {useState} from "react";
import {DELETE_PRODUCT, PRODUCT_TO_TOP, UPSERT_PRODUCT} from "../../graphql/product";
import CmsUpdateField from "../../../../app/CmsUpdateField";
import {route, trans, widget} from "../../../modulesCompiler";
import {useMutation} from "@apollo/client";
import toast from "react-hot-toast";
import {ChevronsUp, Settings, Trash2, X} from "react-feather";
import {processPrice} from "../../../../app/helpers";
import Drawer from "rc-drawer";
import Product from "../product/Product";


const ProductItem = ({item, refetch}) => {
    const [edit] = useState(false);
    const [deleteProduct] = useMutation(DELETE_PRODUCT);
    const [productToTop] = useMutation(PRODUCT_TO_TOP);
    const [open, setOpen] = useState(false);

    const close = () => {
        setOpen(false);
    }

    const deleteItem = () => {
        if(!window.confirm("Zelite da obrisete proizvod?"))  {
            return false;
        }
        deleteProduct({variables: {id: item.id}}).then(response => {
            toast.success("Obrisano!");
            refetch();
        }).catch(error => {
            toast.error(error.message);
        });
    }
    const toTop = () => {
        productToTop({variables: {id: item.id}}).then(response => {
            toast.success("Sačuvano!");
            refetch();
        }).catch(error => {
            toast.error(error.message);
        });
    }

    return (
        <div>
            <Drawer
                open={open}
                onClose={close}
                width="90%"
                placement="right"
                level={null}
                handler={false}>
                <div className="pt-3 position-relative">
                    <button className="btn btn-danger position-absolute" style={{top: 15, right: 20}}
                            onClick={close}>
                        <X />
                    </button>

                    {open &&
                    <Product id={item.id} />}
                </div>
            </Drawer>

            <div className="d-flex" style={{opacity: item.active ? 1 : 0.3}}>
                <div className="flex-grow-1 d-flex pointer" onClick={() => setOpen(true)}>
                    <div className="me-3" style={{width: 100}}>
                        <div className="bcg_image" style={{backgroundImage: `url(${widget("storage.image", item.image)})`}} />
                    </div>
                    <div>
                        {edit
                            ?  <CmsUpdateField
                                element="text" required={true}
                                id={item.id} MUTATION={UPSERT_PRODUCT}
                                name="name" value={item.name} />
                            : <>
                                <div>
                                    <h6>{item.name}</h6>
                                </div>
                            </>}

                        {item.code &&
                        <div>#{item.code}</div>}
                        {item.brand &&
                        <div>{item.brand}</div>}

                        <div>
                            {processPrice(item) &&
                            <span className="me-2">{processPrice(item)} {trans("currency")}</span>}
                            {item.discount_price &&
                            <del className="text-black-50 fs-s">{item.price} {trans("currency")}</del>}
                        </div>

                        {item.group.length > 0 &&
                        <div className="d-flex mt-2">
                            {item.group.map(gr => {
                                if(item.id === gr.id) return null;
                                return <div key={gr.id} className="mb-2 me-2 border hover_border p-1" style={{width: 40}}>
                                    <div>
                                        <div className="bcg_image"
                                             style={{backgroundImage: `url(${widget("storage.image", gr.image)})`}} />
                                    </div>
                                </div>
                            })}
                        </div>}

                    </div>
                </div>

                 <div className="me-2" style={{width: 120}}>
                    <CmsUpdateField
                        element="checkbox"
                        id={item.id}
                        className="w-100 mb-1"
                        MUTATION={UPSERT_PRODUCT}
                        name="active"
                        value={item.active}
                        onTrue="Deaktivirajte"
                        onFalse="Aktivirajte"
                    />
                    <button onClick={toTop} title="Prebacite proizvod na vrh." className="btn btn-light btn-sm w-100 mb-1">
                        <ChevronsUp size={16} /> Na vrh
                    </button>
                </div>
                <div style={{width: 130}}>
                    {/*<button onClick={() => setEdit(!edit)}
                            className={`btn ${edit ? "btn-danger" : "btn-light"} btn-sm w-100 mb-2`}>
                        <Edit size={16} /> {edit ? "Zatvorite" : "Prepravite"}
                    </button>*/}
                    <button onClick={() => setOpen(true)} to={route("products.product", item)} className="btn btn-light btn-sm w-100 mb-2">
                        <Settings size={16} />  Detaljnije
                    </button>
                    <button onClick={deleteItem} className="btn btn-light btn-sm w-100">
                        <Trash2 size={16} />  Obrišite
                    </button>
                </div>
            </div>

            {widget("filters.ProductFilters", {product: item, size: true, refetch})}
        </div>
    )
}

export default ProductItem;
