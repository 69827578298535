import {Minus, Plus, ShoppingBag} from "react-feather";
import {useContext, useState} from "react";
import BasketContext from "../contexts/BasketContext";
import {trans, widget} from "../../modulesCompiler";
import Lightbox from "react-image-lightbox";


function ProductForm({item, basket}) {
    const {add, updateQty, updateFilters} = useContext(BasketContext);
    const [added, setAdded] = useState(false);
    const [openTabela, setOpenTabela] = useState(false);

    const [qty, setQty] = useState(basket?.qty || 1);
    const onPlus = () => {
        let newQty = qty + 1;
        if(item.qty !== null && newQty > item.qty)  {
            alert(`Maksimalno na stanju: ${item.qty}`);
            newQty = item.qty;
        }
        if(newQty > 10) newQty = 10;
        setQty(newQty);
        if(basket)  {
            updateQty(basket.id, newQty);
        }
    }
    const onMinus = () => {
        let newQty = qty - 1;
        if(newQty < 1)   {
            newQty = 1;
        }
        setQty(newQty);
        if(basket)  {
            updateQty(basket.id, newQty);
        }
    }
    const onChange = (e) => {
        const value = e.target.value;
        if(isNaN(value))    {
            alert("Mora biti broj.");
            return false;
        }
        setQty(value);
        if(basket)  {
            updateQty(basket.id, value);
        }
    }


    let tabelaVelicinaImg = null;
    if(item.categories) {
        for(let i=0; i<item.categories.length; i++)    {
            const cat = item.categories[i];
            console.log(cat)
            if(cat.sizes_image) {
                tabelaVelicinaImg = widget("storage.image")(cat.sizes_image, true)
            }
        }
    }
    if(item.sizes_image)    {
        tabelaVelicinaImg = widget("storage.image")(item.sizes_image, true)
    }

    const filtersHolder = {};
    const filtersState = [];
    for(let i=0; i<item.filters.length; i++) {
        const filter = item.filters[i];
        if(!filter.parent)      {
            if(!filter.shop)        {
                continue;
            }
            if(!filtersHolder[filter.id]) filtersHolder[filter.id] = {...filter, ...{children: []}};
            filtersState.push("");
        } else  {
            if(!filtersHolder[filter.parent]) continue;
            filtersHolder[filter.parent].children.push(filter);
        }
    }
    const [filters, setFilters] = useState(basket?.filters || filtersState);

    const changeFilter = (pos, id) => {
        const newFilters = [...filters];
        newFilters[pos] = id;
        setFilters(newFilters);
        if(basket)  {
            updateFilters(basket.id, newFilters);
        }
    }

    const submit = (e) => {
        e.preventDefault();
        add(item, qty, filters);
        setQty(1);
        setFilters(filtersState);
        setAdded(true);
        setTimeout(() => {
            setAdded(false);
        }, 2000);
    }
    if(!item.active || !item.process_price || item.process_price < 1)   {
        return <div className="fw-bold text-secondary h3">
            {trans("proizvod trenutno nema na stanju")}
        </div>
    }
    return (
        <form action="/" onSubmit={submit}>

            {Object.values(filtersHolder).length > 0 &&
            <div className={basket ? "mb-2" : "mb-3"}>
                {Object.values(filtersHolder).map((filter, id) => {
                    return <div key={filter.id}>
                        {basket
                            ?   <div className="justify-content-center">
                                {filter.children.map(child => {
                                    return <label key={child.id}
                                                  className="fw-bold position-relative oswald pointer pt-1 pb-1 ps-2 pe-2 mb-1 me-1">
                                        <input
                                            style={{opacity: 0}}
                                            className="position-absolute filter_radio"
                                            value={child.id}
                                            onChange={() => changeFilter(id, child.id)}
                                            checked={filters.includes(child.id)}
                                            type="radio" name={`filter_${filter.id}`} required />
                                        <span />
                                        {child.name}
                                    </label>
                                })}
                            </div>
                            : <>
                            {openTabela &&
                                <Lightbox mainSrc={tabelaVelicinaImg} onCloseRequest={() => setOpenTabela(false)} />}

                                <div className="d-flex align-items-center mb-2 justify-content-between">
                                    <div className="oswald h4 fw-bold mb-3">
                                        {filter.name}
                                    </div>
                                    {tabelaVelicinaImg &&
                                    <button onClick={() => setOpenTabela(true)}
                                        type="button" className="btn btn-light fw-bold oswald">
                                        {trans("tabela velicina")}
                                    </button>}
                                </div>

                                <div className="">
                                    {filter.children.map(child => {
                                        return <label key={child.id}
                                                      className="fw-bold position-relative oswald pointer pt-2 pb-2 ps-3 pe-3 mb-2 me-2">
                                            <input
                                                style={{opacity: 0}}
                                                className="position-absolute filter_radio"
                                                value={child.id}
                                                onChange={() => changeFilter(id, child.id)}
                                                checked={filters.includes(child.id)}
                                                type="radio" name={`filter_${filter.id}`} required />
                                            <span />
                                            {child.name}
                                        </label>
                                    })}
                                </div>
                            </>}

                    </div>
                })}
            </div>}

            {added
                ? <div className="alert alert-success fw-bold">
                    {trans("dodato")}
                </div>
                : <div className={`${basket ? "d-flex justify-content-center" : "d-md-flex"}`}>
                    <div className={`input-group product_qty_btn ${basket ? "" : "mb-3 mb-md-0"} me-2`}>

                        <div className="input-group-prepend">
                            <button onClick={onMinus} className="btn h-100 btn-dark btn_left shadow-none" type="button">
                                <Minus size={14} />
                            </button>
                        </div>
                        <input type="text" value={qty} onChange={onChange}
                               className="form-control bg-dark border-dark text-white fw-bold"  />
                        <div className="input-group-append">
                            <button onClick={onPlus} className="btn btn-dark h-100 btn_right shadow-none" type="button">
                                <Plus size={14} />
                            </button>
                        </div>
                    </div>

                    {!basket &&
                    <button type="submit" className="btn btn-primary ps-4 pe-4">
                        <span className="fw-bold me-2"> {trans("dodajte u korpu")}</span> <ShoppingBag />
                    </button>}
                </div>}
        </form>
    )
}
export default ProductForm;