import React, {useState} from "react";
import {widget} from "../../../modulesCompiler";
import {Trash2} from "react-feather";
import {useMutation} from "@apollo/client";
import toast from "react-hot-toast";
import {CREATE_PRODUCT_IMAGE, DELETE_PRODUCT_IMAGE} from "../../graphql/productImage";
import Nestable from "react-nestable";
import {ORDER_ITEMS} from "../../../_cms/graphql/items";



function ProductImages({product, refetch}) {
    const [open, setOpen] = useState(false);
    const close = () => setOpen(false);

    const [createProductImage] = useMutation(CREATE_PRODUCT_IMAGE);
    const [deleteProductImage] = useMutation(DELETE_PRODUCT_IMAGE);
    const [orderItems] = useMutation(ORDER_ITEMS);

    const deleteItem = (item) => {
        if(!window.confirm("Zelite da obrišete sliku iz proizvoda?"))  {
            return false;
        }
        deleteProductImage({variables: {id: item.productImagePivot.id}}).then(response => {
            toast.success("Obrisano!");
            refetch();
        }).catch(error => {
            toast.error(error.message);
        });
    }

    const callback = (images) => {
        for(let i=0; i<images.length; i++)  {
            createProductImage({variables: {product_id: product.id, image_id: images[i].id}}).then(response => {
                refetch();
            }).catch(error => {
                toast.error(error.message);
            });
        }
        toast.success("Dodato!");
    }

    const changedOrder = (items) => {
        const holder = items.items.map(p => {
            p.id = p.productImagePivot.id;
            return p;
        });
        orderItems(
            {variables: {items: JSON.stringify(holder), table: "product_images"}}
        ).then(response => {
            toast.success("Sačuvano!");
            refetch();
        }).catch(error => {
            toast.error(error.message);
        });
    }

    return  (
        <>
            {widget("storage.StorageDrawer", {open, close, callback})}

            <button onClick={() => setOpen(true)} className="btn btn-success mb-3">Dodajte slike</button>

            <Nestable
                className="inline align-top"
                collapsed={false}
                maxDepth={1}
                onChange={changedOrder}
                handler={widget("cms.DragHandler")}
                items={product.images}
                renderItem={(props) => <div key={props.item.id} className="">
                    <div className="position-relative">
                        <div className="position-absolute" style={{left: 5, right: 5}}>
                            {props.handler}
                        </div>
                        <button onClick={() => deleteItem(props.item)}
                                title="Obrisite sliku."
                                className="btn btn-light btn-sm position-absolute" style={{top: 5, right: 5}}>
                            <Trash2 size={18} />
                        </button>
                        <div>
                            <div className="bcg_image" style={{backgroundImage: `url(${widget("storage.image", props.item)})`}} />
                        </div>
                    </div>
                </div>} />
        </>
    )
}
export default ProductImages;