import {reset} from "./config";

export function seoReset() {
    const structuredData = document.querySelector('script[type="application/ld+json"]');
    if(structuredData)      {
        structuredData.remove();
    }
    seoTitle(reset.title);
    seoDescription(reset.description);
    seoKeywords(reset.keywords);
    ogImage(reset.logo);
}
export function seoTitle(title) {
    if(!title) return false;
    document.querySelector('title').innerHTML = title;
}
export function seoDescription(description) {
    if(!description) return false;
    document.querySelector('meta[name="description"]')
        .setAttribute("content", description);
}
export function seoKeywords(keywords) {
    if(!keywords) return false;
    document.querySelector('meta[name="keywords"]')
        .setAttribute("content", keywords);
}
export function ogImage(image) {
    if(!image) return false;
    document.querySelector('meta[name="og:image"]')
        .setAttribute("content", image);
}
export function structuredData(data) {
    if(!data) return false;
    let el = document.querySelector('script[type="application/ld+json"]');
    if(!el)     {
        el = document.createElement("script");
        el.type = "application/ld+json";
        document.head.appendChild(el);
    }
    el.innerHTML = JSON.stringify(data);
}
export function seoAll(string) {
    if(!string) return false;
    seoTitle(string);
    seoKeywords(string);
    seoDescription(string);
}