import React from "react";
import RenderCategoryItem from "./RenderCategoryItem";

function RenderCategories({categories, close}) {
    return (
        categories.map(item => {
            return <RenderCategoryItem key={item.id} item={item} close={close} />
        })
    )
}
export default RenderCategories;