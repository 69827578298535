import {useQuery} from "@apollo/client";
import Loader from "../../../../app/Loader";
import GraphQLErrorMessage from "../../../../app/GraphQLErrorMessage";
import {GET_BEST_SELL, GET_BEST_SELL_QUERY} from "../../graphql/statistics";
import {widget} from "../../../modulesCompiler";
import React from "react";


function BestSellByProduct() {
    const {data, loading, error, previousData} = useQuery(GET_BEST_SELL, {
        variables: {byProduct: true},
        notifyOnNetworkStatusChange: true
    });
    if(loading && !previousData) return <Loader />
    if(error)   return <GraphQLErrorMessage error={error} />
    let holder = data || previousData;
    const orderProducts = holder ? holder[GET_BEST_SELL_QUERY].data : [];
    //const paginatorInfo = holder ? holder[GET_BEST_SELL_QUERY].paginatorInfo : {};

    return (
        <div>
            {orderProducts.map(item => {
                const product = item.product;
                if(!product) return null;
                return <div key={item.id} className="d-flex mb-1 pb-1 border-bottom align-items-center justify-content-between">
                    <div className="me-3" style={{width: 50}}>
                        <div className="bcg_image" style={{backgroundImage: `url(${widget("storage.image", product.image)})`}} />
                    </div>
                    <div className="flex-grow-1">
                        <strong>{product.name}</strong>
                        {product.code &&
                        <div>#{product.code}</div>}
                    </div>
                    <div title="Broj prodaja." className="ms-3">
                        Broj prodaja: <strong> {item.total_sell} </strong>
                    </div>
                </div>
            })}
        </div>
    )
}
export default BestSellByProduct;