import {BrowserRouter, Switch} from "react-router-dom";
import ScrollToTop from "../components/ScrollToTop";
import {routes, widget} from "../../modulesCompiler";
import {useQuery} from "@apollo/client";
import {GET_SELF, GET_SELF_QUERY} from "../../_cms/graphql/admin";


function GlobalRouterWrapper()    {
    const {data} = useQuery(GET_SELF);
    const admin = data ? data[GET_SELF_QUERY] : null;

    const last = [];
    return (
        <BrowserRouter>
            <ScrollToTop/>
            <Switch>
                {Object.values(routes).map((route, index) => {
                    if(route.roles && !route.roles.includes(admin?.role)) {
                        return null;
                    }
                    if(typeof route.path === 'string' && route.path.endsWith("*"))        {
                        last.push(route);
                        return null;
                    }
                    return widget("seo.SeoMiddleware", {
                        key: index,
                        exact: true,
                        route: route,
                        path: route.path,
                        component: route.component,
                        middleware: route.middleware
                    });
                })}
                {last.map((route, index) => {
                    return widget("seo.SeoMiddleware", {
                        key: index,
                        exact: true,
                        route: route,
                        path: route.path,
                        component: route.component,
                        middleware: route.middleware
                    });
                })}
            </Switch>
        </BrowserRouter>
    )
}
export default GlobalRouterWrapper;