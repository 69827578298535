import {gql} from "@apollo/client";


export const PROMO_CODE_FRAGMENT = gql`
    fragment PromoCodeFragment on PromoCode { 
        id 
        code
        count
        discount
        expires_at
        created_at
    }
`;

export const GET_PROMO_CODES_QUERY = "getPromoCodes";
export const GET_PROMO_CODES = gql`
    ${PROMO_CODE_FRAGMENT}
    query($first: Int, $page: Int, $search: String) {
        getPromoCodes(first: $first, page: $page, search: $search) {
            data {
                ...PromoCodeFragment
            }
            paginatorInfo {
                currentPage
                lastPage
                count
                total
            }
        }
    }
`

export const GET_PROMO_CODE_QUERY = "getPromoCode";
export const GET_PROMO_CODE = gql`
    ${PROMO_CODE_FRAGMENT}
    query($code: String!) {
        getPromoCode(code: $code) {
            ...PromoCodeFragment
        }
    }
`

export const CREATE_PROMO_CODE = gql`
    ${PROMO_CODE_FRAGMENT}
    mutation ($code: String!, $count: Int!, $discount: Int!, $expires_at: String)  {
        createPromoCode(code: $code, count: $count, discount: $discount, expires_at: $expires_at) {
            ...PromoCodeFragment
        }
    }
`

export const DELETE_PROMO_CODE = gql`
    ${PROMO_CODE_FRAGMENT}
    mutation ($id: ID!) {
        deletePromoCode(id: $id) {
            ...PromoCodeFragment
        }
    }
`
