import React, {useState} from "react";
import {X} from "react-feather";
import logo from "../../../../assets/images/logo.png";
import Drawer from "rc-drawer";
import {trans} from "../../../modulesCompiler";
import {useQuery} from "@apollo/client";
import {GET_CATEGORIES, GET_CATEGORIES_QUERY} from "../../graphql/categories";
import RenderCategories from "./RenderCategories";
import {useSwipeable} from "react-swipeable";


function OnlyCategoriesDrawer({button}) {
    const transName = trans("kategorije");

    const [categoriesDrawer, setCategoriesDrawer] = useState(false);
    const swipeLeftToClose = useSwipeable({
        onSwipedLeft: (eventData) => setCategoriesDrawer(false),
    });
    const { data, loading, error } =  useQuery(GET_CATEGORIES, {
        variables: {parent:  null}
    });
    if(loading) return {...button, props: {...button.props, onClick: () => setCategoriesDrawer(true)}};
    if(error)   return null;
    const categories = data ? data[GET_CATEGORIES_QUERY] : [];

    return (
        <>
            <Drawer
                open={categoriesDrawer}
                onClose={() => setCategoriesDrawer(false)}
                handler={null}
                width="360px"
                placement="left"
                level={null}>

                <div {...swipeLeftToClose} className="mt-3">

                    <div className="border-bottom p-2 text-center">
                        <img src={logo} className="img-fluid" alt="HORUS LOGO" />
                    </div>
                    <div className="border-bottom p-2 d-flex align-items-center justify-content-between">
                        <div className="oswald h5">
                            {transName}
                        </div>
                        <button onClick={() => setCategoriesDrawer(false)} className="btn btn-link btn-sm">
                            <X size={18}/>
                        </button>
                    </div>

                    <div className="p-2">
                        <RenderCategories categories={categories} close={() => setCategoriesDrawer(false)} />
                    </div>
                </div>
            </Drawer>

            {{...button, props: {...button.props, onClick: () => setCategoriesDrawer(true)}}}

        </>

    )
}
export default OnlyCategoriesDrawer;