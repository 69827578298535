import {gql} from "@apollo/client";
import {ORDER_PRODUCT_FRAGMENT} from "./order";
import {CATEGORY_FRAGMENT} from "../../categories/graphql/categories";


export const GET_BEST_SELL_QUERY = "getBestSell";
export const GET_BEST_SELL = gql`
    ${ORDER_PRODUCT_FRAGMENT}
    ${CATEGORY_FRAGMENT}
    query($byProduct: Boolean) {
        getBestSell(byProduct: $byProduct) {
            data    {
                ...OrderProductFragment
                total_sell
                category {
                    ...CategoryFragment
                }
            }
            paginatorInfo {
                currentPage
                lastPage
                count
                total
            }
        }
    }
`
