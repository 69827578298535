import React, {useCallback, useEffect, useState} from "react";
import { useEmblaCarousel } from 'embla-carousel/react'
import Banner from "./Banner";
import {DotButton, NextButton, PrevButton} from "../../../app/EmblaNavigation";


const IndexSlider = ({banners, className}) => {
    const [viewportRef, embla] = useEmblaCarousel({
        containScroll: "trimSnaps",
        loop: true,
        align: "start"
    });
    const [prevBtnEnabled, setPrevBtnEnabled] = useState(false);
    const [nextBtnEnabled, setNextBtnEnabled] = useState(false);

    const [selectedIndex, setSelectedIndex] = useState(0);
    const [scrollSnaps, setScrollSnaps] = useState([]);

    const scrollPrev = useCallback(() => embla && embla.scrollPrev(), [embla]);
    const scrollNext = useCallback(() => embla && embla.scrollNext(), [embla]);
    const scrollTo = useCallback((index) => embla && embla.scrollTo(index), [
        embla
    ]);

    const onSelect = useCallback(() => {
        if (!embla) return;
        setSelectedIndex(embla.selectedScrollSnap());
        setPrevBtnEnabled(embla.canScrollPrev());
        setNextBtnEnabled(embla.canScrollNext());
    }, [embla, setSelectedIndex]);

    useEffect(() => {
        if (!embla) return;
        onSelect();
        setScrollSnaps(embla.scrollSnapList());
        embla.on("select", onSelect);
        setInterval(() => {
            if(!document.hidden)  {
                scrollNext();
            }
        }, 6000);
    }, [embla, setScrollSnaps, onSelect, scrollNext]);

    return (
        <>
            <div className="embla">
                <div className="embla__viewport" ref={viewportRef}>
                    <div className="embla__container">
                        {banners.filter(filter => filter.active).map((banner, index) => {
                            if(!banner.active) return null;
                            return <div className="embla__slide" style={{minWidth: "100%"}} key={banner.id}>
                                <div className="embla__slide__inner" style={{height: 500}}>
                                    <Banner banner={banner} load={index === selectedIndex} overlay={false} className="embla__slide__img"  />
                                </div>
                            </div>
                        })}
                    </div>
                </div>

                <div className="d-none">
                    <PrevButton onClick={scrollPrev} enabled={prevBtnEnabled} />
                    <NextButton onClick={scrollNext} enabled={nextBtnEnabled} />
                </div>


                <div className="embla__dots">
                    {scrollSnaps.map((_, index) => (
                        <DotButton
                            key={index}
                            selected={index === selectedIndex}
                            onClick={() => scrollTo(index)}
                        />
                    ))}
                </div>
            </div>
        </>

    );
};

export default IndexSlider;