import React, {useState} from 'react';
import {Settings, Trash2} from "react-feather";
import {widget} from "../../../modulesCompiler";
import {DELETE_CATEGORY, UPSERT_CATEGORY} from "../../graphql/categories";
import AddCategoryForm from "./AddCategoryForm";
import {useMutation} from "@apollo/client";
import toast from "react-hot-toast";


function CategoryItem({item, collapseIcon, refetch, handler}) {
    const [settings, setOpen] = useState(false);
    const [deleteCategory] = useMutation(DELETE_CATEGORY);
    const mutation = UPSERT_CATEGORY;

    const deleteItem = (e) => {
        if(!window.confirm("Želite da obrišete kategoriju?"))  {
            return false;
        }
        deleteCategory({variables: {id: item.id}}).then(response => {
            setOpen(false);
            toast.success("Obrisano.");
            refetch();
        }).catch(error => {
            toast.error(error.message);
        });
    }

    return  <div>
        <div className="d-flex justify-content-between align-items-center">
            <div>
                {collapseIcon}
                {handler}
                {item.name} <span className="text-black-50 fs-s"> ({item.products_count}) </span>
            </div>
            <div>
                {item.image &&
                <img src={widget("storage.image", item.image)} alt={item.image.name} className="me-3" height={30} />}
                <button onClick={() => setOpen(!settings)} className="btn btn-light btn-sm">
                    <Settings size={16} />
                </button>
            </div>
        </div>
        {settings &&
        <div className="mt-2 border-top p-3">
            <div className="row">
                <div className="col-md-6">
                    <div className="mb-3">
                        <div className="h5">NAZIV</div>
                        {widget("lang.LangCmsUpdateField", {
                            element: "text",
                            id: item.id,
                            MUTATION: mutation,
                            name: "name",
                            refetch: refetch,
                            nullable: true,
                            values: item.names
                        })}

                        <div className="h5">SLUG</div>
                        {widget("lang.LangCmsUpdateField", {
                            element: "text",
                            id: item.id,
                            MUTATION: mutation,
                            name: "slug",
                            refetch: refetch,
                            nullable: true,
                            values: item.slugs
                        })}
                    </div>
                    <div className="mb-3">
                        <AddCategoryForm parent={item} refetch={refetch} />
                    </div>
                    <button onClick={deleteItem} className="btn btn-light mb-3">
                        <Trash2 size={16} />  Obrišite
                    </button>

                    <div style={{width: 220}}>
                        {widget("storage.UpdateImage", {
                            id: item.id,
                            MUTATION: mutation,
                            name: "image_id",
                            refetch: refetch,
                            item: item.image})}
                    </div>

                    <div className="mt-3 border-top pt-2" style={{width: 220}}>
                        <strong className="mb-2"> Tabela veličina: </strong>
                        {widget("storage.UpdateImage", {
                            id: item.id,
                            MUTATION: mutation,
                            name: "sizes_image_id",
                            refetch: refetch,
                            item: item.sizes_image})}
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="h5">SEO TITLE</div>
                    {widget("lang.LangCmsUpdateField", {
                        element: "textarea",
                        id: item.id,
                        MUTATION: mutation,
                        name: "seo_title",
                        refetch: refetch,
                        nullable: true,
                        values: item.seo_titles
                    })}

                    <div className="h5">SEO KEYWORDS</div>
                    {widget("lang.LangCmsUpdateField", {
                        element: "textarea",
                        id: item.id,
                        MUTATION: mutation,
                        name: "seo_keywords",
                        refetch: refetch,
                        nullable: true,
                        values: item.seo_keywords_all
                    })}

                    <div className="h5">SEO DESCRIPTION</div>
                    {widget("lang.LangCmsUpdateField", {
                        element: "textarea",
                        id: item.id,
                        MUTATION: mutation,
                        name: "seo_description",
                        refetch: refetch,
                        nullable: true,
                        values: item.seo_descriptions
                    })}
                </div>
            </div>
        </div>}
    </div>
}
export default CategoryItem;
