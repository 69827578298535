import BasketContext from "../contexts/BasketContext";
import {trans, widget} from "../../modulesCompiler";
import toast from "react-hot-toast";
import {randString} from "../../../app/helpers";
import {useLazyQuery, useMutation} from "@apollo/client";
import {
    GET_BASKET_PRODUCTS,
    GET_BASKET_PRODUCTS_QUERY,
} from "../../products/graphql/product";
import {useEffect, useState} from "react";
import {CREATE_LOG_BASKET_ADD} from "../graphql/logs";



function GlobalBasketGuestWrapper({children})    {
    const getStorage = () => {
        let storage = window.localStorage.getItem("basket");
        if(!storage)    {
            storage = [];
        }
        try {
            storage = JSON.parse(storage);
        } catch (e) {
            storage = [];
        }
        return storage;
    }
    const saveStorage = (storage) => {
        window.localStorage.setItem("basket", JSON.stringify(storage));
    }
    const removeStorage = () => {
        window.localStorage.removeItem("basket");
    }
    const transAdded = trans("dodato");
    const [basket, setBasket] = useState([]);
    const [logBasketAdded] = useMutation(CREATE_LOG_BASKET_ADD);

    const ids = getStorage().map(item => item.product);
    const [getBasket, {data}] = useLazyQuery(GET_BASKET_PRODUCTS, {
        variables: {ids},
        swr: true
    });

    useEffect(() => {
        if(data)    {
            const holder = [];
            const products = data[GET_BASKET_PRODUCTS_QUERY];
            const storage = getStorage();
            for(let i=0; i<storage.length; i++)    {
                const product = products.find(item => item.id === storage[i].product);
                if(!product) continue;
                const place = {...storage[i], product: product};
                holder.push(place);
            }
            setBasket(holder);
        }
    }, [data])

    const user = widget("users.providerCheckUser");
    if(user)   {
        return children;
    }

    const total = () => {
        let holder = 0;
        for(let i=0; i<basket.length; i++)  {
            if(!basket[i].product.process_price) continue;
            holder += (basket[i].product.process_price * basket[i].qty);
        }
        return holder;
    }
    const add = (item, qty, filters = []) => {
        if(!qty) qty = 1;
        qty = parseInt(qty);
        const insert = {product: item.id, qty, filters, id: randString()};
        saveStorage([...getStorage(), insert]);
        getBasket();
        logBasketAdded({variables: {product_id: item.id}});
        toast.success(transAdded);
    }
    const remove = (id) => {
        const newStorage = getStorage().filter(item => item.id !== id);
        saveStorage(newStorage);
        const newBasket = basket.filter(item => item.id !== id);
        setBasket(newBasket);
    }

    const empty = () => {
        removeStorage();
        setBasket([]);
    }

    const updateQty = (id, qty) => {
        const newStorage = getStorage().map(item => {
            if(item.id !== id) return item;
            return {...item, qty: parseInt(qty)};
        });
        saveStorage(newStorage);
        const newBasket = basket.map(item => {
            if(item.id !== id) return item;
            return {...item, qty: parseInt(qty)};
        });
        setBasket(newBasket);
    }
    const updateFilters = (id, filters) => {
        const newStorage = getStorage().map(item => {
            if(item.id !== id) return item;
            return {...item, filters: filters};
        });
        saveStorage(newStorage);
        const newBasket = basket.map(item => {
            if(item.id !== id) return item;
            return {...item, filters: filters};
        });
        setBasket(newBasket);
    }

    return <BasketContext.Provider value={{basket, total, add, remove, empty, updateQty, updateFilters, getBasket}}>
        {children}
    </BasketContext.Provider>
}
export default GlobalBasketGuestWrapper;