import Filters from "./views/filters/Filters";
import CategoryDrawer from "./widgets/CategoryDrawer";
import ProductFilters from "./widgets/productFilters/ProductFilters";


const routes = {
    filters: {path: "/cms/filteri", component: Filters, middleware: 'cms.AdminAuthenticatedMiddleware'},
};
const cmsLeftMenu = [
    {path: routes.filters.path, name: "Filteri", position: 3},
];

const exports = {
    routes: routes,
    cmsLeftMenu: cmsLeftMenu,
    widgets: {
        CategoryDrawer: CategoryDrawer,
        ProductFilters: ProductFilters
    }
}
export default exports;