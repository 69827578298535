import React from "react";
import {trans, widget} from "../../../modulesCompiler";


function About() {

    return (
        <div className="mb-6">
            {widget("public.Breadcrumbs", {links: [{trans: trans("o nama")}]})}

            <div className="container">
                {widget("design.BlocksRender", {code: "about"})}
            </div>
        </div>

    )
}
export default About;