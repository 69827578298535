import {gql} from "@apollo/client";
import {BLOCK_BANNER_FRAGMENT} from "./banner";
import {BLOCK_CATEGORY_FRAGMENT} from "./category";
import {PRODUCT_FRAGMENT} from "../../products/graphql/ProductFragment";
import {BLOCK_PRODUCT_FRAGMENT} from "./product";
import {CATEGORY_FRAGMENT} from "../../categories/graphql/categories";
import {BLOCK_CATEGORIES_FRAGMENT} from "./categories";
import {BLOCK_TEXT_FRAGMENT} from "./text";
import {STORAGE_FRAGMENT} from "../../storage/graphql/StorageFragment";


export const BLOCK_FRAGMENT = gql`
    fragment BlockFragment on Block {
        id
        active
        type
        code
        special
        position
        title
        titles
        var_1
        var_2
        var_3
    }
`;

export const GET_BLOCKS_QUERY = "getBlocks";
export const GET_BLOCKS = gql`
    ${BLOCK_FRAGMENT}
    ${PRODUCT_FRAGMENT}
    ${BLOCK_BANNER_FRAGMENT}
    ${BLOCK_PRODUCT_FRAGMENT}
    ${BLOCK_CATEGORY_FRAGMENT}
    ${BLOCK_CATEGORIES_FRAGMENT}
    ${BLOCK_TEXT_FRAGMENT}
    ${CATEGORY_FRAGMENT}
    ${STORAGE_FRAGMENT}
    query ($code: String!, $active: Boolean) { 
        getBlocks(code: $code, active: $active) {
            ...BlockFragment
            banners { 
                ...BlockBannerFragment
            }
            category { 
                ...BlockCategoryFragment
            } 
            products    {
                ...ProductFragment
                block   {
                    ...BlockProductFragment
                }
            }
            categories {
                ...CategoryFragment
                block   {
                    ...BlockCategoriesFragment
                }
            }
            text { 
                ...BlockTextFragment
            } 
        }
    }
`


export const GET_BLOCKS_BY_ADMIN = gql`
    ${BLOCK_FRAGMENT}
    ${PRODUCT_FRAGMENT}
    ${BLOCK_BANNER_FRAGMENT}
    ${BLOCK_PRODUCT_FRAGMENT}
    ${BLOCK_CATEGORY_FRAGMENT}
    ${BLOCK_CATEGORIES_FRAGMENT}
    ${BLOCK_TEXT_FRAGMENT}
    ${CATEGORY_FRAGMENT}
    ${STORAGE_FRAGMENT}
    query ($code: String!, $active: Boolean) {
        getBlocks(code: $code, active: $active) {
            ...BlockFragment
            banners {
                ...BlockBannerFragment
                images {
                    ...StorageFragment
                }
                mobile_images {
                    ...StorageFragment
                }
            }
            category {
                ...BlockCategoryFragment
            }
            products    {
                ...ProductFragment
                block   {
                    ...BlockProductFragment
                }
            }
            categories {
                ...CategoryFragment
                block   {
                    ...BlockCategoriesFragment
                }
            }
            text {
                ...BlockTextFragment
            }
        }
    }
`

export const UPSERT_BLOCK = gql`
    ${BLOCK_FRAGMENT}
    mutation ($id: ID, $active: Boolean, $code: String, $type: Int, $title: String, 
        $var_1: String, $var_2: String, $var_3: String)  {
        upsertBlock(id: $id, active: $active, code: $code, type: $type, title: $title,
         var_1: $var_1, var_2: $var_2, var_3: $var_3) {
            ...BlockFragment
        }
    }
`

export const DELETE_BLOCK = gql`
    ${BLOCK_FRAGMENT}
    mutation ($id: ID!) {
        deleteBlock(id: $id) {
            ...BlockFragment
        }
    }
`