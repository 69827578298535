import {useQuery} from "@apollo/client";
import {GET_CATEGORIES, GET_CATEGORIES_QUERY} from "../graphql/categories";
import Loader from "../../../app/Loader";
import GraphQLErrorMessage from "../../../app/GraphQLErrorMessage";
import React, {useState} from "react";
import {Link} from "react-router-dom";
import {route} from "../../modulesCompiler";


function DropDownMenu() {
    const [show, setShow] = useState(true);
    const productShopRoute = route("products.shop");

    const { data, loading, error } =  useQuery(GET_CATEGORIES, {
        variables: {parent: null}
    });

    if(loading) return <Loader />
    if(error)   return <GraphQLErrorMessage error={error} />
    if(!data)   return null;
    const categories = data[GET_CATEGORIES_QUERY];

    const close = () => {
        setShow(false);
        setTimeout(() => {
            setShow(true);
        }, 300);
    }

    return (
        <div>
            {show &&
            <div className="p-4 border">
                <div className="row">
                    {categories.map(item => {
                        return <div key={item.id} className="col-md-2">
                            <div className="oswald mb-3 fw-bold text-uppercase">
                                <Link onClick={close} to={`${productShopRoute}/${item.slug}`}>
                                    {item.name}
                                </Link>
                            </div>
                            <div>
                                {item.children.map(child => {
                                    return <div key={child.id} className="mb-2">
                                        <Link onClick={close} to={`${productShopRoute}/${child.slug}`}>
                                               {child.name}
                                            <span style={{color: "#808080"}} className="fs-s ms-1 d-inline-block">
                                                ({child.products_count})
                                           </span>
                                        </Link>
                                    </div>
                                })}
                            </div>
                        </div>
                    })}
                </div>
            </div>}
        </div>
    )

}
export default DropDownMenu;