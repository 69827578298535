import {route, trans, widget} from "../../../modulesCompiler";
import React from "react";


function PolitikaPrivatnosti() {
    const lang = widget("lang.useGetLang", null);

    return (
        <>
            {widget("public.Breadcrumbs", {links: [{trans: trans("politika privatnosti")}]})}
            <div className="container mb-6">
                <h1 className="oswald fw-bold text-uppercase h3">{trans("politika privatnosti")}</h1>

                {lang === 0 &&
                <>
                    Poslovanje je u današnje vreme neizostavno povezano i sa obradom ličnih podataka.
                    <br /><br />
                    Kao kompanija koja ima reputaciju u ovoj delatnosti i koja u prvi plan stavlja odnos prema potrošačima, veoma nam je stalo do zaštite podataka o ličnosti naših kupaca i korisnika sajta.
                    <br /><br />
                    Horus Wear doo se obavezuje da štiti privatnost kupaca i korisnika sajta i garantuje da će podatke koje kupac odnosno korisnik sajta ostavi koristiti, čuvati i obrađivati u skladu sa svrhom i pravnim osnovom, zbog kojih su prikupljeni.
                    <br /><br />
                    Horus Wear doo će preduzeti odgovarajuće tehničke i organizacione mere, u cilju obezbeđivanja da se obrada podataka o ličnosti vrši u skladu sa pozitivnopravnim propisima i kako bi se sprečio gubitak, zloupotreba ili izmena podataka o ličnosti kupaca.
                    <br /><br />
                    Podatke koje ste nam poverili nećemo dostaviti neovlašćenim trećim licima i sa njima ćemo postupati u skladu sa važećim Zakonom o zaštiti podataka o ličnosti (“Sl. Glasnik RS” br. 87/2018). Uvid u prikupljene podatke imaju isključivo ovlašćena zaposlena lica u Horus Wear doo, kao i obrađivači i primaoci sa kojima je regulisan odnos. Svi zaposleni u Horus Wear doo su svesni značaja čuvanja tajnosti podataka i odgovorni su za poštovanje ove Politike privatnosti.
                    <br /><br />
                    Poštovani korisnici, molimo vas da pre korišćenja naših usluga, pažljivo pročitate sledeće uslove. Svaka poseta našoj stranici, kao i kupovina, znači da ste ove uslove pročitali i da se slažete sa njima u celosti. Ukoliko su oni za vas neprihvatljivi, molimo vas da ne koristite ovu web stranicu.
                    <br /><br />
                    Ova Politika privatnosti sadrži informacije o vrsti ličnih podataka koji se obrađuju, o pravnom osnovu i svrsi obrade ličnih podataka, pravima lica na koja se podaci odnose, o rukovaocu, obrađivačima i primaocima ličnih podataka, vremenskom periodu čuvanja podataka o ličnosti i izmenama i dopunama ove Politike privatnosti.
                    <br /><br />

                    <h5 className="oswald fw-bold">PODACI O RUKOVAOCU</h5>
                    Odgovornost za obradu podataka o ličnosti u svojstvu rukovaoca snosi: <br />
                    Naziv: Horus Wear doo <br />
                    Sedište: Kralja Milana 22-24, 11000 Beograd – Stari grad <br />
                    Matični broj: 21578703 <br />
                    Poreski identifikacioni broj: 111944329 <br />
                    Email: shop@horus.rs <br />
                    Telefon: +381 65 4321023 <br />
                    Lice za zaštitu podataka o ličnosti: Nemanja Mijatović <br />
                    Kontakt: <a href="mailto:nemanja@horus.rs" className="link-secondary">nemanja@horus.rs</a> <br /><br />
                    <h5 className="oswald fw-bold"> PODACI O LIČNOSTI KOJI SE OBRAĐUJU </h5>
                    Horus Wear d.o.o obrađuje sledeće podatke:<br />
                    Ime i prezime<br />
                    Adresa stanovanja<br />
                    Broj telefona<br />
                    E-mail adresa<br />
                    IP adresa<br />
                    Broj telefona<br /><br />


                    <h5 className="oswald fw-bold">1. PRAVNI OSNOV I SVRHA OBRADE</h5>

                    Prema važećem Zakonu o zaštiti podataka o ličnosti podatak o ličnosti je svaki podatak koji se odnosi na fizičko lice čiji je identitet određen ili odrediv, neposredno ili posredno, posebno na osnovu oznake identiteta, kao što su: ime i identifikacioni broj, podatak o lokaciji, identifikator u komunikacionim mrežama ili jednog ili više obeležja njegovog fizičkog, fiziološkog, genetskog, mentalnog, ekomonskog, kulturnog i društvenog identiteta; dok je lice na koje se podaci odnose fizičko lice čiji se podaci o ličnosti obrađuju.
                    <br /><br />
                    Pravni osnov za obradu podataka o ličnosti može biti:
                    <br /><br />
                    Pristanak, dat od strane posetioca web stranice HORUS, kojom dozvoljavaju prikupljanje za jasno naznačenu svrhu. Pristanak se može povući u bilo kom trenutku slanjem e-maila odgovarajućeg sadržaja na <a href="mailto:nemanja@horus.rs" className="link-secondary">nemanja@horus.rs</a> ili slanjem dopisa na adresu koja je prethodno naznačena;
                    <br /><br />
                    Zaključenje i/ili izvršenje ugovora;         <br /><br />
                    Poštovanje pravnih obaveza rukovaoca;         <br /><br />
                    Zaštita životno važnih interesa lica na koje se podaci odnose ili drugog fizičkog lica;
                    <br /><br />
                    Obavljanje poslova u javnom interesu ili izvršenje zakonom propisanih ovlašćenja rukovaoca;
                    <br /><br />
                    Legitimni interes rukovaoca - za marketinške i analitičke svrhe. Naš legitimni interes zasnovan je na vašim željama, kako bismo što bolje prilagodili našu ponudu i na kraju ponudili proizvode i usluge koji bolje odgovaraju vašim potrebama i željama;
                    <br /><br />
                    Pravni osnov određen je u skladu sa čl. 12 važećeg Zakona o zaštiti podataka o ličnosti.<br /><br />
                    Svrha obrade:<br /><br />
                    Horus Wear doo obrađuje podatke o ličnosti navedene u ovoj Politici privatnosti o svakom korisniku sa svrhom:
                    <br /><br />
                    odgovaranja na upit ili zahtev korisnika i pružanja najbolje moguće usluge;<br /><br />
                    obrađivanja porudžbine (npr. obaveštavnje kupca o statusu narudžbine, vremenu dostave, odgovaranje na eventualne zahteve u vezi narudžbine i sl.)
                    <br /><br />
                    omogućavanje kupcima podnošenje reklamacija i povrata u online prodavnici;<br /><br />
                    omogućavanje kupcima zamene robe;<br /><br />
                    omogućavanje kupcima različitih načina plaćanja;<br /><br />
                    omogućavanje kupcima povraćaj sredstava plaćanja;<br /><br />
                    omogućavanje prikazivanja potrošačima sadržaja na web stranici na najučinkovitiji način;<br /><br />
                    filtriranje sadržaja koji je prilagođen vašim interesovanjima, to jest kako bismo vam ponudili svoje proizvode za koje je utvrđeno da ih preferiraju korisnici istog pola i istog uzrasta, u cilju olakšavanja pretrage;
                    <br /><br />
                    obaveštavanje o promenama odredaba, uslova i pravila i/ili drugih administrativnih podataka;<br /><br />
                    pohranjivanje bilo kojeg popisa liste želja, koje korisnici web stranice mogu kreirati;<br /><br />
                    omogućavanje učestvovanja korisnika u nagradnim igrama i sličnim promocijama;<br /><br />
                    prilagođavanje sadržaja korisnika našim web stranicama, u cilju pružanja personalizovane ponude;<br /><br />
                    otkrivanje i/ili sprečavanje zloupotrebe ili prevare.<br /><br />

                    Vaše podatke možemo obrađivati i u marketinške svrhe na temelju vaše posebne saglasnosti, i to sa svrhom:
                    <br /><br />
                    izveštavanja o posebnim pogodnostima, koje su često vremenski ograničene;
                    <br /><br />
                    izveštavanja o sezonskim sniženjima, akcijskim prodajama i ostalim prodajnim pogodnostima;
                    <br /><br />
                    izveštavanja o posebnim akcijama rezervisanim za naše članove;
                    <br /><br />
                    obaveštavanja o novim kolekcijama;
                    <br /><br />
                    dostavljanja korisnih informacija vezanih za HORUS Internet prodavnicu i druge maloprodajne objekte;
                    <br /><br />
                    kontaktiranja u vezi oglašavanja i istraživanja tržišta (slanje upitnika, anketa i sl.);
                    <br /><br />
                    marketinških analiza, u cilju poboljšanja prodajne ponude.
                    <br /><br />
                    Svaki korisnik odgovara za tačnost i istinitost svih informacija koje je dao.
                    <br /><br />

                    <h5 className="oswald fw-bold">1.2 PORUČIVANJE PROIZVODA U ONLINE PRODAVNICI</h5>
                    Kupovinu u našoj online prodavnici može se ostvariti kao neregistrovani korisnik - “gost”.
                    <br /><br />
                    U cilju uspešnog obrađivanja narudžbine od strane “gosta” potrebni su nam sledeći lični podaci: ime i prezime, adresa i mesto prebivališta, adresa isporuke, e-mail adresa i kontakt telefon. Ukoliko ne pružite tražene podatke, HORUS verovatno neće moći da obradi vašu porudžbinu i dostavi vam naručenu robu. Svaki korisnik odgovora za tačnost podataka koje je uneo.
                    <br /><br />
                    U skladu sa načelom minimizacije podataka, od vas samo tražimo osnovne podatke, koji su nam neophodni za sprovođenje online prodaje, odnosno podatke koje će nam omogućiti da vas, u skladu sa propozicijama Horus Wear doo i dobrim poslovnim običajima, informišemo o svemu što može doprineti poboljšanju naše usluge, sa jedne, te povećanju vašeg zadovoljstva našom uslugom, sa druge strane.
                    <br /><br />

                    <h5 className="oswald fw-bold">1.3 DOSTAVA PROIZVODA NARUČENIH U ONLINE PRODAVNICI</h5>
                    Lične podatke kontakt osobe za isporuku, tj. ime i prezime, adresa isporuke, e-mail adresa i broj telefona, dobijene potvrdom narudžbine, delimo sa našim partnerima za logistiku (DExpress), kako bismo pripremili proizvode iz vaše narudžbine i dostavili ih na adresu prema vašem zahtevu. Poslovni partneri ovlašćeni su te podatke koristiti i kontaktirati vas isključivo u svrhu isporuke pojedinačnih pošiljki.
                    <br /><br />

                    <h5 className="oswald fw-bold">1.4 POSTAVLJANJE UPITA ILI ZAHTEVA</h5>
                    Korisnik našeg veb-sajta može nam postaviti specifična pitanja posredstvom kontaktnih obrazaca, koji su dostupni na HORUS Internet prodavnicu ili alternativno, putem e-mail adrese. Podaci koje korisnik dostavi (IP adresa i/ili mail adresa) prilikom postavljanja željenih upita biće korišćeni za rešavanje korisnikovih pitanja.
                    <br /><br />
                    Upite ili zahteve možete postaviti putem društvenih mreža ili email-a shop@horus.rs .<br /><br />
                    Navedena e-mail adresa nije lična, već poslovna e-adresa Horus Wear doo.<br /><br />
                    Dakle, ista nije vezana ni za jedno fizičko lice, već podatke dostavljene ovim putem mogu obrađivati različite osobe, zaposlene u Horus Wear doo, a sve u cilju brzog i efikasnog rešavanja korisnikovog zahteva.
                    <br /><br />
                    Slanjem poruke na e-mail adresu navedene na veb-sajtu www.horus.rs korisnik se slaže sa opštim uslovima korišćenja naše online prodavnice.
                    <br /><br />
                    Takođe, ukoliko nam ne pružite navedene neophodne podatke prilikom postavljanja upita ili zahteva, Horus Wear doo verovatno neće moći da postupi na zatraženi način.
                    <br /><br />

                    <h5 className="oswald fw-bold">1.5 PLAĆANJE U ONLINE PRODAVNICI</h5>
                    Plaćanje u online prodavnici se vrši isključivo putem pouzeća, tj. prilikom preuzimanja robe.
                    <br /><br />
                    <h5 className="oswald fw-bold">1.6 Newsletter i upotreba podataka za marketing</h5>
                    Putem newsletter-a Horus Wear doo obaveštava korisnike koji pošalju svoj pristanak o: novostima u ponudi u našoj online prodavnici i maloprodajnim objektima, tekućim marketinškim aktivnostima, programu lojalnosti, novim člancima na blogu i drugim programima vernosti, nagradnim igrama, posebnim događajima koje Horus Wear doo organizuje, kao i o svim drugim aktuelnim događanjima vezanih za Horus Wear doo.
                    <br /><br />
                    Kako biste se prijavili za primanje newsletter-a dovoljno je da pošaljete adresu e-pošte, na koju želite primati newsletter. U svakom trenutku možete se odjaviti sa newsletter-a u skladu sa uputstvima koje ćete dobiti e-poštom nakon prijave, kao i u svakom newsletter-u.
                    <br /><br />
                    Prijavom na newsletter izjavljujete da ste saglasni da vršimo procenu vaše aktivnosti na našem sajtu, u cilju prilagođavanja HORUS Internet prodavnice vašim zahtevima i interesovanjima, odnosno da dobijanja personalizovane kompilacije vesti, ponuda i drugih informacija. Vaši podaci se čuvaju i koriste za istraživanje tržišta i u promotivne svrhe. Ukoliko je potrebno, kontaktiraćemo vas isključivo putem e-mail adrese, koju ste nam pružali kao podatak o ličnosti. Vaše podatke ćemo koristiti i za analizu i poboljšanje efikasnosti HORUS Internet prodavnice.
                    <br /><br />
                    Za upravljanje prijavama i slanjem newsletter-a koristimo MailChimp platformu i web sajt React JS aplikaciju putem push notifikacija ukoliko je instalirate na svoj mobilni telefon.
                    <br /><br />

                    <h5 className="oswald fw-bold">2. PODACI PRIKUPLJENI KROZ VAŠU INTERAKCIJU SA HORUSOM</h5>
                    Različite tehnologije mogu se koristiti na HORUS Internet prodavnici, kako bismo je poboljšali, učinili je jednostavnijom, prilagođenoj korisnicima, efikasnijom i sigurnom. Takve tehnologije mogu dovesti do automatskog prikupljanja ličnih podataka od strane nas ili trećih strana u naše ime. Primeri takvih tehnologija uključuju kolačiće, flash kolačiće i web-analizu.
                    <br /><br />
                    Svi podaci koje prikupimo kroz online prodaju, uključujući i podatke o proizvodima koje naši kupci traže i kupuju, odnosno stranicama koje posećuju, biće pažljivo analizirani i obrađeni, a isti će biti iskorišćeni za poboljšanje naše ponude kao i vizuelnog izgleda naše web stranice, sve u cilju omogućavanja jednostavnije, sigurnije, brže i udobnije kupovine.
                    <br /><br />

                    <h5 className="oswald fw-bold">2.1 Click-stream podaci</h5>
                    Kada posetite našu web stranicu, podaci se šalju sa vašeg pretraživača na naš server. Ti podaci omogućuju nam da optimizujemo naše usluge i poboljšamo vaše iskustvo na našim web stranicama i aplikacijama. Podatke automatski prikupljamo i pohranjujemo mi ili obrađivači, sa kojima imamo regulisan odnos.
                    <br /><br />
                    Možemo prikupljati informacije o vašem računaru za svrhe sistemske administracije, kao i za prijavljivanje zbirnih informacija za potrebe unutrašnjih marketinških analiza. Ovo su podaci o postupcima i uzorcima pregledanja naših korisnika i mogu uključivati sledeće:
                    <br /><br />
                    IP adresu posetioca <br /><br />
                    Datum i vreme posete <br /><br />
                    URL preporuke (web mesto sa koje je posetilac poslat) <br /><br />
                    Posećene stranice i “putovanje” korisnika na našoj web stranici <br /><br />
                    Informacije o korišćenom pregledaču (tip, verzija, operativni sistem i sl.).
                    <br /><br />

                    <h5 className="oswald fw-bold">2.2 Kolačići (cookies)</h5>
                    Kolačić je tekstualna datoteka koju web server postavlja na vaš hard disk. Kolačići se ne mogu koristiti za pokretanje programa ili isporuku zlonamernog softvera na vaš računar.
                    <br /><br />
                    Kolačiće upotrebljavamo kako bi HORUS Internet prodavnica radila pravilno te kako bismo bili u stanju da vršimo unapređenja stranice sa svrhom poboljšavanja vašeg korisničkog iskustva, kako bismo personalizovali sadržaj i oglase, u cilju pružanja specifičnih tehničkih karakteristika i celokupnog pozitivnog korisničkog iskustva.
                    <br /><br />
                    Pročitajte našu Politiku kolačića kako biste saznali više o našoj upotrebi kolačića.
                    <br /><br />
                    <a href={route("public.politikaKolacica")} target="_blank" rel="noreferrer" className="link-secondary">https://www.horus.rs{route("public.politikaKolacica")}</a>
                    <br /><br />

                    <h5 className="oswald fw-bold">2.3 Re-targeting tehnologije </h5>
                    HORUS Internet prodavnica koristi re-targeting tehnologije. To nam omogućava direktno i lično oglašavanje posetiocima koji su već pokazali interesovanje za naše proizvode.
                    <br /><br />
                    Re-targeting tehnologije analiziraju vaše kolačiće i prikazuju oglase na osnovu vašeg prethodnog ponašanja pri surfovanju internetom. Za više informacija o kolačićima pogledajte našu politiku kolačića.
                    <br /><br />

                    <h5 className="oswald fw-bold">3. PRAVA LICA NA KOJE SE PODACI ODNOSE</h5>
                    Ukoliko ostavite podatke o ličnosti na HORUS Internet prodavnici, to činite u potpunosti na dobrovoljnoj osnovi. Ipak, ako odlučite da ne date tražene informacije, različite pogodnosti za kupce vam možda neće biti dostupne. U određenim slučajevima samo oni koji su nam pružili potrebne lične podatke moći će da naručuju proizvode, koriste određene usluge i na drugi način iskoriste aktivnosti i ponude dostupne na našoj web stranici.
                    <br /><br />
                    U zavisnosti od okolnosti konkretnog slučaja, Horus Wear doo pruža dosta opcija, u cilju pružanja pomoći našim korisnicima u zadržavanju kontrole nad svojim ličnim podacima, odnosno u ostvarivanju svojih prava.
                    <br /><br />
                    Sva prava možete ostvariti slanjem upita ili zahteva na shop@horus.rs ili na adresu Kralja Milana 22-24, 11000 Beograd – Stari grad. Upite ili zahteve ćemo obraditi bez odlaganja i u skladu sa zakonskim obavezama te ćemo vas informisati o merama koje smo preduzeli.
                    <br /><br />
                    Kao lice na koje se podaci odnose imate sledeća prava:
                    <br /><br />

                    <h5 className="oswald fw-bold">3.1 Pravo na informisanje</h5>
                    Sve informacije u vezi obrade vaših podataka o ličnosti možete zatražiti na gore naznačenu adresu ili putem e-mail adrese. Radi zaštite lica na koje se podaci odnose, potrebno je da se kao podnosilac upita ili zahteva identifikujete na odgovarajući način.
                    <br /><br />
                    <h5 className="oswald fw-bold">3.2 Pravo pristupa</h5>
                    Imate pravo da dobijete potvrdu o obrađivanju vaših podataka o ličnosti. Po osnovu ovog prava možete dobiti informaciju o tome koji se vaši podaci obrađuju, svrhu obrade kao i pravni osnov za obradu, informacije o rukovaocu i obrađivačima ličnih podatka, roku čuvanja podataka o ličnosti i dr.
                    <br /><br />
                    <h5 className="oswald fw-bold">3.3 Pravo na ispravku i dopunu</h5>
                    Imate pravo da tražite ispravku netačnih ličnih podataka i pravo da dodate lične podatke.
                    <br /><br />
                    <h5 className="oswald fw-bold">3.4 Pravo na brisanje</h5>
                    U određenim slučajevima imate pravo da zatražite brisanje vaših ličnih podataka (npr. ako podaci više nisu neophodni za ostvarivanje svrhe zbog koje su obrađivani, opozvali ste pristanak na osnovu kojeg se obrada vršila, itd). Ukoliko iz bilo kojeg razloga ne možemo udovoljiti vašem zahtevu, obavestićemo vas o istom.
                    <br /><br />
                    <h5 className="oswald fw-bold">3.5 Pravo na ograničenje obrade</h5>
                    U posebnim okolnostima, imate pravo ograničiti obradu vaših ličnih podataka. Svoja prava vezana za ograničenje obrade možete ostvariti i putem Poverenika za informacije od javnog značaja i zaštitu podataka o ličnosti.
                    <br /><br />
                    <h5 className="oswald fw-bold">3.6 Pravo na prenosivost podataka</h5>
                    Imate pravo primati svoje lične podatke u strukturiranom, uobičajenom i mašinski čitljivom formatu, a vi imate pravo da ih prenesete drugom subjektu. Ovo pravo možete ostvariti samo ako se obrada zasniva na pristanku ili na osnovu ugovora i kada se vrši automatizovano.
                    <br /><br />
                    <h5 className="oswald fw-bold">3.7 Pravo na prigovor</h5>
                    Imate pravo da se žalite na određene vrste obrade i prikupljanja podataka, kada se obrada zasniva na legitimnim interesima HORUSA, npr. za marketinške svrhe.
                    <br /><br />
                    <h5 className="oswald fw-bold">3.8 Pravo na povlačenje pristanka</h5>
                    U svakom trenutku imate pravo da povučete pristanak za obradu podataka o ličnosti. Povlačenje saglasnosti neće uticati na zakonitost obrade za period pre povlačenja pristanka.
                    <br /><br />
                    <h5 className="oswald fw-bold">3.9 Pravo na pritužbu nadzornom organu</h5>
                    Ako smatrate da obrada vaših ličnih podataka krši odredbe važećeg Zakona o zaštiti podataka o ličnosti, imate pravo da podnesete pritužbu Povereniku za informacije od javnog značaja i zaštitu podataka o ličnosti, ul. Bulevar kralja Aleksandra br. 15, Beograd.
                    <br /><br />


                    <h5 className="oswald fw-bold">4. KAKO MOŽETE OSTVARITI SVOJA PRAVA, KONTAKT ZA POTREBE IZRICANJA PRIGOVORA, REŠAVANJA PRITUŽBI</h5>                    Kako biste poslali pritužbu ili prigovor, ako želite da rešite neki nesporazum, nejasnoću ili nedoumicu u vezi vaših ličnih podataka koje obrađuje Horus Wear doo molimo Vas da to učinite koristeći naše sledeće kontakte:
                    Horus Wear doo  <br /><br />
                    ul. Kralja Milana 22-24,  <br /><br />
                    11000 Beograd – Stari grad,  <br /><br />
                    Republika Srbija  <br /><br />
                    e-mail: shop@horus.rs  <br /><br />
                    Da bismo vas zaštitili kao titulara ličnih podataka, potrebno je da se na odgovarajući način identifikujete kao podnosilac prijave, koji će za Horus Wear doo predstavljati dokaz da lične podatke daje njihovom vlasniku.
                    <br /><br />

                    <h5 className="oswald fw-bold">5. OBRAĐIVAČI LIČNIH PODATAKA</h5>
                    Horus Wear doo koristi obrađivače za prikupljanje i obradu podataka o ličnosti. Svi obrađivači podataka i primaoci koje Horus Wear doo angažuje obrađuju samo vaše lične podatke u skladu sa Horusovim uputstvima i zakonski i ugovorno su dužni da se pridržavaju strogih sigurnosnih postupaka prilikom rukovanja ličnim podacima.
                    <br /><br />
                    Horus Wear doo kao rukovalac može da odredi kao obrađivača samo ono lice, koje u potpunosti garantuje primenu odgovarajućih tehničkih, organizacionih i kadrovskih mera, na način koji obezbeđuje da se obrada vrši u skladu sa odredbama važećeg Zakona o zaštiti podataka o ličnosti i da se obezbeđuje zaštita prava lica na koje se podaci odnose.obrade podataka o ličnosti i u skladu s tim je odredila sledeće obrađivače podataka o ličnosti:
                    <br /><br />
                    SuperWeb agencija za uslugu pripreme štampe, grafički i web dizajn - Obrađivač koji Rukovaocu izrađuje i održava HORUS Internet prodavnicu, kao i panel na kome se obrađuju porudžbine, te obrađuje sledeće podatke o ličnosti Potrošača: ime i prezime, broj telefona, e-mail adresa, adresa stanovanja, IP adresa;
                    <br /><br />

                    <h5 className="oswald fw-bold">6. Primaoci podataka o ličnosti</h5>
                    Horus Wear doo deli podatke o ličnosti potrošača sa sledećim poslovnim partnerima, u svrhe ispunjavanja funkcije HORUS Internet prodavnice i pružanja Potrošačima kvalitetnog iskustva na HORUS Internet prodavnici:
                    <br /><br />
                    DExpress - kurirska služba, koja pruža uslugu isporuke poručenih artikala Potrošačima, a radi izvršenja porudžbine prima sledeće podatke: ime i prezime, adresa i broj telefona;
                    <br /><br />
                    Veb-stranica www.horus.rs tehničkim i organizacijskim merama štiti od neovlašćenog pristupa, izmene ili deljena podataka o ličnosti, kao i od gubljenja ili brisanja, ali ne može garantovati za druge Internet kompanije.
                    <br /><br />

                    <h5 className="oswald fw-bold">7. VREMENSKI ROK U KOM SE VAŠI LIČNI PODACI ČUVAJU</h5>
                    Podaci koje dostavite se čuvaju onoliko koliko je potrebno da bi se ispunila određena svrha zbog koje ste nam dostavili podatke ili da bi se ispoštovale zakonske odredbe.
                    <br /><br />
                    Svi prikupljeni podaci u vezi čuvaju se dok se ne dobije zahtev za njihovo brisanje. Nakon što dobijemo zahtev za uklanjanje podataka, HORUS će u narednih šest meseci čuvati vaše lične podatke kako bi se u tom razdoblju rešile eventualne reklamacije iz prethodnog perioda.
                    <br /><br />
                    Svi prikupljeni podaci u vezi kupoprodaje robe u HORUS Internet prodavnici obrađuju se do trenutka dok se ne ostvari svrha obrade tj. do trenutka uspešno izvršene kupovine, uračunavajući i vreme za rešavanje eventualnih prigovora, reklamacija, povraćaja ili zahteva koje se odnose na kupovinu proizvoda.
                    <br /><br />
                    Prikupljeni podaci u vezi podnošenja upita ili zahteva čuvaju se za ono vreme koje je potrebno da bi se udovoljilo vašem zahtevu ili upitu.
                    <br /><br />
                    Ako ste nam dali svoju izričitu saglasnost za upotrebu ličnih podataka u promotivne svrhe, mi ćemo koristiti vaše podatke za takvu namenu, sve dok ne opozovete svoju saglasnost. Možete opozvati saglasnost koju ste dali u svakom trenutku tako da opoziv proizvodi dejstvo u budućnosti.
                    <br /><br />
                    Svi podaci prikupljeni kroz interakciju sa Horusom (kao što su analiza kupovine, IP adresa, analiza uređaja, retargeting marketing i sl) prilikom posete web stranici brisaće se u roku od 24 meseca od dana njihovog prikupljanja.
                    <br /><br />
                    Ako to zahtevaju pozitivnopravni propisi, HORUS navedene podatke može čuvati i duže, u skladu sa propisanim rokom čuvanja podataka.
                    <br /><br />
                    Po isteku perioda čuvanja, lične podatke brišemo trajno ili ih činimo anonimnim, tako da se više ne mogu povezati sa određenim pojedincem.
                    <br /><br />

                    <h5 className="oswald fw-bold">8. MALOLETNICI </h5>
                    Maloletnici mlađi od 15 godina ne smeju da koriste HORUS veb-stranicu. Horus Wear doo ne prikuplja, niti zadržava lične podatke od lica mlađih od 15 godina, te nijedan deo ove Internet stranice nije dizajniran na način da privuče bilo koga ko je mlađi od 15 godina. Ako roditelji, rođak ili zakonski zastupnik takvog lica otkrije da je poslalo lične podatke bez njegove saglasnosti, obratite se na našu adresu ili putem e-mail-a na <a href="mailto:nemanja@horus.rs" className="link-secondary">nemanja@horus.rs</a> .
                    <br /><br />
                    <h5 className="oswald fw-bold">9. IZMENE I DOPUNE POLITIKE PRIVATNOSTI</h5>
                    Politika o privatnosti se može povremeno menjati kako bi se uskladila sa pozitivnopravnim propisima.
                    <br /><br />
                    Sve promene koje možemo načiniti u Politici privatnosti biće objavljene na ovoj stranici i prema potrebi, možete biti obavešteni putem e-pošte.

                </>}
                {lang === 1 &&
                <>
                    Пословање је у данашње време неизоставно повезано и са обрадом личних података.
                    <br /><br />
                    Као компанија која има репутацију у овој делатности и која у први план ставља однос према потрошачима, веома нам је стало до заштите података о личности наших купаца и корисника сајта.
                    <br /><br />
                    Horus Wear doo се обавезује да штити приватност купаца и корисника сајта и гарантује да ће податке које купац односно корисник сајта остави користити, чувати и обрађивати у складу са сврхом и правним основом, због којих су прикупљени.
                    <br /><br />
                    Horus Wear doo ће предузети одговарајуће техничке и организационе мере, у циљу обезбеђивања да се обрада података о личности врши у складу са позитивноправним прописима и како би се спречио губитак, злоупотреба или измена података о личности купаца.
                    <br /><br />
                    Податке које сте нам поверили нећемо доставити неовлашћеним трећим лицима и са њима ћемо поступати у складу са важећим Законом о заштити података о личности (“Сл. Гласник РС” бр. 87/2018). Увид у прикупљене податке имају искључиво овлашћена запослена лица у Horus Wear doo, као и обрађивачи и примаоци са којима је регулисан однос. Сви запослени у Horus Wear doo су свесни значаја чувања тајности података и одговорни су за поштовање ове Политике приватности.
                    <br /><br />
                    Поштовани корисници, молимо вас да пре коришћења наших услуга, пажљиво прочитате следеће услове. Свака посета нашој страници, као и куповина, значи да сте ове услове прочитали и да се слажете са њима у целости. Уколико су они за вас неприхватљиви, молимо вас да не користите ову веб страницу.
                    <br /><br />
                    Ова Политика приватности садржи информације о врсти личних података који се обрађују, о правном основу и сврси обраде личних података, правима лица на која се подаци односе, о руковаоцу, обрађивачима и примаоцима личних података, временском периоду чувања података о личности и изменама и допунама ове Политике приватности.
                    <br /><br />

                    <h5 className="oswald fw-bold">ПОДАЦИ О РУКОВАОЦУ</h5>
                    Одговорност за обраду података о личности у својству руковаоца сноси:
                    <br />
                    Назив: Horus Wear doo<br />
                    Седиште: Краља Милана 22-24, 11000 Београд – Стари град<br />
                    Матични број: 21578703<br />
                    Порески идентификациони број: 111944329<br />
                    Емаил: shop@horus.rs<br />
                    Телефон: +381 65 4321023<br />
                    Лице за заштиту података о личности: Немања Мијатовић<br />
                    Контакт: <a href="mailto:nemanja@horus.rs" className="link-secondary">nemanja@horus.rs</a><br /><br />

                    <h5 className="oswald fw-bold">ПОДАЦИ О ЛИЧНОСТИ КОЈИ СЕ ОБРАЂУЈУ</h5>
                    Horus Wear doo обрађује следеће податке:<br />
                    Име и презиме<br />
                    Адреса становања<br />
                    Број телефона<br />
                    Е-маил адреса<br />
                    ИП адреса<br />
                    Број телефона
                    <br /><br />

                    <h5 className="oswald fw-bold">1. ПРАВНИ ОСНОВ И СВРХА ОБРАДЕ</h5>
                    Према важећем Закону о заштити података о личности податак о личности је сваки податак који се односи на физичко лице чији је идентитет одређен или одредив, непосредно или посредно, посебно на основу ознаке идентитета, као што су: име и идентификациони број, податак о локацији, идентификатор у комуникационим мрежама или једног или више обележја његовог физичког, физиолошког, генетског, менталног, екомонског, културног и друштвеног идентитета; док је лице на које се подаци односе физичко лице чији се подаци о личности обрађују.
                    <br /><br />
                    Правни основ за обраду података о личности може бити:
                    <br /><br />
                    Пристанак, дат од стране посетиоца веб странице ХОРУС, којом дозвољавају прикупљање за јасно назначену сврху. Пристанак се може повући у било ком тренутку слањем е-маила одговарајућег садржаја на <a href="mailto:nemanja@horus.rs" className="link-secondary">nemanja@horus.rs</a> или слањем дописа на адресу која је претходно назначена;
                    <br /><br />
                    Закључење и/или извршење уговора;<br /><br />
                    Поштовање правних обавеза руковаоца;<br /><br />
                    Заштита животно важних интереса лица на које се подаци односе или другог физичког лица;<br /><br />
                    Обављање послова у јавном интересу или извршење законом прописаних овлашћења руковаоца;<br /><br />
                    Легитимни интерес руковаоца - за маркетиншке и аналитичке сврхе. Наш легитимни интерес заснован је на вашим жељама, како бисмо што боље прилагодили нашу понуду и на крају понудили производе и услуге који боље одговарају вашим потребама и жељама;
                    <br /><br />
                    Правни основ одређен је у складу са чл. 12 важећег Закона о заштити података о личности.<br /><br />
                    Сврха обраде:<br /><br />
                    Horus Wear doo обрађује податке о личности наведене у овој Политици приватности о сваком кориснику са сврхом:<br /><br />
                    одговарања на упит или захтев корисника и пружања најбоље могуће услуге;<br /><br />
                    обрађивања поруџбине (нпр. обавештавње купца о статусу наруџбине, времену доставе, одговарање на евентуалне захтеве у вези наруџбине и сл.)
                    <br /><br />
                    омогућавање купцима подношење рекламација и поврата у онлине продавници;<br /><br />
                    омогућавање купцима замене робе;<br /><br />
                    омогућавање купцима различитих начина плаћања;<br /><br />
                    омогућавање купцима повраћај средстава плаћања;<br /><br />
                    омогућавање приказивања потрошачима садржаја на веб страници на најучинковитији начин;<br /><br />
                    филтрирање садржаја који је прилагођен вашим интересовањима, то јест како бисмо вам понудили своје производе за које је утврђено да их преферирају корисници истог пола и истог узраста, у циљу олакшавања претраге;
                    <br /><br />
                    обавештавање о променама одредаба, услова и правила и/или других административних података;<br /><br />
                    похрањивање било којег пописа листе жеља, које корисници веб странице могу креирати;<br /><br />
                    омогућавање учествовања корисника у наградним играма и сличним промоцијама;<br /><br />
                    прилагођавање садржаја корисника нашим веб страницама, у циљу пружања персонализоване понуде;<br /><br />
                    откривање и/или спречавање злоупотребе или преваре.<br /><br />

                    Ваше податке можемо обрађивати и у маркетиншке сврхе на темељу ваше посебне сагласности, и то са сврхом:<br /><br />
                    извештавања о посебним погодностима, које су често временски ограничене;<br /><br />
                    извештавања о сезонским снижењима, акцијским продајама и осталим продајним погодностима;<br /><br />
                    извештавања о посебним акцијама резервисаним за наше чланове;<br /><br />
                    обавештавања о новим колекцијама;<br /><br />
                    достављања корисних информација везаних за ХОРУС интернет продавницу и друге малопродајне објекте;<br /><br />
                    контактирања у вези оглашавања и истраживања тржишта (слање упитника, анкета и сл.);<br /><br />
                    маркетиншких анализа, у циљу побољшања продајне понуде.<br /><br />
                    Сваки корисник одговара за тачност и истинитост свих информација које је дао.
                    <br /><br />


                    <h5 className="oswald fw-bold">1.2 ПОРУЧИВАЊЕ ПРОИЗВОДА У ОНЛИНЕ ПРОДАВНИЦИ</h5>
                    Куповину у нашој онлине продавници може се остварити као нерегистровани корисник - “гост”.
                    <br /><br />
                    У циљу успешног обрађивања наруџбине од стране “госта” потребни су нам следећи лични подаци: име и презиме, адреса и место пребивалишта, адреса испоруке, е-маил адреса и контакт телефон. Уколико не пружите тражене податке, ХОРУС вероватно неће моћи да обради вашу поруџбину и достави вам наручену робу. Сваки корисник одговора за тачност података које је унео.
                    <br /><br />
                    У складу са начелом минимизације података, од вас само тражимо основне податке, који су нам неопходни за спровођење онлине продаје, односно податке које ће нам омогућити да вас, у складу са пропозицијама Horus Wear doo и добрим пословним обичајима, информишемо о свему што може допринети побољшању наше услуге, са једне, те повећању вашег задовољства нашом услугом, са друге стране.
                    <br /><br />
                    <h5 className="oswald fw-bold">1.3 ДОСТАВА ПРОИЗВОДА НАРУЧЕНИХ У ОНЛИНЕ ПРОДАВНИЦИ</h5>
                    Личне податке контакт особе за испоруку, тј. име и презиме, адреса испоруке, е-маил адреса и број телефона, добијене потврдом наруџбине, делимо са нашим партнерима за логистику (DExpress), како бисмо припремили производе из ваше наруџбине и доставили их на адресу према вашем захтеву. Пословни партнери овлашћени су те податке користити и контактирати вас искључиво у сврху испоруке појединачних пошиљки.
                    <br /><br />
                    <h5 className="oswald fw-bold">1.4 ПОСТАВЉАЊЕ УПИТА ИЛИ ЗАХТЕВА</h5>
                    Корисник нашег веб-сајта може нам поставити специфична питања посредством контактних образаца, који су доступни на ХОРУС интернет продавницу или алтернативно, путем е-маил адресе. Подаци које корисник достави (ИП адреса и/или маил адреса) приликом постављања жељених упита биће коришћени за решавање корисникових питања.
                    <br /><br />
                    Упите или захтеве можете поставити путем друштвених мрежа или емаил-а shop@horus.rs .
                    <br /><br />
                    Наведена е-маил адреса није лична, већ пословна е-адреса Horus Wear doo.
                    <br /><br />
                    Дакле, иста није везана ни за једно физичко лице, већ податке достављене овим путем могу обрађивати различите особе, запослене у Horus Wear doo, а све у циљу брзог и ефикасног решавања корисниковог захтева.
                    <br /><br />
                    Слањем поруке на е-маил адресу наведене на веб-сајту www.horus.rs корисник се слаже са општим условима коришћења наше онлине продавнице.
                    <br /><br />
                    Такође, уколико нам не пружите наведене неопходне податке приликом постављања упита или захтева, Horus Wear doo вероватно неће моћи да поступи на затражени начин.
                    <br /><br />
                    <h5 className="oswald fw-bold">1.5 ПЛАЋАЊЕ У ОНЛИНЕ ПРОДАВНИЦИ</h5>
                    Плаћање у онлине продавници се врши искључиво путем поузећа, тј. приликом преузимања робе.
                    <br /><br />
                    <h5 className="oswald fw-bold">1.6 Newsletter и употреба података за маркетинг</h5>
                    Путем newsletter-а Horus Wear doo обавештава кориснике који пошаљу свој пристанак о: новостима у понуди у нашој онлине продавници и малопродајним објектима, текућим маркетиншким активностима, програму лојалности, новим чланцима на блогу и другим програмима верности, наградним играма, посебним догађајима које Horus Wear doo организује, као и о свим другим актуелним догађањима везаних за Horus Wear doo.
                    <br /><br />
                    Како бисте се пријавили за примање newsletter-а довољно је да пошаљете адресу е-поште, на коју желите примати newsletter. У сваком тренутку можете се одјавити са newsletter-а у складу са упутствима које ћете добити е-поштом након пријаве, као и у сваком newsletter-у.
                    <br /><br />
                    Пријавом на newsletter изјављујете да сте сагласни да вршимо процену ваше активности на нашем сајту, у циљу прилагођавања ХОРУС интернет продавнице вашим захтевима и интересовањима, односно да добијања персонализоване компилације вести, понуда и других информација. Ваши подаци се чувају и користе за истраживање тржишта и у промотивне сврхе. Уколико је потребно, контактираћемо вас искључиво путем е-маил адресе, коју сте нам пружали као податак о личности. Ваше податке ћемо користити и за анализу и побољшање ефикасности ХОРУС интернет продавнице.
                    <br /><br />
                    За управљање пријавама и слањем newsletter-а користимо MailChimp платформу и веб сајт React.JS апликацију путем push нотификација уколико је инсталирате на свој мобилни телефон.
                    <br /><br />

                    <h5 className="oswald fw-bold">2. ПОДАЦИ ПРИКУПЉЕНИ КРОЗ ВАШУ ИНТЕРАКЦИЈУ СА ХОРУСОМ</h5>
                    Различите технологије могу се користити на ХОРУС интернет продавници, како бисмо је побољшали, учинили је једноставнијом, прилагођеној корисницима, ефикаснијом и сигурном. Такве технологије могу довести до аутоматског прикупљања личних података од стране нас или трећих страна у наше име. Примери таквих технологија укључују колачиће, flash колачиће и веб-анализу.
                    <br /><br />
                    Сви подаци које прикупимо кроз онлине продају, укључујући и податке о производима које наши купци траже и купују, односно страницама које посећују, биће пажљиво анализирани и обрађени, а исти ће бити искоришћени за побољшање наше понуде као и визуелног изгледа наше веб странице, све у циљу омогућавања једноставније, сигурније, брже и удобније куповине.
                    <br /><br />
                    <h5 className="oswald fw-bold">2.1 Click-stream подаци</h5>
                    Када посетите нашу веб страницу, подаци се шаљу са вашег претраживача на наш сервер. Ти подаци омогућују нам да оптимизујемо наше услуге и побољшамо ваше искуство на нашим веб страницама и апликацијама. Податке аутоматски прикупљамо и похрањујемо ми или обрађивачи, са којима имамо регулисан однос.
                    <br /><br />
                    Можемо прикупљати информације о вашем рачунару за сврхе системске администрације, као и за пријављивање збирних информација за потребе унутрашњих маркетиншких анализа. Ово су подаци о поступцима и узорцима прегледања наших корисника и могу укључивати следеће:
                    <br /><br />
                    ИП адресу посетиоца; <br /><br />
                    Датум и време посете; <br /><br />
                    УРЛ препоруке (веб место са које је посетилац послат); <br /><br />
                    Посећене странице и “путовање” корисника на нашој веб страници; <br /><br />
                    Информације о коришћеном прегледачу (тип, верзија, оперативни систем и сл.).
                    <br /><br />

                    <h5 className="oswald fw-bold">2.2 Колачићи (cookies)</h5>
                    Колачић је текстуална датотека коју веб сервер поставља на ваш хард диск. Колачићи се не могу користити за покретање програма или испоруку злонамерног софтвера на ваш рачунар.
                    <br /><br />
                    Колачиће употребљавамо како би ХОРУС интернет продавница радила правилно те како бисмо били у стању да вршимо унапређења странице са сврхом побољшавања вашег корисничког искуства, како бисмо персонализовали садржај и огласе, у циљу пружања специфичних техничких карактеристика и целокупног позитивног корисничког искуства.
                    <br /><br />
                    Прочитајте нашу Политику колачића како бисте сазнали више о нашој употреби колачића.
                    <br /><br />
                    <a href={route("public.politikaKolacica")} target="_blank" rel="noreferrer" className="link-secondary">www.horus.rs{route("public.politikaKolacica")}</a>
                    <br /><br />

                    <h5 className="oswald fw-bold">2.3 Ре-таргетинг технологије</h5>
                    ХОРУС интернет продавница користи ре-таргетинг технологије. То нам омогућава директно и лично оглашавање посетиоцима који су већ показали интересовање за наше производе.
                    <br /><br />
                    Ре-таргетинг технологије анализирају ваше колачиће и приказују огласе на основу вашег претходног понашања при сурфовању интернетом. За више информација о колачићима погледајте нашу политику колачића.
                    <br /><br />

                    <h5 className="oswald fw-bold">3. ПРАВА ЛИЦА НА КОЈЕ СЕ ПОДАЦИ ОДНОСЕ</h5>
                    Уколико оставите податке о личности на ХОРУС интернет продавници, то чините у потпуности на добровољној основи. Ипак, ако одлучите да не дате тражене информације, различите погодности за купце вам можда неће бити доступне. У одређеним случајевима само они који су нам пружили потребне личне податке моћи ће да наручују производе, користе одређене услуге и на други начин искористе активности и понуде доступне на нашој веб страници.
                    <br /><br />
                    У зависности од околности конкретног случаја, Horus Wear doo пружа доста опција, у циљу пружања помоћи нашим корисницима у задржавању контроле над својим личним подацима, односно у остваривању својих права.
                    <br /><br />
                    Сва права можете остварити слањем упита или захтева на shop@horus.rs или на адресу Краља Милана 22-24, 11000 Београд – Стари град. Упите или захтеве ћемо обрадити без одлагања и у складу са законским обавезама те ћемо вас информисати о мерама које смо предузели.
                    <br /><br />
                    Као лице на које се подаци односе имате следећа права:
                    <br /><br />
                    <h5 className="oswald fw-bold">3.1 Право на информисање</h5>
                    Све информације у вези обраде ваших података о личности можете затражити на горе назначену адресу или путем е-маил адресе. Ради заштите лица на које се подаци односе, потребно је да се као подносилац упита или захтева идентификујете на одговарајући начин.
                    <br /><br />
                    <h5 className="oswald fw-bold">3.2 Право приступа</h5>
                    Имате право да добијете потврду о обрађивању ваших података о личности. По основу овог права можете добити информацију о томе који се ваши подаци обрађују, сврху обраде као и правни основ за обраду, информације о руковаоцу и обрађивачима личних податка, року чувања података о личности и др.
                    <br /><br />
                    <h5 className="oswald fw-bold">3.3 Право на исправку и допуну</h5>
                    Имате право да тражите исправку нетачних личних података и право да додате личне податке.
                    <br /><br />
                    <h5 className="oswald fw-bold">3.4 Право на брисање</h5>
                    У одређеним случајевима имате право да затражите брисање ваших личних података (нпр. ако подаци више нису неопходни за остваривање сврхе због које су обрађивани, опозвали сте пристанак на основу којег се обрада вршила, итд). Уколико из било којег разлога не можемо удовољити вашем захтеву, обавестићемо вас о истом.
                    <br /><br />
                    <h5 className="oswald fw-bold">3.5 Право на ограничење обраде</h5>
                    У посебним околностима, имате право ограничити обраду ваших личних података. Своја права везана за ограничење обраде можете остварити и путем Повереника за информације од јавног значаја и заштиту података о личности.
                    <br /><br />
                    <h5 className="oswald fw-bold">3.6 Право на преносивост података</h5>
                    Имате право примати своје личне податке у структурираном, уобичајеном и машински читљивом формату, а ви имате право да их пренесете другом субјекту. Ово право можете остварити само ако се обрада заснива на пристанку или на основу уговора и када се врши аутоматизовано.
                    <br /><br />
                    <h5 className="oswald fw-bold">3.7 Право на приговор</h5>
                    Имате право да се жалите на одређене врсте обраде и прикупљања података, када се обрада заснива на легитимним интересима ХОРУСА, нпр. за маркетиншке сврхе.
                    <br /><br />
                    <h5 className="oswald fw-bold">3.8 Право на повлачење пристанка</h5>
                    У сваком тренутку имате право да повучете пристанак за обраду података о личности. Повлачење сагласности неће утицати на законитост обраде за период пре повлачења пристанка.
                    <br /><br />
                    <h5 className="oswald fw-bold">3.9 Право на притужбу надзорном органу</h5>
                    Ако сматрате да обрада ваших личних података крши одредбе важећег Закона о заштити података о личности, имате право да поднесете притужбу Поверенику за информације од јавног значаја и заштиту података о личности, ул. Булевар краља Александра бр. 15, Београд.
                    <br /><br />

                    <h5 className="oswald fw-bold">4. КАКО МОЖЕТЕ ОСТВАРИТИ СВОЈА ПРАВА, КОНТАКТ ЗА ПОТРЕБЕ ИЗРИЦАЊА ПРИГОВОРА, РЕШАВАЊА ПРИТУЖБИ</h5>
                    Како бисте послали притужбу или приговор, ако желите да решите неки неспоразум, нејасноћу или недоумицу у вези ваших личних података које обрађује Horus Wear doo молимо Вас да то учините користећи наше следеће контакте:
                    <br /><br />
                    Horus Wear doo <br /><br />
                    ул. Краља Милана 22-24, <br /><br />
                    11000 Београд – Стари град, <br /><br />
                    Република Србија <br /><br />
                    е-маил: shop@horus.rs <br /><br />
                    Да бисмо вас заштитили као титулара личних података, потребно је да се на одговарајући начин идентификујете као подносилац пријаве, који ће за Horus Wear doo представљати доказ да личне податке даје њиховом власнику.
                    <br /><br />

                    <h5 className="oswald fw-bold">5. ОБРАЂИВАЧИ ЛИЧНИХ ПОДАТАКА</h5>
                    Horus Wear doo користи обрађиваче за прикупљање и обраду података о личности. Сви обрађивачи података и примаоци које Horus Wear doo ангажује обрађују само ваше личне податке у складу са Хорусовим упутствима и законски и уговорно су дужни да се придржавају строгих сигурносних поступака приликом руковања личним подацима.
                    <br /><br />
                    Horus Wear doo као руковалац може да одреди као обрађивача само оно лице, које у потпуности гарантује примену одговарајућих техничких, организационих и кадровских мера, на начин који обезбеђује да се обрада врши у складу са одредбама важећег Закона о заштити података о личности и да се обезбеђује заштита права лица на које се подаци односе.обраде података о личности и у складу с тим је одредила следеће обрађиваче података о личности:
                    <br /><br />
                    SuperWeb агенција за услугу припреме штампе, графички и веб дизајн - Обрађивач који Руковаоцу израђује и одржава ХОРУС интернет продавницу, као и панел на коме се обрађују поруџбине, те обрађује следеће податке о личности Потрошача: име и презиме, број телефона, е-маил адреса, адреса становања, ИП адреса;
                    <br /><br />

                    <h5 className="oswald fw-bold">6. Примаоци података о личности</h5>
                    Horus Wear doo дели податке о личности потрошача са следећим пословним партнерима, у сврхе испуњавања функције ХОРУС интернет продавнице и пружања Потрошачима квалитетног искуства на ХОРУС интернет продавници:
                    <br /><br />
                    DExpress - курирска служба, која пружа услугу испоруке поручених артикала Потрошачима, а ради извршења поруџбине прима следеће податке: име и презиме, адреса и број телефона;
                    <br /><br />
                    Веб-страница www.horus.rs техничким и организацијским мерама штити од неовлашћеног приступа, измене или дељена података о личности, као и од губљења или брисања, али не може гарантовати за друге Интернет компаније.
                    <br /><br />
                    <h5 className="oswald fw-bold">7. ВРЕМЕНСКИ РОК У КОМ СЕ ВАШИ ЛИЧНИ ПОДАЦИ ЧУВАЈУ</h5>
                    Подаци које доставите се чувају онолико колико је потребно да би се испунила одређена сврха због које сте нам доставили податке или да би се испоштовале законске одредбе.
                    <br /><br />
                    Сви прикупљени подаци у вези чувају се док се не добије захтев за њихово брисање. Након што добијемо захтев за уклањање података, ХОРУС ће у наредних шест месеци чувати ваше личне податке како би се у том раздобљу решиле евентуалне рекламације из претходног периода.
                    <br /><br />
                    Сви прикупљени подаци у вези купопродаје робе у ХОРУС интернет продавници обрађују се до тренутка док се не оствари сврха обраде тј. до тренутка успешно извршене куповине, урачунавајући и време за решавање евентуалних приговора, рекламација, повраћаја или захтева које се односе на куповину производа.
                    <br /><br />
                    Прикупљени подаци у вези подношења упита или захтева чувају се за оно време које је потребно да би се удовољило вашем захтеву или упиту.
                    <br /><br />
                    Ако сте нам дали своју изричиту сагласност за употребу личних података у промотивне сврхе, ми ћемо користити ваше податке за такву намену, све док не опозовете своју сагласност. Можете опозвати сагласност коју сте дали у сваком тренутку тако да опозив производи дејство у будућности.
                    <br /><br />
                    Сви подаци прикупљени кроз интеракцију са Хорусом (као што су анализа куповине, ИП адреса, анализа уређаја, ретаргетинг маркетинг и сл) приликом посете веб страници брисаће се у року од 24 месеца од дана њиховог прикупљања.
                    <br /><br />
                    Ако то захтевају позитивноправни прописи, ХОРУС наведене податке може чувати и дуже, у складу са прописаним роком чувања података.
                    <br /><br />
                    По истеку периода чувања, личне податке бришемо трајно или их чинимо анонимним, тако да се више не могу повезати са одређеним појединцем.
                    <br /><br />

                    <h5 className="oswald fw-bold">8. МАЛОЛЕТНИЦИ</h5>
                    Малолетници млађи од 15 година не смеју да користе ХОРУС веб-страницу. Horus Wear doo не прикупља, нити задржава личне податке од лица млађих од 15 година, те ниједан део ове интернет странице није дизајниран на начин да привуче било кога ко је млађи од 15 година. Ако родитељи, рођак или законски заступник таквог лица открије да је послало личне податке без његове сагласности, обратите се на нашу адресу или путем е-маил-а на <a href="mailto:nemanja@horus.rs" className="link-secondary">nemanja@horus.rs</a> .
                    <br /><br />
                    <h5 className="oswald fw-bold">9. ИЗМЕНЕ И ДОПУНЕ ПОЛИТИКЕ ПРИВАТНОСТИ</h5>
                    Политика о приватности се може повремено мењати како би се ускладила са позитивноправним прописима.
                    <br /><br />
                    Све промене које можемо начинити у Политици приватности биће објављене на овој страници и према потреби, можете бити обавештени путем е-поште.
                </>}

                {lang === 2 &&
                <>
                    Nowadays business activities have a mandatory connection with the personal data processing.
                    <br /><br />
                    As a company which has performed business activities for more than 13 years and which emphasizes its treatment of consumers, the protection of Your personal data is very important for us.
                    <br /><br />
                    HORUS WEAR doo is obliged to protect the privacy of buyers and users of the website and guarantees that the data left here by the buyer or website user will be used, stored and processed in compliance with the intention for which they were collected and within the limits of the valid regulations.
                    <br /><br />
                    HORUS WEAR doo will take reasonable technical and organizational measures of precaution so as to prevent the loss, abuse or alteration of the personal data of buyers.
                    <br /><br />
                    The data you have entrusted us with will not be delivered to an unauthorized third persons and they will be treated in compliance with valid General Data Protection Regulation (GDPR). Authorized employees of HORUS WEAR doo are exclusively the persons authorized to have insight in the collected data, and the persons in charge of processing with whom the relation is regulated pursuant to the General Data Protection Regulation. All employees at HORUS WEAR doo are aware of the importance of keeping of the data confidentiality and have the liability for observance of this Privacy Policy.
                    <br /><br />
                    Since you use our web application, we understand that you have previously read and agreed to the general conditions of use and sale and agreed to the privacy policy.
                    <br /><br />
                    This Privacy Policy shall inform you on the type of personal data that are processed, on legal basis and purpose of the processing of personal data, rights of the personal data holder, on the persons in charge of handling and processing of personal data, time period during which Your data are kept and amendments to this Privacy Policy.
                    <br /><br />
                    In the continuation we shall inform You on the manner we process Your personal data.
                    <br /><br />

                    <h5 className="oswald fw-bold">DATA ON THE ENTITY HANDLING THE DATA</h5>
                    Responsibility for personal data processing in the capacity of the entity in charge of handling the data is borne by:
                    <br /><br />
                    Name: HORUS WEAR doo Beograd - Stari Grad         <br />
                    Seat: Kralja Milana 22-24, 11000 Belgrade – Stari grad<br />
                    ID number: 21578703<br />
                    Tax ID number: 111944329<br />
                    Email:<a href="mailto:nemanja@horus.rs" className="link-secondary">nemanja@horus.rs</a><br />
                    Telephone: +381 65 4321023
                    <br /><br />

                    <h5 className="oswald fw-bold">LEGAL BASIS AND PURPOSE OF PROCESSING</h5>
                    According to the General Data Protection Regulation (GDPR) PERSONAL DATA are all data referring to a natural person whose identity is designated or may be designated; natural person whose identity may be designated is the person who may be identified directly or indirectly, especially using identifiers, such as name, ID number, data on location, network identifier or via one or several factors belonging to physical, physiological, genetic, mental, economic, cultural or social identity of this natural person.
                    <br /><br />
                    The legal basis for processing of Your personal data may be:
                    <br /><br />
                    Consent given by visitors of HORUS website, which allows the collection for clearly stated purpose. The consent may be withdrawn at any point by sending an e-mail of the corresponding content to <a href="mailto:nemanja@horus.rs" className="link-secondary">nemanja@horus.rs</a> or by sending a letter to the stated address.
                    <br /><br />
                    Conclusion and execution of an agreement<br /><br />
                    Legitimate interest of HORUS WEAR doo – for marketing and analytical purposes. Our legitimate interest is based on Your wishes so that we could adjust better our offer and eventually offer products and services which suit Your needs and wishes better.
                    <br /><br />
                    Legal obligation.<br /><br />
                    The legal basis is designated in compliance with provisions of General Data Protection Regulation (GDPR).
                    <br /><br />

                    <h5 className="oswald fw-bold">REGISTRATION AND ACCOUNT SET-UP</h5>
                    You may use our online store as a guest. <br /><br />
                    Every user is responsible for the correctness and accuracy of all information entered to HORUS website.
                    <br /><br />
                    The user guarantees exclusive personal management of the account or via authorized person.
                    <br /><br />
                    HORUS WEAR doo keeps the data until the recall or signing out of the account in compliance with the legal regulation of the Republic of Serbia. The user may at any point request removing data from the system in writing by an email sent to <a href="mailto:nemanja@horus.rs" className="link-secondary">nemanja@horus.rs</a> (the mail must be sent from the electronic address where the user odered up as a guest).
                    <br /><br />
                    All data and history of orders are available to the certain employees at HORUS WEAR doo and other persons if needed when it is necessary to fulfill the purposes of processing.
                    <br /><br />

                    <h5 className="oswald fw-bold">DELIVERY OF PRODUCTS ORDERED AT ONLINE STORE</h5>
                    Personal data of contact person for delivery, i.e. name and surname, address of the delivery and phone number which we obtained from You by the confirmation of the order, are shared with our logistic partners (Post export) in order to prepare products from Your order and deliver them to the address according to your request. Business partners are authorized to use these data and contact You exclusively for the purpose of the delivery of individual parcels.
                    <br /><br />
                    <h5 className="oswald fw-bold">SENDING INQUIRIES OR REQUESTS</h5>
                    The user of our website may ask us specific questions via contact forms which are available at our website on various locations or alternatively via email addresses. The data submitted by the user (IP address and/or mail address) in asking desired questions will be used for solving user’s questions.
                    <br /><br />

                    Questions or requests may be asked via email <a href="mailto:shop@horus.rs" className="link-secondary">shop@horus.rs</a>.
                    <br /><br />
                    The stated email address is not personal, but business e-address of HORUS WEAR doo, which means that it is not related to a specific person but the data submitted in this manner may be processed by various persons, all with the aim of fast and efficient solving of the user’s question.
                    <br /><br />
                    If you have an account at some social network, Facebook, Instagram, Google, you may ask questions or requests via these profiles. In that case HORUS would collect Your username at these social networks and IP address, but HORUS does not have access to Your accounts and passwords of these social networks, or share Your personal data stored with us with the providers of these social networks.
                    <br /><br />
                    By sending an message to email addresses stated at this website the user agrees to general conditions of the use of our online store.
                    <br /><br />
                    Furthermore, if you do not provide us with essential data in sending an inquiry or requests which are necessary for HORUS WEAR doo to respond to your inquiry or request, HORUS WEAR doo might not be able to act in the manner you asked, that is, to answer to your inquiry or request.
                    <br /><br />

                    <h5 className="oswald fw-bold">PAYMENT AT ONLINE STORE</h5>
                    Payment can be done only using Bank Wire transfer to the HORUS WEAR doo business account at Banca Intesa AD, using Proforma Invoice that you will recieve on your email after sending your order to us using HORUS online store.
                    <br /><br />
                    <h5 className="oswald fw-bold">Instruction for customer transfer in EUR can see here.</h5>
                    The personal data which you enter in to make the order at our online store are accepted and processed at our and Bank systems to check and verify transactions.
                    <br /><br />
                    The order form is secured by SSL transport code of the greatest reliability.
                    <br /><br />
                    The refund is done exclusively via Bank Wire transfer.
                    <br /><br />
                    <h5 className="oswald fw-bold">NEWSLETTER AND THE USE OF DATA FOR MARKETING</h5>
                    You may sign up for our newsletter via which we inform You, until you sign out about: news in the offer of our online store and retail stores, current marketing activities, discounts for trust-loyalty program, competitions, special events organized by HORUS WEAR doo, as well as other current events related to HORUS WEAR doo.
                    <br /><br />
                    In order to sign up to our newsletter it is enough just to provide the e-mail address where you want to receive the newsletter. You may sign out of the newsletter at any point in accordance with the instructions you will receive by e-mail after the sign-up, as well as in every newsletter.
                    <br /><br />
                    By signing up to the newsletter you declare that you agree to the estimation of your activities at our website so that we could adjust the website to your requests and interests, so that you can obtain personalized compilation of news, offers and other information. Your data are stored and used for market research and for promotional purposes. If needed, we will contact you exclusive via email. Your data will be used for the analysis and improvement of the efficiency of our websites.
                    <br /><br />
                    For the management of applications and deliveries of newsletters we use MailChimp platform.
                    <br /><br />
                    If during the sign-up to the newsletter you choose the option to receive messages which are more adjusted to Your interests and for this purpose you provide us with the information on Your sex and age, HORUS WEAR doo will process the obtained data in order to filter the content which is adjusted to Your interests, i.e. to provide you with the offer of products for which it was determined that they are preferred by similar users, i.e. users of the same sex and age like you, all that in order to facilitate your search of our offer. HORUS WEAR doo will process Your data for these offers only with your prior consent.
                    <br /><br />
                    <h5 className="oswald fw-bold">DATA COLLECTED THROUGH YOUR INTERACTION WITH HORUS</h5>
                    Various technologies may be used at our webpage in order to improve it, make it simpler, more user-friendly, more efficient and safer. Such technologies may lead to automatic collection of personal data by us or third parties on our behalf. Examples of such technologies involve cookies, flash cookies and web analysis.
                    <br /><br />
                    All data we collect via “online” sale, including the data on products which are requested and bought by our customers, pages they visit will be carefully analyzed and processed and they will be used for the improvement of our offer and appearance of our page, all with the aim of enabling simpler, safer, faster and more comfortable purchase.
                    <br /><br />

                    <h5 className="oswald fw-bold">CLICK-STREAM DATA</h5>
                    When you visit our web page, the data are sent from Your browser to our server. These data enable us to optimize our services and improve Your experience at our webpages and applications. The data are automatically collected and stored by us or a third party to our benefit.
                    <br /><br />
                    We may collect information on Your computer for the purpose of system administration as well as for reporting of collective information for the purposes of internal marketing analyses. These are the data on actions and samples of browsing of our users and they may include the following:
                    <br /><br />
                    IP address of the visitor<br /><br />
                    Date and time of the visit<br /><br />
                    URL recommendations (web location from which the visitor was sent)<br /><br />
                    Visited pages and “journey” of the user at our webpage<br /><br />
                    Information on used browser (type, version, operating system, etc.).
                    <br /><br />
                    <h5 className="oswald fw-bold">COOKIES</h5>
                    Cookie is a text file set by the webserver to your hard disk. Cookies cannot be used to initiate the program or delivery of malicious software to Your computer.
                    <br /><br />
                    We use cookies so that this web page would operate regularly and that we would be in the condition to improve pages with the aim of the improvement of Your user experience, personalization of the content and ads, provision of specific technical characteristics to you and thus providing you with positive user experience.
                    <br /><br />
                    Read our Cookie Policy in order to learn more about our cookie use.
                    <br /><br />
                    <h5 className="oswald fw-bold">RE-TARGETING TECHNOLOGIES</h5>
                    Our webpage uses re-targeting technologies. It enables us directly and personally to show advertisements to users who have already shown interest for our products.
                    <br /><br />
                    Re-targeting technologies analyze Your cookies and present ads on the basis of Your previous surfing experience. For further information on cookies, you may read our cookie policy.
                    <br /><br />

                    <h5 className="oswald fw-bold">RIGHTS OF THE PERSONAL DATA HOLDER</h5>
                    If you provide us with the personal data at our webpage, it is done on a completely voluntary basis. If you decide not to provide requested information, different benefits for customers might not be available to you. In certain cases, only those who provided requested personal data may be able to order products, use certain services and in another manner use the activities and offers available at our webpage.
                    <br /><br />
                    We provide many options, depending on exact circumstances in order to help you retain control of your data and in order to ensure the exercise of Your rights.
                    <br /><br />
                    You may exercise Your rights by sending the inquiry or request to <a href="mailto:nemanja@horus.rs" className="link-secondary">nemanja@horus.rs</a> or to the address Kralja Milana 22-24 Belgrade – Stari grad, Serbia. We will process your inquiries or requests without any delay and in compliance with legal obligations we will inform you on the taken measures.
                    <br /><br />
                    As personal data holder you are entitled to following rights:
                    <br /><br />
                    <h5 className="oswald fw-bold">RIGHT TO INFORMATION.</h5>
                        All information with regards to processing of Your personal data may be requested to the address stated above or via mail. With the aim of Your protection as personal data holder it is necessary that you are identified as the sender of the inquiry or request in an adequate manner.
                    <br /><br />
                    <h5 className="oswald fw-bold">RIGHT TO ACCESS.</h5>
                        You are entitled to receive the confirmation that Your personal data are processed or not. On the basis of this right, you may receive information on whether Your data are processed, purpose of processing as well as the legal basis for processing, information on processors of personal data, how long the data will be kept, etc.
                    <br /><br />
                    <h5 className="oswald fw-bold">RIGHT TO CORRECTION AND ADDITION.</h5>
                        You are entitled to ask for the correction of incorrect personal data and the right to add personal data which would complete any incomplete personal data.
                    <br /><br />
                    <h5 className="oswald fw-bold">RIGHT TO DELETION.</h5>
                        In certain cases, you are entitled to ask for the deletion of Your personal data (e.g. if the data are no longer necessary for the accomplishment of the purpose for which they have been processed, you withdrew the consent based on which the processing was done). If for any reason we cannot please Your request, we will inform You about that.
                    <br /><br />
                    <h5 className="oswald fw-bold">RIGHT TO PROCESSING LIMITATION.</h5>
                        In special circumstances, you are entitled to limit of the processing of Your personal data.
                    <br /><br />
                    <h5 className="oswald fw-bold">RIGHT TO DATA TRANSFER.</h5>
                        You are entitled to the reception of your personal data (referring only to You) in a structured, usual and mechanically legible form, and You are entitled to transfer them to another party. This right may be exercised only if the processing is based on the consent or an agreement even when it is done automatically.
                    <br /><br />
                    <h5 className="oswald fw-bold">RIGHT TO COMPLAINT.</h5>
                        You are entitled to complain about certain types of processing and data collection when processing is based on legitimate interests of HORUS WEAR doo, e.g. for marketing purposes.
                    <br /><br />
                    <h5 className="oswald fw-bold"> RIGHT TO CONSENT WITHDRAWAL.</h5>
                        At any moment, you are entitled to withdraw Your consent to personal data processing. The consent withdrawal will not affect the legality of the processing for the period prior to consent withdrawal.
                    <br /><br />
                    <h5 className="oswald fw-bold">RIGHT TO A COMPLAINT TO A SUPERVISORY BODY. </h5>
                        If you believe that processing of your personal data breaches the General Data Protection Regulation (GDPR), you are entitled to complain to the supervisory body.
                    <br /><br />
                    For the territory of the Republic of Serbia the supervisory body is the Commission for Personal Data Protection with the seat at 15, Kralja Aleksandra Blvd, 11000 Belgrade.
                    <br /><br />

                    <h5 className="oswald fw-bold">HOW YOU CAN EXERCISE YOUR RIGHTS, CONTACT FOR THE PURPOSES OF REMARK PRESENTATION, COMPLAINT SOLVING</h5>

                    In order to send Your complaint or remark or if you want to solve some misunderstanding, unclarity or doubt with regards to Your personal data processed by HORUS WEAR doo please do that using the following contacts:
                    <br /><br />
                    HORUS WEAR doo   <br /><br />
                    Kralja Milana 22-24, Belgrade – Stari grad   <br /><br />
                    Republic of Serbia   <br /><br />
                    e-mail: <a href="mailto:nemanja@horus.rs" className="link-secondary">nemanja@horus.rs</a>   <br /><br />
                    In order to protect you as the personal data owner, it will be needed in the corresponding manner that you identify yourself as the applicant, which will be sufficient guarantee for HORUS WEAR doo to provide personal data to their owner.
                    <br /><br />
                    <h5 className="oswald fw-bold">THIRD PARTIES WHO HAVE INSIGHTS IN YOUT DATA</h5>
                    HORUS WEAR doo uses processors for collection and processing of Your personal data. All data processors engaged by HORUS WEAR doo process only Your personal data in accordance with HORUS WEAR doo instructions and are legally bound to adhere to strict safety procedures in handling personal data.
                    <br /><br />
                    Within legally allowed frameworks and depending on the purpose of processing, Your data may be shared with the following categories of data processors:
                    <br /><br />
                    - Delivery services, freight-forwarders, post services
                    <br /><br />
                    - IT partners within software support and maintenance   <br /><br />
                    - Software service providers in “cloud”;   <br /><br />
                    - Payment and bank service providers   <br /><br />
                    - HORUS WEAR doo employees   <br /><br />
                    - Advertising agency, marketing and PR agencies   <br /><br />
                    - Bodies for fraud revelation and prevention   <br /><br />
                    In processing of Your personal data HORUS may present your personal data to business partners abroad.   <br /><br />
                    Data transfer via internet is naturally unsafe so HORUS cannot guarantee the safety of the data sent via Internet.
                    <br /><br />

                    <h5 className="oswald fw-bold">TIME PERIOD IN WHICH YOUR PERSONAL DATA ARE KEPT</h5>
                    The data you submit are kept for as long as it is needed to fulfill certain purpose for which you submitted the data or to comply with legal provisions.
                    <br /><br />
                    All collected data are kept 6 months after the moment of request for removing. After you request removing your data, Horus Wear doo will in the following six months after request keep Your personal data in order to solve at this time period potential claims from the previous period.
                    <br /><br />
                    All collected data with regards to the purchase of goods at online store are processed until the purpose of processing is accomplished, i.e. until the moment of successfully performed purchase, including the time of the submission of potential remarks, claims, return or requests referring to the purchase of the product.
                    <br /><br />
                    Collected data with regards to the submission of inquiries or requests are kept for the period needed to please Your request or inquiry.
                    <br /><br />
                    If you gave us your explicit consent for the use of personal data for promotional purposes, we will use your data for such purpose as long as you withdraw your consent. You may withdraw your consent which you gave at any moment so that the withdrawal is effective in future.
                    <br /><br />
                    If it is required by applicable regulations, HORUS WEAR doo may keep your data for even longer period, pursuant to the prescribed deadline for data keeping.
                    <br /><br />
                    Upon the expiration of the period of keeping, personal data are deleted permanently or made anonymous so that they can no longer be connected with certain individuals.
                    <br /><br />

                    <h5 className="oswald fw-bold">MINORS</h5>
                    Minors below 16 years of age must not use HORUS web page. HORUS WEAR doo does not collect or retain information from any person below the age of 16 so therefore none part of these Internet pages is designed in the manner to attract anyone younger than 16. If parents, a cousin or legal representative of such person reveals that their child sent personal data without their consent, you may contact us via our address or via e-mail on <a href="mailto:nemanja@horus.rs" className="link-secondary">nemanja@horus.rs</a>.
                    <br /><br />
                    <h5 className="oswald fw-bold">AMENDMENTS TO THE PRIVACY POLICY</h5>
                    The statement hereby on the data protection may be altered occasionally in order to be harmonized with the actual state and legal regulations.
                    <br /><br />
                    All amendments we may make in the privacy policy in future will be published at this page.
                    <br /><br />
                    <b> ALL CONTRACTS CONCLUDED THROUGH THIS SITE ARE GOVERNED BY THE LAWS OF REPUBLIC OF SERBIA. </b>

                </>}
            </div>
        </>
    )
}
export default PolitikaPrivatnosti;