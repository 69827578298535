import React from "react";
import {useMutation} from "@apollo/client";
import {CREATE_PRODUCT_CATEGORY, DELETE_PRODUCT_CATEGORY} from "../../graphql/productCategory";
import toast from "react-hot-toast";



function ProductCategoryItem({category, checked, product_id, refetch}) {
    const [deleteProductCategory] = useMutation(DELETE_PRODUCT_CATEGORY);
    const [createProductCategory] = useMutation(CREATE_PRODUCT_CATEGORY);

    const changed = (cat) => {
        if(cat.productCategoryPivot?.id)    {   // ZA BRISANJE
            deleteProductCategory({variables: {id: cat.productCategoryPivot?.id}}).then(response => {
                toast.success("Obrisano!");
                refetch();
            }).catch(error => {
                toast.error(error.message);
            });
            if(cat.id === category.id) {    // AKO JE PARENT
                for(let i=0; i<checked.length; i++) {
                    const item = checked[i];
                    if(item.parent === parseInt(cat.id) && item.productCategoryPivot?.id)   {
                        deleteProductCategory({variables: {id: item.productCategoryPivot.id}})
                    }
                }
            } else  {   // OBRISAN CHILD
                const children = checked.filter(item => item.parent === parseInt(category.id));
                if(children.length === 1)    {
                    const find = checked.find(item => item.id === category.id);
                    if(find)    {
                        deleteProductCategory({variables: {id: find.productCategoryPivot.id}})
                    }
                }
            }
        } else  {
            createProductCategory({variables: {product_id: product_id, category_id: cat.id}}).then(response => {
                toast.success("Sačuvano!");
                refetch();
            }).catch(error => {
                toast.error(error.message);
            });
            if(cat.id !== category.id) {    // NIJE PARENT
                const find = checked.find(item => item.id === category.id);
                if(!find)  {
                    createProductCategory({variables: {product_id: product_id, category_id: category.id}})
                }
            }
        }
    }
    const find = checked.find(item => item.id === category.id);

    return <div className="mb-3">
        <label>
            <strong>
                <input
                    value={category.id}
                    name="parent"
                    type="checkbox"
                    onChange={() => changed(find || category)}
                    checked={!!find} />  {category.name}
            </strong>
        </label>

        <div>
            {category.children.map(child => {
                const findChild = checked.find(item => item.id === child.id);
                return <label key={child.id} className="p-2 border mb-2 me-2 d-inline-block">
                    <input
                        value={child.id}
                        name="category"
                        type="checkbox"
                        onChange={() => changed(findChild || child)}
                        checked={!!findChild} />  {child.name}
                </label>
            })}
        </div>
    </div>

}
export default ProductCategoryItem;