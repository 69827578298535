import {widget} from "../../modulesCompiler";
import {seoDescription, seoKeywords, seoReset, seoTitle} from "../seoProvider";


const SeoMiddleware = ({ middleware, route, ...rest }) => {
    seoReset();
    const title =  widget("settings.useGetSetting", "default SEO title");
    seoTitle(title.value);
    const keywords =  widget("settings.useGetSetting", "default SEO keywords");
    seoKeywords(keywords.value);
    const description =  widget("settings.useGetSetting", "default SEO description");
    seoDescription(description.value);

    if(route && route?.seo)   {
        const title =  widget("settings.useGetSetting", route.seo.key + "_seo_title");
        if(title?.value)   {
            seoTitle(title.value);
        }
        const keywords =  widget("settings.useGetSetting", route.seo.key + "_seo_keywords");
        if(keywords?.value)   {
            seoKeywords(keywords.value);
        }
        const description =  widget("settings.useGetSetting", route.seo.key + "_seo_description");
        if(description?.value)   {
            seoDescription(description.value);
        }
    }
    return widget(middleware, {...rest})
};

export default SeoMiddleware;