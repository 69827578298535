import React, {useContext, useEffect} from "react";
import BasketDrawerContext from "../contexts/BasketDrawerContext";
import BasketContext from "../contexts/BasketContext";


function BasketDrawerToggle({button, invert}) {
    const {setOpen} = useContext(BasketDrawerContext);
    const {basket, getBasket} = useContext(BasketContext);

    useEffect(() => {
        getBasket();
    }, [getBasket]);

    const openDrawer = (e) => {
        e.preventDefault();
        setOpen(true);
    }

    return (
        <div className="icon_wrapper">
            {invert
                ? <span className="bg-white text-primary">{basket.length}</span>
                : <span className="bg-primary text-white">{basket.length}</span>}
            {{...button, props: {...button.props, onClick: openDrawer}}}
        </div>
    )
}

export default BasketDrawerToggle;