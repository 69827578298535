import React from "react";
import {useQuery} from "@apollo/client";
import {GET_CATEGORIES, GET_CATEGORIES_QUERY} from "../../graphql/categories";
import Loader from "../../../../app/Loader";
import GraphQLErrorMessage from "../../../../app/GraphQLErrorMessage";
import RenderCategories from "./RenderCategories";

function GetAllCategories() {
    const { data, loading, error } =  useQuery(GET_CATEGORIES, {
        variables: {parent:  null}
    });
    if(loading) return <Loader opacity={.3} />
    if(error)   return <GraphQLErrorMessage error={error} />
    const categories = data ? data[GET_CATEGORIES_QUERY] : [];

    return <RenderCategories categories={categories} />
}
export default GetAllCategories;