import React, {useState} from "react";
import ProductItem from "./ProductItem";
import {useQuery} from "@apollo/client";
import Loader from "../../../../app/Loader";
import GraphQLErrorMessage from "../../../../app/GraphQLErrorMessage";
import {GET_PRODUCTS_QUERY, GET_PRODUCTS_WITH_FILTERS} from "../../graphql/product";
import {widget} from "../../../modulesCompiler";
import AddProductForm from "./AddProductForm";


const Products = ({callback}) => {
    const [selected, setSelected] = useState([]);
    const [search] = useState(undefined);
    const { data, loading, error, variables, refetch, previousData } =  useQuery(GET_PRODUCTS_WITH_FILTERS, {
        variables: {first: 40, page: 1, search, all: true},
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only'
    });

    if(loading && !previousData) return <Loader />
    if(error)   return <GraphQLErrorMessage error={error} />
    let holder = data || previousData;
    const products = holder ? holder[GET_PRODUCTS_QUERY].data : [];
    const paginatorInfo = holder ? holder[GET_PRODUCTS_QUERY].paginatorInfo : {};

    const toggleSelected = (item) => {
        const find = selected.find(select => select.id === item.id);
        if(!find)       {
            setSelected([...selected, ...[item]]);
        }
        else        {
            const newSelected = selected.filter(select => select.id !== item.id);
            setSelected(newSelected);
        }
    }
    const selectItems = () => {
        if(selected.length === 0) return false;
        callback(selected);
    }

    return (
        <div className="container">
            <div className="mb-3">
                <AddProductForm refetch={refetch} />
            </div>
            <div className="mb-3 row">
                <div className="col-md-6">
                    <input value={variables.search || ""} name="search"
                           //onChange={(e) => setSearch(e.target.value)}
                           onChange={(e) => refetch({search: e.target.value || undefined, page: 1})}
                           type="text" placeholder="Pretraga..." className="form-control" />
                </div>
                <div className="col-md-3">
                    {widget("categories.CategorySelect",
                        {value: variables.category, callback: (item) => refetch({category: item ? item.id : undefined, page: 1})})}
                </div>
                <div className="col-md-3 text-end">
                    {products.length > 0 &&
                    widget("cms.Pagination", {paginatorInfo: paginatorInfo, refetch: refetch})}
                </div>
            </div>

            {products.length === 0 &&
            <div className="p-5 text-center"> Prazna pretraga </div>}
            {products.map(item => {
                return <div key={item.id} className="d-flex align-items-center bg-white mb-2 shadow-sm p-3 list_item">
                    {callback &&
                    <input type="checkbox" onChange={() => toggleSelected(item)} className="me-2" />}
                    <div className="flex-grow-1">
                        <ProductItem item={item} refetch={refetch} />
                    </div>
                </div>
            })}

            <div className="mb-3 text-end">
                {products.length > 0 &&
                widget("cms.Pagination", {paginatorInfo: paginatorInfo, refetch: refetch})}
            </div>

            {selected.length > 0 &&
            <div className="bg-white p-2 pe-4 border fixed-bottom text-end">
                {callback &&
                <button onClick={selectItems} className="btn btn-primary me-2">
                    Odaberi
                </button>}
            </div>}
        </div>
    )
}

export default Products;
