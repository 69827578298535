import React from "react";
import {useQuery} from "@apollo/client";
import {GET_PRODUCTS, GET_PRODUCTS_QUERY} from "../graphql/product";
import Loader from "../../../app/Loader";
import GraphQLErrorMessage from "../../../app/GraphQLErrorMessage";
import ProductsCarousel from "./ProductsCarousel";


const LoadProductsCarousel = ({variables = {}}) => {
    const {data, loading, error} = useQuery(GET_PRODUCTS, {
        variables: variables
    });
    if(loading)     return <Loader opacity={.3} />
    if(error)       return <GraphQLErrorMessage error={error} />
    const products = data ? data[GET_PRODUCTS_QUERY].data : [];

    return (
        <ProductsCarousel products={products} />
    );
};

export default LoadProductsCarousel;