import GlobalBasketDrawerWrapper from "./middlewares/GlobalBasketDrawerWrapper";
import BasketDrawerToggle from "./widgets/BasketDrawerToggle";
import BagBar from "./widgets/BagBar";
import GlobalBasketUserWrapper from "./middlewares/GlobalBasketUserWrapper";
import BasketDrawer from "./widgets/BasketDrawer";
import ProductForm from "./widgets/ProductForm";
import Checkout from "./views/checkout/Checkout";
import Orders from "./views/orders/Orders";
import UnseenOrderAppend from "./views/orders/UnseenOrdersAppend";
import GlobalBasketGuestWrapper from "./middlewares/GlobalBasketGuestWrapper";
import OrderStatus from "./views/status/OrderStatus";
import Statistics from "./views/statistics/Statistics";
import {CornerDownRight} from "react-feather";
import PromoCodes from "./views/promoCodes/PromoCodes";

const orderRoot = ["/porudzbina", "/поруџбина", "/order"];
const routes = {
    checkout: {path: ["/placanje", "/плати", "/checkout"], component: Checkout, middleware: "public.PublicMiddleware"},
    status: {path: [`${orderRoot[0]}/:code/:thankYou?`, `${orderRoot[1]}/:code/:thankYou?`, `${orderRoot[2]}/:code/:thankYou?`],
        compile: (code, lang) => {
            if(!code)  return orderRoot[lang];
            return `${orderRoot[lang]}/${code}`;
        },
        component: OrderStatus, middleware: "public.PublicMiddleware"},
    orders: {path: "/cms/porudzbine", component: Orders, roles: [1,2], middleware: "cms.AdminAuthenticatedMiddleware"},
    statistics: {path: "/cms/statistika", component: Statistics, roles: [1,2], middleware: "cms.AdminAuthenticatedMiddleware"},
    promo: {path: "/cms/promo-kod", component: PromoCodes, roles: [1,2], middleware: "cms.AdminAuthenticatedMiddleware"},
};
const cmsLeftMenu = [
    {path: routes.orders.path, name: "Porudžbine", position: 5, roles: [1,2], append: UnseenOrderAppend},
    {path: routes.statistics.path, name: <div><CornerDownRight size={18} />  Statistika</div>, roles: [1,2], position: 5},
    {path: routes.promo.path, name: "Promo kod", roles: [1,2], position: 6},
];
const exports = {
    routes: routes,
    cmsLeftMenu: cmsLeftMenu,
    wrappers: [GlobalBasketUserWrapper, GlobalBasketGuestWrapper, GlobalBasketDrawerWrapper],
    widgets:  {
        BasketDrawerToggle: BasketDrawerToggle,
        BasketDrawer: BasketDrawer,
        BagBar: BagBar,
        ProductForm: ProductForm,
    }
}
export default exports;