import {ApolloClient, ApolloProvider, createHttpLink, from, InMemoryCache} from "@apollo/client";
import {graphQL} from "../../../app/config";
import {setContext} from "@apollo/client/link/context";
import {widget} from "../../modulesCompiler";



function GlobalApolloWrapper({children})    {
    const lang = widget("lang.useGetLang", null);
    const adminToken = widget("cms.providerCheckAdmin", null);
    const userToken = widget("users.providerCheckUser", null);

    const httpLink = createHttpLink({
        uri: graphQL(),
    });
    const authLink = setContext((_, { headers }) => {
        return {
            headers: {
                ...headers,
                Authorization: `Bearer ${adminToken}`,
                Authuser: userToken,
                Lang: lang
            }
        }
    });
    const client = new ApolloClient({
        cache: new InMemoryCache(),
        link: from([authLink, httpLink]),
    });

    return (
        <ApolloProvider client={client}>
            {children}
        </ApolloProvider>
    )
}
export default GlobalApolloWrapper;