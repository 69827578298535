import {gql} from "@apollo/client";
import {STORAGE_FRAGMENT} from "../../storage/graphql/StorageFragment";


export const PRODUCT_FRAGMENT = gql`
    ${STORAGE_FRAGMENT} 
    fragment ProductFragment on Product {
        id
        active
        group_id
        name
        names
        code
        brand
        price
        prices
        discount_price
        discount_prices
        process_price
        action
        new
        qty
        seo_title
        seo_keywords
        seo_description
        video
        seo_titles
        seo_keywords_all
        seo_descriptions
        image {
            ...StorageFragment
        }

        Cotton
        Cotton_polyester
        Leather
        Polyester
        Ripstop
        Twill_keper
        Very_cold_weather
        Waterproof
        Water_repellent
        Windproof
    }
`;

