import {route, trans, widget} from "../../../modulesCompiler";
import React from "react";
import {Link} from "react-router-dom";


function KakoKupiti() {
    const lang = widget("lang.useGetLang", null);

    return (
        <>
            {widget("public.Breadcrumbs", {links: [{trans: trans("kako kupiti")}]})}
            <div className="container mb-6">
                <h1 className="oswald fw-bold text-uppercase h3">{trans("kako kupiti")}</h1>
                {lang === 0 &&
                <>
                    <h5 className="oswald fw-bold">Kako se poručuju artikli na www.horus.rs sajtu?</h5>
                    Naručivanje možete izvršiti odabirom boje i veličine klikom na kvadratiće u kojima su upisane veličine i prikazane boje. Nakon toga kliknite na dugme "Dodajte u korpu", klikom na to dugme ispisaće se tekst “Dodato u korpu”, nakon toga željeni artikal će biti dodat u Vašu korpu kojoj možete pristupiti u gornjem desnom uglu. Nakon pristupa korpi, kliknite na “Završite poružbinu” i sajt će Vas voditi kroz intuitivan proces naručivanja. Ako Vam zatreba pomoć, budite slobodni da nas pozovete na 065 43210 23.
                    <br /><br />
                    <h5 className="oswald fw-bold">Koji su načini dostave?</h5>
                    Sve poručene artikle Vam možemo dostaviti putem kurirske službe DExpress na kućnu adresu.
                    <br />
                    <br />
                    Vrednost dostave plaća se po sledećim težinskim razredima:
                    <br />
                    <table className="table table-bordered">
                        <tbody>
                        <tr>
                            <td className="fw-bold">Masa paketa</td>
                            <td className="fw-bold">Cena sa PDV</td>
                        </tr>
                        <tr><td>do 1 kg </td><td> 390,00 RSD </td></tr>
                        <tr> <td>od 1 do 2 kg</td><td> 490,00 RSD </td></tr>
                        <tr> <td>od 2 do 5 kg</td><td> 590,00 RSD </td></tr>
                        <tr> <td>od 5 do 10 kg</td><td> 690,00 RSD </td></tr>
                        <tr> <td>od 10 do 20 kg</td><td> 890,00 RSD </td></tr>
                        </tbody>
                    </table>

                    Troškovi isporuke će biti jasno istaknuti na fiskalnom računu kao posebna stavka u skladu sa Zakonom RS. Toškovi
                    isporuke za porudžbine od 8000,00 RSD sa PDV ili više se ne naplaćuju.

                    <br /><br />
                    <h5 className="oswald fw-bold">Koji je rok dostave naručenih artikala?</h5>
                    Rok isporuke kurirskom službom je do 1 do 3 radna dana. Porudžbine kreirane do 12 časova, ukoliko poručenu robu imamo na stanju biće poslata istog dana, a na adresi Primaoca će biti sledeći dan. Ukoliko je porudžbina kreirana u petak posle 12 časova, paket će biti poslat u ponedeljak a na adresi Primaoca će biti u utorak. Isporuke paketa se vrše samo radnim danima, tj. vikendom se isporuke paketa ne vrše.
                    <br /><br />
                    Molimo Vas za razumevanje za moguća kašnjenja u isporuci u periodima akcija i sezonskih sniženja obzirom da isporuka zavisi od eksternih službi.
                    <br /><br />

                    <h5 className="oswald fw-bold">Koji su načini plaćanja?</h5>
                    Artikle koji su dostupni za kupovinu na sajtu možete platiti isključivo u gotovini kuriru koji Vam dostavi artikal. Ukoliko kupujete u prodavnici, možete platiti gotovinom, karticama, Banca Intesa VISA i/ili MASTER karticom na 6 rata bez kamate i čekovima građana na 5 odloženih rata i prvom ratom u momentu kupovine.
                    <br /><br />
                    <h5 className="oswald fw-bold">Da li se poručeni artikal može zameniti za isti artikal u drugoj veličini?</h5>
                    Postoji mogućnost zamene artikla za drugi ili isti artikal u drugoj veličini. Potrebno je, uz vraćeni artikal, dostaviti odštampan i popunjen obrazac za odustanak od ugovora koji dostavljamo prilikom isporuke paketa.
                    <br /><br />
                    <h5 className="oswald fw-bold">Koje je radno vreme prodavnica?</h5>
                    Radno vreme prodavnica kao i druge informacije o prodavnicama, možete pogledati
                    <Link to={route("public.contact")} className="ms-2 fw-bold link-secondary"
                       target="_blank" rel="noreferrer">ovde</Link>
                    <br /><br />
                    <h5 className="oswald fw-bold">Da li se artikli mogu poručiti iz drugih država?</h5>


                    Da, i mogu se platiti bankovnim transferom ili putem PayPal-a po izvoznim cenama u EUR valuti koje su
                    istaknute na sajtu kada izaberete engleski jezik: <Link to="/home" className="ms-2 fw-bold link-secondary"
                                                                            target="_blank" rel="noreferrer">www.horus.rs/home</Link> .
                    <br />
                    Ukoliko kao Kupac pošaljete porudžbenicu na engleskom jeziku, izvoz se obavlja uslugom JP “Pošta
                    Srbije” – PostExport ili PostPak ukoliko robu poručujete iz Crne Gore, Bosne i Hercegovine ili Republike
                    Srpske.
                    <br /><br />
                    Roba se šalje nakon evidentiranja uplate na račun Prodavca.
                    <br /><br />
                    Aktuelni cenovnik troškova izvoza PostExport usluge možete pogledati <a href="https://www.posta.rs/cir/privreda/usluga.aspx?usluga=postanske-usluge/paketske-usluge-inostranstvo/post-eksport-izvoz-robe&amp;strana=cene" className="ms-2 fw-bold link-secondary"
                                                                                               target="_blank" rel="noreferrer">ovde</a>
                    <br />
                    Aktuelni cenovnik troškova izvoza PostPak usluge možete pogledati <a href="https://www.posta.rs/lat/privreda/usluga.aspx?usluga=postanske-usluge/paketske-usluge-inostranstvo/postpak-otkupni-paket-za-region&amp;strana=cene" className="ms-2 fw-bold link-secondary"
                                                                                         target="_blank" rel="noreferrer">ovde</a>
                    <br />
                    Takodje, da bi izbegli dodatne troškove izvoza naručeni artikal uz dogovor možete naručiti i na adresu
                    Vašeg prijatelja ili rođaka u Srbiji koji će Vam artikal proslediti kao poklon.
                </>}
                {lang === 1 &&
                <>
                    <h5 className="oswald fw-bold">Како се поручују артикли на www.horus.rs сајту?</h5>
                    Наручивање можете извршити одабиром боје и величине кликом на квадратиће у којима су уписане величине и приказане боје. Након тога кликните на дугме "Додајте у корпу", кликом на то дугме исписаће се текст “Додато у корпу”, након тога жељени артикал ће бити додат у Вашу корпу којој можете приступити у горњем десном углу. Након приступа корпи, кликните на “Завршите поружбину” и сајт ће Вас водити кроз интуитиван процес наручивања. Ако Вам затреба помоћ, будите слободни да нас позовете на 065 43210 23.
                    <br /><br />
                    <h5 className="oswald fw-bold">Који су начини доставе?</h5>
                    Све поручене артикле Вам можемо доставити путем курирске службе DExpress на кућну адресу.

                    <br />
                    <br />
                    Вредност доставе плаћа се по следећим тежинским разредима:
                    <br />
                    <table className="table table-bordered">
                        <tbody>
                        <tr>
                            <td className="fw-bold">Маса пакета</td>
                            <td className="fw-bold">Цена са ПДВ</td>
                        </tr>
                        <tr><td>до 1 кг </td><td> 390,00 РСД </td></tr>
                        <tr> <td>од 1 до 2 кг</td><td> 490,00 РСД </td></tr>
                        <tr> <td>од 2 до 5 кг</td><td> 590,00 РСД </td></tr>
                        <tr> <td>од 5 до 10 кг</td><td> 690,00 РСД </td></tr>
                        <tr> <td>од 10 до 20 кг</td><td> 890,00 РСД </td></tr>
                        </tbody>
                    </table>

                    Трошкови испоруке ће бити јасно истакнути на фискалном рачуну као посебна ставка у складу са
                    Законом РС. Тошкови испоруке за поруџбине од 8000,00 РСД са ПДВ или више се не наплаћују.




                    <br /><br />
                    <h5 className="oswald fw-bold">Који је рок доставе наручених артикала?</h5>
                    Рок испоруке курирском службом је до 1 до 3 радна дана. Поруџбине креиране до 12 часова, уколико поручену робу имамо на стању биће послата истог дана, а на адреси Примаоца ће бити следећи дан. Уколико је поруџбина креирана у петак после 12 часова, пакет ће бити послат у понедељак а на адреси Примаоца ће бити у уторак. Испоруке пакета се врше само радним данима, тј. викендом се испоруке пакета не врше.
                    <br /><br />
                    Молимо Вас за разумевање за могућа кашњења у испоруци у периодима акција и сезонских снижења обзиром да испорука зависи од екстерних служби.
                    <br /><br />
                    <h5 className="oswald fw-bold">Који су начини плаћања?</h5>
                    Артикле који су доступни за куповину на сајту можете платити искључиво у готовини куриру који Вам достави артикал. Уколико купујете у продавници, можете платити готовином, картицама, Banca Intesa VISA и/или MASTER картицом на 6 рата без камате и чековима грађана на 5 одложених рата и првом ратом у моменту куповине.
                    <br /><br />
                    <h5 className="oswald fw-bold">Да ли се поручени артикал може заменити за исти артикал у другој величини?</h5>
                    Постоји могућност замене артикла за други или исти артикал у другој величини. Потребно је, уз враћени артикал, доставити одштампан и попуњен образац за одустанак од уговора који достављамо приликом испоруке пакета.
                    <br /><br />
                    <h5 className="oswald fw-bold">Које је радно време продавница?</h5>
                    Радно време продавница као и друге информације о продавницама, можете погледати
                    <Link to={route("public.contact")} className="ms-2 fw-bold link-secondary"
                          target="_blank" rel="noreferrer">овде</Link>
                    <br /><br />
                    <h5 className="oswald fw-bold">Да ли се артикли могу поручити из других држава?</h5>
                    Да, и могу се платити банковним трансфером или путем PayPal-a по извозним ценама у ЕУР валути
                    које су истакнуте на сајту када изаберете енглески језик: <Link to="/home" className="ms-2 fw-bold link-secondary"
                                                                                    target="_blank" rel="noreferrer">www.horus.rs/home</Link> .
                    <br /><br />.
                    Уколико као Купац пошаљете поруџбеницу на енглеском језику, извоз се обавља услугом ЈП
                    “Пошта Србије” – PostExport или PostPak уколико робу поручујете из Црне Горе, Босне и
                    Херцеговине или Републике Српске. Роба се шаље након евидентирања уплате на рачун
                    Продавца.<br /><br />
                    Ценовник трошкова извоза PostExport услуге можете погледати <a href="https://www.posta.rs/cir/privreda/usluga.aspx?usluga=postanske-usluge/paketske-usluge-inostranstvo/post-eksport-izvoz-robe&amp;strana=cene" className="ms-2 fw-bold link-secondary"
                                                                                   target="_blank" rel="noreferrer">овде</a>.<br />
                    Ценовник трошкова извоза PostPak услуге можете погледати <a href="https://www.posta.rs/lat/privreda/usluga.aspx?usluga=postanske-usluge/paketske-usluge-inostranstvo/postpak-otkupni-paket-za-region&amp;strana=cene" className="ms-2 fw-bold link-secondary"
                                                                                target="_blank" rel="noreferrer">овде</a>.<br />
                    Такође, да бисте избегли додатне трошкове извоза наручени артикал уз договор можете наручити
                    и на адресу Вашег пријатеља или рођака у Србији који ће Вам артикал проследити као поклон.
                </>}
                {lang === 2 &&
                <>
                    <h5 className="oswald fw-bold">HOW TO ORDER THE ITEMS ON HORUS.RS WEBSITE?</h5>
                    You can place an order by following the next steps:
                    <br /><br />
                    Select the desired product (size and amount)
                    <br /><br />
                    Access the shopping cart to continue the purchase process
                    <br /><br />
                    Enter the data <br /><br />
                    Confirm the order <br /><br />
                    If you need any help, feel free to contact us by sending an e-mail to shop@horus.rs
                    <br /><br />
                    <h5 className="oswald fw-bold">WHAT ARE THE DELIVERY METHODS AND TERMS?</h5>
                    Post export (an external company that delivers goods for us), delivers packages to your home address.
                    <br /><br />
                    Most often, it takes 10-20 business days for processing orders and shipping packages. <br /><br />
                    The estimated delivery time is shown for informative purposes only. HORUS WEAR doo company cannot vouch for the estimated delivery time shown in the table. The package can be kept longer than initially planned at certain posts for safety checking procedures, which can postpone the estimated delivery time. According to previosly stated, delivery  can last up to 30 days. Prolonged deliveries are also prone to happen during holiday seasons, as processing the packages can take longer than the usual.
                    <br /><br />
                    <h5 className="oswald fw-bold">WHAT ARE THE PAYMENT METHODS?</h5>
                    You can pay via <b>bank wire transfer</b> or <b>PayPal</b>.  <br /><br />
                    By confirming and clicking on the ‘SUBMIT ORDER’ button, you agree with the delivery terms and conditions stated.
                    <br /><br />
                    After confirmation, the order enters processing status. Once your order has been
                    processed, you will be notified of its status by email.
                    <br /><br />
                    If all goods are in stock, you will receive a <b>Proforma Invoice</b> with the prices of the
                    goods, transport costs and payment instructions. It usually takes 1-3 working days
                    to receive the payment in the HORUS WEAR d.o.o. bank account, and only a few
                    minutes if you pay via PayPal. After the payment is received, HORUS WEAR d.o.o.
                    will send the package to you. Once your package is sent, you will be notified with a
                    tracking code via email.
                    <br /><br />
                    The buyer shall bear and pay the full cost, including all banking charges.
                    <br /><br />

                    <h5 className="oswald fw-bold">CAN THE ORDERED ITEM BE REPLACED BY THE SAME ITEM ONLY IN OTHER SIZE?</h5>
                    You can have a refund if the size of the ordered item is not suitable. Buyers can be free to place a new order with the same item in different, more suitable size. Before returning, it is necessary to contact us via e-mail shop@horus.rs
                    <br /><br />
                    All transport costs will be paid by the buyer.
                    <br /><br />
                    <h5 className="oswald fw-bold">CWHAT ARE THE WORKING HOURS OF THE STORE?</h5>
                    Working time of HORUS online store is from  9.00 AM until 5.00 PM on business days (Mon-Fri).<br />
                    Working time of HORUS SHOP at the address Kralja Milana 22-24, Belgrade – Stari grad, Serbia is:<br />
                    Mon-Fri: 9AM – 9PM<br />
                    Saturday: 10AM – 9PM<br />
                    Sunday: 12PM – 7PM
                    <br /><br />
                    <h5 className="oswald fw-bold">WHETHER THE ITEMS CAN BE ORDERED FROM OTHER STATE?</h5>

                    Yes, we are providing delivery to all countries worldwide. Delivery costs can vary
                    depending on the weight of the package and the country you are coming from. <br/><br/>
                    Prices can check here:<br/>

                    <a href="https://www.posta.rs/eng/stanovnistvo/usluga.aspx?usluga=postal-services/parcel-services-international/sending-parcels-abroad&amp;strana=prices" className="fw-bold link-secondary"
                       target="_blank" rel="noreferrer">https://www.posta.rs/eng/stanovnistvo/usluga.aspx?usluga=postal-services/parcel-services-international/sending-parcels-abroad&amp;strana=prices
                    </a>
                </>}
            </div>
        </>
    )
}
export default KakoKupiti;