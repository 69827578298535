import {useMutation} from "@apollo/client";
import React, {useState} from "react";
import GraphQLErrorField from "../../../../app/GraphQLErrorField";
import {UPSERT_FILTER} from "../../graphql/filter";
import {Edit, PlusCircle} from "react-feather";
import {widget} from "../../../modulesCompiler";
import toast from "react-hot-toast";



const AddFilterForm = ({item, parent, refetch}) => {
    const [open, setOpen] = useState(false);
    const langOptions = widget("lang.options");

    let defaultForm = {names: []};
    if(parent)  defaultForm = {...defaultForm, parent: parent.id};
    if(item)   {
        defaultForm = {id: item.id, names: item.names};
        if(item.parent)  defaultForm = {...defaultForm, parent: item.id};

    }
    const [form, setForm] = useState(defaultForm);
    const [upsertFilter, {error, loading}] = useMutation(UPSERT_FILTER);

    const changeName = (e) => {
        const value = e.target.value;
        const id = e.target.name;
        const values = [...form.names];
        values[id] = value;
        setForm({...form, names: values});
    }

    const submit = (e) => {
        e.preventDefault();
        upsertFilter({variables: form}).then(response => {
            if(!item)  {
                setForm(defaultForm);
            }
            toast.success("Sačuvano!");
            refetch();
        }).catch(error => {
            toast.error(error.message);
        });
    }

    return (
        <div>
            {!open &&
            <button onClick={() => setOpen(!open)} className={`btn ${item ? "btn-light" : "btn-success"}`}>
                {item ? <><Edit size={16} />  Prepravite</> : <><PlusCircle size={16} /> Dodajte</>} {parent && "pod"}filter
            </button>}
            {open &&
            <form onSubmit={submit} className="bg-white shadow mb-4 mt-3 p-3 rounded border border-success border-3">

                {langOptions.map(item => {
                    return <div key={item.id} className="bg-light mb-2 p-2 d-flex align-items-center">
                        <div className="me-2">{item.label}</div>
                        <div className="flex-grow-1">
                            <div className="mb-2">
                                <b>Naziv</b>
                                <input type="text" required autoComplete="off" className="form-control"
                                       name={item.id}  value={form.names[item.id] || ""} onChange={changeName} />
                                <GraphQLErrorField error={error} field={`names.${item.id}`} />
                            </div>
                        </div>
                    </div>
                })}

                <button disabled={loading} type="submit" className="btn btn-success me-2">
                    Sacuvajte {parent && "pod"}filter
                </button>
                <button onClick={() => setOpen(false)} type="button" className="btn btn-light">
                    Zatvorite
                </button>
            </form>}
        </div>
    )
}

export default AddFilterForm;
