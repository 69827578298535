import React from "react";
import CmsUpdateField from "../../../../app/CmsUpdateField";
import {UPSERT_PRODUCT} from "../../graphql/product";
import {route, widget} from "../../../modulesCompiler";
import {Link} from "react-router-dom";
import {Trash2} from "react-feather";
import {useMutation} from "@apollo/client";
import toast from "react-hot-toast";



function GroupProducts({product, refetch}) {
    const [deleteFromGroup] = useMutation(UPSERT_PRODUCT);

    const deleteItem = (id) => {
        if(!window.confirm("Zelite da uklonite iz grupe?"))  {
            return false;
        }
        deleteFromGroup({variables: {id: id, group_id: null}}).then(response => {
            toast.success("Obrisano!");
            refetch();
        }).catch(error => {
            toast.error(error.message);
        });
    }

    return  (
        <>
            <div className="col-md-5">
                <CmsUpdateField
                    placeholder="Šifra"
                    refetch={refetch}
                    element="text" nullable={true}
                    id={product.id} MUTATION={UPSERT_PRODUCT}
                    name="group_id" value={product.group_id} />
            </div>
            {product.group.length > 0 &&
            <div className="mt-3">
                {product.group.map(item => {
                    return <div key={item.id} className="d-flex align-items-center p-2 mb-2 bg-light">
                        <div className="me-3" style={{width: 50}}>
                            <div className="bcg_image" style={{backgroundImage: `url(${widget("storage.image", item.image)})`}} />
                        </div>
                        <Link to={route("products.product", item)}>
                            <h6 className="mb-0">{item.name}</h6>
                            <span className="fs-s">{item.group_id}</span>
                        </Link>
                        <button onClick={() => deleteItem(item.id)}
                            className="btn btn-light btn-sm ms-3">
                            <Trash2 size={16} />
                        </button>
                    </div>
                })}
            </div>}
        </>
    )
}
export default GroupProducts;