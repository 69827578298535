import React, {useState} from "react";
import {Filter, X} from "react-feather";
import logo from "../../../../assets/images/logo.png";
import Drawer from "rc-drawer";
import {trans, widget} from "../../../modulesCompiler";
import RenderCategories from "./RenderCategories";
import GetAllCategories from "./GetAllCategories";
import {useSwipeable} from "react-swipeable";


function CategoriesDrawer({category, url}) {
    const transName = category?.name || trans("kategorije");
    const transCategories = !category
        ? trans("kategorije")
        : category.children.length === 0 ? trans("filteri") : trans("kategorije");
    const [categoriesDrawer, setCategoriesDrawer] = useState(false);
    const swipeLeftToClose = useSwipeable({
        onSwipedLeft: (eventData) => setCategoriesDrawer(false),
    });


    return (
        <>
            <Drawer
                open={categoriesDrawer}
                onClose={() => setCategoriesDrawer(false)}
                handler={<div onClick={() => setCategoriesDrawer(!categoriesDrawer)} className="drawer-handle">
                    <Filter />
                </div>}
                width="360px"
                placement="left"
                level={null}>

                <div {...swipeLeftToClose} className="mt-3">

                    <div className="border-bottom p-2 text-center">
                        <img src={logo} className="img-fluid" alt="HORUS LOGO" />
                    </div>
                    <div className="border-bottom p-2 d-flex align-items-center justify-content-between">
                        <div className="oswald h5">
                            {transName}
                        </div>
                        <button onClick={() => setCategoriesDrawer(false)} className="btn btn-link btn-sm">
                            <X size={18}/>
                        </button>
                    </div>

                    <div className="p-2">
                        {!category &&
                        <GetAllCategories />}

                        {(category && category.children.length > 0) &&
                        <RenderCategories categories={category.children} />}

                        {(category && category.children.length === 0) &&
                        widget("filters.CategoryDrawer", {category, url})}
                    </div>

                </div>
            </Drawer>

            <button onClick={() => setCategoriesDrawer(true)} className="btn btn-secondary fs-5 oswald">
                <Filter size={18} /> {transCategories}
            </button>
        </>

    )
}
export default CategoriesDrawer;