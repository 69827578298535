import React, {useState} from "react";
import {Link} from "react-router-dom";
import {route} from "../../../modulesCompiler";
import {Minus, Plus} from "react-feather";

function RenderCategories({item, close}) {
    const productShopRoute = route("products.shop");
    const [open, setOpen] = useState(false);

    const onClick = () => {
        if(close) close();
    }

    return (
        <div key={item.id}>
            <div className="d-flex align-items-center">
                <Link onClick={onClick} to={`${productShopRoute}/${item.slug}`} className="oswald flex-grow-1 d-inline-block w-100 p-2">
                    {item.name}
                    <span className="text-black-50 fs-s ms-1 d-inline-block">
                            ({item.products_count})
                       </span>
                </Link>
                {item.children.length > 0 &&
                <button onClick={() => setOpen(!open)} className="btn btn-light btn-sm shadow-none">
                    {open ? <Minus size={17} /> : <Plus size={17} />}
                </button>}
            </div>

            {open &&
            <div>
                {item.children.map(child => {
                    return <div key={child.id}>
                        <Link onClick={onClick} to={`${productShopRoute}/${child.slug}`} className="oswald d-inline-block w-100 ps-4 p-2">
                            {child.name}
                            <span className="text-black-50 fs-s ms-1 d-inline-block">
                                ({child.products_count})
                           </span>
                        </Link>
                    </div>
                })}
            </div>}
        </div>
    )
}
export default RenderCategories;