import {useMutation} from "@apollo/client";
import React, {useState} from "react";
import GraphQLErrorField from "../../../../app/GraphQLErrorField";
import {PlusCircle, RefreshCw} from "react-feather";
import GraphQLErrorMessage from "../../../../app/GraphQLErrorMessage";
import toast from "react-hot-toast";
import {CREATE_PROMO_CODE} from "../../graphql/promo";
import {randString} from "../../../../app/helpers";
import moment from "moment";


const AddPromoCodeForm = ({refetch}) => {
    const [open, setOpen] = useState(false);
    const defaultForm = {code: randString(6).toUpperCase(), count: 1, discount: "", expires_at: ""}
    const [form, setForm] = useState(defaultForm);
    const [createPromoCode, {error, loading}] = useMutation(CREATE_PROMO_CODE);

    const generateCode = () => {
        return randString(6).toUpperCase()
    }

    const setField = (e) => {
        const field = e.target.name;
        let value = e.target.value;
        const type = e.target.type;
        if(type === "number" && value)   {
            value = parseInt(value);
        }
        if(value === "")   {
            value = null;
        }
        setForm({...form, [field]: value});
    }

    const submit = (e) => {
        e.preventDefault();
        let send = {...form};
        if(form.expires_at)   {
            const expires = moment(form.expires_at).utc().format("YYYY-MM-DD HH:mm:ss");
            send = {...form, expires_at: expires};
        }
        createPromoCode({variables: send}).then(response => {
            setForm({...form, code: ""});
            if(refetch) {
                refetch();
            }
            toast.success("Sačuvano!");
        }).catch(error => {
            toast.error(error.message);
        });
    }

    return (
        <div>
            <div className="mb-3">
                {!open &&
                <button onClick={() => setOpen(!open)} className="btn btn-success">
                    <PlusCircle size={16} /> Kreirajte promo kod
                </button>}
                {open &&
                <form onSubmit={submit} className="bg-white shadow mb-4 mt-3 p-3 rounded border border-success border-3">
                    <div className="mb-3">
                        <strong className="mb-2"> PROMO KOD: </strong>

                        <div className="row mb-3">
                            <div className="col-md-6">
                                <div className="input-group">
                                    <input onChange={setField}
                                           type="text" name="code" value={form.code} className="form-control" required />
                                    <div className="input-group-append">
                                        <button type="button" className="btn btn-primary"
                                            onClick={() => setForm({...form, code: generateCode()})}>
                                            <RefreshCw size={18} className="me-2" />
                                            REGENERATE
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="d-flex align-items-center mb-2">
                            <div style={{width: 100}} className="me-3">
                                <input type="number" className="form-control" min={1} value={form.count}
                                      name="count" onChange={setField} />
                            </div>
                            <div>
                                <strong> BROJ </strong>
                                <div className="text-black-50">koliko puta kod može da se iskoristi (default je 1) </div>
                            </div>
                        </div>

                        <div className="d-flex align-items-center">
                            <div style={{width: 100}} className="me-3">
                                <input type="number" min={1} className="form-control" value={form.discount}
                                       name="discount" onChange={setField} required />
                            </div>
                            <div>
                                <strong> POPUST </strong>
                            </div>
                        </div>

                        <div className="d-flex align-items-center mb-2">
                            <div style={{width: 250}} className="me-3">
                                <input type="datetime-local" className="form-control" min={1} value={form.expires_at}
                                      name="expires_at" onChange={setField} />
                            </div>
                            <div>
                                <strong> DATUM </strong>
                                <div className="text-black-50">isteka koda. Ako je prazno, traje neograničeno. </div>
                            </div>
                        </div>


                        <GraphQLErrorField error={error} field="code" />
                    </div>
                    <button disabled={loading} className="btn btn-success me-2">Sačuvajte kod</button>
                    <button onClick={() => setOpen(false)} type="button" className="btn btn-light">
                        Zatvorite
                    </button>
                    <GraphQLErrorMessage error={error} />
                </form>}
            </div>

        </div>
    )
}

export default AddPromoCodeForm;
