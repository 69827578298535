import {useQuery} from "@apollo/client";
import Loader from "../../../../app/Loader";
import GraphQLErrorMessage from "../../../../app/GraphQLErrorMessage";
import {widget} from "../../../modulesCompiler";
import React from "react";
import {GET_LOG_PRODUCTS_VIEW, GET_LOG_PRODUCT_VIEWS_QUERY} from "../../graphql/logs";


function MostViewedProducts() {
    const {data, loading, error, previousData} = useQuery(GET_LOG_PRODUCTS_VIEW, {
        variables: {
            first: 10,
            page: 1
        },
        notifyOnNetworkStatusChange: true,
    });
    if(loading && !previousData) return <Loader />
    if(error)   return <GraphQLErrorMessage error={error} />
    let holder = data || previousData;
    const products = holder ? holder[GET_LOG_PRODUCT_VIEWS_QUERY].data : [];
    //const paginatorInfo = holder ? holder[GET_LOG_PRODUCT_VIEW_QUERY].paginatorInfo : {};

    if(products.length === 0)   {
        return (
            <div className="bg-white p-3 fw-bold text-center">
                Nema rezultata
            </div>
        )
    }

    return (
        <div>
            {products.map(item => {
                const product = item.product;
                if(!product) return null;
                return <div key={item.id} className="d-flex mb-1 pb-1 border-bottom align-items-center justify-content-between">
                    <div className="me-3" style={{width: 50}}>
                        <div className="bcg_image" style={{backgroundImage: `url(${widget("storage.image", product.image)})`}} />
                    </div>
                    <div className="flex-grow-1">
                        <strong>{product.name}</strong>
                        {product.code &&
                        <div>#{product.code}</div>}
                    </div>
                    <div title="Broj dodatih." className="ms-3">
                        Pregledi: <strong> {item.total} </strong>
                    </div>
                </div>
            })}
        </div>
    )
}
export default MostViewedProducts;