import React, {useState} from "react";
import {useMutation} from "@apollo/client";
import {PlusCircle, Trash2} from "react-feather";
import Nestable from "react-nestable";
import {ORDER_ITEMS} from "../../../_cms/graphql/items";
import {widget} from "../../../modulesCompiler";
import toast from "react-hot-toast";
import {DELETE_PRODUCT_CONNECTED, UPSERT_PRODUCT_CONNECTED} from "../../graphql/connected";


function ConnectedProducts({product, refetch}) {
    const [productsDrawer, setProductsDrawer] = useState(false);
    const [deleteProductConnected] = useMutation(DELETE_PRODUCT_CONNECTED);
    const [upsertProduct] = useMutation(UPSERT_PRODUCT_CONNECTED);
    const [orderItems] = useMutation(ORDER_ITEMS);

    const selectedProducts = (items) => {
        for(let i=0; i<items.length; i++)       {
            upsertProduct({variables: {product_id: product.id, connected_id: items[i].id}}).then(response => {
                refetch();
            }).catch(error => {
                toast.error(error.message);
            });
        }
    }

    const productsChangedOrder = (items) => {
        const holder = items.items.map(p => {
            p.id = p.connectedPivot.id;
            return p;
        })
        orderItems(
            {variables: {items: JSON.stringify(holder), table: "product_connected"}}
        ).then(response => {
            toast.success("Sacuvano!");
            refetch();
        }).catch(error => {
            toast.error(error.message);
        });
    }

    const deleteProduct = (id) => {
        if(!window.confirm("Zelite da obrisete proizvod?"))  {
            return false;
        }
        deleteProductConnected({variables: {id: id}}).then(response => {
            toast.success("Obrisano!");
            refetch();
        }).catch(error => {
            toast.error(error.message);
        });
    }
    return  (
        <div>
             <div className="mb-3">
                <button onClick={() => setProductsDrawer(true)} className="btn btn-success">
                    <PlusCircle size={16} /> Dodajte proizvode
                </button>
            </div>

            {widget("products.ProductsDrawer", {
                open: productsDrawer,
                close: () => setProductsDrawer(false),
                callback: selectedProducts
            })}

            <Nestable
                className="inline align-top"
                collapsed={false}
                maxDepth={1}
                onChange={productsChangedOrder}
                handler={widget("cms.DragHandler")}
                items={product.connected}
                renderItem={(props) => <div key={props.item.id} className="">
                    <div className="d-flex justify-content-between mb-2">
                        {props.handler}
                        <button onClick={() => deleteProduct(props.item.connectedPivot.id)}
                            className="btn btn-sm btn-light">
                            <Trash2 />
                        </button>
                    </div>
                    {widget("products.ProductRender", props)}
                </div>} />
        </div>
    )
}
export default ConnectedProducts;