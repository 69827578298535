import {route, trans, widget} from "../../../modulesCompiler";
import React from "react";


function UsloviKoriscenja() {
    const lang = widget("lang.useGetLang", null);

    return (
        <>
            {widget("public.Breadcrumbs", {links: [{trans: trans("uslovi koriscenja")}]})}
            <div className="container mb-6">
                <h1 className="oswald fw-bold text-uppercase h3">{trans("uslovi koriscenja")}</h1>
                <br />
                {lang === 0 &&
                <>
                    <h5 className="oswald fw-bold">HORUS Internet prodavnica</h5>
                    Opšti uslovi poslovanja HORUS Internet prodavnice utvrđeni su uslovima kupovine i prodaje proizvoda, koje realizuje Horus Wear doo (u daljem tekstu: “Prodavac”). Opšti uslovi poslovanja HORUS Internet prodavnice detaljnije su utvrđeni po oblastima, kojima možete pristupiti klikom na željenu oblast, a sve u skladu sa važećim Zakonom o zaštiti potrošača i međunarodnim kodeksom elektronskog poslovanja. Opšti uslovi poslovanja definišu delatnost Internet prodavnice, korisnike prava i poslovni odnos između prodavca i potrašača.
                    <br /><br />
                    INTERNET PRODAVNICA je informacioni sistem namenjen predstavljanju i prodaji proizvoda potrošačima. Njime upravlja Horus Wear doo. Korisnik je osoba koja koristi navedeni sistem (u daljem tekstu: “Potrošač” u Internet prodavnici).
                    <br /><br />
                    HORUS INTERNET PRODAVNICA omogućava Potrošačima korišćenje usluga i sadržaja svoje stranice, a prava i obaveze Prodavca i Potrošača su regulisana dole navedenim Uslovima korišćenja. Uslovi korišćenja se primenjuju na sve sadržaje i usluge stranice www.horus.rs . Korišćenjem bilo kojeg dela stranice, smatra se da su Potrošači upoznati Uslovima korišćenja HORUS Internet prodavnice, kao i da prihvataju korišćenje sadržaja ove stranice isključivo za ličnu upotrebu i na vlastitu odgovornost.
                    <br /><br />
                    HORUS INTERNET PRODAVNICA može revidirati Uslove korišćenja u bilo kojem trenutku, ažuriranjem ovog dokumenta. Uslovi korišćenja su obavezujući za Potrošače, te savetujemo da se redovno posećuju, radi pregleda eventualnih izmena.
                    <br /><br />

                    <h5 className="oswald fw-bold">PROCES PORUČIVANJA</h5>
                    Sve informacije o procesu kupovine možete pogledati na linku u nastavku
                    <a href={route("public.kakoKupiti")} className="ms-2 fw-bold link-secondary" target="_blank" rel="noreferrer">KAKO KUPITI</a>
                    <br /><br />
                    <h5 className="oswald fw-bold">USLOVI I NAČINI ISPORUKE</h5>
                    Više informacija o uslovima i načinu isporuke možete pogledati
                    <a href={route("public.usloviIsporuke")} className="ms-2 fw-bold link-secondary"
                       target="_blank" rel="noreferrer">OVDE</a>
                    <br /><br />
                    <h5 className="oswald fw-bold">NAČIN PLAĆANJA</h5>
                    Potrošač plaća vrednost porudžbine i trošak isporuke prilikom dostave poručene robe. Plaćanje pouzećem se vrši isključivo u gotovini, kuriru prilikom same isporuke na navedenu adresu. Potrošač kreira porudžbenicu klikom na taster POŠALJI PORUDŽBINU. HORUS Internet prodavnica preuzima obavezu slanja poručenog/poručenih artikala Potrošaču, ukoliko su dostupni. Ukoliko porudžbenica nije kompletna jer nekih artikala nema na stanju, Potrošač će biti kontaktiran od strane Prodavca u najkraćem roku.
                    <br /><br />

                    <h5 className="oswald fw-bold">CENE U HORUS INTERNET PRODAVNICI</h5>
                    Sve cene su iskazane u dinarima sa uključenim PDV-om. Prodavac zadržava pravo izmene cena bez prethodnog obavešavanja kupaca, ako nije drugačije navedeno (u slučaju akcija i specijalnih popusta). Cene važe u trenutku kreiranja porudžbine i imaju dejstvo i validnost i za naredni period. Navedene cene važe samo za kupovinu u Internet prodavnici i mogu da se razlikuju od cena u maloprodajnim objektima Prodavca.
                    <br /><br />

                    <h5 className="oswald fw-bold">SAOBRAZNOST</h5>
                    Prodavac je dužan da isporuči robu koja je saobrazna ugovoru i odgovoran je za
                    nesaobraznost robe u ugovoru koja se pojavi u roku od 2 godine od dana prelaska rizika na
                    potrošača. Ako nesaobraznost nastane u roku od 6 meseci od dana prelaska rizika na
                    potrošača, može se pretpostaviti da je nesaobraznost postojala u trenutku prelaska rizika na
                    potrošača. Sve troškove koji su neophodni da bi se roba saobrazila ugovoru, a naročito
                    troškove rada, materijala, preuzimanja i isporuke, snosi Prodavac.&quot;
                    <br /><br />

                    <h5 className="oswald fw-bold">VANSUDSKO REŠAVANJE POTROŠAČKIH SPOROVA</h5>
                    Potrošački spor može se rešiti postupkom vansudskog rešavanja potrošačkih sporova. Kao
                    trgovac smo dužni da vas obavestimo da smo po zakonu obavezni da učestvujemo u ovom
                    postupku. Vansudsko rešavanje potrošačkih sporova obavlja se na transparentan, efikasan, brz
                    i pravičan način pred telom za vansudsko rešavanje potrošačkih sporova. Ministarstvo
                    sačinjava listu tela i javno je objavljuje. Dostupna je na adresi
                    <a href="https://vansudsko.mtt.gov.rs/adrbodies" className="ms-2 link-secondary" target="_blank" rel="noreferrer">https://vansudsko.mtt.gov.rs/adrbodies</a>
                    <br /> <br />
                    Postupak pred telom može da pokrene potrošač samo ukoliko je prethodno izjavio
                    reklamaciju ili prigovor trgovcu. Potrošač protekom jedne godine od dana (bezuspešnog)
                    podnošenja reklamacije gubi pravo na podnošenje predloga za vansudsko rešavanje spora.
                    Vansudsko rešavanje potrošačkog spora može da traje najduže 90 dana od dana podnošenja
                    predloga.<br /> <br />
                    Vansudsko rešavanje potrošačkih sporova, ne primenjuje se, pored ostalog:<br />
                    - u potrošačkim sporovima koji su predmet Zakona o zaštiti potrošača, ako je vansudsko
                    rešavanje sporova uređeno posebnim zakonom, a naročito u oblasti pružanja
                    elektronskih komunikacionih usluga, poštanskih usluga, finansijskih usluga, osim
                    finansijskih pogodbi, usluga putovanja;<br />
                    - za rešavanje sporova po procedurama koje je ustanovio sam trgovac;<br />
                    - na neposredne pregovore između potrošača i trgovca;<br />
                    - na pokušaj mirenja strana povodom spora u parničnom postupku;<br />
                    - u postupcima koje je trgovac pokrenuo protiv potrošača.<br /><br />
                    Svaka stranka u postupku vansudskog rešavanja potrošačkog spora plaća svoje troškove
                    (troškovi zastupanja, putni troškovi i sl.). Rad tela za vansudsko rešavanje potrošačkog spora
                    je besplatan za stranke u postupku vansudskog rešavanja potrošačkog spora.
                    <br /><br />


                    <h5 className="oswald fw-bold">ODUSTANAK OD KUPOVINE</h5>
                    Više informacija o pravu potrošača na odustanak od ugovora možete pogledati
                    <a href={route("public.pravoNaOdustajanje")} className="ms-2 fw-bold link-secondary"
                       target="_blank" rel="noreferrer">OVDE</a>
                    <br /><br />
                    <h5 className="oswald fw-bold">PRIMEDBE I REKLAMACIJE NA RAD INTERNET PRODAVNICE www.horus.rs</h5>
                    Više informacija o reklamacijama možete pogledati
                    <a href={route("public.reklamacije")} className="ms-2 fw-bold link-secondary"
                       target="_blank" rel="noreferrer">OVDE</a>
                    <br /><br />
                    <h5 className="oswald fw-bold">POLITIKA PRIVATNOSTI</h5>
                    Više informacija o Politici privatnosti možete pogledati
                    <a href={route("public.politikaPrivatnosti")} className="ms-2 fw-bold link-secondary"
                       target="_blank" rel="noreferrer">OVDE</a>
                    <br /><br />
                    <h5 className="oswald fw-bold">POLITIKA KOLAČIĆA</h5>
                    Više informacija o politici kolačića možete pogledati
                    <a href={route("public.politikaKolacica")} className="ms-2 fw-bold link-secondary"
                       target="_blank" rel="noreferrer">OVDE</a>
                    <br /><br />
                    <h5 className="oswald fw-bold">ZAVRŠNE ODREDBE</h5>
                    Isporučilac zadržava pravo da može menjati ove uslove bez prethodnog obaveštavanja svojih potrošača. Svaki potrošač prilikom naručivanja proizvoda može proveriti trenutno važeće uslove.
                </>}
                {lang === 1 &&
                <>
                    <h5 className="oswald fw-bold">ХОРУС интернет продавница</h5>
                    Општи услови пословања ХОРУС интернет продавнице утврђени су условима куповине и продаје производа, које реализује Horus Wear doo (у даљем тексту: “Продавац”). Општи услови пословања ХОРУС интернет продавнице детаљније су утврђени по областима, којима можете приступити кликом на жељену област, а све у складу са важећим Законом о заштити потрошача и међународним кодексом електронског пословања. Општи услови пословања дефинишу делатност Интернет продавнице, кориснике права и пословни однос између продавца и потрашача.
                    <br /><br />
                    ИНТЕРНЕТ ПРОДАВНИЦА је информациони систем намењен представљању и продаји производа потрошачима. Њиме управља Horus Wear doo. Корисник је особа која користи наведени систем (у даљем тексту: “Потрошач” у Интернет продавници).
                    <br /><br />
                    ХОРУС ИНТЕРНЕТ ПРОДАВНИЦА омогућава Потрошачима коришћење услуга и садржаја своје странице, а права и обавезе Продавца и Потрошача су регулисана доле наведеним Условима коришћења. Услови коришћења се примењују на све садржаје и услуге странице www.horus.rs . Коришћењем било којег дела странице, сматра се да су Потрошачи упознати Условима коришћења ХОРУС интернет продавнице, као и да прихватају коришћење садржаја ове странице искључиво за личну употребу и на властиту одговорност.
                    <br /><br />
                    ХОРУС ИНТЕРНЕТ ПРОДАВНИЦА може ревидирати Услове коришћења у било којем тренутку, ажурирањем овог документа. Услови коришћења су обавезујући за Потрошаче, те саветујемо да се редовно посећују, ради прегледа евентуалних измена.
                    <br /><br />

                    <h5 className="oswald fw-bold">ПРОЦЕС ПОРУЧИВАЊА</h5>
                    Све информације о процесу куповине можете погледати на линку у наставку
                    <a href={route("public.kakoKupiti")} className="ms-2 fw-bold link-secondary" target="_blank" rel="noreferrer">КАКО КУПИТИ</a>
                    <br /><br />
                    <h5 className="oswald fw-bold">УСЛОВИ И НАЧИНИ ИСПОРУКЕ</h5>
                    Више информација о условима и начину испоруке можете погледати
                    <a href={route("public.usloviIsporuke")} className="ms-2 fw-bold link-secondary"
                       target="_blank" rel="noreferrer">ОВДЕ</a>
                    <br /><br />

                    <h5 className="oswald fw-bold">НАЧИН ПЛАЋАЊА</h5>
                    Потрошач плаћа вредност поруџбине и трошак испоруке приликом доставе поручене робе. Плаћање поузећем се врши искључиво у готовини, куриру приликом саме испоруке на наведену адресу. Потрошач креира поруџбеницу кликом на тастер ПОШАЉИ ПОРУЏБИНУ. ХОРУС интернет продавница преузима обавезу слања порученог/поручених артикала Потрошачу, уколико су доступни. Уколико поруџбеница није комплетна јер неких артикала нема на стању, Потрошач ће бити контактиран од стране Продавца у најкраћем року.
                    <br /><br />
                    <h5 className="oswald fw-bold">ЦЕНЕ У ХОРУС ИНТЕРНЕТ ПРОДАВНИЦИ</h5>
                    Све цене су исказане у динарима са укљученим ПДВ-ом. Продавац задржава право измене цена без претходног обавешавања купаца, ако није другачије наведено (у случају акција и специјалних попуста). Цене важе у тренутку креирања поруџбине и имају дејство и валидност и за наредни период. Наведене цене важе само за куповину у интернет продавници и могу да се разликују од цена у малопродајним објектима Продавца.
                    <br /><br />

                    <h5 className="oswald fw-bold">САОБРАЗНОСТ</h5>
                    Продавац је дужан да испоручи робу која је саобразна уговору и одговоран је за
                    несаобразност робе у уговору која се појави у року од 2 године од дана преласка ризика
                    на потрошача. Ако несаобразност настане у року од 6 месеци од дана преласка ризика
                    на потрошача, може се претпоставити да је несаобразност постојала у тренутку
                    преласка ризика на потрошача. Све трошкове који су неопходни да би се роба
                    саобразила уговору, а нарочито трошкове рада, материјала, преузимања и испоруке,
                    сноси Продавац.
                    <br /><br />


                    <h5 className="oswald fw-bold">ВАНСУДСКО РЕШАВАЊЕ ПОТРОШАЧКИХ СПОРОВА</h5>
                    Потрошачки спор може се решити поступком вансудског решавања потрошачких
                    спорова. Као трговац смо дужни да вас обавестимо да смо по закону обавезни да
                    учествујемо у овом поступку. Вансудско решавање потрошачких спорова обавља се на
                    транспарентан, ефикасан, брз и правичан начин пред телом за вансудско решавање
                    потрошачких спорова. Министарство сачињава листу тела и јавно је објављује.
                    Доступна је на адреси
                    <a href="https://mtt.gov.rs/tekst/2306/zastita-potrosaca.php" className="ms-2 link-secondary" target="_blank" rel="noreferrer">https://mtt.gov.rs/tekst/2306/zastita-potrosaca.php</a>
                    <br /> <br />
                    Поступак пред телом може да покрене потрошач само уколико је претходно изјавио
                    рекламацију или приговор трговцу. Потрошач протеком једне године од дана
                    (безуспешног) подношења рекламације губи право на подношење предлога за
                    вансудско решавање спора. Вансудско решавање потрошачког спора може да траје
                    најдуже 90 дана од дана подношења предлога.
                    <br /> <br />
                    Вансудско решавање потрошачких спорова, не примењује се, поред осталог:
                    <br />
                    - у потрошачким споровима који су предмет Закона о заштити потрошача, ако је
                    вансудско решавање спорова уређено посебним законом, а нарочито у области
                    пружања електронских комуникационих услуга, поштанских услуга,
                    финансијских услуга, осим финансијских погодби, услуга путовања;<br />
                    - за решавање спорова по процедурама које је установио сам трговац;<br />
                    - на непосредне преговоре између потрошача и трговца;<br />
                    - на покушај мирења страна поводом спора у парничном поступку;<br />
                    - у поступцима које је трговац покренуо против потрошача.<br /><br />
                    Свака странка у поступку вансудског решавања потрошачког спора плаћа своје
                    трошкове (трошкови заступања, путни трошкови и сл.). Рад тела за вансудско
                    решавање потрошачког спора је бесплатан за странке у поступку вансудског решавања
                    потрошачког спора.
                    <br /><br />


                    <h5 className="oswald fw-bold">ОДУСТАНАК ОД КУПОВИНЕ</h5>
                    Више информација о праву потрошача на одустанак од уговора можете погледати
                    <a href={route("public.pravoNaOdustajanje")} className="ms-2 fw-bold link-secondary"
                       target="_blank" rel="noreferrer">ОВДЕ</a>
                    <br /><br />

                    <h5 className="oswald fw-bold">ПРИМЕДБЕ И РЕКЛАМАЦИЈЕ НА РАД ИНТЕРНЕТ ПРОДАВНИЦЕ WWW.HORUS.RS</h5>
                    Више информација о рекламацијама можете погледати
                    <a href={route("public.reklamacije")} className="ms-2 fw-bold link-secondary"
                       target="_blank" rel="noreferrer">ОВДЕ</a>
                    <br /><br />
                    <h5 className="oswald fw-bold">ПОЛИТИКА ПРИВАТНОСТИ</h5>
                    Више информација о Политици приватности можете погледати
                    <a href={route("public.politikaPrivatnosti")} className="ms-2 fw-bold link-secondary"
                       target="_blank" rel="noreferrer">ОВДЕ</a>
                    <br /><br />
                    <h5 className="oswald fw-bold">ПОЛИТИКА КОЛАЧИЋА</h5>
                    Више информација о политици колачића можете погледати
                    <a href={route("public.politikaKolacica")} className="ms-2 fw-bold link-secondary"
                       target="_blank" rel="noreferrer">ОВДЕ</a>
                    <br /><br />
                    <h5 className="oswald fw-bold">ЗАВРШНЕ ОДРЕДБЕ</h5>
                    Испоручилац задржава право да може мењати ове услове без претходног обавештавања својих потрошача. Сваки потрошач приликом наручивања производа може проверити тренутно важеће услове.

                </>}
           </div>
        </>
    )
}
export default UsloviKoriscenja;