import React, {useState} from "react";
import {useQuery} from "@apollo/client";
import Loader from "../../../../app/Loader";
import GraphQLErrorMessage from "../../../../app/GraphQLErrorMessage";
import {GET_BLOGS, GET_BLOGS_QUERY} from "../../graphql/blog";
import {widget} from "../../../modulesCompiler";
import AddBlockForm from "./AddBlockForm";
import BlogItem from "./BlogItem";



function CmsBlogs() {
    const [search] = useState(undefined);
    const { data, loading, error, variables, refetch, previousData } =  useQuery(GET_BLOGS, {
        variables: {first: 40, page: 1, search},
        notifyOnNetworkStatusChange: true,
    });

    if(loading && !previousData) return <Loader />
    if(error)   return <GraphQLErrorMessage error={error} />
    let holder = data || previousData;
    const blogs = holder ? holder[GET_BLOGS_QUERY].data : [];
    const paginatorInfo = holder ? holder[GET_BLOGS_QUERY].paginatorInfo : {};

    return (
        <div className="container">
            <div className="mb-3">
                <AddBlockForm refetch={refetch} />
            </div>

            <div className="mb-3 row">
                <div className="col-md-6">
                    <input value={variables.search || ""} name="search"
                           onChange={(e) => refetch({search: e.target.value || undefined, page: 1})}
                           type="text" placeholder="Pretraga..." className="form-control" />
                </div>
                <div className="col-md-3">

                </div>
                <div className="col-md-3 text-end">
                    {blogs.length > 0 &&
                    widget("cms.Pagination", {paginatorInfo: paginatorInfo, refetch: refetch})}
                </div>
            </div>

            {blogs.length === 0 &&
            <div className="p-5 text-center"> Prazna pretraga </div>}

            {blogs.map(item => {
                return <div key={item.id} className="bg-white mb-3 shadow-sm p-3 list_item">
                    <BlogItem item={item} refetch={refetch} />
                </div>
            })}

            <div className="mb-3 text-end">
                {blogs.length > 0 &&
                widget("cms.Pagination", {paginatorInfo: paginatorInfo, refetch: refetch})}
            </div>

        </div>
    )
}

export default CmsBlogs;
