import {gql} from "@apollo/client";
import {STORAGE_FRAGMENT} from "../../storage/graphql/StorageFragment";


export const BLOG_FRAGMENT = gql`
    ${STORAGE_FRAGMENT}
    fragment BlogFragment on Blog {
        id
        title
        titles
        content
        contents
        description
        descriptions
        created_at
        image {
            ...StorageFragment
        }
    }
`

export const CREATE_BLOCK = gql`
    ${BLOG_FRAGMENT}
    mutation ($title: String!)  {
        createBlog(title: $title) {
            ...BlogFragment
        }
    }
`

export const GET_BLOG_QUERY = "getBlog";
export const GET_BLOG = gql`
    ${BLOG_FRAGMENT}
    query($id: ID!) {
        getBlog(id: $id) {
            ...BlogFragment
        }
    }
`

export const GET_BLOGS_QUERY = "getBlogs";
export const GET_BLOGS = gql`
    ${BLOG_FRAGMENT}
    query($first: Int, $page: Int, $search: String) {
        getBlogs(first: $first, page: $page, search: $search) {
            data {
                ...BlogFragment
            }
            paginatorInfo {
                currentPage
                lastPage
                count
                total
            }
        }
    }
`

export const UPDATE_BLOG = gql`
    ${BLOG_FRAGMENT}
    mutation ($id: ID!, $image_id: ID, $title: String, $content: String, $description: String)  {
        updateBlog(id: $id, image_id: $image_id, title: $title, content: $content, description: $description) {
            ...BlogFragment
        }
    }
`

export const DELETE_BLOG = gql`
    ${BLOG_FRAGMENT}
    mutation ($id: ID!) {
        deleteBlog(id: $id) {
            ...BlogFragment
        }
    }
`