import {Link} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {widget} from "../../modulesCompiler";


function Banner({banner, className, overlay = true, load=true}) {
    const [loaded, setLoaded] = useState(false);
    const [visible, setVisible] = useState(load);
    useEffect(() => {
        if(!load) return false;
        if(!visible)    {
            setVisible(true);
        }
    }, [load]);

    if(!banner.active) return null;


    let aspect = "37%";
    if(banner?.image?.width && banner?.image?.height)   {
        aspect = ((banner?.image?.height*100) / banner?.image?.width) + "%";
    }

    let width;
    if(window.outerHeight)   width = window.outerWidth;
    else                     width = document.body.clientWidth;

    const image = <>
        {!loaded && <div className="img_overlay bg-light" style={{paddingTop: aspect}} />}
        {visible &&
        <>
            {width > 768
                ? <img
                    className={`${className ? className : ""} ${loaded ? "d-none d-md-block" : "d-none"} img-fluid`}
                    src={widget("storage.image")(banner.image, true)}
                    onLoad={() => setLoaded(true)}
                    alt={banner.alt}  />
                :<img
                    className={`${className ? className : ""} ${loaded ? "d-md-none" : "d-none"} img-fluid`}
                    src={widget("storage.image", banner.mobile_image)}
                    onLoad={() => setLoaded(true)}
                    alt={banner.alt} />}
        </>}
    </>

    if(banner.link)     {
        if(banner.blank)        {
            return <a href={banner.link} target="_blank" rel="noreferrer">
                {overlay
                    ? <div className="img_overlay h-100">
                        {image}
                    </div>
                    : image}
            </a>
        }
        return <Link to={banner.link}>
            <div className="h-100">
                {overlay
                    ? <div className="img_overlay h-100">
                        {image}
                    </div>
                    : image}
            </div>
        </Link>
    }
    return image;
}
export default Banner;