import React from "react";
import {trans, widget} from "../../../modulesCompiler";
import useGetSetting from "../../../settings/widgets/useGetSetting";


function Places() {
    const kragujevac = useGetSetting("contact kragujevac");

    return (
        <div className="mb-6">
            {widget("public.Breadcrumbs", {links: [{trans: trans("prodajna mesta")}]})}

            <div className="container">
                {widget("design.BlocksRender", {code: "places"})}
            </div>
        </div>

    )
}
export default Places;