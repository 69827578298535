import OrderRender from "./OrderRender";
import {useMutation, useQuery} from "@apollo/client";
import {GET_ORDER_BY_PATH, GET_ORDER_BY_PATH_QUERY, UPDATE_ORDER} from "../../graphql/order";
import {useEffect} from "react";
import Loader from "../../../../app/Loader";
import GraphQLErrorMessage from "../../../../app/GraphQLErrorMessage";



function Order({item}) {
    const [update] = useMutation(UPDATE_ORDER);

    const {data, loading, error} = useQuery(GET_ORDER_BY_PATH, {
        variables: {path: item.path},
        fetchPolicy: 'network-only',
    });

    useEffect(() => {
        if(!item.seen) {
            update({variables: {id: item.id, seen: true}});
        }
    }, [item, update]);

    if(loading) return <Loader />
    if(error)   return <GraphQLErrorMessage error={error} />
    const order = data[GET_ORDER_BY_PATH_QUERY];
    if(!order) return 'nema porudzbine';

    return  (
        <div className="p-3 pt-0">
            <h3 className="mb-4 text-center">Porudžbina #{order.code} </h3>
            <div className="p-3">
                <OrderRender order={order} />
            </div>
        </div>
    )
}
export default Order;