import {trans, widget} from "../../../modulesCompiler";
import React from "react";


function PravoNaOdustajanje() {
    const lang = widget("lang.useGetLang", null);

    return (
        <>
            {widget("public.Breadcrumbs", {links: [{trans: trans("pravo na odustajanje")}]})}
            <div className="container mb-6">
                {lang === 0 &&
                <>
                    <h1 className="oswald fw-bold text-uppercase h3">KORISNIČKI SERVIS - PRAVO NA ODUSTAJANJE/POVRAĆAJ ROBE </h1>
                    <br/>
                    Potrošač ima pravo da odustane od ugovora zaključenog na daljinu u roku od 14 dana od trenutka kada roba dospe u vlasništvo Potrošača, odnosno trećeg lica koje je odredio Potrošač, a koje nije prevoznik, bez navođenja razloga i dodatnih troškova (u daljem tekstu: odustanak od ugovora).
                    <br /><br/>
                    Potrošač ostvaruje pravo na odustanak od ugovora izjavom koju može dati na posebnom obrascu za odustanak od ugovora zaključenog na daljinu, koji dobija u paketu sa isporučenim artiklima, odnosno izvan poslovnih prostorija ili na drugi nedvosmislen način (u daljem tekstu: obrazac za odustanak).
                    <br /><br/>
                    Izjava o odustanku od ugovora kod ugovora zaključenog na daljinu smatra se blagovremenom ukoliko je uredno popunjena, potpisana i poslata Prodavcu poštom ili kurirskom službom zajedno sa robom, na adresu sedišta kompanije, u roku iz stava 1. ovog člana.
                    <br /><br/>
                    Izjava o odustanku od ugovora proizvodi pravno dejstvo od dana kada je poslata Prodavcu.
                    <br /><br/>
                    Potrošač snosi teret dokazivanja da je postupio u skladu sa odredbama ovog člana, radi ostvarivanja prava na odustanak od ugovora.
                    <br /><br/>
                    Istekom roka od 14 dana od momenta kada roba dospe u vlasništvo Potrošača, odnosno trećeg lica koje je odredio Potrošač, a koje nije prevoznik, prestaje pravo Potrošača na odustanak od ugovora.
                    <br /><br/>
                    Potrošač snosi isključivo direktne troškove vraćanja robe, osim u slučaju kada je razlog povraćaja greška Prodavca. Potrošač je isključivo odgovoran za umanjenu vrednost robe, koja nastane kao posledica rukovanja robom na način koji nije adekvatan, odnosno prevazilazi ono što je neophodno, kako bi ste ustanovili priroda, karakteristike i funkcionalnost robe.
                    <br /><br/>
                    U slučaju korišćenja prava na odustanak od ugovora Prodavac je dužan da izvrši povraćaj uplata koje je primio od Potrošača bez odlaganja, a najkasnije u roku od 14 dana od dana kada je primio robu i obrazac za odustanak od ugovora. Prodavac vrši povraćaj koristeći elektronsko sredstvo plaćanja i nije moguće vratiti gotovinu na adresu Potrošača, već isključivo uplatom na tekući račun Potrošača. Tekući račun potrošača mora biti dostavljen na obrascu/izjavi o odustanku.
                    <br /><br/>
                    Šta se ne smatra odustankom od ugovora u smislu čl. 28 Zakona o zaštiti potrošača:
                    <br /><br/>
                    Ukoliko Potrošač nakon što je naručio određeni artikal putem HORUS Internet prodavnice i poružbenica mu je potvrđena od strane HORUS Internet prodavnice ne preuzme pošiljku, nakon dva pokušaja dostavljanja od strane kurirske službe sa kojom Horus Wear doo ima zaključen Ugovor o pružanju ekspresnih usluga u unutrašnjem saobraćaju, smatra se da je Potrošač odustao od ugovora u smislu čl. 28 Zakona o zaštiti potrošača. U skladu sa Zakonom o obligacionim odnosima, ukoliko kupac ne preuzme naručene artikle, ugovor o prodaji se raskida zbog povrede ugovorne obaveze preuzimanja stvari i isplate kupoprodajne cene od strane kupca, te prodavac ima pravo na naknadu štete koju zbog toga trpi, prema opštim pravilima o naknadi štete. Obaveza naknade štete se smatra dospelom od trenutka nastanka štete. Dakle, u slučaju nepreuzimanja pošiljke, Potrošač je dužan da Prodavcu naknadi nastalu štetu, u visini troškova transporta.
                    <br /><br/>
                    U cilju izvršenja povrata poručenog ili zamene artikala, Potrošači treba da Prodavca kontaktiraju putem
                    telefona <a href="tel:+381654321023" className="link-secondary">(+381)654321023</a> ili slanjem e-mail-a sa svojim ličnim podacima (ime i prezime i kontakt telefon)
                    na e-mail adresu <a href="mailto:shop@horus.rs" className="link-secondary">shop@horus.rs</a>
                    <br /><br/>
                    Obrazac/izjavu o odustanku možete preuzeti <a href="/obrazac-izjava-o-odustanak-od-ugovora-na-daljinu.pdf"
                    target="_blank" rel="noreferrer" className="link-secondary">OVDE</a>.
                </>}

                {lang === 1 &&
                <>
                    <h1 className="oswald fw-bold text-uppercase h3">КОРИСНИЧКИ СЕРВИС - ПРАВО НА ОДУСТАЈАЊЕ/ПОВРАЋАЈ РОБЕ</h1>
                    <br/>
                    Потрошач има право да одустане од уговора закљученог на даљину у року од 14 дана од тренутка када роба доспе у власништво Потрошача, односно трећег лица које је одредио Потрошач, а које није превозник, без навођења разлога и додатних трошкова (у даљем тексту: одустанак од уговора).
                    <br /><br/>
                    Потрошач остварује право на одустанак од уговора изјавом коју може дати на посебном обрасцу за одустанак од уговора закљученог на даљину, који добија у пакету са испорученим артиклима, односно изван пословних просторија или на други недвосмислен начин (у даљем тексту: образац за одустанак).
                    <br /><br/>
                    Изјава о одустанку од уговора код уговора закљученог на даљину сматра се благовременом уколико је уредно попуњена, потписана и послата Продавцу поштом или курирском службом заједно са робом, на адресу седишта компаније, у року из става 1. овог члана.
                    <br /><br/>
                    Изјава о одустанку од уговора производи правно дејство од дана када је послата Продавцу.
                    <br /><br/>
                    Потрошач сноси терет доказивања да је поступио у складу са одредбама овог члана, ради остваривања права на одустанак од уговора.
                    <br /><br/>
                    Истеком рока од 14 дана од момента када роба доспе у власништво Потрошача, односно трећег лица које је одредио Потрошач, а које није превозник, престаје право Потрошача на одустанак од уговора.
                    <br /><br/>
                    Потрошач сноси искључиво директне трошкове враћања робе, осим у случају када је разлог повраћаја грешка Продавца. Потрошач је искључиво одговоран за умањену вредност робе, која настане као последица руковања робом на начин који није адекватан, односно превазилази оно што је неопходно, како би сте установили природа, карактеристике и функционалност робе.
                    <br /><br/>
                    У случају коришћења права на одустанак од уговора Продавац је дужан да изврши повраћај уплата које је примио од Потрошача без одлагања, а најкасније у року од 14 дана од дана када је примио робу и образац за одустанак од уговора. Продавац врши повраћај користећи електронско средство плаћања и није могуће вратити готовину на адресу Потрошача, већ искључиво уплатом на текући рачун Потрошача. Текући рачун потрошача мора бити достављен на обрасцу/изјави о одустанку.
                    <br /><br/>
                    Шта се не сматра одустанком од уговора у смислу чл. 28 Закона о заштити потрошача:
                    <br /><br/>
                    Уколико Потрошач након што је наручио одређени артикал путем ХОРУС интернет продавнице и поружбеница му је потврђена од стране ХОРУС Интернет продавнице не преузме пошиљку, након два покушаја достављања од стране курирске службе са којом Horus Wear doo има закључен Уговор о пружању експресних услуга у унутрашњем саобраћају, сматра се да је Потрошач одустао од уговора у смислу чл. 28 Закона о заштити потрошача. У складу са Законом о облигационим односима, уколико купац не преузме наручене артикле, уговор о продаји се раскида због повреде уговорне обавезе преузимања ствари и исплате купопродајне цене од стране купца, те продавац има право на накнаду штете коју због тога трпи, према општим правилима о накнади штете. Обавеза накнаде штете се сматра доспелом од тренутка настанка штете. Дакле, у случају непреузимања пошиљке, Потрошач је дужан да Продавцу накнади насталу штету, у висини трошкова транспорта.
                    <br /><br/>
                    У циљу извршења поврата порученог или замене артикала, Потрошачи треба да Продавца контактирају путем
                    телефона  <a href="tel:+381654321023" className="link-secondary">(+381)654321023</a> или
                    слањем е-маил-а са својим личним подацима (име и презиме и контакт телефон)
                    на е-маил адресу <a href="mailto:shop@horus.rs" className="link-secondary">shop@horus.rs</a> .
                    <br /><br/>
                    Образац/изјаву о одустанку можете преузети <a href="/obrazac-izjava-o-odustanak-od-ugovora-na-daljinu.pdf"
                       target="_blank" rel="noreferrer" className="link-secondary">ОВДЕ</a>.
                </>}

                {lang === 2 &&
                <>
                    <h1 className="oswald fw-bold text-uppercase h3">CUSTOMER SERVICE - RIGHT OF WITHDRAWAL / REFUND</h1>
                    <br/>

                    Please be aware that according to the Consumer protection act ("Off. Herald of RS", Nos. 62/2014, 6/2016 - other act and 44/2018 - other act), online shopping through our website www.horus.rs is considered distance selling.
                    <br /><br/>
                    In the case of distance selling, the Law provides for the right of the Buyer, considered the Consumer (i.e. a natural person who purchases an item for his/her individual needs, rather than for conducting a professional activity), to cancel the purchase contract within 14 days of delivery of the product. When cancelling the order, the Buyer may but is not obliged to state the reason for cancellation.
                    <br /><br/>
                    The Contract Cancellation Form/Statement produces legal effects as of the day it was sent to the Trader.
                    <br /><br/>
                    All returns will be happily accepted in cases that the item doesn’t fit right according to the sizing instructions provided, in case the item has any manufacturing faults and damages, or simply if you don’t like it anymore, within 14 days from your purchase. It is not possible to return the product upon expiry of the period of 14 days from the day the Cancellation Form was sent. Items that are damaged in transport are the responsibility of the selected courier service. HORUS WEAR doo will not be held responsible for such misconduct of it’s goods.
                    <br /><br/>
                    Please contact us by sending an e-mail to the following address: shop@horus.rs. The necessary information our team will request from you at this stage is your order ID .
                    <br /><br/>
                    Following up to the above explained procedure, our team will proceed to send you the detailed instructions to follow so that you can have a refund.
                    <br /><br/>
                    We don’t accept returns if the item initially ordered has already been worn. This being said, it is mandatory that you return the item as new and undamaged as it has been delivered, in its original packaging.
                    <br /><br/>
                    After sending a request to return the item, the item should be packed and sent via courier service along with the payment confirmation/receipt received after you have ordered the item online. As soon as our team receives the item you have returned and inspects the item’s condition, we will proceed with the process of a refund.. You can deliver the item in person but only with the payment confirmation/receipt and the Request for Return form. The full address for returning the articles in person is Kralja Milana 22-24, Belgrade – Stari grad 8.00 AM until 4.00 PM on business days (Mon-Fri).
                    <br /><br/>

                    <h5 className="oswald fw-bold">STEPS TO SUCCESSFUL RETURN</h5>
                    Contact us by sending am e-mail to  <a href="mailto:shop@horus.rs" className="link-secondary">shop@horus.rs</a>
                    <br /><br/>
                    Follow our return instructions  <br /><br/>

                    Send the item in question via courier service or deliver in person at the following address: Kralja Milana 22-24, Belgrade – Stari grad, 8.00 AM until 4.00 PM on business days (Mon-Fri).
                    <br /><br/>
                    You can download the Request for Cancellation Form  <a href="/obrazac-za-odustanak-od-ugovora-na-daljinu-ENG.pdf"
                                   target="_blank" rel="noreferrer" className="link-secondary">HERE</a>.
                    <br /><br/>



                    <h5 className="oswald fw-bold">DELIVERY EXPENSES</h5>
                    The cost of delivery is covered by the customer, unless the buyer receives damaged or wrong items. If the customer has made an error while purchasing the item, they cover the delivery expenses of the courier service when sending the item back.
                    <br /><br/>
                    By confirming your order and clicking the CONFIRM your order button, you agree with the return/exchange terms and conditions as stated above.
                    <br /><br/>
                    Return and refund costs will be handled by the Buyer, except in cases where the Buyer has received a defective or wrong item.
                    <br /><br/>
                    <h5 className="oswald fw-bold">REFUND</h5>
                    In case of customer cancelling the order contract, the customer has the right to receive the refund or exchange their order for another item. Payment refunds are effective after HORUS WEAR doo receives the item, inspects and confirms that the item is not damaged.
                    <br /><br/>
                    HORUS WEAR doo is responsible of making the refund equal to the amount that the customer has paid as per the contract with no delays, no later than 14 days after receiving the order contract cancellation request and the consumer sending the item back.
                    <br /><br/>
                    Costs of shipping the item back and refund taxes are covered by the customer in all cases except if a wrong item has been sent or if the item has been sent as damaged.
                    <br /><br/>
                    HORUS WEAR doo hereby confirms that we will make the refund transfer as soon as we receive the item ordered back from the customer.
                    <br /><br/>
                    <h5 className="oswald fw-bold">REFUND PROCEDURE</h5>
                    Please contact us by sending an email at <a href="mailto:shop@horus.rs" className="link-secondary">shop@horus.rs</a> specifying any of your unique data - your invoice/dispatch note number, name and surename .
                    <br /><br/>
                    As soon as our team receive the necessary data, we will prepare the necessary documentation and send it along with the refund procedure instructions to the e-mail address you entered when placing the order.
                    <br /><br/>
                    In case of returns or refunds the Seller bank will conduct the refund to the bank account of the Buyer.
                    <br /><br/>
                    The ordered items may not be sent to the addresses indicated on the invoice/dispatch note without first contacting our Online Shop operators by sending an email address specified above to discuss and agree on the return.
                    <br /><br/>
                    IMPORTANT STUFF: Until it’s received by HORUS WEAR doo, the condition of the item you are returning is your responsibility. HORUS WEAR doo will not be held responsible for any goods damaged in transport, or any other damage applied to the goods before their arrival. We will not replace items that have clearly been damaged from use, that are torn, stretched or stained. All products must be returned in their departure condition (from us) with the package case intact. If in the meantime we run out of stock of the item that has been returned, we will offer you any of the products from our store within the similar price range of the item that’s the subject of the return.

                </>}
                </div>
        </>
    )
}
export default PravoNaOdustajanje;