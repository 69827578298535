import BestSellByProduct from "./BestSellByProduct";
import MostAddedToBasket from "./MostAddedToBasket";
import MostViewedProducts from "./MostViewedProducts";


function Statistics() {

    return (
        <div className="container-fluid">
            <div className="row mb-5">
                <div className="col-md-6">
                    <h5 className="mb-3">Proizvodi sa najviše pregleda</h5>
                    <div className="bg-white p-3 shadow-sm">
                        <MostViewedProducts />
                    </div>

                </div>
                <div className="col-md-6">
                    <h5 className="mb-3">Najviše dodatih proizvoda u korpu</h5>
                    <div className="bg-white p-3 shadow-sm">
                        <MostAddedToBasket />
                    </div>
                </div>
            </div>

            <div className="row mb-5">
                <div className="col-md-6">
                    <h5 className="mb-3">Proizvodi sa najvećom prodajom</h5>
                    <div className="bg-white p-3 shadow-sm">
                        <BestSellByProduct />
                    </div>
                </div>
                <div className="col-md-6">

                </div>
            </div>


        </div>
    )
}
export default Statistics;