import {gql} from "@apollo/client";
import {PRODUCT_FRAGMENT} from "../../products/graphql/ProductFragment";
import {FILTER_FRAGMENT} from "../../filters/graphql/filter";


export const ORDER_FRAGMENT = gql`
    fragment OrderFragment on Order {
        id
        user_id
        seen
        code
        path
        status
        full_price
        promo_code
        promo_code_discount
        currency
        name
        lastname
        email
        phone
        address
        zip
        city
        country
        message
        delivery_method
        payment_method
        created_at
    }
`

export const ORDER_PRODUCT_FRAGMENT = gql`
      ${PRODUCT_FRAGMENT}
    fragment OrderProductFragment on OrderProduct {
       id
       qty
       price
       product {
        ...ProductFragment
       }       
    }
`

export const GET_ORDERS_QUERY = "getOrders";
export const GET_ORDERS = gql`
    ${ORDER_FRAGMENT}
    ${ORDER_PRODUCT_FRAGMENT}
    ${FILTER_FRAGMENT}
    query ($first: Int, $page: Int, $search: String, $seen: Boolean, $status: Boolean) {
        getOrders(first: $first, page: $page, search: $search, seen: $seen, status: $status) {
            data    {
                ...OrderFragment
                orderProducts {
                    ...OrderProductFragment
                    filters {
                        ...FilterFragment
                        parentFilter {
                            ...FilterFragment
                        }
                    }
                }
            }
            paginatorInfo {
                currentPage
                lastPage
                count
                total
            }
        }
    }
`

export const GET_ORDER_BY_PATH_QUERY = "getOrderByPath";
export const GET_ORDER_BY_PATH = gql`
    ${ORDER_FRAGMENT}
    ${PRODUCT_FRAGMENT}
    ${ORDER_PRODUCT_FRAGMENT}
    ${FILTER_FRAGMENT}
    query ($path: String!) {
         getOrderByPath(path: $path) {
              ...OrderFragment
               orderProducts {
                    ...OrderProductFragment
                       filters {
                        ...FilterFragment
                        parentFilter {
                            ...FilterFragment
                        }
                    }
                }
         }
    }
`

export const PLACE_ORDER_MUTATION = "placeOrder";
export const PLACE_ORDER = gql`
    mutation ($basket: String!, $name: String!, $lastname: String!, $email: String!, $phone: String!,
        $address: String!, $zip: String!, $city: String!, $payment_method: Int!, $message: String,
        $currency: String!, $country: String!, $promo: String, $newsletter: String)  {
        placeOrder(basket: $basket, name: $name, lastname: $lastname, email: $email, phone: $phone,
        address: $address, zip: $zip, city: $city, payment_method: $payment_method, message: $message,
        currency: $currency, country: $country, promo: $promo, newsletter: $newsletter) {
            error
            path
        }
    }
`

export const UPDATE_ORDER = gql`
    ${ORDER_FRAGMENT}
    mutation ($id: ID!, $seen: Boolean, $status: Int)  {
        updateOrder(id: $id, seen: $seen, status: $status) {
            ...OrderFragment
        }
    }
`
