import {useQuery} from "@apollo/client";
import {findBySlug} from "../helpers";
import {GET_CATEGORIES, GET_CATEGORIES_QUERY} from "../graphql/categories";


function useGetBreadcrumbsBySlug(categorySlug) {
    const { data, loading } =  useQuery(GET_CATEGORIES, {
        variables: {parent: null}
    });
    if(loading) return {breadcrumbs: [], categories:[], loading};
    if(!data) return {breadcrumbs: [], categories:[], loading};
    const categories = data[GET_CATEGORIES_QUERY];
    let breadcrumbs = findBySlug(categories, categorySlug);
    return {breadcrumbs, categories, loading};
}
export default useGetBreadcrumbsBySlug;