import React, {Suspense} from "react";

const PublicLayout = React.lazy(() => import("../views/layout/PublicLayout"));
//import PublicLayout from "../views/layout/PublicLayout";

const PublicMiddleware = ({ component: Component, route, ...rest }) => {
    return <Suspense fallback={<div> </div>}>
        <PublicLayout {...{component: Component, ...rest}} />
    </Suspense>
};

export default PublicMiddleware;
