import React, {useState} from "react";
import {useMutation} from "@apollo/client";
import {PlusCircle, Trash2} from "react-feather";
import Nestable from "react-nestable";
import {ORDER_ITEMS} from "../../../_cms/graphql/items";
import {widget} from "../../../modulesCompiler";
import {DELETE_BLOCK_PRODUCT, UPSERT_BLOCK_PRODUCT} from "../../graphql/product";
import toast from "react-hot-toast";


function Products({block, refetch}) {
    const [productsDrawer, setProductsDrawer] = useState(false);
    const [deleteBlockProduct] = useMutation(DELETE_BLOCK_PRODUCT);
    const [upsertProduct] = useMutation(UPSERT_BLOCK_PRODUCT);
    const [orderItems] = useMutation(ORDER_ITEMS);

    const selectedProducts = (items) => {
        for(let i=0; i<items.length; i++)       {
            upsertProduct({variables: {block_id: block.id, product_id: items[i].id}}).then(response => {
                refetch();
            }).catch(error => {
                toast.error(error.message);
            });
        }
    }

    const productsChangedOrder = (items) => {
        const holder = items.items.map(p => {
            p.id = p.block.id;
            return p;
        })
        orderItems(
            {variables: {items: JSON.stringify(holder), table: "block_products"}}
        ).then(response => {
            toast.success("Sacuvano!");
            refetch();
        }).catch(error => {
            toast.error(error.message);
        });
    }

    const deleteProduct = (id) => {
        if(!window.confirm("Zelite da obrisete proizvod?"))  {
            return false;
        }
        deleteBlockProduct({variables: {id: id}}).then(response => {
            toast.success("Obrisano!");
            refetch();
        }).catch(error => {
            toast.error(error.message);
        });
    }
    return  (
        <div className="p-3">
            <div className="border p-3">
                <div className="text-black-50 mb-3">
                    Dodajte rucno proizvode u carousel.
                </div>
                 <div className="mb-3">
                    <button onClick={() => setProductsDrawer(true)} className="btn btn-success">
                        <PlusCircle size={16} /> Dodajte proizvode
                    </button>
                </div>

                {widget("products.ProductsDrawer", {
                    open: productsDrawer,
                    close: () => setProductsDrawer(false),
                    callback: selectedProducts
                })}

                <Nestable
                    className="inline align-top"
                    collapsed={false}
                    maxDepth={1}
                    onChange={productsChangedOrder}
                    handler={widget("cms.DragHandler")}
                    items={block.products}
                    renderItem={(props) => <div key={props.item.id} className="">
                        <div className="d-flex justify-content-between mb-2">
                            {props.handler}
                            <button onClick={() => deleteProduct(props.item.block.id)}
                                className="btn btn-sm btn-light">
                                <Trash2 />
                            </button>
                        </div>
                        {widget("products.ProductRender", props)}
                    </div>} />
            </div>
        </div>
    )
}
export default Products;