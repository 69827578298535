import GraphQLErrorField from "./GraphQLErrorField";
import {useEffect, useState} from "react";
import {useMutation} from "@apollo/client";
import GraphQLErrorMessage from "./GraphQLErrorMessage";
import toast from "react-hot-toast";
import {widget} from "../modules/modulesCompiler";

function CmsUpdateField({id, element, name, value, checkValue, MUTATION, required, lang, width,
             placeholder, nullable, className, onTrue, onFalse, price,  refetch, type, extend = {}}) {

    const defaultValue = value || "";
    const [field, setField] = useState(defaultValue);
    const [update, {error, loading}] = useMutation(MUTATION);

    useEffect(() => {
        const defaultValue = value || "";
        setField(defaultValue);
    }, [value]);

    const submit = (e) => {
        e.preventDefault();
        save(field);
    }
    const submitCheckbox = (e) => {
        let value = e.target.checked ? true : null;
        if(checkValue)  {
            value =  e.target.checked ? checkValue : null;
        }
        save(value);
        setField(value);
    }
    const save = (value) => {
        if(nullable && (value === "" || !value))    {
            value = null;
        }
        if(price && value)        {
            value = parseFloat(value.replace(" ", "").replace(",", "."));
        }
        if(type === "number")   {
            value = parseInt(value);
        }
        let form = {id: id, [name]: value};
        if(!id) {
            form = {...extend, [name]: value};
        }
        let send = {variables: form};
        if(lang !== undefined)  {
            send = {...send, context: {
                    headers: {
                        "LangMutation": lang,
                    }
                }};
        }
        update(send).then(response => {
            if(refetch) {
                refetch();
            }
            toast.success("Sačuvano!");
        }).catch(error => {});
    }
    const text = () => {
        if(element !== "text") return null;
        return  <div className="input-group">
            {width
                ? <div style={{width: width}}>
                    <input onChange={(e) => setField(e.target.value)}
                           type={type || "text"} name={name} value={field} placeholder={placeholder}
                           className="form-control" required={required} />
                </div>
                : <input onChange={(e) => setField(e.target.value)}
                       type={type || "text"} name={name} value={field} placeholder={placeholder}
                       className="form-control" required={required} />}
            {submitButton()}
        </div>
    }
    const textarea = () => {
        if(element !== "textarea") return null;
        return  <>
            <textarea onChange={(e) => setField(e.target.value)}
                   name={name} value={field} placeholder={placeholder}
                   className="form-control mb-1" required={required} />
            {submitButton()}
        </>
    }
    const editor = () => {
        if(element !== "editor") return null;
        return  <>
            <div className="bg-white p-3">
                {widget("textEditor.Editor",
                    {onSave: (state) => save(JSON.stringify(state)), content: JSON.parse(value)})}
                <GraphQLErrorMessage error={error} />
            </div>
        </>
    }

    const checkbox = () => {
        if(element !== "checkbox") return null;
        return <label className={`btn btn-light btn-sm ${className}`}>
            <input type="checkbox" value={checkValue || 1} onChange={submitCheckbox}
                   checked={checkValue ? field === checkValue : !!field} /><span />
            <span className="ms-2"> {field ? onTrue : onFalse} </span>
        </label>
    }
    const submitButton = () => {
        if(field === defaultValue) return null;
        return <div className="input-group-append">
            <button disabled={loading} type="submit" className="btn btn-success"> Sačuvajte </button>
        </div>
    }
    return (
        <>
            {editor()}
            <form onSubmit={submit} className={className ? className : ""}>
                {checkbox()}
                {text()}
                {textarea()}
                <GraphQLErrorField error={error} field={name} />
                <GraphQLErrorMessage error={error} />
            </form>
        </>

    )
}
export default CmsUpdateField;