import ProductRender from "./widgets/ProductRender";
import ProductsCarousel from "./widgets/ProductsCarousel";
import Products from "./views/products/Products";
import ProductsDrawer from "./widgets/ProductsDrawer";
import LoadProductsCarousel from "./widgets/LoadProductsCarousel";
import Shop from "./views/shop/Shop";
import slugify from "slugify";
import ShopProduct from "./views/shopProduct/ShopProduct";
import Product from "./views/product/Product";
import SearchModal from "./widgets/SearchModal";

const shopRoot = ["/prodavnica", "/продавница", "/shop"];
const actionRoot = ["/akcija", "/акција", "/action"];
const shopProductRoot = ["/proizvod", "/производ", "/product"];
const productRoot = "/cms/proizvod";

const routes = {
    product: {
        path: `${productRoot}/:id/:slug?`,
        component: Product,
        middleware: 'cms.AdminAuthenticatedMiddleware',
        compile: (item) => {
            if (!item) return productRoot;
            return `${productRoot}/${item.id}/${slugify(item.name)}`;
        },
    },

    products: {path: "/cms/proizvodi",  component: Products, middleware: 'cms.AdminAuthenticatedMiddleware'},
    shop: {
        path: [`${shopRoot[0]}/:cat1?/:cat2?/:cat3?`, `${shopRoot[1]}/:cat1?/:cat2?/:cat3?`, `${shopRoot[2]}/:cat1?/:cat2?/:cat3?`],
        component: Shop,
        middleware: 'public.PublicMiddleware',
        seo: {key: "products", title: "SHOP strana"},
        compile: (item, lang) => {
            if(!item)  return shopRoot[lang];
            return `${shopRoot[lang]}/${item.slug}`;
        },
    },
    /*action: {
        path: [`${actionRoot[0]}/:cat1?/:cat2?/:cat3?`, `${actionRoot[1]}/:cat1?/:cat2?/:cat3?`, `${actionRoot[2]}/:cat1?/:cat2?/:cat3?`],
        component: Shop,
        middleware: 'public.PublicMiddleware',
        seo: {key: "products", title: "AKCIJA strana"},
        compile: (item, lang) => {
            if(!item)  return actionRoot[lang];
            return `${actionRoot[lang]}/${item.slug}`;
        },
    },*/
    shopProduct: {
        path: [`${shopProductRoot[0]}/:id/:slug?`, `${shopProductRoot[1]}/:id/:slug?`, `${shopProductRoot[2]}/:id/:slug?`],
        component: ShopProduct,
        middleware: 'public.PublicMiddleware',
        compile: (item, lang) => {
            if (!item) return shopProductRoot[lang];
            return `${shopProductRoot[lang]}/${item.id}/${slugify(item.name)}`;
        },
    },
}
const cmsLeftMenu = [
    {path: routes.products.path, name: "Proizvodi", position: 2},
];

const exports = {
    routes: routes,
    cmsLeftMenu: cmsLeftMenu,
    widgets: {
        ProductRender: ProductRender,
        ProductsCarousel: ProductsCarousel,
        LoadProductsCarousel: LoadProductsCarousel,
        ProductsDrawer: ProductsDrawer,
        SearchModal: SearchModal,
    }


}
export default exports;