import React from "react";
import {Trash2} from "react-feather";
import {useMutation} from "@apollo/client";
import {DELETE_BLOCK_BANNER, UPSERT_BLOCK_BANNER} from "../../graphql/banner";
import toast from "react-hot-toast";
import CmsUpdateField from "../../../../app/CmsUpdateField";
import {widget} from "../../../modulesCompiler";


const BlockBannerItem = ({item, handler, refetch}) => {
    const [deleteBanner] = useMutation(DELETE_BLOCK_BANNER);

    const deleteItem = () => {
        if(!window.confirm("Zelite da obrišete baner?"))  {
            return false;
        }
        deleteBanner({variables: {id: item.id}}).then(response => {
            toast.success("Obrisano!");
            refetch();
        }).catch(error => {
            toast.error(error.message);
        });
    }

    return (
        <div className="p-3 bg-light border">
            {!item.active &&
            <div className="alert alert-danger p-2">Baner nije aktiviran i nece se prikazivati.</div>}
            <div className="position-absolute top-0 end-0"> {handler} </div>
            <div className="row">
                <div className="col-md-2">
                    <div className="fw-bold text-center mb-2">DESKTOP</div>

                    {widget("lang.LangCmsUpdateImage", {
                        id: item.id,
                        MUTATION: UPSERT_BLOCK_BANNER,
                        name: "image_id",
                        refetch: refetch,
                        values: item.images,
                    })}
                </div>
                <div className="col-md-2">
                    <div className="fw-bold text-center mb-2">MOBILNI</div>

                    {widget("lang.LangCmsUpdateImage", {
                        id: item.id,
                        MUTATION: UPSERT_BLOCK_BANNER,
                        name: "mobile_image_id",
                        refetch: refetch,
                        values: item.mobile_images,
                    })}
                </div>
                <div className="col-md-5">
                    <div className="mb-1 fw-bold">Opis (alt) slike. </div>
                    <div className="mb-3">
                        {widget("lang.LangCmsUpdateField", {
                            element: "text",
                            id: item.id,
                            MUTATION: UPSERT_BLOCK_BANNER,
                            name: "alt",
                            refetch: refetch,
                            nullable: true,
                            placeholder: "Unesite kratak opis slike.",
                            values: item.alts
                        })}
                    </div>

                    <div className="mb-1 fw-bold">Link slike. </div>
                    {widget("lang.LangCmsUpdateField", {
                        element: "text",
                        id: item.id,
                        MUTATION: UPSERT_BLOCK_BANNER,
                        name: "link",
                        refetch: refetch,
                        nullable: true,
                        placeholder: "Unesite link slike.",
                        values: item.links
                    })}
                </div>
                <div className="col-md-3">
                    <CmsUpdateField
                        element="checkbox"
                        id={item.id}
                        MUTATION={UPSERT_BLOCK_BANNER}
                        name="active"
                        nullable={true}
                        value={item.active}
                        onTrue="Baner je aktiviran"
                        onFalse="Baner je deaktiviran"
                        className="mb-3" />

                    <CmsUpdateField
                        element="checkbox"
                        id={item.id}
                        MUTATION={UPSERT_BLOCK_BANNER}
                        name="mobile"
                        nullable={true}
                        value={item.mobile}
                        onTrue="Prikazuj na mobilnom"
                        onFalse="Prikazuj na mobilnom"
                        className="mb-3" />

                    <CmsUpdateField
                        element="checkbox"
                        id={item.id}
                        MUTATION={UPSERT_BLOCK_BANNER}
                        name="blank"
                        nullable={true}
                        value={item.blank}
                        onTrue="Otvaraj u novom prozoru"
                        onFalse="Otvaraj u novom prozoru"
                        className="mb-3" />

                    <button onClick={deleteItem} className="btn btn-light btn-sm">
                        <Trash2 size={16} />  Obrišite
                    </button>
                </div>
            </div>
        </div>
    )
}

export default BlockBannerItem;
