import React, {useRef, useState} from "react";
import {EditorState, RichUtils, convertToRaw, convertFromRaw} from "draft-js";
import Editor, { composeDecorators } from '@draft-js-plugins/editor';
import BlockStyleControls from "./BlockStyleControls";
import InlineStyleControls from "./InlineStyleControls";

import createImagePlugin from '@draft-js-plugins/image';
import createAlignmentPlugin from '@draft-js-plugins/alignment';
import createFocusPlugin from '@draft-js-plugins/focus';
import createResizeablePlugin from '@draft-js-plugins/resizeable';
import createBlockDndPlugin from '@draft-js-plugins/drag-n-drop';
import createInlineToolbarPlugin from '@draft-js-plugins/inline-toolbar';
import createLinkPlugin from '@draft-js-plugins/anchor';
import createUndoPlugin from '@draft-js-plugins/undo';
import {ItalicButton, BoldButton, UnderlineButton} from '@draft-js-plugins/buttons';

import '@draft-js-plugins/image/lib/plugin.css';
import '@draft-js-plugins/alignment/lib/plugin.css';
import '@draft-js-plugins/focus/lib/plugin.css';
import '@draft-js-plugins/anchor/lib/plugin.css';
import '@draft-js-plugins/inline-toolbar/lib/plugin.css';
import '@draft-js-plugins/undo/lib/plugin.css';
import {widget} from "../../../modulesCompiler";

const focusPlugin = createFocusPlugin();
const resizeablePlugin = createResizeablePlugin();
const blockDndPlugin = createBlockDndPlugin();
const alignmentPlugin = createAlignmentPlugin();
const { AlignmentTool } = alignmentPlugin;

const decorator = composeDecorators(
    resizeablePlugin.decorator,
    alignmentPlugin.decorator,
    focusPlugin.decorator,
    blockDndPlugin.decorator
);
const imagePlugin = createImagePlugin({ decorator });

const linkPlugin = createLinkPlugin({
    placeholder: 'kopirajte link i pritisnite enter',
    linkTarget: '_blank',
    validateUrl: () => { return true }
});
const inlineToolbarPlugin = createInlineToolbarPlugin();
const { InlineToolbar } = inlineToolbarPlugin;

const undoPlugin = createUndoPlugin();
const { UndoButton, RedoButton } = undoPlugin;


function RichTextEditor({onChange, onSave, content, readOnly}) {
    const refEditor = useRef();

    const [imageDrawer, setImageDrawer] = useState(false);
    const insertImageCallback = (images) => {
        if (images.length === 0) return false;
        const newState = imagePlugin.addImage(editorState, widget("storage.image")(images[0]));
        changed(newState);
    }

    const [different, setDifferent] = useState(false);
    const [editorState, setEditorState] = React.useState(
        content
            ? () => EditorState.createWithContent(convertFromRaw(content))
            : () => EditorState.createEmpty(),
    );

    const changed = (newEditorState) => {
        if(JSON.stringify(convertToRaw(newEditorState.getCurrentContent())) !== JSON.stringify(convertToRaw(editorState.getCurrentContent())))  {
            setDifferent(true);
        }

        setEditorState(newEditorState);
        if(onChange)    {
            onChange(convertToRaw(newEditorState.getCurrentContent()))
        }
    }
    const saved = () => {
        if(!different) return false;
        setDifferent(false);
        if(onSave)    {
            onSave(convertToRaw(editorState.getCurrentContent()))
        }
    }

    const toggleBlockType = (blockType) => {
        if (blockType === "image") {
            setImageDrawer(true);
            return false;
        }
        changed(
            RichUtils.toggleBlockType(
                editorState,
                blockType
            )
        );
    }
    const toggleInlineStyle = (inlineStyle) => {
        changed(
            RichUtils.toggleInlineStyle(
                editorState,
                inlineStyle
            )
        );
    }
    function getBlockStyle(block) {
        switch (block.getType()) {
            case 'blockquote':
                return 'RichEditor-blockquote';
            default:
                return null;
        }
    }

    let className = 'RichEditor-editor';
    var contentState = editorState.getCurrentContent();
    if (!contentState.hasText()) {
        if (contentState.getBlockMap().first().getType() !== 'unstyled') {
            className += ' RichEditor-hidePlaceholder';
        }
    }

    const editor = () => {
        return <Editor
            readOnly={readOnly}
            blockStyleFn={getBlockStyle}
            customStyleMap={{
                OSWALD: {
                    fontFamily: 'Oswald',
                    fontWeight: 'bold'
                },
                CENTER: {
                    display: "block",
                    textAlign: 'center',
                },
                RIGHT: {
                    display: "block",
                    textAlign: "right",
                }
            }}
            editorState={editorState}
            //handleKeyCommand={this.handleKeyCommand}
            onChange={changed}
            onBlur={saved}
            ref={refEditor}
            spellCheck={false}
            plugins={[
                blockDndPlugin,
                focusPlugin,
                alignmentPlugin,
                resizeablePlugin,
                imagePlugin,
                inlineToolbarPlugin,
                linkPlugin,
                //undoPlugin
            ]}>
        </Editor>
    }

    if(readOnly)    {
        return editor();
    }

    return <>
        {widget("storage.StorageDrawer",
            {open: imageDrawer, close: () => setImageDrawer(false), callback: insertImageCallback})}

        <div className="RichEditor-root">
            <BlockStyleControls
                editorState={editorState}
                onToggle={toggleBlockType}
            />

            <InlineStyleControls
                editorState={editorState}
                onToggle={toggleInlineStyle}
            />

            <div className={className} onClick={() => refEditor.current.focus()}>
                {editor()}
                <AlignmentTool />
                <InlineToolbar>
                    {
                        (externalProps) => (
                            <div>
                                <BoldButton {...externalProps} />
                                <ItalicButton {...externalProps} />
                                <UnderlineButton {...externalProps} />
                                <linkPlugin.LinkButton {...externalProps} />
                            </div>
                        )
                    }
                </InlineToolbar>
                <div className="mt-3 d-none">
                    <UndoButton /> <RedoButton />
                </div>
            </div>
            {onSave && different &&
            <div className="mt-3">
                <button className="btn btn-success" onClick={saved}> Sačuvajte </button>
            </div>}
        </div>
    </>
}

export default RichTextEditor;