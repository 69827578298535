import {gql} from "@apollo/client";
import {PRODUCT_FRAGMENT} from "../../products/graphql/ProductFragment";


export const GET_LOG_BASKET_ADD_QUERY = "getLogBasketAdd";
export const GET_LOG_BASKET_ADD = gql`
    ${PRODUCT_FRAGMENT}
    query($first: Int, $page: Int) {
        getLogBasketAdd(first: $first, page: $page) {
            data {
                id
                product_id
                product {
                    ...ProductFragment
                }
                total
            }
            paginatorInfo {
                currentPage
                lastPage
                count
                total
            }
        }
    }
`

export const CREATE_LOG_BASKET_ADD = gql`
    mutation ($product_id: ID!)  {
        createLogBasketAdd(product_id: $product_id) {
            id
            product_id
        }
    }
`

export const GET_LOG_PRODUCT_VIEWS_QUERY = "getLogProductViews";
export const GET_LOG_PRODUCTS_VIEW = gql`
    ${PRODUCT_FRAGMENT}
    query($first: Int, $page: Int) {
        getLogProductViews(first: $first, page: $page) {
            data {
                id
                product_id
                product {
                    ...ProductFragment
                }
                total
            }
            paginatorInfo {
                currentPage
                lastPage
                count
                total
            }
        }
    }
`

export const CREATE_LOG_PRODUCT_VIEW = gql`
    mutation ($product_id: ID!)  {
        createLogProductView(product_id: $product_id) {
            id
            product_id
        }
    }
`
