import React, {useState} from "react";
import Blocks from "./Blocks";

const Design = (props) => {
    const [code, setCode] = useState("index");

    return (
        <div className="container">

            <div className="mb-4">
                <button onClick={() => setCode("index")} className="btn btn-light me-2">
                    NASLOVNA STRANA
                </button>
                <button onClick={() => setCode("about")} className="btn btn-light me-2">
                    O NAMA STRANA
                </button>
                <button onClick={() => setCode("places")} className="btn btn-light">
                    PRODAJNA MESTA
                </button>
            </div>

            {code === "index"
            && <Blocks code={code} />}
            {code === "about"
            && <Blocks code={code} />}
            {code === "places"
            &&  <Blocks code={code} />}
        </div>
    )
}

export default Design;
