import {options} from "../config";
import CmsUpdateField from "../../../app/CmsUpdateField";
import {useState} from "react";


function LangCmsUpdateField(props)   {
    const [tab, setTab] = useState(0);

    if(options.length === 1)    {
        return (
            <CmsUpdateField {...props} value={props.values ? props.values[options[0].id] : null} />
        )
    }

    if(props.tabs)  {
        return (
            <>
                <div className="d-flex mb-2">
                    {options.map((item, i) => {
                        return <button onClick={() => setTab(i)}
                                       key={item.short} className={`btn ${i===tab ? "btn-primary" : "btn-secondary"} me-1`}>
                            <div className="me-2" style={{whiteSpace: "nowrap"}}>
                                {item.label} <span className="fs-s"> {item.name} </span>
                            </div>
                        </button>
                    })}
                </div>
                {options.map((item, i) => {
                      if(i !== tab) return null;
                      return  <CmsUpdateField
                          key={item.short}
                          {...props}
                          lang={item.id}
                          value={props.values ? props.values[item.id] : null} />
                })}
            </>


        )
    }

    return (
        options.map(item => {
            return <div key={item.short} className="mb-1 bg-light p-1 d-flex align-items-center">
                <div className="me-2" style={{whiteSpace: "nowrap"}}>{item.label}</div>
                <div className="flex-grow-1">
                    <CmsUpdateField
                        {...props}
                        lang={item.id}
                        value={props.values ? props.values[item.id] : null} />
                </div>
            </div>
        })
    )
}
export default LangCmsUpdateField;