
export function server(path) {
    if(path.startsWith("/")) path = path.substring(1);
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        //return `https://new.horus.rs/${path}`;
        return `http://127.0.0.1:5014/${path}`;
    }
    return `https://horus.rs/${path}`;
}
export function graphQL() {
    return server("graphql");
}

export const urlQueryOptions = {
    page: {values: ["strana", "страна", "page"], integer: true, default: 1},
    order: {values: ["red", "ред", "order"], integer: true, default: 1},
    filters: {values: ["filteri", "филтери", "filters"], array: true, default: undefined},
}

export const roles = {
    1: {name: "Administrator", role: 1},
    2: {name: "Menadžer", role: 2},
    3: {name: "Editor ", role: 3},
}
