import {useQuery} from "@apollo/client";
import {GET_BLOCKS, GET_BLOCKS_QUERY} from "../graphql/blocks";
import Loader from "../../../app/Loader";
import GraphQLErrorMessage from "../../../app/GraphQLErrorMessage";
import React from "react";
import IndexSlider from "../components/IndexSlider";
import Banner from "../components/Banner";
import {widget} from "../../modulesCompiler";
import BannersCarousel from "../components/BannersCarousel";

function BlocksRender({code}) {
    const { data, loading, error } =  useQuery(GET_BLOCKS, {
        variables: {code: code}
    });

    if(loading) return <Loader />
    if(error)   return <GraphQLErrorMessage error={error} />
    if(!data)   return null;
    const blocks = data[GET_BLOCKS_QUERY];

    return (
        <>
            {blocks.map(item => {
                if(!item.active) return null;
                return <div key={item.id} className="mb-6">
                    {item.title  &&
                    <div className="container mb-4">
                        <h3 className="oswald fw-bold text-uppercase">{item.title}</h3>
                    </div>}

                    {item.type === 1 &&
                    <div className="">
                        {item.banners.length === 1
                            ? <Banner banner={item.banners[0]} />
                            : <IndexSlider banners={item.banners} />}
                    </div>}

                    {item.type === 2 &&
                    <div className="container">
                        {item.var_2
                            ? <BannersCarousel count={item.var_1 || 5} slides={item.banners} />
                            : <div className="row">
                                {item.banners.map((banner, i) => {
                                    if(!banner.active) return null;
                                    let className = "col-md";
                                    if(item.banners.length > 3) {
                                        className = "col-md col-6";
                                    }
                                    return <>
                                        {item.var_1 && i !== 0 && ((i % parseInt(item.var_1)) === 0) &&
                                        <div className="w-100" />}
                                        <div key={banner.id} className={`${className} col-md mb-3 mb-md-0`}>
                                            <Banner banner={banner} className="img_overlay" />
                                        </div>
                                    </>
                                })}
                            </div>}
                    </div>}

                    {item.type === 3 && item.products && item.products.length > 0 &&
                    <div className="container">
                        {widget("products.ProductsCarousel", {products: item.products})}
                    </div>}

                    {item.type === 4 &&
                    <div className="container">
                        {widget("products.LoadProductsCarousel",
                            {variables: {
                                category: item.category?.category_id || undefined,
                                first: item.category?.max || undefined,
                                price: item.category?.price ? parseFloat(item.category?.price) : undefined,
                            }})}
                    </div>}

                    {item.type === 5 && item.categories && item.categories.length > 0 &&
                    <div className="container">
                        {widget("categories.CategoriesCarousel", {categories: item.categories})}
                    </div>}

                    {item.type === 6 && item.text &&
                    <div className="container">
                        <h2 className="oswald text-center mb-5 h3">
                            {item.text.text}
                        </h2>
                    </div>}

                    {item.type === 7 && item.text &&
                    <div className="container mb-5">
                        {widget("textEditor.Editor", {content: JSON.parse(item.text.text), readOnly: true})}
                    </div>}
                </div>
            })}

        </>
    )
}
export default BlocksRender;