import {route, trans, widget} from "../../../modulesCompiler";
import React from "react";
import {Calendar} from "react-feather";
import {dateTime} from "../../../../app/helpers";


function BlogRender({blog})  {
    const blogBreadcrumbs = {trans: trans("blog"), route: route("blog.blogs")};

    return <div>
        {widget("public.Breadcrumbs", {links: [blogBreadcrumbs, {trans: blog.title}]})}
        <div className="container mb-6">
            <h1 className="oswald mb-3">{blog.title}</h1>
            <div className="text-black-50 mb-4">
                <Calendar /> {dateTime(blog.created_at)}
            </div>
            {widget("textEditor.Editor", {content: JSON.parse(blog.content), readOnly: true})}
        </div>

    </div>
}
export default BlogRender;