import React from "react";
import {useQuery} from "@apollo/client";
import {GET_PRODUCT, GET_PRODUCT_QUERY, UPSERT_PRODUCT} from "../../graphql/product";
import Loader from "../../../../app/Loader";
import GraphQLErrorMessage from "../../../../app/GraphQLErrorMessage";
import ConnectedProducts from "./ConnectedProducts";
import {widget} from "../../../modulesCompiler";
import CmsUpdateField from "../../../../app/CmsUpdateField";
import GroupProducts from "./GroupProducts";
import ProductImages from "./ProductImages";
import {youtubeLink} from "../../../../app/helpers";


const Product = ({id}) => {
    const { data, loading, error, refetch } =  useQuery(GET_PRODUCT, {
        //variables: {id: props.computedMatch?.params?.id}
        variables: {id: id, all: true},
        fetchPolicy: 'network-only'
    });
    if(loading) return <Loader />
    if(error)   return <GraphQLErrorMessage error={error} />
    const product = data[GET_PRODUCT_QUERY];

    return (
        <div className="container">
            <div className="row mb-4">
                <div className="col-md-3">
                    <div className="bg-white rounded-3 p-3">
                        {widget("storage.UpdateImage", {
                            id: product.id,
                            MUTATION: UPSERT_PRODUCT,
                            name: "image_id",
                            refetch: refetch,
                            item: product.image})}

                        <div className="mt-3 border-top pt-2">
                            <strong className="mb-2"> Tabela veličina: </strong>
                            {widget("storage.UpdateImage", {
                                id: product.id,
                                MUTATION: UPSERT_PRODUCT,
                                width:100,
                                name: "sizes_image_id",
                                refetch: refetch,
                                item: product.sizes_image})}
                        </div>
                    </div>
                </div>
                <div className="col-md-9">
                    <div className="bg-white rounded-3 p-3">
                        <div className="mb-3">
                            <strong className="mb-2"> Naziv proizvoda: </strong>
                            <div>
                                {widget("lang.LangCmsUpdateField", {
                                    element: "text",
                                    id: product.id,
                                    MUTATION: UPSERT_PRODUCT,
                                    name: "name",
                                    required: true,
                                    values: product.names
                                })}
                            </div>
                        </div>
                        <div className="mb-3">
                            <strong className="mb-2"> Sifra: </strong>
                            <div>
                                <CmsUpdateField
                                    placeholder="Šifra"
                                    element="text" nullable={true}
                                    id={product.id} MUTATION={UPSERT_PRODUCT}
                                    name="code" value={product.code} />
                            </div>
                        </div>
                        <div className="mb-3 row">
                            <div className="col-md-6">
                                <strong className="mb-2"> Cena: </strong>
                                <div>
                                    <div>
                                        {widget("lang.LangCmsUpdateField", {
                                            element: "text",
                                            id: product.id,
                                            MUTATION: UPSERT_PRODUCT,
                                            name: "price",
                                            price: true,
                                            nullable: true,
                                            values: product.prices
                                        })}
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <strong className="mb-2"> Cena na popustu: </strong>
                                <div>
                                    <div>
                                        {widget("lang.LangCmsUpdateField", {
                                            element: "text",
                                            id: product.id,
                                            MUTATION: UPSERT_PRODUCT,
                                            name: "discount_price",
                                            price: true,
                                            nullable: true,
                                            values: product.discount_prices
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="mb-3 row">
                            <div className="col-md-6">
                                <strong className="mb-2"> Akcija: </strong>
                                <div>
                                    <CmsUpdateField
                                        element="checkbox"
                                        id={product.id}
                                        MUTATION={UPSERT_PRODUCT}
                                        name="action"
                                        value={product.action}
                                        onTrue="AKCIJA"
                                        onFalse="AKCIJA"
                                        />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <strong className="mb-2"> NOVO: </strong>
                                <div>
                                    <CmsUpdateField
                                        element="checkbox"
                                        id={product.id}
                                        MUTATION={UPSERT_PRODUCT}
                                        name="new"
                                        value={product.new}
                                        onTrue="NOVO"
                                        onFalse="NOVO"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="bg-white rounded p-3 mb-4">
                <div className="">
                    <h5 className="mb-3"> BEDŽEVI </h5>
                    {["Cotton", "Cotton_polyester", "Leather", "Polyester", "Ripstop", "Twill_keper",
                        "Very_cold_weather", "Waterproof", "Water_repellent", "Windproof"].map(item => {
                        return  <div className="d-inline-block me-3 mb-3">
                            <div className="d-flex align-items-center">
                                <img src={`/badges/${item}.png`} width={40} alt={item} className="me-1" />
                                <CmsUpdateField
                                    element="checkbox"
                                    id={product.id}
                                    MUTATION={UPSERT_PRODUCT}
                                    name={item}
                                    value={product[item]}
                                    onTrue={item}
                                    onFalse={item}
                                />
                            </div>
                        </div>
                    })}
                </div>
            </div>
            <div className="bg-white rounded p-3 mb-4">
                <h5 className="mb-2"> GRUPA PROIZVODA </h5>
                <div className="text-black-50 fs-s fst-italic">
                    Unesite istu šifru za vise proizvoda da biste dodali u grupu.
                </div>
                <GroupProducts product={product} refetch={refetch} />
            </div>

            <div className="bg-white rounded p-3 mb-4">
                <h5 className="mb-2"> GALERIJA </h5>
                <div>
                    <ProductImages product={product} refetch={refetch} />
                </div>
            </div>

            <div className="bg-white rounded p-3 mb-4">
                <h5 className="mb-3"> KATEGORIJE </h5>
                {widget("categories.ProductCategories", {product, refetch})}
            </div>

            <div className="bg-white rounded p-3 mb-4">
                <h5 className="mb-3"> FILTERI </h5>
                {widget("filters.ProductFilters", {product, refetch})}
            </div>

            <div className="bg-white rounded p-3 mb-4">
                <h5 className="mb-3"> YOUTUBE VIDEO  </h5>
                <div>
                    <CmsUpdateField
                        placeholder="Youtube link"
                        refetch={refetch}
                        element="text" nullable={true}
                        id={product.id} MUTATION={UPSERT_PRODUCT}
                        name="video" value={product.video} />
                </div>
                {product.video &&
                    <div className="mt-3 row">
                        <div className="col-md-5">
                            <iframe title={product.name} width="100%" height="315" src={youtubeLink(product.video)} frameBorder="0" allowFullScreen />
                        </div>
                    </div>}
            </div>

            <div className="bg-white rounded p-3 mb-4">
                <h5 className="mb-3"> POVEZANI PROIZVODI </h5>
                <ConnectedProducts product={product} refetch={refetch} />
            </div>

            <div className="bg-white rounded p-3 mb-4">
                <h5 className="mb-2"> OPIS PROIZVODA </h5>
                <div>
                    {widget("lang.LangCmsUpdateField", {
                        element: "textarea",
                        id: product.id,
                        MUTATION: UPSERT_PRODUCT,
                        name: "description",
                        refetch: refetch,
                        nullable: true,
                        values: product.descriptions
                    })}
                </div>
            </div>

            <div className="bg-white rounded p-3 mb-4">
                <div className="h5">SEO TITLE</div>
                {widget("lang.LangCmsUpdateField", {
                    element: "textarea",
                    id: product.id,
                    MUTATION: UPSERT_PRODUCT,
                    name: "seo_title",
                    refetch: refetch,
                    nullable: true,
                    values: product.seo_titles
                })}

                <div className="h5 mt-3">SEO KEYWORDS</div>
                {widget("lang.LangCmsUpdateField", {
                    element: "textarea",
                    id: product.id,
                    MUTATION: UPSERT_PRODUCT,
                    name: "seo_keywords",
                    refetch: refetch,
                    nullable: true,
                    values: product.seo_keywords_all
                })}

                <div className="h5 mt-3">SEO DESCRIPTION</div>
                {widget("lang.LangCmsUpdateField", {
                    element: "textarea",
                    id: product.id,
                    MUTATION: UPSERT_PRODUCT,
                    name: "seo_description",
                    refetch: refetch,
                    nullable: true,
                    values: product.seo_descriptions
                })}
            </div>

        </div>
    )
}

export default Product;
