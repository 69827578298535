import React, {useState} from 'react';
import {useMutation} from "@apollo/client";
import {UPSERT_FILTER, DELETE_FILTER} from "../../graphql/filter";
import AddFilterForm from "./AddFilterForm";
import {Settings, Trash2} from "react-feather";
import toast from "react-hot-toast";
import {widget} from "../../../modulesCompiler";



function FilterItem({item, refetch, collapseIcon, handler}) {
    const [settings, setOpen] = useState(false);
    const [deleteFilter] = useMutation(DELETE_FILTER);
    const mutation = UPSERT_FILTER;

    const deleteItem = (e) => {
        if(!window.confirm("Želite da obrišete filter?"))  {
            return false;
        }
        deleteFilter({variables: {id: item.id}}).then(response => {
            setOpen(false);
            toast.success("Obrisano.");
            refetch();
        }).catch(error => {
            toast.error(error.message);
        });
    }

    return  <div>
        <div className="d-flex justify-content-between align-items-center">
            <div>
                {collapseIcon}
                {handler}
                {item.name} {item.count && <span className="text-black-50 fs-s"> ({item.count}) </span>}
            </div>
            <button onClick={() => setOpen(!settings)} className="btn btn-light btn-sm">
                <Settings size={16} />
            </button>
        </div>
        {settings &&
        <div className="mt-2 border-top p-3">
            <div className="row">
                <div className="col-md-6">
                    <div className="mb-3">
                        <div className="h5">NAZIV</div>
                        {widget("lang.LangCmsUpdateField", {
                            element: "text",
                            id: item.id,
                            MUTATION: mutation,
                            name: "name",
                            refetch: refetch,
                            nullable: true,
                            values: item.names
                        })}
                    </div>
                    <div className="mb-3">
                        <AddFilterForm parent={item} refetch={refetch} />
                    </div>
                    {!item.locked &&
                    <button onClick={deleteItem} className="btn btn-light">
                        <Trash2 size={16} />  Obrišite
                    </button>}
                </div>
            </div>
        </div>}
    </div>
}
export default FilterItem;
