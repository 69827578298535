import GlobalSettingsWrapper from "./middlewares/GlobalSettingsWrapper";
import useGetSetting from "./widgets/useGetSetting";
import FieldUpdate from "./widgets/FieldUpdate";
import Settings from "./views/settings/Settings";

const routes = {
    settings: {path: "/cms/podesavanja", roles: [1,2],  component: Settings, middleware: 'cms.AdminAuthenticatedMiddleware'},
}
const cmsLeftMenu = [
    {path: routes.settings.path, name: "Podešavanja", roles: [1,2], position: 8},
];

const exports = {
    routes: routes,
    cmsLeftMenu: cmsLeftMenu,
    wrappers: [GlobalSettingsWrapper],
    widgets: {
        useGetSetting: useGetSetting,
        FieldUpdate: FieldUpdate
    }

}
export default exports;