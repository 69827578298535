
export const types = {
    1: {type: 1, name: "veliki slajder"},
    2: {type: 2, name: "baneri"},
    3: {type: 3, name: "carousel proizvoda"},
    4: {type: 4, name: "carousel proizvoda (kategorija)"},
    5: {type: 5, name: "kategorije u carousel-u"},
    6: {type: 6, name: "naslov (tekst)"},
    7: {type: 7, name: "TEXT editor"},
};
