import React, {useState} from "react";
import {dateTime, formatPrice, fromNow} from "../../../../app/helpers";
import {widget} from "../../../modulesCompiler";
import {X} from "react-feather";
import Drawer from "rc-drawer";
import Order from "../order/Order";


const OrderItem = ({item, refetch}) => {
    const [open, setOpen] = useState(false);

    const close = () => {
        setOpen(false);
    }
    const currency = item.currency;

    return (
        <>
            <Drawer
                open={open}
                onClose={close}
                width="90%"
                placement="right"
                level={null}
                handler={false}>
                <div className="pt-3 position-relative">
                    <button className="btn btn-danger position-absolute" style={{top: 15, right: 20}}
                            onClick={close}>
                        <X />
                    </button>

                    {open &&
                    <Order item={item} />}
                </div>
            </Drawer>

            <div onClick={() => setOpen(true)} className={`p-3 row pointer ${item.seen ? "" : "bg-unseen"}`}>
                <div className="col-md-4">
                    <div className="mb-2">
                        {dateTime(item.created_at)}
                        <div className="text-black-50">{fromNow(item.created_at)}</div>
                    </div>
                    {item.code &&
                    <div className="mb-2">#{item.code}</div>}
                    <div className="fw-bold">
                        {item.promo_code
                            ? <div className="fw-bold">
                                <div className="fs-s">
                                    <del>{formatPrice(item.full_price)} {currency}</del>
                                </div>
                                <div className="fs-s">
                                    PROMO KOD: {item.promo_code} ({item.promo_code_discount}%)
                                </div>
                                <div>
                                    Cena: {formatPrice(item.full_price - ((item.full_price/100) * item.promo_code_discount))} {currency}
                                </div>
                            </div>
                            : <strong>Cena: {formatPrice(item.full_price)} {currency}</strong>}
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="mb-1">
                        {item.name}  {item.lastname}
                    </div>
                    <div className="mb-1">
                        {item.email}
                    </div>
                    <div className="mb-1">
                        {item.phone}
                    </div>
                    <div className="mb-1">
                        {item.address}
                    </div>
                    <div className="mb-1">
                        {item.zip} {item.city}, {item.country}
                    </div>

                </div>
                <div className="col-md-4">
                    {item.orderProducts.map(orderProduct => {
                        const product = orderProduct.product;
                        return <div key={orderProduct.id} className="d-flex fs-s align-items-center mb-2">
                            <div style={{width: 60}}>
                                <img src={widget("storage.image", product.image)} alt={product.name} className="img-fluid" />
                            </div>
                            <div className="ms-2">
                                <strong> {product.name} </strong>
                                <div className=""> {orderProduct.price} {currency} </div>
                                <div className=""> količina: <strong>{orderProduct.qty}</strong> </div>
                            </div>
                        </div>
                    })}

                </div>
            </div>
        </>
    )
}

export default OrderItem;
