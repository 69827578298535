import AdminAuthenticatedMiddleware from "./middlewares/AdminAuthenticatedMiddleware";
import Login from "./views/login/Login";
import AdminNotAuthenticatedMiddleware from "./middlewares/AdminNotAuthenticatedMiddleware";
import Administrators from "./views/administrators/Administrators";
import Pagination from "./widgets/Pagination";
import Dashboard from "./views/dashboard/Dashboard";
import DragHandler from "./widgets/DragHandler";
import {providerCheckAdmin} from "./adminProvider";
import Error404 from "./views/layout/Error404";
import AdminGuard from "./widgets/AdminGuard";

const routes = {
    dashboard: {path: "/cms/dashboard", component: Dashboard, middleware: 'cms.AdminAuthenticatedMiddleware'},
    administrators: {path: "/cms/administratori", roles: [1,2], component: Administrators, middleware: 'cms.AdminAuthenticatedMiddleware'},
    login: {path: "/cms", component: Login, middleware: 'cms.AdminNotAuthenticatedMiddleware'},
    all: {path: "/cms/*", component: Error404, middleware: 'cms.AdminAuthenticatedMiddleware'},
};
const cmsLeftMenu = [
    {path: routes.administrators.path, roles: [1,2], name: "Administratori"},
];

const exports = {
    routes: routes,
    cmsLeftMenu: cmsLeftMenu,
    widgets: {
        AdminAuthenticatedMiddleware: AdminAuthenticatedMiddleware,
        AdminNotAuthenticatedMiddleware: AdminNotAuthenticatedMiddleware,
        Pagination: Pagination,
        DragHandler: DragHandler,
        providerCheckAdmin: providerCheckAdmin,
        AdminGuard: AdminGuard
    }
}
export default exports;