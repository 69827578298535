import Loader from "../../../app/Loader";
import {useQuery} from "@apollo/client";
import {GET_FILTERS, GET_FILTERS_QUERY} from "../graphql/filter";
import GraphQLErrorMessage from "../../../app/GraphQLErrorMessage";
import React from "react";
import slugify from "slugify";
import useGetSearchParams from "../../../app/useGetSearchParams";


function CategoryDrawer({category, url}) {
    const [filters] = useGetSearchParams(["filters"]);

    const { data, loading, error } =  useQuery(GET_FILTERS, {
        variables: {category_id: category.id}
    });
    if(loading) return <Loader />
    if(error)   return <GraphQLErrorMessage error={error} />
    const dataFilters = data[GET_FILTERS_QUERY];

    const has = dataFilters.map(item => {
       if(!item.parent) return false;
       if(item.children.length > 0) return false;
       return item.id;
    });

    const used = [];
    if(filters) {
        const spl = filters.split(",");
        for(let i=0; i<spl.length; i++) {
            const fil = spl[i].split("-");
            if(!fil[1]) continue;
            used.push(fil[1]);
        }
    }

    const checkbox = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        url(name, value);
    }

    return (
        <div>
            {dataFilters.map(item => {
                if(item.parent) return null;
                return <div key={item.id} className="mb-3">
                    <div className="oswald p-1 ps-2 bg-light">
                        {item.name}
                    </div>

                    {item.children.map(child => {
                        if(!has.includes(child.id)) return null;
                        return <label key={child.id} className="oswald d-inline-block w-100 ps-4 p-1">
                            <input type="checkbox" name="filters" checked={used.includes(child.id)}
                                   value={`${item.id}-${child.id}-${slugify(child.name)}`} className="me-2"
                                   onChange={checkbox}
                            /> {child.name}

                            <span className="text-black-50 fs-s ms-1 d-inline-block">
                                {/*({child.category_count})*/}
                           </span>
                        </label>
                    })}
                </div>
            })}
        </div>
    )

}
export default CategoryDrawer;