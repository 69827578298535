import React, {useState} from "react";
import {trans, widget} from "../../../modulesCompiler";
import ReCAPTCHA from "react-google-recaptcha";
import {Download, Loader, Send} from "react-feather";
import Map from "./Map";
import useGetSetting from "../../../settings/widgets/useGetSetting";
import {gql, useMutation} from "@apollo/client";
import toast from "react-hot-toast";
import GraphQLErrorField from "../../../../app/GraphQLErrorField";
import GraphQLErrorMessage from "../../../../app/GraphQLErrorMessage";

const CONTACT_FORM_QUERY = "contactForm";
const CONTACT_FORM = gql`
    mutation ($name: String!, $email: String!, $message: String!, $recaptcha: String!)  {
        contactForm(name: $name, email: $email, message: $message, recaptcha: $recaptcha) {
            error
            success
        }
    }
`


function Contact() {
    const defaultForm = {email: "", name: "", message: "", recaptcha: ""};
    const [form, setForm] = useState(defaultForm);
    const contact = useGetSetting("contact");
    const [contactForm, {error, loading}] = useMutation(CONTACT_FORM);

    const changeField = (e) => {
        const name = e.target.name;
        let value = e.target.value;
        setForm({...form, [name]: value});
    };
    const onChangeRecaptcha = (key) => {
        setForm({...form, recaptcha: key});
    }

    const submit = (e) => {
        e.preventDefault();
        contactForm({variables: form}).then(response => {
            const data = response.data[CONTACT_FORM_QUERY];
            if(data.success)    {
                toast.success("Poslato!");
                setForm(defaultForm);
                return false;
            }
            if(data.error)  {
                toast.error(data.error);
            }

        }).catch(error => {

        });
    }


    return (
        <>
            {widget("public.Breadcrumbs", {links: [{trans: trans("kontakt")}]})}
            <div className="container mb-6">

                <div className="row gx-md-5 mb-5">
                    <div className="col-md-6">
                        <h3 className="oswald fw-bold text-uppercase mb-4">{trans("kontaktirajte nas")}</h3>
                        <form className="mb-md-0 mb-3" action="#" method="post" onSubmit={submit}>
                            <div className="mb-3">
                                <input onChange={changeField} value={form.name}
                                       type="text" name="name" className="form-control form-control-lg"
                                       placeholder={trans("ime")} required />
                                <GraphQLErrorField error={error} field="name" />
                            </div>
                            <div className="mb-3">
                                <input type="text" onChange={changeField} value={form.email}
                                       name="email" className="form-control form-control-lg"
                                       placeholder={trans("email")} required />
                                <GraphQLErrorField error={error} field="email" />
                            </div>
                            <div className="mb-3">
                            <textarea onChange={changeField} value={form.message}
                                      name="message" className="form-control form-control-lg"
                                      placeholder={trans("poruka")} />
                                <GraphQLErrorField error={error} field="message" />
                            </div>

                            <div className="mb-3">
                                <ReCAPTCHA
                                    sitekey="6LdfTZAcAAAAAIT0Me9II-BwW6kYdWXP6ACwFkQs"
                                    onChange={onChangeRecaptcha} />
                                <GraphQLErrorField error={error} field="recaptcha" />
                            </div>

                            <div className="">
                                <button disabled={loading} className="btn ps-3 pe-3 btn-primary" type="submit">
                                    {loading ? <Loader  className="rotate" /> : <Send />}  {trans("posaljite")} </button>
                            </div>
                            <GraphQLErrorMessage error={error} />
                        </form>
                    </div>
                    <div className="col-md-6">
                        <h3 className="oswald fw-bold text-uppercase mb-4">{trans("adresa")}</h3>
                        <div className="prewrap lh-lg links_secondary mb-3"
                             dangerouslySetInnerHTML={{__html: contact.value}} />
                        <div>
                            <a href="/Obrazac-za-identifikaciju-Horus-Wear.pdf" target="_blank"  rel="noreferrer">
                                <Download /> {trans("obrazac za identifikaciju")}
                            </a>
                        </div>
                    </div>
                </div>

                <Map />
            </div>
        </>
    )
}
export default Contact;