import {useMutation} from "@apollo/client";
import React, {useState} from "react";
import GraphQLErrorField from "../../../../app/GraphQLErrorField";
import {PlusCircle} from "react-feather";
import GraphQLErrorMessage from "../../../../app/GraphQLErrorMessage";
import toast from "react-hot-toast";
import {CREATE_BLOCK} from "../../graphql/blog";


const AddBlockForm = ({refetch}) => {
    const [open, setOpen] = useState(false);
    const [title, setTitle] = useState("");
    const [createBlog, {error, loading}] = useMutation(CREATE_BLOCK);

    const submit = (e) => {
        e.preventDefault();
        createBlog({variables: {title: title}}).then(response => {
            setTitle("");
            setOpen(false);
            if(refetch) {
                refetch();
            }
            toast.success("Sačuvano!");
        }).catch(error => {
            toast.error(error.message);
        });
    }

    return (
        <div>
            <div className="mb-3">
                {!open &&
                <button onClick={() => setOpen(!open)} className="btn btn-success">
                    <PlusCircle size={16} /> Dodajte vest
                </button>}
                {open &&
                <form onSubmit={submit} className="bg-white shadow mb-4 mt-3 p-3 rounded border border-success border-3">
                    <div className="mb-3">
                        <strong className="mb-2"> Naslov: </strong>
                        <input onChange={(e) => setTitle(e.target.value)}
                               type="text" name="name" value={title} className="form-control" required />
                        <GraphQLErrorField error={error} field="name" />
                    </div>
                    <button disabled={loading} className="btn btn-success me-2"> Sačuvajte </button>
                    <button onClick={() => setOpen(false)} type="button" className="btn btn-light">
                        Zatvorite
                    </button>
                    <GraphQLErrorMessage error={error} />
                </form>}
            </div>

        </div>
    )
}

export default AddBlockForm;
