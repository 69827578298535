import {useMutation} from "@apollo/client";
import {route, trans, widget} from "../../../modulesCompiler";
import {processPrice, youtubeLink} from "../../../../app/helpers";
import ProductsCarousel from "../../widgets/ProductsCarousel";
import ShopProductGallery from "./ShopProductGallery";
import {Link} from "react-router-dom";
import {CREATE_LOG_PRODUCT_VIEW} from "../../../orders/graphql/logs";
import React, {useEffect} from "react";
import {
    FacebookIcon,
    FacebookShareButton,
    LinkedinIcon,
    LinkedinShareButton, TelegramIcon, TelegramShareButton,
    TwitterIcon,
    TwitterShareButton, ViberIcon, ViberShareButton, WhatsappIcon, WhatsappShareButton
} from "react-share";


function ShopProductRender({product})  {
    const currency = trans("currency");
    const transColors = trans("druge boje");
    const [logProductView] = useMutation(CREATE_LOG_PRODUCT_VIEW);
    const shopBreadcrumbs = {trans: trans("shop"), route: route("products.shop")};

    useEffect(() => {
        logProductView({variables: {product_id: product.id}});
    }, [product, logProductView]);

    const catBread = product.categories.map(item => {
        return {trans: item.name, route: route("products.shop", item)};
    });

    return <div>
        {widget("public.Breadcrumbs", {links: [shopBreadcrumbs, ...catBread, {trans: product.name}]})}
        <div className="container mb-5">

            <div className="row g-md-5">
                <div className="col-md-5 mb-4">
                    <ShopProductGallery image={product.image} images={product.images} />

                    {product.video &&
                    <div className="mt-4">
                        <div className="oswald h4 fw-bold mb-3"> Youtube video</div>
                        <iframe title={product.name} width="100%" height="315" src={youtubeLink(product.video)} frameBorder="0" allowFullScreen />
                    </div>}
                </div>

                <div className="col-md-1" />
                <div className="col-md-6">
                    <h1 className="oswald fw-bold h3 mb-3">{product.name}</h1>

                    <div>
                        {["Cotton", "Cotton_polyester", "Leather", "Polyester", "Ripstop", "Twill_keper",
                            "Very_cold_weather", "Waterproof", "Water_repellent", "Windproof"].map(item => {
                            if(!product[item]) return null;
                            return  <div className="d-inline-block" title={item}>
                                <img src={`/badges/${item}.png`} width={60} alt={item} className="me-1" />
                            </div>
                        })}
                    </div>

                    {product.description &&
                    <div className="mb-3"
                         dangerouslySetInnerHTML={{__html: product.description}} />}
                    <div className="oswald fw-bold h3 border-bottom pb-3 mb-4">
                        {processPrice(product) &&
                        <span className="me-2">{processPrice(product)} {currency}</span>}
                        {product.discount_price &&
                        <del className="text-black-50 h4">{product.price} {currency}</del>}
                    </div>

                    {product.group.length > 0 &&
                    <div className="mt-3">
                        <div className="oswald fw-bold h5 mb-3">
                            {transColors}
                        </div>
                        <div className="">
                            {product.group.map(gr => {
                                if(product.id === gr.id) return null;
                                return <div key={gr.id} className="mb-2 me-2 d-inline-block border hover_border p-1" style={{width: 100}}>
                                    <Link to={route("products.shopProduct", gr)}>
                                        <div className="bcg_image"
                                             style={{backgroundImage: `url(${widget("storage.image", gr.image)})`}} />
                                    </Link>
                                </div>
                            })}
                        </div>
                    </div>}

                    <div className="mt-3 mb-4">
                        {widget("orders.ProductForm", {item: product, full: true})}
                    </div>

                    <div className="mb-3">
                        <div className="mb-1">share: </div>
                        <FacebookShareButton url={window.location.href} title={product.name} className="me-1">
                            <FacebookIcon size={42} round={true} />
                        </FacebookShareButton>

                        <TwitterShareButton url={window.location.href} title={product.name} className="me-1">
                            <TwitterIcon size={42} round={true} />
                        </TwitterShareButton>

                        <LinkedinShareButton url={window.location.href} title={product.name} className="me-1">
                            <LinkedinIcon size={42} round={true} />
                        </LinkedinShareButton>

                        <TelegramShareButton url={window.location.href} title={product.name} className="me-1">
                            <TelegramIcon size={42} round={true} />
                        </TelegramShareButton>

                        <ViberShareButton url={window.location.href} title={product.name} className="me-1">
                            <ViberIcon size={42} round={true} />
                        </ViberShareButton>

                        <WhatsappShareButton url={window.location.href} title={product.name}>
                            <WhatsappIcon size={42} round={true} />
                        </WhatsappShareButton>
                    </div>

                </div>
            </div>
        </div>


        {product.connected.length > 0 &&
        <div className="container mb-6">
            <div className="mb-4">
                <h4 className="fw-bold mb-3">POVEZANI PROIZVODI</h4>
            </div>
            <ProductsCarousel products={product.connected} />
        </div>}
    </div>
}
export default ShopProductRender;