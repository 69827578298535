import React, {useContext, useState} from "react";
import {route, trans, widget} from "../../../modulesCompiler";
import BasketContext from "../../contexts/BasketContext";
import {ShoppingBag, X} from "react-feather";
import {formatPrice} from "../../../../app/helpers";
import ProductForm from "../../widgets/ProductForm";
import {useLazyQuery, useMutation} from "@apollo/client";
import {PLACE_ORDER, PLACE_ORDER_MUTATION} from "../../graphql/order";
import GraphQLErrorMessage from "../../../../app/GraphQLErrorMessage";
import toast from "react-hot-toast";
import GraphQLErrorField from "../../../../app/GraphQLErrorField";
import {payment_method} from "../../orderProvider";
import Loader from "../../../../app/Loader";
import {useHistory} from "react-router-dom";
import {GET_PROMO_CODE, GET_PROMO_CODE_QUERY} from "../../graphql/promo";


function Checkout() {
    const history = useHistory();
    const {basket, total, remove, empty} = useContext(BasketContext);
    const currency = trans("currency");
    const [error, setError] = useState(false);
    const lang = widget("lang.useGetLang")();
    const defaultForm = {name: "", lastname: "", email: "", phone: "", address: "", zip: "", city: "", country: "",
        message: "", payment_method: lang === 2 ? 2 : 1, currency: currency,
        promo: "", newsletter: ""};
    const [form, setForm] = useState(defaultForm);
    const [placeOrder, placeResponse] = useMutation(PLACE_ORDER);
    const [checkPromoCode, promoCodeResponse] = useLazyQuery(GET_PROMO_CODE, {
        variables: {code: form.promo},
        fetchPolicy: "network-only"
    });
    const routeThankYou = route("orders.status");
    let promoCode = false;
    if(promoCodeResponse.data)    {
        promoCode = promoCodeResponse.data[GET_PROMO_CODE_QUERY];
    }

    const updateForm = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setForm({...form, [name]: value});
    }
    const updateCheckbox = (e) => {
        const name = e.target.name;
        const value = e.target.checked ? e.target.value : "";
        setForm({...form, [name]: value});
    }
    const submit = (e) => {
        e.preventDefault();
        const bask = basket.map(item => {
            return {product_id: item.product.id, qty: item.qty, filters: item.filters};
        });
        setError(false);
        placeOrder({variables: {...form, basket: JSON.stringify(bask)}}).then(response => {
            const resp = response.data[PLACE_ORDER_MUTATION];
            if(resp.error)   {
                setError(resp.error);
                return false;
            }
            empty();
            history.push(routeThankYou + "/" + resp.path + "/thank-you");
        }).catch(error => {
            toast.error(error.message);
        });
    }

    const promoCodeInputKeyDown = (e) => {
        if(e.keyCode === 13) {
            e.preventDefault();
            checkPromoCode();
        }
    }
    return (
        <div>
            {widget("public.Breadcrumbs", {links: [{trans: "Checkout"}]})}

            <div className="container mb-6">
                {basket.length === 0
                    ? <div className="h5 text-center bg-white rounded-3 p-4">
                        <ShoppingBag size={34} />
                        <div className="mt-2">
                            {trans("korpa je prazna")}
                        </div>
                    </div>
                    : <div>
                        <div className="d-none d-md-block">
                            <table className="table table-bordered bg-white rounded-3 mb-4 text-center">
                                <thead>
                                <tr className="fw-bold">
                                    <td>{trans("br")}</td>
                                    <td style={{width: 140}}>{trans("slika")}</td>
                                    <td>{trans("artikal")}</td>
                                    <td>{trans("cena")}</td>
                                    <td style={{width: 140}}>{trans("kolicina")}</td>
                                    <td>{trans("ukupno")}</td>
                                </tr>
                                </thead>
                                <tbody>
                                {basket.map((item, index) => {
                                    const product = item.product;
                                    return <tr key={item.id}>
                                        <td className="align-middle">{index + 1}.</td>
                                        <td className="align-middle">
                                            <div className="me-3" style={{width: 140}}>
                                                <div className="bcg_image" style={{backgroundImage: `url(${widget("storage.image", product.image)})`}} />
                                            </div>
                                        </td>
                                        <td className="fw-bold align-middle oswald">{product.name}</td>
                                        <td className="align-middle oswald fw-bold">
                                            {formatPrice(product.process_price)} {currency}
                                        </td>
                                        <td className="align-middle">
                                            <ProductForm item={product} basket={item} />
                                        </td>
                                        <td className="fw-bold align-middle">
                                            {formatPrice(product.process_price * item.qty)} {currency}
                                            <div className="mt-2">
                                                <button onClick={() => remove(item.id)} className="btn btn-light shadow-sm">
                                                    <X /> {trans("uklonite")}
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                })}
                                </tbody>
                                <tfoot>
                                <tr className="fw-bold h5">
                                    <td colSpan={5} className="text-end p-4">
                                        {trans("ukupno sa pdv-om")}:
                                    </td>
                                    <td className="text-danger p-4">
                                        {formatPrice(total())} {currency}
                                    </td>
                                </tr>
                                </tfoot>
                            </table>
                        </div>

                        <div className="d-md-none mb-4">
                            {basket.map((item, index) => {
                                const product = item.product;
                                return <div key={item.id} className="mb-2 pb-2 border-bottom">
                                    <div className="mb-2 d-flex">
                                        <div className="me-2" style={{flex: "100px 0 0"}}>
                                            <div className="bcg_image" style={{backgroundImage: `url(${widget("storage.image", product.image)})`}} />
                                        </div>
                                        <div>
                                            <div className="oswald h3 mb-2">{product.name}</div>
                                            <div className="oswald mb-3">
                                                {formatPrice(product.process_price)} {currency}
                                            </div>
                                            <div>
                                                <ProductForm item={product} basket={item} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-between">
                                        <div className="fw-bold text-danger">
                                            {formatPrice(product.process_price * item.qty)} {currency}
                                        </div>
                                        <div className="mt-2">
                                            <button onClick={() => remove(item.id)} className="btn btn-light shadow-sm">
                                                <X /> {trans("uklonite")}
                                            </button>
                                        </div>
                                    </div>
                                </div>})}
                            <div className="fw-bold h4 text-danger p-3 bg-light text-center">
                                {trans("ukupno")}:  {formatPrice(total())} {currency}
                            </div>
                        </div>

                        <form onSubmit={submit} action="/">
                            <div className="row g-md-5 mb-3 align-content-stretch">
                                <div className="col-md-6 mb-5">
                                    <h4 className="oswald fw-bold mb-3">
                                        {trans("informacije za porucivanje")}
                                    </h4>

                                    <div className="text-danger fst-italic mb-2">
                                        {trans("obavezna polja")}
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6 mb-3">
                                            {trans("ime")} <span className="text-danger"> * </span>
                                            <input value={form.name} onChange={updateForm}
                                                name="name" className="form-control" required />
                                            <GraphQLErrorField error={placeResponse.error} field="name" />
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            {trans("prezime")} <span className="text-danger"> * </span>
                                            <input  value={form.lastname} onChange={updateForm}
                                                name="lastname" className="form-control" required />
                                            <GraphQLErrorField error={placeResponse.error} field="lastname" />
                                        </div>

                                        <div className="col-md-6 mb-3">
                                            Email <span className="text-danger"> * </span>
                                            <input value={form.email} onChange={updateForm}
                                                   type="email" name="email" className="form-control" required />
                                            <GraphQLErrorField error={placeResponse.error} field="email" />
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            {trans("telefon")} <span className="text-danger"> * </span>
                                            <input  value={form.phone} onChange={updateForm}
                                                    name="phone" className="form-control" required />
                                            <GraphQLErrorField error={placeResponse.error} field="phone" />
                                        </div>

                                        <div className="col-md-12 mb-3">
                                            {trans("zemlja")} <span className="text-danger"> * </span>
                                            <input  value={form.country} onChange={updateForm}
                                                    name="country" className="form-control" required />
                                            <GraphQLErrorField error={placeResponse.error} field="country" />
                                        </div>

                                        <div className="col-md-12 mb-3">
                                            {trans("adresa")} <span className="text-danger"> * </span>
                                            <input  value={form.address} onChange={updateForm}
                                                    name="address" className="form-control" required />
                                            <GraphQLErrorField error={placeResponse.error} field="address" />
                                        </div>

                                        <div className="col-md-4 mb-3">
                                            {trans("postanski broj")} <span className="text-danger"> * </span>
                                            <input  value={form.zip} onChange={updateForm}
                                                    name="zip" className="form-control" required />
                                            <GraphQLErrorField error={placeResponse.error} field="zip" />
                                        </div>

                                        <div className="col-md-8 mb-3">
                                            {trans("grad")} <span className="text-danger"> * </span>
                                            <input value={form.city} onChange={updateForm}
                                                    name="city" className="form-control" required />
                                            <GraphQLErrorField error={placeResponse.error} field="city" />
                                        </div>
                                        <div className="col-md-12">
                                            {trans("dodatna napomena")}
                                            <textarea className="form-control h-100"
                                                      name="message" value={form.message}
                                                      onChange={updateForm} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <h4 className="oswald fw-bold mb-3">
                                        {trans("dostava")}
                                    </h4>
                                    {lang === 2 &&
                                    <div className="mb-4">
                                        You can pay via bank wire transfer or PayPal. If all goods are in stock, you will receive a
                                        Proforma Invoice with the prices of the goods, transport costs and payment instructions.
                                        The buyer shall bear and pay the full cost, including all banking charges.
                                    </div>}
                                    {lang !== 2 &&<div style={{whiteSpace: "pre-wrap"}} className="mb-4 links_secondary">{trans("dostava desc")}</div>}
                                    <h4 className="oswald fw-bold mb-3">
                                        {trans("nacin placanja")}
                                    </h4>
                                    <div className="mb-3">
                                        {lang !== 2 &&
                                        <label className="mb-3 p-3 bg-light w-100">
                                            <input value={1} checked={form.payment_method === 1}
                                                   onChange={() => setForm({...form, payment_method: 1})}
                                                type="radio" name="payment_method" />
                                            <span className="ms-1 fs-5 oswald"> {trans(payment_method(1))} </span><br />
                                            <span className="fst-italic mt-2 d-inline-block">
                                                {trans("placanje 1 desc")}
                                            </span>
                                        </label>}


                                        {lang === 2 &&
                                        <label className="p-3 bg-light w-100">
                                            <input value={4} checked={form.payment_method === 4}
                                                   onChange={() => setForm({...form, payment_method: 4})}
                                                   type="radio" name="payment_method" />
                                            <span className="ms-1 fs-5 oswald"> {payment_method(4)} </span><br />
                                        </label>}

                                        <label className="p-3 bg-light w-100">
                                            <input value={2} checked={form.payment_method === 2}
                                                   onChange={() => setForm({...form, payment_method: 2})}
                                                type="radio" name="payment_method" />
                                            <span className="ms-1 fs-5 oswald"> {trans(payment_method(2))} </span><br />
                                            <span className="fst-italic mt-2 d-inline-block">
                                                {trans("placanje 2 desc")}
                                            </span>
                                        </label>
                                    </div>

                                    <div className="fw-bold oswald h4 text-danger p-3 bg-light mb-4">

                                        {promoCode
                                            ? <div>
                                                <div className="mb-2">
                                                    <del>{trans("ukupno")}:  {formatPrice(total())} {currency}</del>
                                                </div>
                                                <div className="mb-2 fw-normal"> Promo kod: {promoCode.code} ({promoCode.discount}%) </div>
                                                <div>{trans("ukupno")}:  {formatPrice(total() - ((total()/100)*promoCode.discount))} {currency} </div>
                                            </div>
                                            : <div>{trans("ukupno sa pdv-om")}:  {formatPrice(total())} {currency}</div>}
                                    </div>

                                    <div className="fw-bold h4 oswald">
                                        {trans("promo kod")}
                                    </div>
                                    <div className="mb-4 oswald fw-bold">
                                        <div className="input-group input-group-lg" style={{maxWidth: 420}}>
                                            <input type="text" className="form-control shadow-none"
                                                   onKeyDown={promoCodeInputKeyDown} onChange={updateForm} autoComplete="off"
                                               name="promo" value={form.promo} placeholder={trans("unesite promo kod")} />
                                            <div className="input-group-append">
                                                <button onClick={checkPromoCode} disabled={!form.promo}
                                                    className="btn btn-primary btn-lg" type="button">
                                                    {trans("proverite kod")}
                                                </button>
                                            </div>
                                        </div>
                                        {promoCode === null &&
                                        <div className="alert alert-danger mt-2">
                                            Ne postoji PROMO KOD.
                                        </div>}
                                        {form.promo &&
                                        <GraphQLErrorField error={promoCodeResponse.error} field="code" />}
                                        <GraphQLErrorField error={placeResponse.error} field="promo" />
                                    </div>

                                    <div className="mb-3">
                                        <label className="fw-bold">
                                            <input
                                                type="checkbox"
                                                onChange={updateCheckbox}
                                                name="newsletter"
                                                checked={form.newsletter}
                                                value={1} />
                                            &nbsp; {trans("newsletter desc")}
                                        </label>
                                    </div>

                                    <div className="d-flex align-items-center">
                                        <button disabled={placeResponse.loading} type="submit"
                                                className="btn btn-secondary btn-lg me-3">
                                          {trans("posalji porudzbinu")}
                                        </button>
                                        {placeResponse.loading && <Loader height={40} />}
                                    </div>
                                </div>
                            </div>
                            <GraphQLErrorMessage error={placeResponse.error || promoCodeResponse.error} />
                            {error &&
                            <div className="alert alert-danger mt-3">{error}</div>}
                        </form>
                    </div>}
            </div>
        </div>
    )
}
export default Checkout;