import React from "react";
import PublicMiddleware from "./middlewares/PublicMiddleware";
import Error404 from "./widgets/Error404";
import Welcome from "./views/welcome/Welcome";
import About from "./views/about/About";
import Contact from "./views/contact/Contact";
import Breadcrumbs from "./widgets/Breadcrumbs";
import Pagination from "./widgets/Pagination";
import UsloviKoriscenja from "./views/faq/UsloviKoriscenja";
import PravoNaOdustajanje from "./views/faq/PravoNaOdustajanje";
import KakoKupiti from "./views/faq/KakoKupiti";
import UsloviIsporuke from "./views/faq/UsloviIsporuke";
import PolitikaPrivatnosti from "./views/faq/PolitikaPrivatnosti";
import Reklamacije from "./views/faq/Reklamacije";
import Places from "./views/places/Places";
import PolitikaKolacica from "./views/faq/PolitikaKolacica";


const exports = {
    routes: {
        index: {path: ["/", "/насловна", "/home"], seo: {key: "index", title: "NASLOVNA strana"}, component: Welcome, middleware: "public.PublicMiddleware"},
        about: {path: ["/o-nama", "/о-нама", "/about-us"], seo: {key: "about", title: "O NAMA strana"}, component: About, middleware: "public.PublicMiddleware"},
        places: {path: ["/prodajna-mesta", "/продајна-места", "/selling-places"], seo: {key: "places", title: "PRODAJNA MESTA strana"}, component: Places, middleware: "public.PublicMiddleware"},
        contact: {path: ["/kontakt", "/контакт", "/contact"], seo: {key: "contact", title: "KONTAKT strana"}, component: Contact, middleware: "public.PublicMiddleware"},

        politikaPrivatnosti: {path: ["/politika-privatnosti", "/политика-приватности", "/privacy-policy"], component: PolitikaPrivatnosti, middleware: "public.PublicMiddleware"},
        usloviKoriscenja: {path: ["/uslovi-koriscenja", "/услови-коришћења", "/terms-of-use"], component: UsloviKoriscenja, middleware: "public.PublicMiddleware"},
        kakoKupiti: {path: ["/kako-kupiti", "/како-купити", "/how-to-buy"], component: KakoKupiti, middleware: "public.PublicMiddleware"},
        politikaKolacica: {path: ["/politika-kolacica", "/политика-колачића", "/cookie-policy"], component: PolitikaKolacica,  middleware: "public.PublicMiddleware"},
        pravoNaOdustajanje: {path: ["/pravo-na-odustajanje", "/право-на-одустајање", "/right-of-withdrawal-refund"], component: PravoNaOdustajanje, middleware: "public.PublicMiddleware"},
        usloviIsporuke: {path: ["/uslovi-isporuke", "/услови-испоруке", "/delivery"], component: UsloviIsporuke, middleware: "public.PublicMiddleware"},
        reklamacije: {path: ["/reklamacije", "/рекламацијe", "/complaints"], component: Reklamacije, middleware: "public.PublicMiddleware"},

        error: {path: "*", component: Error404, middleware: "public.PublicMiddleware"},
    },
    widgets: {
        PublicMiddleware: PublicMiddleware,
        PublicLayout: React.lazy(() => import("./views/layout/PublicLayout")),
        Error404: Error404,
        Breadcrumbs: Breadcrumbs,
        Pagination: Pagination,
    }
}
export default exports;