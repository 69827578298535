import React from "react";
import {useQuery} from "@apollo/client";
import Loader from "../../../../app/Loader";
import GraphQLErrorMessage from "../../../../app/GraphQLErrorMessage";
import {GET_FILTERS, GET_FILTERS_QUERY} from "../../graphql/filter";
import ProductFilterItem from "./ProductFilterItem";



function ProductFilters({product, refetch, size}) {
    const { data, loading, error} =  useQuery(GET_FILTERS, {
        variables: {parent: null}
    });

    if(loading) return <Loader />
    if(error)   return <GraphQLErrorMessage error={error} />
    const filters = data[GET_FILTERS_QUERY];

    return  (
        <div>
            {filters.map(filter => {
                if(size)    {
                    if(parseInt(filter.id) !== 2) return null;
                }
                return <div key={filter.id} className={size ? "" : "mb-3"}>
                        <ProductFilterItem
                            checked={product.filters}
                            filter={filter}
                            product_id={product.id}
                            refetch={refetch} />
                    </div>
            })}
        </div>
    )
}
export default ProductFilters;